import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import './MaterialForm.scss';
import {withSnackbar} from 'notistack';
import {withTheme} from '@mui/styles';
import {fetchMaterials} from '../../../../../../services/materials';
import {Autocomplete, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {fetchPartConfig} from '../../../../../../services/parts';
import {InsertLink, Search} from '@mui/icons-material';
import {isURL} from '../../../../../../utils/urlHelpers';
import {updatePartConfig} from '../../../../../../services/configs';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class MaterialForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedMaterial: null,
      materialIsUrl: false,
      materialUrl: '',
      selectedMaterialId: null,
      selectedMaterialHeatState: null,
      selectedMaterialFeatures: [],
      materials: [],
    };
  }

  getPartConfigAndUpdateState = async () => {
    const {data: partConfig} = await fetchPartConfig(this.props.partConfig.configId, {filter: {include: 'materialFeatures'}});

    this.setState({
      selectedMaterialId: partConfig.materialId,
      materialIsUrl: partConfig.materialIsUrl,
      materialUrl: partConfig.materialIsUrl ? partConfig.materialName : '',
      selectedMaterialHeatState: partConfig.materialHeatStateKey,
      selectedMaterialFeatures: partConfig.materialFeatures,
      selectedMaterial: this.state.materials.find(material => material.id === partConfig.materialId) || null,
    });
  };

  async componentDidMount() {
    try {
      const {data: materials} = await fetchMaterials({
        filter: {include: {materialNames: true, materialCategory: {include: {materialFeatures: true}}, materialHeatStates: true}},
      });

      this.setState({
        materials,
      });

      if (this.props.partConfig?.configId) {
        await this.getPartConfigAndUpdateState();
      }
    } catch (err) {}
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.partConfig.configId !== this.props.partConfig.configId) {
      try {
        await this.getPartConfigAndUpdateState();
      } catch (err) {}
    }
  }

  promptInvalidateMA = async () => {
    const {isConfirmed} = await MySwal.fire({
      title: <Typography variant={'h5'}>{this.props.t('pc_mdl_change_material_ma_alert')}</Typography>,
      icon: 'warning',
      confirmButtonText: '✓',
      showCancelButton: true,
      cancelButtonText: this.props.t('cancel'),
      reverseButtons: true,
      confirmButtonColor: '#32bee1',
    });

    return isConfirmed;
  };

  updatePartConfig = async (data, updateState = true) => {
    try {
      // Set the value on the backend
      await updatePartConfig(this.props.partConfig.configId, data);
    } catch (err) {
      if (err.response?.status === 420) {
        const isConfirmed = await this.promptInvalidateMA();
        if (isConfirmed) {
          await updatePartConfig(this.props.partConfig.configId, data, true);
        }
      } else {
        this.props.enqueueSnackbar(this.props.t('pc_mdl_material_save_error'), {variant: 'error'});
      }
    }

    if (updateState) {
      await this.getPartConfigAndUpdateState();
    }
  };

  handleMaterialUrlChange = async value => {
    if (!isURL(value)) return;

    await this.setState({loading: true});

    await this.updatePartConfig({
      partConfigMaterialFeatures: {deleteMany: {}},
    }, false);
    await this.updatePartConfig({
      materialIsUrl: true,
      materialName: value,
      materialId: null,
      materialHeatStateKey: null,
    });

    await this.setState({loading: false});
  };

  handleMaterialChange = async (field, value, reason = null, details = null) => {
    await this.setState({loading: true});

    switch (field) {
      case 'material':
        // If the material is being updated, then set the materialId and materialName to the new value and reset material heat state and features
        await this.updatePartConfig({
          partConfigMaterialFeatures: {
            deleteMany: {},
          },
        }, false);
        await this.updatePartConfig({
          materialId: value.id,
          materialName: value.key || value.materialNames[0]?.name,
          materialHeatStateKey: null,
          materialIsUrl: false,
        });
        break;
      case 'materialHeatState':
        await this.updatePartConfig({
          materialHeatStateKey: value,
        });
        break;
      case 'materialFeatures':
        switch (reason) {
          case 'selectOption':
            await this.updatePartConfig({
              partConfigMaterialFeatures: {
                create: {
                  materialFeatureKey: details.option.key,
                  materialCategoryKey: details.option.materialCategoryKey,
                },
              },
            });
            break;
          case 'removeOption':
            await this.updatePartConfig({
              partConfigMaterialFeatures: {
                deleteMany: {
                  materialFeatureKey: details.option.materialFeatureKey || details.option.key,
                },
              },
            });
            break;
          case 'clear':
            await this.updatePartConfig({
              partConfigMaterialFeatures: {
                deleteMany: {},
              },
            });
            break;
        }
        break;
    }

    await this.setState({loading: false});
  };

  render() {
    const {t, i18n} = this.props;

    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <ToggleButtonGroup
          disabled={this.state.loading}
          value={this.state.materialIsUrl}
          exclusive
          onChange={(e, value) => this.setState({materialIsUrl: value})}
        >
          <ToggleButton value={false}>
            <Search />
          </ToggleButton>
          <ToggleButton value={true}>
            <InsertLink />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {
        !this.state.materialIsUrl ?
          <>
            <Grid item xs={6}>
              <Autocomplete
                disabled={this.state.loading}
                options={this.state.materials}
                disableClearable
                renderInput={params => <TextField {...params} variant={'outlined'} label={t('material')} size={'small'} />}
                getOptionLabel={option => [option.key, ...option.materialNames.map(mn => mn.name)].filter(Boolean).join(' / ')}
                value={this.state.materials.find(material => material.id === this.state.selectedMaterialId) || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => this.handleMaterialChange('material', value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                renderInput={params => <TextField {...params} variant={'outlined'} label={t('material_heat_states')} size={'small'} />}
                options={this.state.selectedMaterial?.materialHeatStates.map(hs => hs.materialHeatStateKey) || []}
                disabled={!this.state.selectedMaterial?.materialHeatStates?.length || this.state.loading}
                value={this.state.selectedMaterialHeatState}
                getOptionLabel={option => i18n.exists(`material_heat_states:${option}`) ? t(`material_heat_states:${option}`) : option.toUpperCase()}
                onChange={(e, value) => this.handleMaterialChange('materialHeatState', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={params => <TextField {...params} variant={'outlined'} label={t('material_features')} size={'small'} />}
                ChipProps={{size: 'small'}}
                getOptionLabel={option => t(`material_features:${option.key || option.materialFeatureKey}`)}
                options={this.state.selectedMaterial?.materialCategory?.materialFeatures || []}
                multiple
                disabled={!this.state.selectedMaterial?.materialCategory?.materialFeatures || this.state.loading}
                isOptionEqualToValue={(option, value) => option.key === value.materialFeatureKey}
                value={this.state.selectedMaterialFeatures}
                onChange={(e, value, reason, details) => this.handleMaterialChange('materialFeatures', value, reason, details)}
              />
            </Grid>
          </>
          : <>
            <Grid item xs={12}>
              <TextField
                variant={'outlined'}
                label={this.props.t('material_url')}
                size={'small'}
                value={this.state.materialUrl}
                onChange={e => this.setState({materialUrl: e.target.value})}
                onBlur={e => this.handleMaterialUrlChange(e.target.value)}
                fullWidth
                helperText={this.state.materialUrl && !isURL(this.state.materialUrl) ? t('help_material_url_error') : t('help_material_url')}
                error={this.state.materialUrl && !isURL(this.state.materialUrl)}
                disabled={this.state.loading}
              />
            </Grid>
          </>
      }
    </Grid>;
  }
}

export default withTheme(withSnackbar(withTranslation(['partconfigurator', 'material_features', 'material_heat_states'])(MaterialForm)));
