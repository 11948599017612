import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logEvent} from 'utils/firebase';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Box,
  Tooltip,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import {getFirstPageLists} from 'store/general/generalActions';
import {assignConfigsToList} from 'services/part-lists';

import {Row} from './TableRow/TableRow';
import {ListModal} from 'components/Modals';

import './CollapseTable.scss';
import {useSnackbar} from 'notistack';
import {DeleteForever} from '@mui/icons-material';
import {DeleteAlert} from '../Alerts/DeleteAlert';

import {
  cancelManufacturingAnalysis,
  deletePartConfig,
} from '../../services/configs';

export function CollapseTable({
  editConfigId,
  openConfigModal,
  workMode,
  configs,
  refetchConfigs = () => {},
}) {
  const {t} = useTranslation(['partconfigurations']);
  const listPagination = useSelector(state => state.general.lists.pagination);
  const {enqueueSnackbar} = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const configIdQueryParam = new URLSearchParams(window.location.search).get('configId');

  useEffect(() => {
    openModalIfNeeded();
  }, [configIdQueryParam && !editConfigId]);

  const getListPage = event => {
    const pagination = listPagination;
    const listOptions = {limit: 4, order: 'createdAt DESC'};
    if (event.target.value === '<<' && pagination.currentPage > 0) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: pagination.currentPage - 1},
        }),
      );
    } else if (
      event.target.value === '>>' &&
      pagination.currentPage < pagination.totalPageCount
    ) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: pagination.currentPage + 1},
        }),
      );
    } else if (
      event.target.value === '>>' &&
      pagination.currentPage === pagination.totalPageCount
    ) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: pagination.currentPage},
        }),
      );
    } else {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: event.target.value},
        }),
      );
    }
  };

  const openModalIfNeeded = () => {
    if (configIdQueryParam && configs.some(config => config.id === parseInt(configIdQueryParam))) {
      editConfigId.current = parseInt(configIdQueryParam);
      openConfigModal(true);
    }
  };

  const handleListModalConfirm = async ({
    configId,
    selectedLists,
    endConfirmLoading,
  }) => {
    for (const list of selectedLists) {
      try {
        const result = await assignConfigsToList({
          listId: list.id,
          partConfigIdsList: [configId],
        });

        if (result.isAxiosError) {
          enqueueSnackbar(`CONFIG NOT SAVED TO ${list.name}`, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`CONFIG SAVED TO ${list.name}`, {
            variant: 'success',
          });
        }
      } catch (error) {
        enqueueSnackbar(`CONFIG NOT SAVED TO ${list.name}`, {
          variant: 'error',
        });
      }
    }

    endConfirmLoading();
  };

  const handleListRefresh = () => {
    dispatch(
      getFirstPageLists({filter: {limit: 3, order: 'createdAt DESC'}}),
    );
  };

  const [deletePartConfigAlertOpen, setDeletePartConfigAlertOpen] = useState(
    false,
  );
  const [configToDeleteId, setConfigToDeleteId] = useState(null);

  const runningMa = config => {
    return config.batchSizes.some(batchSize => batchSize.maRunning);
  };

  return (
    <>
      {workMode !== 'summary' && (
        <Button
          className="configuration-add-button"
          variant="outlined"
          onClick={() => {
            editConfigId.current = null;
            openConfigModal(true);
            logEvent('click_new_configuration');
          }}
        >
          {t('partconfigurations:new_configuration')}
        </Button>
      )}
      <TableContainer component={Paper} className="configuration-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{width: '10%'}}>
                {t('partconfigurations:t_id')}
              </TableCell>
              <TableCell style={{width: '20%'}}>
                {t('partconfigurations:t_name')}
              </TableCell>
              <TableCell style={{width: '30%'}}>
                {t('partconfigurations:t_config')}
              </TableCell>
              <TableCell style={{width: '25%'}}>
                {t('partconfigurations:t_analysis')}
              </TableCell>
              {workMode !== 'summary' && (
                <TableCell style={{width: '15%'}} align="center">
                  {t('partconfigurations:t_actions')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {configs && configs.length > 0 ? (
              configs.map((config, index) => (
                <Row
                  key={config.name + index}
                  row={config}
                  workMode={workMode}
                  actions={[
                    {
                      actionComponent: (
                        <ListModal
                          isIconButton
                          buttonSize="small"
                          saveLoadingMsg={t('modals:mdl_saving_list_loading')}
                          getListPage={getListPage}
                          onConfirm={event =>
                            handleListModalConfirm({
                              configId: config.id,
                              ...event,
                            })
                          }
                          onListRefresh={handleListRefresh}
                        />
                      ),
                    },
                    {
                      icon: () =>
                        runningMa(config) ? (
                          <Tooltip title={t('cancel_manufacturing_analysis')}>
                            <CancelIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('edit_config')}>
                            <EditIcon />
                          </Tooltip>
                        ),
                      reaction: async () => {
                        if (runningMa(config)) {
                          logEvent('click_cancel_manufacturing_analysis');
                          await cancelManufacturingAnalysis(config.id);
                          return refetchConfigs();
                        } else {
                          logEvent('click_edit_configuration');
                          const params = new URLSearchParams();
                          params.append('configId', config.id);
                          navigate({search: params.toString()});
                          editConfigId.current = config.id;
                          openConfigModal(true);
                        }
                      },
                    },
                    {
                      icon: ({color}) => (
                        <DeleteForever color={color || 'black'} />
                      ),
                      reaction: () => {
                        logEvent('click_delete_configuration');
                        setConfigToDeleteId(config.id);
                        setDeletePartConfigAlertOpen(true);
                      },
                    },
                  ]}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Box textAlign="center">
                    {t('partconfigurations:t_no_configs_available')}
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteAlert
        open={deletePartConfigAlertOpen}
        triggerDelete={() => deletePartConfig(configToDeleteId)}
        onCancel={() => {
          setConfigToDeleteId(null);
          setDeletePartConfigAlertOpen(false);
        }}
        onErrorConfirm={() => {
          setConfigToDeleteId(null);
          setDeletePartConfigAlertOpen(false);
        }}
        onSuccessConfirm={() => {
          setConfigToDeleteId(null);
          refetchConfigs();
          setDeletePartConfigAlertOpen(false);
        }}
        headerText={t('delete_config_header')}
        description={t('delete_config_description')}
      />
    </>
  );
}
