export const validatePassword = password => {
  if (!password) {
    return { error: true, msg: 'errors:error_password_required' };
  }

  const criteria = [
    password.length >= 12,
    password.search(/[A-Z]/g) > -1,
    password.search(/[0-9]/g) > -1,
    password.search(/[!@#$%^&*()\-_+=\[\]{}\\|;:'",.<>/?]/g) > -1,
  ];

  const valid = criteria.reduce((acc, next) => {
    if (acc && next) {
      return true;
    }
    return false;
  });

  if (valid) {
    return { error: false, msg: '' };
  }

  return {
    error: true,
    msg: 'errors:error_password_incorrect',
  };
};
