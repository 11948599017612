import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

import makeStyles from '@mui/styles/makeStyles';

import UploadFiles from 'components/Uploader/UploadFiles';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import '../parts-modals.scss';

const useStyles = makeStyles(styles);

export const UploadToListModal = ({ confirm, open }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const disableDone = useRef(false);

  const hideAlert = () => {
    if (disableDone.current) {
      return;
    }
    confirm(false);
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const uploadModal = () => {
    disableDone.current = false;
    setAlert(
      <SweetAlert
        title=""
        style={{ marginTop: -300, width: 500 }}
        customClass={'tracking-modal'}
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        confirmBtnText={t('mdl_general_upload_button')}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        <h3>{`${t('mdl_general_upload_title')}`}</h3>
        <UploadFiles
          url="/attachments/upload"
          width="100%"
          height={350}
          onUploadClose={hideAlert}
        />
        <br></br>
        <small>{t('mdl_general_upload_help')}</small>
        <br></br>
        <small>{t('mdl_general_images_generation_info')}</small>
      </SweetAlert>
    );
  };

  useEffect(() => {
    if (open) {
      uploadModal();
    } else {
      closeAlert();
    }
  }, [open]);

  return <div>{alert}</div>;
};
