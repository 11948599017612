import React, {Component} from 'react';

import UploadFiles from 'components/Uploader/UploadFiles';
import CreatePartListForm from 'components/Uploader/CreatePartList/CreatePartListForm';
import AutomaticConfiguration from 'components/AutomaticConfiguration/AutomaticConfiguration';

import {Fade, IconButton, Modal, Paper, Grid, Button, Typography} from '@mui/material';
import {Close} from '@mui/icons-material';
import {withTheme} from '@mui/styles';

import './UploadModal.scss';
import {fetchPartList} from 'services/part-lists';
import {withTranslation} from 'react-i18next';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class UploadModal extends Component {
  constructor (props) {
    super(props);

    this.defaultSate = {
      uploadedFiles: [],
      uploadComplete: false,
      configurationComplete: false,
      partListCreationComplete: false,
      skipAutomaticConfiguration: false,
      createdPartListId: null,
      createdPartList: null,
    };

    this.state = {
      ...this.defaultSate,
    };
  }

  setUploadedFiles = async uploadedFiles => {
    await this.setState({
      uploadedFiles,
    });
  };

  setCreatedPartList = async (partListId, skipAutomaticConfiguration) => {
    const createdPartList = await this.getCreatedPartList(partListId);

    await this.setState({
      createdPartList,
      createdPartListId: createdPartList.id,
      partListCreationComplete: true,
      skipAutomaticConfiguration,
    });
  };

  getCreatedPartList = async (partListId = null) => {
    const {data: partList} = await fetchPartList(partListId || this.state.createdPartList.id, {
      filter: {
        include: {partConfigs: [{part: {attachments: {currentFile: 'fileFormat'}}}, 'batchSizes']},
      },
    }, false);

    return partList;
  };

  refreshCreatedPartList = async () => {
    this.setState({
      createdPartList: await this.getCreatedPartList(),
    });
  };

  closeModal = async () => {
    // If files were actually uploaded, warn that closing would prevent easily creating
    // a part list and proceeding with automatic configuration

    const {t, listUpload, theme} = this.props;

    if (
      this.state.uploadedFiles.length && // files were uploaded
      this.state.uploadedFiles.some(f => f?.filesList?.some(innerF => !innerF.errorKeysList.length)) && // at least one of those files is not errored
      !listUpload && // we are not uploading to a part/part list
      (!this.state.partListCreationComplete || // part list creation is not complete 
        (!this.state.skipAutomaticConfiguration && !this.state.configurationComplete)) // or we are not skipping automatic configuration and configuration is not complete
    ) {
      const html = this.state.partListCreationComplete ?
        <Typography variant={'body1'}>{t('cpl_cancel_automatic_configuration_warning')}</Typography> :
        <Typography variant={'h5'}>{t('cpl_close_modal_warning')}</Typography>;

      const title = this.state.partListCreationComplete ?
        <Typography variant={'h5'}>{t('cpl_cancel_automatic_configuration_warning_title')}</Typography> : null;

      const {isConfirmed} = await MySwal.fire({
        title,
        html,
        icon: 'warning',
        confirmButtonText: this.state.partListCreationComplete ? t('cpl_close_and_cancel_automatic_configuration') : t('cpl_close'),
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.state.partListCreationComplete ? t('cpl_continue_with_automatic_configuration') : t('cpl_cancel'),
        reverseButtons: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonColor: theme.palette.secondary.main,
      });

      if (!isConfirmed) return;
    }

    this.props.confirm();
    this.props.setOpen(false);

    // Reset state
    this.setState(this.defaultSate);
  };

  setConfigurationComplete = async createdPartListId => {
    await this.setState({
      createdPartListId,
    });

    await this.refreshCreatedPartList();

    await this.setState({
      configurationComplete: true,
    });
  };

  render () {
    const {open, listUpload, url, t} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true}>
        <Fade in={open} timeout={250}>
          <div className={'upload-modal'}>
            <Paper className={'upload-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-upload-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              {!this.state.uploadComplete ? (
                <>
                  <Grid
                    justifyContent={'center'}
                    alignItems={'center'}
                    container
                    style={{height: '100%'}}
                  >
                    <Grid item alignContent={'center'} xs={12} style={{height: '100%'}}>
                      <UploadFiles url={url || '/parts/upload'} uploadedFiles={this.state.uploadedFiles} setUploadedFiles={this.setUploadedFiles} />
                    </Grid>
                  </Grid>
                  {this.state.uploadedFiles.length && this.state.uploadedFiles.some(f => f?.filesList?.some(innerF => !innerF.errorKeysList.length)) && !listUpload && (
                    <div style={{position: 'absolute', bottom: 16, width: '100%'}}>
                      <Grid container justifyContent={'center'}>
                        <Grid item>
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            style={{color: 'white'}}
                            size={'large'}
                            onClick={() => this.setState({uploadComplete: true})}
                          >
                            {t('cpl_continue')}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  ) || <></>}
                </>
              ) : !this.state.partListCreationComplete || this.state.skipAutomaticConfiguration ? (
                <Grid item alignContent={'center'} xs={12} style={{height: '100%'}}>
                  <CreatePartListForm
                    uploadedFiles={this.state.uploadedFiles}
                    setCreatedPartList={this.setCreatedPartList}
                  />
                </Grid>
              ) : (
                <>
                  {!this.state.configurationComplete ? (
                    <AutomaticConfiguration
                      createdPartList={this.state.createdPartList}
                      refreshCreatedPartList={this.refreshCreatedPartList}
                      setConfigurationComplete={this.setConfigurationComplete}
                    />
                  ) : (
                    <>
                      <Grid
                        spacing={2}
                        justifyContent={'center'}
                        alignItems={'center'}
                        container
                        style={{height: '100%'}}
                      >
                        <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
                          <CloudDoneIcon style={{fontSize: 160, color: '#4CAF50'}} />
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center', margin: '40px, 0'}} alignContent={'center'}>
                          <Typography variant={'h5'} style={{padding: '0 4rem'}}>{t('cpl_configuration_completed')}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
                          <Button
                            size={'large'}
                            color={'primary'}
                            style={{color: 'white'}}
                            variant={'contained'}
                            href={/part-lists/ + this.state.createdPartListId}
                          >
                            {t('cpl_created_list_go_to_list')}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Paper>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withTheme(withTranslation('fileuploaded')(UploadModal));