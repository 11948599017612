import {initializeApp} from 'firebase/app';
import {initializeAnalytics, logEvent as log, setUserProperties} from 'firebase/analytics';
import {initializePerformance} from 'firebase/performance';

let analytics = null;

export default function initializeFirebase(userEmail) {
  if (!process.env.REACT_APP_FIREBASE_APP_ID || !process.env.REACT_APP_FIREBASE_API_KEY) return;

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'kreatize-ms.firebaseapp.com',
    projectId: 'kreatize-ms',
    storageBucket: 'kreatize-ms.appspot.com',
    messagingSenderId: '371022294508',
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  const app = initializeApp(firebaseConfig);

  analytics = initializeAnalytics(app);
  setUserProperties(analytics, {user_email: userEmail});

  initializePerformance(app);
};

export function logEvent(title, params) {
  if (!analytics) {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;
    console.error('Firebase is not initialized, cannot make a call to logEvent');
    return;
  }

  return log(analytics, title, params);
}
