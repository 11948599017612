import {
  GET_PART,
  GET_PART_SUCCESS,
  GET_PART_ERROR,
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_ERROR,
  GET_LISTS_FOR_PART,
  GET_LISTS_FOR_PART_SUCCESS,
  GET_LISTS_FOR_PART_ERROR,
  GET_PART_ATTACHMENTS,
  GET_PART_ATTACHMENTS_SUCCESS,
  GET_PART_ATTACHMENTS_ERROR,
  PART_DETAILS_CLEANUP,
  EDIT_PART_NAME,
  UNASSIGN_PART_ATTACHMENT,
  UNASSIGN_PART_ATTACHMENT_ERROR,
  UNASSIGN_PART_ATTACHMENT_SUCCESS,
  UNASSIGN_PART_ATTACHMENT_CLEAN,
} from './PartDetailsTypes';

const INITIAL_STATE = {
  data: null,
  loading: true,
  error: null,
  listsForPart: null,
  listsForPartLoading: false,
  listsForPartError: null,
  features: null,
  featuresLoading: false,
  featuresError: null,
  attachments: null,
  attachmentsLoading: true,
  attachmentsError: null,
  unassignLoading: false,
  unassignError: false,
  unassignSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART:
      return { ...state, data: null, loading: true };

    case GET_PART_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case GET_PART_ERROR:
      return { ...state, error: action.payload, loading: false };

    case GET_FEATURES:
      return { ...state, features: null, featuresLoading: true };

    case GET_FEATURES_SUCCESS:
      return { ...state, features: action.payload, featuresLoading: false };

    case GET_FEATURES_ERROR:
      return {
        ...state,
        featuresError: action.payload,
        featuresLoading: false,
      };
    /**
     * Lists for part
     */
    case GET_LISTS_FOR_PART:
      return { ...state, listsForPart: null, listsForPartLoading: true };

    case GET_LISTS_FOR_PART_SUCCESS:
      return {
        ...state,
        listsForPart: action.payload,
        listsForPartLoading: false,
      };

    case GET_LISTS_FOR_PART_ERROR:
      return { ...state, listsForPartError: action.payload, loading: false };

    case GET_PART_ATTACHMENTS:
      return { ...state, attachments: null, attachmentsLoading: true };

    case GET_PART_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: action.payload,
        attachmentsLoading: false,
      };

    case GET_PART_ATTACHMENTS_ERROR:
      return {
        ...state,
        attachmentsError: action.payload,
        attachmentsLoading: false,
      };

    case PART_DETAILS_CLEANUP:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
        listsForPart: null,
        listsForPartLoading: false,
        listsForPartError: null,
        attachments: null,
        attachmentsLoading: false,
        attachmentsError: null,
      };

    case EDIT_PART_NAME:
      return {
        ...state,
        data: { ...state.data, name: action.payload },
      };

    case UNASSIGN_PART_ATTACHMENT:
      return {
        ...state,
        unassignLoading: true,
      };
    case UNASSIGN_PART_ATTACHMENT_SUCCESS:
      return {
        ...state,
        unassignLoading: false,
        unassignSuccess: true,
      };
    case UNASSIGN_PART_ATTACHMENT_ERROR:
      return {
        ...state,
        unassignLoading: false,
        unassignSuccess: false,
        unassignError: action.payload,
      };
    case UNASSIGN_PART_ATTACHMENT_CLEAN:
      return {
        ...state,
        selectedAttachment: null,
        unassignLoading: false,
        unassignSuccess: false,
        unassignError: false,
      };

    default:
      return state;
  }
};
