import React, { useContext } from 'react';

import { ConfigContext } from 'components/Modals/ConfigsModal/ConfigContext';
import { CHANGE_SUBSTEP } from 'components/Modals/ConfigsModal/ConfigTypes';
import CloseIcon from '@mui/icons-material/Close';
import './ConfigSubstep.scss';
import { StepType } from 'components/Modals/ConfigsModal/Models/StepTypeEnum';

export const ConfigSubstep = ({
  id,
  stepId,
  name,
  showRemoveBtn,
  onRemoveSubstepClick,
}) => {
  const { state, dispatch } = useContext(ConfigContext);

  const openSubstep = () => {
    dispatch({
      type: CHANGE_SUBSTEP,
      payload: {
        currentStep: stepId,
        currentSubstep: id,
      },
    });
  };

  const handleRemoveSubStep = ({ id, stepId, event }) => {
    event.stopPropagation();
    if (typeof onRemoveSubstepClick === 'function') {
      onRemoveSubstepClick({ id, stepId });
    }
  };

  const isActive = state.currentStep === stepId && state.currentSubstep === id;

  const getClassByStepId = stepId => {
    switch (stepId) {
      case StepType.GENERAL.val:
        return 'general-step';
      case StepType.MAIN_PROCESS.val:
        return 'main-process-step';
      case StepType.POST_PROCESS.val:
        return 'post-process-step';
    }
    return '';
  };

  return (
    <>
      <div
        className={`config-substep ${
          isActive ? 'active' : ''
        } ${getClassByStepId(stepId)}`}
        onClick={openSubstep}
        title={name}
      >
        <button
          className={`config-substep-lbl-btn ${getClassByStepId(stepId)}`}
          onClick={openSubstep}
        >{`${name}`}</button>
        {showRemoveBtn && (
          <button
            id={id}
            className={'remove-substep-btn'}
            onClick={event => handleRemoveSubStep({ id, stepId, event })}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    </>
  );
};
