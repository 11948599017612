import React, {Component} from 'react';

import {
  IconButton,
  Snackbar,
} from '@mui/material';
import {CircularProgress} from '@mui/material';
import {Close} from '@mui/icons-material';
import {Alert} from '@mui/material';

import {withTranslation} from 'react-i18next';

import {downloadOrderConfirmation, downloadEstimate, downloadInvoice} from '../../services/orders';
import {logEvent} from 'utils/firebase';
import {withSnackbar} from 'notistack';

class DownloadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarSeverity: '',
      loading: false,
    };
  }

  downloadFile = async () => {
    this.setState({loading: true});
    try {
      if (this.props.fileType === 'estimates_pdf') {
        await this.downloadEstimatesPdf();
        logEvent('download_estimates_pdf');
      }
      if (this.props.fileType === 'order_confirmation') {
        await downloadOrderConfirmation(this.props.model.id);
        logEvent('download_order_confirmation');
      }
      if (this.props.fileType === 'invoice') {
        await downloadInvoice(this.props.model.id);
        logEvent('download_invoice');
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({loading: false});
  };

  downloadEstimatesPdf = () => {
    const estimatesPDFAttachment =
      this.props.model?.orderedPartListTeaserOrderedAttachments?.find(opltoa => opltoa.orderedAttachment.attachmentType.key === 'estimates_pdf')?.orderedAttachment ||
      this.props.model?.orderedAttachments?.find(a => a.attachmentType.key === 'estimates_pdf') ||
      this.props.model?.attachments?.find(a => a.attachmentType.key === 'estimates_pdf');

    if (estimatesPDFAttachment) {
      return downloadEstimate(estimatesPDFAttachment.id);
    }

    this.props.enqueueSnackbar(this.props.t('orders:no_estimates_pdf_found'), {variant: 'error'});
  };

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarSeverity: '',
    });
  };

  render() {
    const {ComponentToRender, ComponentToRenderChildren, ComponentToRenderProps} = this.props;
    return (
      <>
        <ComponentToRender onClick={this.downloadFile} disabled={!this.props.model || this.state.loading} {...ComponentToRenderProps}>
          {ComponentToRenderChildren}
          {this.state.loading && <CircularProgress style={{marginLeft: '6px'}} size={20} />}
        </ComponentToRender>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <Alert
            severity={this.state.snackbarSeverity}
            elevation={6}
            variant="filled"
            action={<IconButton onClick={this.closeSnackbar} size="small" color="inherit"><Close /></IconButton>}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default withSnackbar(withTranslation('orders')(DownloadFile));
