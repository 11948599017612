import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {
  Grid,
  IconButton,
  Modal,
  Paper,
  Fade,
  Chip,
  Checkbox,
  Tooltip,
  Button,
} from '@mui/material';
import {Close, OpenInNew} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import {withSnackbar} from 'notistack';

import './PartLibraryConfigDetailsModal.scss';
import PartConfigDetailsTable from '../../PartListDetails/PartConfigDetailsTable';
import {constructPartThumbnailUrl, fetchPdfPreview} from '../../../services/parts';
import noPreview from '../../../assets/img/no-preview.jpg';
import {getPartConfig} from '../../../services/configs';

class ConfigDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      thumbnailId: uuidv4(),
    };
  }

  setThumbnailToNoPreviewAvailable = () => {
    document.getElementById(this.state.thumbnailId).src = noPreview;
  };

  openDrawingInNewWindow = () => {
    const pdfAttachment = this.getPdfAttachment();
    fetchPdfPreview(pdfAttachment.currentFile.id).catch();
  };

  getPdfAttachment = () => {
    const {partConfig} = this.props;

    return partConfig?.part?.attachments?.find(attachment => attachment.currentFile?.fileFormat?.key === 'pdf');
  };

  render() {
    const {disableSelect, partConfig, t, selected, onCheckboxChange} = this.props;

    const mainProcesses = partConfig?.processes.filter(p => p.process.class === 'process').map(p => p.process.key) || [];
    const batchSizes = partConfig?.batchSizes.map(bs => bs.batchSize) || [];

    return <Grid
      container
      rowSpacing={2}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid item xs={2} container rowSpacing={1} alignItems={'center'} justifyContent={'center'}>
        <Grid item xs={12}>
          <div className={'thumbnail-container'}>
            <img
              id={this.state.thumbnailId}
              src={constructPartThumbnailUrl(partConfig.partId)}
              alt="Part Config Thumbnail"
              onError={this.setThumbnailToNoPreviewAvailable}
            />
            {this.getPdfAttachment() && <div className={'thumbnail-button'}>
              <Button variant={'contained'} color={'secondary'} onClick={this.openDrawingInNewWindow}><OpenInNew /></Button>
            </div>}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={10} container justifyContent={'space-between'} rowSpacing={1}>
        <Grid item xs={12} container alignItems={'center'} spacing={1}>
          <Grid item sx={{maxWidth: 5 / 18}}>
            <div
              style={{
                fontSize: '1.2rem',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              {partConfig.part.name}
            </div>
          </Grid>
          <Grid item sx={{maxWidth: 1 / 18}}>
            <Tooltip title={t('partconfigurations:go_to_part')}>
              <NavLink to={`/part/${partConfig.partId}`}><IconButton size={'small'}><OpenInNew /></IconButton></NavLink>
            </Tooltip>
          </Grid>
          <Grid item sx={{maxWidth: 1 / 3}}>
            <div
              style={{
                border: '1px solid #656D79',
                borderRadius: 16,
                fontWeight: 550,
                fontSize: '0.8125rem',
                padding: '2px 8px',
                color: '#656D79',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
                  <span style={{textOverflow: 'ellipses'}}>
                    {t('partconfigurations:article_number')}:&nbsp;{partConfig.part.articleNr || '-'}
                  </span>
            </div>
          </Grid>
          <Grid item sx={{maxWidth: 1 / 3}}>
            <div
              style={{
                border: '1px solid #656D79',
                borderRadius: 16,
                fontWeight: 550,
                fontSize: '0.8125rem',
                padding: '2px 8px',
                color: '#656D79',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
                  <span style={{whiteSpace: 'nowrap'}}>
                    {t('partconfigurations:drawing_number')}:&nbsp;{partConfig.part.drawingNr || '-'}
                  </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={4} style={{fontStyle: 'italic', fontSize: '1rem'}}>
            {t('partconfigurations:t_config')}: {partConfig.name?.trim().length ? partConfig.name.trim() : partConfig.id}
          </Grid>
          <Grid item xs={8} alignItems={'center'} justifyContent={'flex-end'} container columnSpacing={2}>
            {!disableSelect && (
              <Checkbox
                checked={selected}
                onChange={({target: {checked}}) => onCheckboxChange(checked)}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems={'center'}>
          <Grid item>
            <Chip
              variant={'filled'}
              color={'primary'}
              label={`${t('partconfigurations:t_material_name')}: ${partConfig.materialName || '-'}`}
              style={{color: 'white', fontWeight: 550}}
            />
          </Grid>
          <Grid item>
            <Chip
              variant={'filled'}
              color={'primary'}
              label={`${t('partconfigurations:t_batch_sizes')}: ${batchSizes.length ? batchSizes.join(', ') : '-'}`}
              style={{color: 'white', fontWeight: 550}}
            />
          </Grid>
          <Grid item>
            <Chip
              variant={'filled'}
              color={'primary'}
              label={`${t('partconfigurations:t_main_processes')}: ${mainProcesses.length
                ? mainProcesses.map(p => t(`processes:${p}`)).join(', ')
                : '-'}`}
              style={{color: 'white', fontWeight: 550}}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{padding: 0}}>
        <div style={{paddingTop: 16}}>
          <PartConfigDetailsTable partConfig={partConfig} hideManufacturingAnalysis={true} />
        </div>
      </Grid>
    </Grid>;
  }
}

class PartLibraryConfigDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      partConfig: null,
    };
  }

  closeModal = () => {
    this.props.onClose();
  };

  async componentDidUpdate(prevProps) {
    if (this.props.partConfigId !== prevProps.partConfigId && this.props.partConfigId) {
      try {
        // Get part config
        const {data: partConfig} = await getPartConfig(this.props.partConfigId, {
          filter: {
            include: [
              {part: {attachments: {currentFile: 'fileFormat'}}},
              'batchSizes',
              {processes: ['process', {manufacturingAnalyses: 'batchSize'}, 'processFeatureValues']},
            ],
          },
        });

        // Set it in the state
        await this.setState({
          loading: false,
          partConfig,
        });
      } catch (err) {
        console.error(err);
        this.props.enqueueSnackbar(this.props.t('modals:mdl_general_error_title'), {
          variant: 'error',
        });
      }
    }
  }

  render() {
    const {open, t} = this.props;

    return (
      <Modal open={open} closeAfterTransition>
        <Fade in={open}>
          <div className={'part-library-config-details-modal'}>
            <Paper className={'part-library-config-details-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-part-library-config-details-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                rowSpacing={2}
                justifyContent={'center'}
                alignContent={'space-between'}
                className={'part-library-config-details-modal-content'}
              >
                <Grid item xs={12} container rowSpacing={2} justifyContent={'center'} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    {!this.state.loading && this.state.partConfig
                      ? <ConfigDetails partConfig={this.state.partConfig} t={t} disableSelect={true} />
                      : <></>}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withSnackbar(withTranslation(['modals', 'partconfigurations'])(PartLibraryConfigDetailsModal));
