import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SharedPartsTable from 'components/SharedParts/SharedPartsTable/SharedPartsTable';
import { PaginationComponent } from 'containers/Pagination/Pagination';
import SharedLinkReactivateModal from 'components/Modals/SharedLinkReactivateModal/SharedLinkReactivateModal';
import SharedLinkDeactivateModal from 'components/Modals/SharedLinkDeactivateModal/SharedLinkDeactivateModal';
import SharedLinkDeleteModal from 'components/Modals/SharedLinkDeleteModal/SharedLinkDeleteModal';
import SharedTableActionMenu from 'components/SharedParts/SharedTableActionMenu/SharedTableActionMenu';
import SharedLinkResetPasswordModal from 'components/Modals/SharedLinkResetPasswordModal/SharedLinkResetPasswordModal';
import { SharedLinksTableActionsEnum } from 'models/enums/SharedLinksTableActionsEnum';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  updateSharedListAction,
  deleteSharedListAction,
} from 'containers/SharedParts/SharedPartsActions';
import { isDateExpiredByNow } from 'utils/datetimeHelpers';
import './SharePartsComponent.scss';

const SharePartsComponent = ({
  list,
  pagination,
  getListPage,
  listOptions,
  searchOptions,
  isSearchMode,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [resetPassModalOpen, setResetPassModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const loadingAction = useSelector(state => state.sharedParts.loading);

  const loadingError = useSelector(state => state.sharedParts.error);
  const { t } = useTranslation('sharedparts');
  /**
   * @description Stored link item id ready for password change
   * - Removed when password change will be finished or canceled
   */
  const currentResetPassId = useRef(null);
  const dispatch = useDispatch();

  const executionActionType = {
    DeactivateLink: 0,
    ReactivateLink: 1,
    DeleteLink: 2,
    ResetPassword: 3,
  };

  const currentExecutionType = useRef(null);

  useEffect(() => {
    if (loadingError != null && !loadingAction) {
      enqueueSnackbar(t('shareparts_action_link_error_msg'), {
        variant: 'error',
      });
      return;
    }
    if (!loadingAction) {
      switch (currentExecutionType.current) {
        case executionActionType.DeactivateLink:
          enqueueSnackbar(t('shareparts_deactivate_link_success_msg'), {
            variant: 'success',
          });
          break;
        case executionActionType.ReactivateLink:
          enqueueSnackbar(t('shareparts_reactivate_link_success_msg'), {
            variant: 'success',
          });
          break;
        case executionActionType.DeleteLink:
          enqueueSnackbar(t('shareparts_delete_link_success_msg'), {
            variant: 'success',
          });
          break;
        case executionActionType.ResetPassword:
          enqueueSnackbar(t('shareparts_reset_password_link_success_msg'), {
            variant: 'success',
          });
          break;
        default:
          break;
      }
      currentExecutionType.current = null;
    }
  }, [loadingAction]);
  /**
   * MENU SELECTION
   * @param {*} param0
   */
  const onActionSelect = selectedAction => {
    // prevent action to be started if no item is selected
    if (selectedItems.length === 0) {
      return;
    }
    switch (selectedAction) {
      case SharedLinksTableActionsEnum.ReactivateLink.val:
        setReactivateModalOpen(true);
        break;
      case SharedLinksTableActionsEnum.DeactivateLink.val:
        setDeactivateModalOpen(true);
        break;
      case SharedLinksTableActionsEnum.DeleteLink.val:
        setDeleteModalOpen(true);
        break;
    }
  };

  /**
   * TABLE SELECTION
   */
  const handleItemSelection = ({ isSelected, item, select }) => {
    if (isSelected) {
      setSelectedItems([...selectedItems, { id: item.id, item, select }]);
    } else {
      let items = selectedItems.filter(x => x.id !== item.id);
      setSelectedItems(items);
    }
  };

  const clearItemsSelection = () => {
    selectedItems.forEach(data => {
      data.select(false);
    });

    setSelectedItems([]);
  };

  // REACTIVATE LINKS
  const handleReactivateLinksConfirm = () => {
    // check for empty list
    if (!selectedItems.length) {
      return;
    }

    // filter the list, exclude inactive links
    const filteredList = selectedItems.filter(data => {
      if (!isDateExpiredByNow(data.item.expiryDatetime) && !data.item.active) {
        return data.item.id;
      }
    });

    // check for empty list
    if (!filteredList.length) {
      return;
    }

    const ids = filteredList.map(data => {
      return data.item.id;
    });

    // update the DB
    currentExecutionType.current = executionActionType.ReactivateLink;
    dispatch(
      updateSharedListAction({
        payload: { active: true },
        ids,
        listPagination: {
          filter: {
            ...listOptions,
            page: pagination.currentPage,
            ...searchOptions,
          },
        },
      })
    );
    // close modal
    setReactivateModalOpen(false);
    clearItemsSelection();
  };

  // DEACTIVATE LINKS
  const handleDeactivateLinksConfirm = () => {
    // check for empty list
    if (!selectedItems.length) {
      return;
    }

    // filter the list, exclude inactive links
    const filteredList = selectedItems.filter(data => {
      if (!isDateExpiredByNow(data.item.expiryDatetime) && data.item.active) {
        return data.item.id;
      }
    });

    // check for empty list
    if (!filteredList.length) {
      return;
    }

    const ids = filteredList.map(data => {
      return data.item.id;
    });

    // update the DB
    currentExecutionType.current = executionActionType.DeactivateLink;
    dispatch(
      updateSharedListAction({
        payload: { active: false },
        ids,
        listPagination: {
          filter: {
            ...listOptions,
            page: pagination.currentPage,
            ...searchOptions,
          },
        },
      })
    );
    // close modal
    setDeactivateModalOpen(false);
    clearItemsSelection();
  };

  // DELETE LINKS
  const handleDeleteLinksConfirm = () => {
    // check for empty list
    if (selectedItems.length === 0) {
      return;
    }

    const ids = selectedItems.map(data => {
      return data.item.id;
    });
    currentExecutionType.current = executionActionType.DeleteLink;

    let loadPrevLinksPage = false;
    if (selectedItems.length === list.length && pagination.currentPage > 1) {
      loadPrevLinksPage = true;
    }

    // update the DB
    dispatch(
      deleteSharedListAction({
        ids,
        listPagination: {
          filter: {
            ...listOptions,
            page: loadPrevLinksPage
              ? pagination.currentPage - 1
              : pagination.currentPage,
            ...searchOptions,
          },
        },
        loadPrevPage: loadPrevLinksPage,
      })
    );
    // close modal
    setDeleteModalOpen(false);
    clearItemsSelection();
  };

  // RESET PASSWORD
  const handleResetPassLinkConfirm = passwordValue => {
    // update the DB
    currentExecutionType.current = executionActionType.ResetPassword;
    if (currentResetPassId.current) {
      dispatch(
        updateSharedListAction({
          payload: { password: passwordValue },
          ids: [currentResetPassId.current],
          listPagination: {
            filter: {
              ...listOptions,
              page: pagination.currentPage,
              ...searchOptions,
            },
          },
        })
      );
    }
    setResetPassModalOpen(false);
  };

  const handleItemResetPassClick = id => {
    currentResetPassId.current = id;
    setResetPassModalOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            {list.length > 0 && !isSearchMode && (
              <SharedTableActionMenu
                onSelect={onActionSelect}
                disabled={!selectedItems.length}
              />
            )}
          </Grid>
        </Grid>
        <SharedPartsTable
          list={list}
          isSearchMode={isSearchMode}
          searchOptions={searchOptions}
          onItemSelect={handleItemSelection}
          onLinkResetPassClick={handleItemResetPassClick}
        />
        {list.length > 0 && !isSearchMode && (
          <PaginationComponent
            paginationObject={pagination}
            getPage={getListPage}
          />
        )}
      </Grid>
      <SharedLinkReactivateModal
        open={reactivateModalOpen}
        onConfirm={handleReactivateLinksConfirm}
        onCancel={() => setReactivateModalOpen(false)}
      />
      <SharedLinkDeactivateModal
        open={deactivateModalOpen}
        onConfirm={handleDeactivateLinksConfirm}
        onCancel={() => setDeactivateModalOpen(false)}
      />
      <SharedLinkDeleteModal
        open={deleteModalOpen}
        onConfirm={handleDeleteLinksConfirm}
        onCancel={() => setDeleteModalOpen(false)}
      />
      <SharedLinkResetPasswordModal
        open={resetPassModalOpen}
        onConfirm={handleResetPassLinkConfirm}
        onCancel={() => setResetPassModalOpen(false)}
      />
    </>
  );
};

export default SharePartsComponent;
