import API from './api';

export const createSharedLink = async payload =>
  await API.post({
    url: `/shared-part-list-links`,
    data: payload,
  });

export const fetchSharedList = async id =>
  await API.get({
    url: `/shared-part-list-links/${id}`,
  });

export const fetchAllSharedLists = async params =>
  await API.get({
    url: `/shared-part-list-links`,
    params,
  });

export const updateSharedList = async (payload, id) =>
  await API.patch({
    url: `/shared-part-list-links/${id}`,
    data: payload,
  });

export const updateAllSharedLinks = async (payload, ids = []) => {
  if (ids.length === 0) {
    return;
  }

  let listUpdates = [];
  ids.forEach(id => {
    listUpdates.push(updateSharedList(payload, id));
  });
  return Promise.all(listUpdates);
};

export const deleteSharedList = async id =>
  await API.delete({
    url: `/shared-part-list-links/${id}`,
  });

export const deleteAllSharedList = async ids => {
  let listUpdates = [];
  ids.forEach(id => {
    listUpdates.push(deleteSharedList(id));
  });
  return Promise.all(listUpdates);
};

export const fetchSharedListZip = async urlId =>
  await API.get({
    url: `/part-list-archives/${urlId}`,
    responseType: 'blob',
  }).then(
    data => {
      if (data.status === 200) {
        let fileName = '';
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        const contentDisposition = data.headers['content-disposition'];

        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        link.href = url;
        link.setAttribute('download', `${fileName}`);
        link.setAttribute('id', `${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.getElementById(`${fileName}`).remove();
      }

      return data.status || data.response.status;
    },
    err => {
      return err.status || err.response.status;
    }
  );
