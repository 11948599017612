import Axios from 'axios';
import { getProcessFeaturesValues } from 'services/configs';

const baseUrl = id => `${process.env.REACT_APP_PARTS_API}/part-configs/${id}`;

export const ConfigActions = {
  Create: 0,
  Update: 0,
  Delete: 0,
};

export const saveData = async data => {
  const response = await Axios.post(
    `${process.env.REACT_APP_PARTS_API}/part-configs`,
    {
      name: data.name,
      partId: data.partId,
    }
  );

  if (response.status && response.status >= 200 && response.status < 300) {
    if (data.mainProcess) {
      await saveProcess(response.data.id, data.mainProcess, 'main');
    }

    if (data.postProcesses && !!data.postProcesses.length) {
      data.postProcesses.map(async postProcess => {
        return await saveProcess(response.data.id, postProcess, 'post');
      });
    }

    if (data.batchSizes && !!data.batchSizes.length) {
      data.batchSizes.map(async size => {
        return await saveBatchSize(response.data.id, { batchSize: size });
      });
    }
  }
};

export const createConfig = async data => {
  const response = await Axios.post(
    `${process.env.REACT_APP_PARTS_API}/part-configs`,
    {
      name: data.name,
      partId: data.partId,
    }
  );

  if (response.status && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return { error: `Error: ${response.status}`, code: response.status };
  }
};

export const saveBatchSize = async (id, data) => {
  return Axios.put(`${baseUrl(id)}/batch-sizes`, data);
};

export const saveProcess = async (id, processId, partConfigProcessId, type) => {
  const payload = {
    processId,
  };

  // Replace existing part config process
  if (partConfigProcessId) {
    return Axios.post(
      `${baseUrl(id)}/part-config-processes/${partConfigProcessId}/replace`,
      { ...payload }
    );
  }

  // Save new part config process
  return Axios.put(
    `${baseUrl(id)}/part-config-processes/${
      type === 'main' ? 'first' : 'last'
    }`,
    { ...payload }
  );
};

export const updateConfigInfo = async (id, data) => {
  return Axios.patch(`${baseUrl(id)}`, data);
};

// FEATURE VALUES
export const getFormByPostProcess = async (configId, processId) => {
  return getProcessFeaturesValues(configId, processId);
};

// Manufacturing Analysis
export const triggerManufacturingAnalysis = async configId => {
  return Axios.post(`${baseUrl(configId)}/manufacturing-analysis`);
};
