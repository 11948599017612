import {
  SHARED_LIST_GET,
  SHARED_LIST_GET_SUCCESS,
  SHARED_LIST_GET_ERROR,
  SHARED_INITIAL_LISTS_GET,
  SHARED_INITIAL_LISTS_GET_SUCCESS,
  SHARED_INITIAL_LISTS_GET_ERROR,
  SHARED_LISTS_GET,
  SHARED_LISTS_GET_SUCCESS,
  SHARED_LISTS_GET_ERROR,
  SHARED_LIST_CREATE,
  SHARED_LIST_CREATE_SUCCESS,
  SHARED_LIST_CREATE_ERROR,
  SHARED_LIST_UPDATE,
  SHARED_LIST_UPDATE_SUCCESS,
  SHARED_LIST_UPDATE_ERROR,
  SHARED_LIST_DELETE,
  SHARED_LIST_DELETE_SUCCESS,
  SHARED_LIST_DELETE_ERROR,
  SHARED_LIST_CLEAR_ERROR,
} from './SharedPartsTypes';

const INITIAL_STATE = {
  initialLoading: true,
  loading: false,
  error: null,
  linksList: {},
  singleLink: {},
  listId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHARED_LIST_GET:
      return { ...state, loading: true };
    case SHARED_LIST_GET_SUCCESS:
      return {
        ...state,
        singleLink: action.payload,
        loading: false,
        error: null,
      };
    case SHARED_LIST_GET_ERROR:
      return { ...state, error: action.payload, loading: false };

    case SHARED_INITIAL_LISTS_GET:
      return { ...state, initialLoading: true, error: null };
    case SHARED_INITIAL_LISTS_GET_SUCCESS:
      return {
        ...state,
        linksList: action.payload,
        initialLoading: false,
        error: null,
      };
    case SHARED_INITIAL_LISTS_GET_ERROR:
      return { ...state, error: action.payload, initialLoading: false };

    case SHARED_LISTS_GET:
      return { ...state, loading: true };
    case SHARED_LISTS_GET_SUCCESS:
      return {
        ...state,
        linksList: action.payload,
        loading: false,
        error: null,
      };
    case SHARED_LISTS_GET_ERROR:
      return { ...state, error: action.payload, loading: false };

    case SHARED_LIST_CREATE:
      return { ...state, loading: true };
    case SHARED_LIST_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleLink: action.payload,
        error: null,
      };
    case SHARED_LIST_CREATE_ERROR:
      return { ...state, error: action.payload };

    case SHARED_LIST_UPDATE:
      return { ...state, loading: true };
    case SHARED_LIST_UPDATE_SUCCESS:
      return { ...state, loading: false, error: null };
    case SHARED_LIST_UPDATE_ERROR:
      return { ...state, error: action.payload, loading: false };

    case SHARED_LIST_DELETE:
      return { ...state, loading: true };
    case SHARED_LIST_DELETE_SUCCESS:
      return {
        ...state,
        singleLink: action.payload,
        loading: false,
        error: null,
      };
    case SHARED_LIST_DELETE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SHARED_LIST_CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
