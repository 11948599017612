import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchConfigsForList } from 'services/part-lists';
import { fetchPartConfigs } from 'services/parts';
import { cancelManufacturingAnalysis } from 'services/configs';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Box,
  Tooltip,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { Row } from 'components/CollapseTable/TableRow/TableRow';
import Loading from 'components/Loading/Loading';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import ConfigsModal from 'components/Modals/ConfigsModal/ConfigsModal';

import { unassignConfigFromList } from 'services/part-lists';

import './ConfigCardBody.scss';
import CloneConfigModal from 'components/Modals/CloneConfigModal/CloneConfigModal';
import { cloneConfigToAllPartsInList } from 'containers/PartListDetails/PartListDetailsActions';
import CloneConfigListModal from 'components/Modals/CloneConfigListModal/CloneConfigListModal';
import { clearSelectedConfig } from 'containers/PartListDetails/PartListDetailsActions';
import { saveSelectedConfigs } from 'containers/PartListDetails/PartListDetailsActions';
import { addEmptyConfigInPart } from 'containers/PartListDetails/PartListDetailsActions';

export const ConfigCardBody = ({
  part,
  fetchAll,
  selectableConfigs,
  canAddConfig,
  cloneModalMode = false,
  toggleConfigSelect = null,
  hideRemove,
}) => {
  const { t } = useTranslation('partconfigurations');
  const listId = useSelector(state => state.partList.data.id);
  const addEmptyConfigSuccess = useSelector(
    state => state.partList.addEmptyConfigSuccess
  );

  const selectedCloningIds = useSelector(
    state => state.newPartList.selectedConfigs
  );

  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState([]);
  const [configToBeRemoved, setConfigToBeRemoved] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCloneConfigModal, setOpenCloneConfigModal] = useState(false);
  const [openCloneAskModal, setOpenCloneAskModal] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const cloneConfigId = useRef(null);
  const editConfigId = useRef(null);

  useEffect(() => {
    if (expanded) {
      setLoading(true);
      getConfigs();
    } else {
      setConfigs([]);
    }
  }, [expanded]);

  useEffect(() => {
    async function loadConfigs() {
      await getConfigs();
    }

    if (addEmptyConfigSuccess) {
      loadConfigs();
    }
  }, [addEmptyConfigSuccess]);

  useEffect(() => {
    if (selectedCloningIds.length > 0) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [selectedCloningIds]);

  const getConfigs = async () => {
    const response = fetchAll
      ? await fetchPartConfigs(part.id, {
        filter: {
          include: [
            {
              processes: [
                'process',
                'processFeatureValues',
                { manufacturingAnalysis: 'batchSize' },
              ],
            },
            'batchSizes',
          ],
        },
      })
      : await fetchConfigsForList(listId, {
        filter: {
          where: { partId: part.id },
          include: [
            {
              processes: [
                'process',
                'processFeatureValues',
                { manufacturingAnalysis: 'batchSize' },
              ],
            },
            'batchSizes',
          ],
        },
      });

    if (response.status && response.status >= 200 && response.status < 300) {
      setLoading(false);

      fetchAll ? setConfigs(response.data) : setConfigs(response.data.page);
    }
  };

  const handleAddConfig = async () => {
    dispatch(addEmptyConfigInPart(part.id, listId));
  };

  const removeConfigFromList = async config => {
    const response = await unassignConfigFromList({
      listId,
      configId: config.id,
    });

    setConfigToBeRemoved(null);
    if (response.status && response.status >= 200 && response.status < 300) {
      await getConfigs();
    }
  };

  const confirmRemove = isConfirmed => {
    setOpenConfirm(false);

    if (isConfirmed && configToBeRemoved) {
      setLoading(true);
      return removeConfigFromList(configToBeRemoved);
    }
  };

  const runningMA = config => {
    return config.batchSizes.some(batchSize => batchSize.maRunning);
  };

  const handleCloneConfigAnswer = answer => {
    if (answer === 'all') {
      dispatch(
        cloneConfigToAllPartsInList({
          listId,
          partConfigId: cloneConfigId.current,
        })
      );
    } else if (answer === 'some') {
      setOpenCloneConfigModal(true);
    }
    setOpenCloneAskModal(false);
  };

  const handleCloneSomeConfigConfirmation = answer => {
    if (cloneConfigId.current === null) {
      return;
    }
    if (answer) {
      dispatch(
        saveSelectedConfigs({
          listId,
          partConfigId: cloneConfigId.current,
          destinationConfigIds: [...selectedCloningIds],
        })
      );
    } else {
      dispatch(clearSelectedConfig());
    }
    setOpenCloneConfigModal(false);
    cloneConfigId.current = null;
  };

  return (
    <>
      <Accordion
        className="cc-expander"
        square
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{marginRight: '20px', marginLeft: '20px'}} />}
          aria-controls="panel1a-content"
          id="cc-header"
          className="cc-header"
        >
          <Table>
            <TableHead>
              <TableRow key={part.id}>
                <TableCell align="center" style={{ width: '10%', display: 'flex' }}>
                  {t('partconfigurations:t_id')}
                </TableCell>
                <TableCell align="left" style={{ width: '20%' }}>
                  {t('partconfigurations:t_name')}
                </TableCell>
                <TableCell align="left" style={{ width: '30%' }}>
                  {t('partconfigurations:t_config')}
                </TableCell>
                <TableCell align="left" style={{ width: '25%' }}>
                  {t('partconfigurations:t_analysis')}
                </TableCell>
                <TableCell align="left" style={{ width: '15%' }}>
                  {t('partconfigurations:t_actions')}
                </TableCell>
                {selectableConfigs && (
                  <TableCell align="left" style={{width: '5%'}}/>
                )}
              </TableRow>
            </TableHead>
          </Table>
        </AccordionSummary>
        <AccordionDetails className="cc-details">
          <div className="cc-details-element">
            {loading ? (
              <Loading pageHeight={230} />
            ) : configs && configs.length ? (
              <Table>
                <TableBody>
                  {configs.map(config => {
                    let actions = [
                      {
                        icon: () => {
                          return (
                            !cloneModalMode && (
                              <Tooltip title={t('clone_config')}>
                                <FileCopyIcon />
                              </Tooltip>
                            )
                          );
                        },
                        reaction: () => {
                          cloneConfigId.current = config.id;
                          setOpenCloneAskModal(true);
                        },
                      },
                      {
                        icon: () =>
                          runningMA(config) ? (
                            <Tooltip title={t('cancel_manufacturing_analysis')}>
                              <CancelIcon />
                            </Tooltip>
                          ) : (
                            !cloneModalMode && (
                              <Tooltip title={t('edit_config')}>
                                <EditIcon />
                              </Tooltip>
                            )
                          ),
                        reaction: async () => {
                          if (runningMA(config)) {
                            await cancelManufacturingAnalysis(config.id);
                            return getConfigs();
                          } else {
                            setOpenEdit(true);
                            editConfigId.current = config.id;
                          }
                        },
                      },
                    ];

                    if (!hideRemove) {
                      actions.push({
                        icon: () => (
                          <Tooltip title={t('delete_config')}>
                            <DeleteIcon />
                          </Tooltip>
                        ),
                        reaction: () => {
                          setConfigToBeRemoved(config);
                          setOpenConfirm(true);
                        },
                      });
                    }

                    return (
                      <React.Fragment
                        key={`${config.id}${new Date().getTime()}`}
                      >
                        <Row
                          row={config}
                          actions={actions}
                          workMode={'summary'}
                          selectable={selectableConfigs}
                          toggleConfigSelect={toggleConfigSelect}
                          key={`${config.id}${new Date().getTime()}-row`}
                        />
                        <ConfirmModal
                          open={openConfirm}
                          title={t('t_remove_config_title')}
                          description={t('t_remove_config_description')}
                          confirm={isConfirmed => confirmRemove(isConfirmed)}
                        />
                        <ConfigsModal
                          part={part}
                          open={openEdit}
                          setOpen={setOpenEdit}
                          editConfigId={editConfigId}
                          confirm={isConfirmed => {
                            editConfigId.current = null;

                            if (isConfirmed) {
                              setLoading(true);
                              getConfigs();
                            }
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box className="cc-configs-empty" textAlign="center">
                {t('t_configs_empty')}
              </Box>
            )}
            {canAddConfig && (
              <Button
                variant="outlined"
                className="cc-add-config-button"
                onClick={handleAddConfig}
              >
                {t('t_add_config')}
              </Button>
            )}
            <CloneConfigModal
              open={openCloneAskModal}
              setOpen={setOpenCloneAskModal}
              onClick={answer => handleCloneConfigAnswer(answer)}
            />
            <CloneConfigListModal
              open={openCloneConfigModal}
              setOpen={setOpenCloneConfigModal}
              confirm={answer => handleCloneSomeConfigConfirmation(answer)}
              title={t('t_clone_to_some_configurations_title')}
              enableSave={enableSave}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
