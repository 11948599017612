import React, {Component} from 'react';
import {Chip, Grid, Tooltip} from '@mui/material';
import {withTheme} from '@mui/styles';
import {withTranslation} from 'react-i18next';

class StatusChip extends Component {
  render() {
    const {
      theme,
      t,
      data: {
        maPartiallyComplete,
        maComplete,
        maRejected,
        maRunning,
        maPartiallyRejected,
        batchSizes,
        configurationComplete,
        quotingStatus,
        kmsId,
        automaticConfigurationCompleted,
      },
      size,
    } = this.props;

    let status, color;

    if (kmsId) {
      if (maComplete) {
        status = 'ma_complete';
        color = theme.palette.success.main;
      } else if (maPartiallyComplete) {
        status = 'ma_partially_complete';
        color = theme.palette.info.main;
      } else if (maPartiallyRejected) {
        status = 'ma_partially_rejected';
        color = theme.palette.warning.main;
      } else if (maRejected) {
        status = 'ma_rejected';
        color = theme.palette.error.main;
      }
    } else {
      if (maRunning) {
        status = 'ma_running';
        color = theme.palette.primary.main;
      } else if (maRejected) {
        status = 'ma_rejected';
        color = theme.palette.error.main;
      } else if (!automaticConfigurationCompleted) {
        status = 'automatic_configuration_pending';
        color = theme.palette.warning.main;
      } else if (!configurationComplete) {
        status = 'configuration_incomplete';
        color = theme.palette.warning.main;
      } else if (quotingStatus === 'pending') {
        status = 'quoting_pending';
        color = theme.palette.info.main;
      } else if (quotingStatus === 'expired') {
        status = 'quote_expired';
        color = theme.palette.error.main;
      } else if (quotingStatus === 'rejected') {
        status = 'quoting_rejected';
        color = theme.palette.error.main;
      } else if (quotingStatus === 'completed') {
        status = 'quoting_completed';
        color = theme.palette.success.main;
      } else if (quotingStatus === 'in_progress') {
        status = 'quoting_in_progress';
        color = theme.palette.info.main;
      }
    }

    if (status) {
      const chip = (
        <Chip
          style={{color: 'white', backgroundColor: color}}
          size={size || 'small'}
          label={t(status)}
        />
      );
      return (
        <Grid container justifyContent={this.props.justify || 'center'}>
          {batchSizes && (maRejected || maPartiallyRejected) ?
            <Tooltip arrow title={batchSizes.filter(bs => bs.maComment).map(bs => t(`reject_reasons:${bs.maComment}`) === `reject_reasons:${bs.maComment}` ? bs.maComment.split('REJECT: ')[1] : t(`reject_reasons:${bs.maComment}`)).join('\n')}>
              {chip}
            </Tooltip> :
            chip
          }
        </Grid>
      );
    }
    return null;
  }
}

export default withTranslation('partlists')(withTheme(StatusChip));
