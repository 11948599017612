import axios from 'axios';

const API_URL = process.env.REACT_APP_PARTS_API;
const genUrl = url => `${API_URL}${url}`;

export const uploadFile = async (url, formData, config = {}, cancelToken) =>
  await axios
    .post(genUrl(url), formData, {
      headers: { 'content-type': 'multipart/form-data' },
      cancelToken,
      ...config,
    })
    .then(res => {
      return { status: 'success', data: res.data };
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        return {
          status: 'cancel',
          error: {
            code: 'upload-canceled',
          },
        };
      } else {
        if (error?.response?.data?.message.includes('cannot belong to multiple parts')) {
          return {
            status: 'error',
            error: {
              code: 'cannot-belong-to-multiple-parts',
            },
          };  
        }
        
        return {
          status: 'error',
          error: {
            code: error?.response?.status || 'unknown-error',
          },
        };
      }
    });
