import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectConfig,
  removeConfig,
} from 'containers/PartList/NewPartListActions';

import { Checkbox, TableCell } from '@mui/material';

export const TableRowSelect = ({ configId, selectable, toggleConfigSelect }) => {
  const dispatch = useDispatch();
  const { selectedConfigs } = useSelector(state => typeof toggleConfigSelect === 'function' ? state.partList : state.newPartList);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selectable) {
      setSelected(selectedConfigs.find(item => item === configId));
    }
  }, []);

  const toggleSelect = event => {
    if (toggleConfigSelect && typeof toggleConfigSelect === 'function') {
      return toggleConfigSelect(event);
    }
    
    if (event.toggle) {
      dispatch(selectConfig(event.configId));
    } else {
      dispatch(removeConfig(event.configId));
    }
  };

  return (
    <TableCell>
      <Checkbox
        checked={selected}
        onChange={() => {
          toggleSelect({ toggle: !selected, configId });
          setSelected(!selected);
        }}
        value={selected}
        color="primary"
      />
    </TableCell>
  );
};
