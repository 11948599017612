import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SweetAlert from 'react-bootstrap-sweetalert';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import makeStyles from '@mui/styles/makeStyles';

import '../parts-modals.scss';

const useStyles = makeStyles(styles);

export const ConfirmModal = ({ title, description, open, confirm }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();

  const [alert, setAlert] = React.useState(null);

  const hideAlert = isConfirmed => {
    confirm(isConfirmed);
    setAlert(null);
  };

  useEffect(() => {
    if (open) {
      confirmModal();
    }
  }, [open]);

  const confirmModal = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_general_remove')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={`${classes.button} ${classes.danger}`}
        cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
        title={title}
      >
        {description}
      </SweetAlert>
    );
  };

  return <>{alert}</>;
};
