export const getLocation = href => {
  if (!href) {
    return '';
  }

  var l = document.createElement('a');
  l.href = href;
  return l;
};

export const getLocationPathname = href => {
  if (!href) {
    return '';
  }
  const link = getLocation(href);
  return link.pathname;
};

export const isURL = str => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}
