import {KnestOrderingAPI, OrderingAPI} from './api';
import {getToken} from './auth';

export const getOrders = async params => {
  return OrderingAPI.get({
    url: '/ordered-part-lists',
    params,
  });
};

export const getOrderTeasers = async params => {
  return OrderingAPI.get({
    url: '/ordered-part-list-teasers',
    params,
  });
};

export const getOrderById = async (id, params) => {
  return OrderingAPI.get({
    url: `/ordered-part-lists/${id}`,
    params,
  });
};

export const getOrderTeaserById = async (id, params) => {
  return KnestOrderingAPI.get({
    url: `/ordered-part-list-teasers/${id}`,
    params,
  });
};

export const getOrderedPartListTeasersCount = async params =>
  await OrderingAPI.get({
    url: `/ordered-part-list-teasers/count`,
    params,
  });

export const patchOrder = async (id, data) => {
  return OrderingAPI.patch({
    url: `/ordered-part-lists/${id}/internal-order-number`,
    data,
  });
};

const handleOpenWindow = url => {
  return window.open(
    url,
    '_blank',
    'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
  );
};

export const downloadOrderConfirmation = async id => {
  return handleOpenWindow(`${OrderingAPI.genUrl(`/ordered-part-lists/${id}/order-confirmation`, true)}?access_token=${getToken().access_token}`);
};

export const downloadInvoice = async id => {
  return handleOpenWindow(`${OrderingAPI.genUrl(`/ordered-part-lists/${id}/invoice`, true)}?access_token=${getToken().access_token}`);
};

export const downloadEstimate = id => {
  return handleOpenWindow(`${OrderingAPI.genUrl(`/ordered-attachments/${id}/file`, true)}?access_token=${getToken().access_token}`);
};

export const placeOrderWithKreatize = async (partListId, selectedBatchSizeIds = [], desiredDeliveryDate, deliveryAddress, billingAddress, isDirectShipment = false) => {
  return KnestOrderingAPI.post({
    url: `/part-lists/${partListId}/order`,
    data: {
      batchSizeIds: selectedBatchSizeIds,
      desiredDeliveryDate,
      deliveryAddress,
      billingAddress,
      isDirectShipment,
    },
  });
};

export const getAddressesFromAPI = async () => {
  return KnestOrderingAPI.get({
    url: `/company-addresses`,
  });
};

export const createAddress = async data => {
  return KnestOrderingAPI.post({
    url: `/company-addresses`,
    data,
  });
};

export const cancelOrder = async id => {
  return KnestOrderingAPI.post({
    url: `/ordered-part-lists/${id}/cancel`,
  });
};

export const constructThumbnailUrl = orderedPartId => {
  const userToken = getToken();
  return `${process.env.REACT_APP_ORDERING_API}/ordered-parts/${orderedPartId}/thumbnail?access_token=${userToken.access_token}`;
};

export const constructOrderThumbnailUrl = orderedPartListId => {
  const userToken = getToken();
  return `${process.env.REACT_APP_ORDERING_API}/ordered-part-lists/${orderedPartListId}/thumbnail?access_token=${userToken.access_token}`;
};
