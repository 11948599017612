import React from 'react';
import {useTranslation} from 'react-i18next';

import {Grid} from '@mui/material';

import Button from 'components/CustomButtons/Button.js';

const NoItemsErrorComponent = ({pageType, history}) => {
  const {t} = useTranslation('errors');
  return (
    <Grid
      container
      style={{minHeight: 200, height: '33vh'}}
      alignItems="center"
    >
      <Grid item sm={12} style={{textAlign: 'center'}}>
        <h1>{t('error_no_results')}</h1>
        <h3>{t(`error_${pageType}`)}</h3>
        {pageType === 'storage_add' ? (
          <Button color="success" onClick={() => history.push('/part-storage')}>
            {t(`error_${pageType}_empty_button`)}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};

export const NoItemsError = NoItemsErrorComponent;
