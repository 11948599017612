export const snackbar = (message, severity = 'info') => {
  return dispatch => {
    dispatch({ type: "SNACKBAR", message, severity });
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_CLEAR" });
  };
};