import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormControl, Select, MenuItem, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'components/CustomButtons/Button.js';
//Styles
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import buttonsStyles from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import {getThumbnailURL} from 'utils/helpers';
import './PartThumbnail.scss';
import {fetchPdfPreview, fetchFile, fetchFileAsArraybuffer} from 'services/parts';
import STLViewer from 'kad-viewer';

const useStyles = makeStyles({
  ...selectStyles,
  ...buttonsStyles,
  ...alertStyles,
});

import noImage from 'assets/img/no-preview.jpg';

const PartThumbnail = ({attachments}) => {
  const {t} = useTranslation('partdetails');
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = React.useState(
    attachments && attachments.length ? `${attachments[0].id}` : '',
  );
  const [selectedFile, setSelectedFile] = React.useState(attachments && attachments.length && attachments[0]);
  const [stlFile, setStlFile] = React.useState(null);

  const handleSelect = event => {
    setThumbnailSrc(getThumbnailURL(event.target.value));
    setSelectedFileName(event.target.value);
    setSelectedFile(attachments.find(el => el.id === parseInt(event.target.value)));
  };

  const getFileFormat = file => {
    if (!file) return;
    return file.fileFormatKey || file.currentFile.fileFormat.key;
  };

  const [thumbnailSrc, setThumbnailSrc] = React.useState('');

  const openPreviewOrDownload = () => {
    if (getFileFormat(selectedFile) === 'pdf')
      return fetchPdfPreview(selectedFile.currentFileId);
    else {
      return fetchFile(
        selectedFile.id,
        selectedFile.name,
        getFileFormat(selectedFile),
      );
    }
  };

  React.useEffect(() => {
    setThumbnailSrc(
      getThumbnailURL(attachments.length ? attachments[0].id : 0) || '',
    );
  }, [attachments]);

  const onThumbnailError = () => {
    setThumbnailSrc(noImage);
  };

  React.useEffect(() => {
    if (getFileFormat(selectedFile) === 'stl') {
      fetchFileAsArraybuffer(selectedFile.id)
        .then(res => setStlFile(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedFile]);

  return (
    <>
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        disabled={attachments && !attachments.length}
      >
        <Select
          variant="standard"
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={selectedFileName || ''}
          onChange={handleSelect}
        >
          {attachments.map(attachment => {
            return (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={attachment.id}
                key={attachment.id}
              >
                {`${attachment.name}.${getFileFormat(attachment)}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Paper className="part-thumbnail-container" style={{display: 'flex', justifyContent: 'center'}}>
        {selectedFile && getFileFormat(selectedFile) === 'stl' && stlFile ?
          <STLViewer
            rotate={true}
            orbitControls={true}
            model={stlFile}
            rotationSpeeds={[0, 0.01, 0]}
            modelColor={'#32bee1'}
            backgroundColor={'#FFF'}
            height={250}
            width={250}
          /> :
          <img
            src={thumbnailSrc}
            onError={onThumbnailError}
            alt="part thumbnail"
            className="part-thumbnail-image"
          />
        }

        {attachments && attachments.length && selectedFile && getFileFormat(selectedFile) !== 'stl' ? (
          <Button
            className="part-thumbnail-button"
            onClick={openPreviewOrDownload}
          >
            {getFileFormat(selectedFile) === 'pdf'
              ? t('pdf_open')
              : t('pdf_download')}
          </Button>
        ) : null}
      </Paper>
    </>
  );
};
export default PartThumbnail;
