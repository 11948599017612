/**
 * @description FIre event when reach the break point starting from top to bottom approach
 * @param {*} elementId
 * @param {*} breakpoint
 * @param {*} onBreakpointEvent
 */
export const addScrollListener = (elementId, breakpoint, onBreakpointEvent) => {
  let pageScroll = document.getElementById(elementId);
  let isPointReached = false;
  if (pageScroll) {
    pageScroll.addEventListener('wheel', () => {
      var viewportOffset = pageScroll.getBoundingClientRect();
      if (viewportOffset.top < breakpoint && !isPointReached) {
        isPointReached = true;
        if (typeof onBreakpointEvent === 'function') {
          onBreakpointEvent({ reachedBreakPoint: true });
        }
      } else if (viewportOffset.top > breakpoint && isPointReached) {
        isPointReached = false;
        if (typeof onBreakpointEvent === 'function') {
          onBreakpointEvent({ reachedBreakPoint: false });
        }
      }
    });
    return pageScroll;
  }
};

/**
 * @description remove scroll event from memory
 * @param {*} pageScrollElement
 */
export function removeScrollListener(pageScrollElement) {
  if (pageScrollElement) {
    pageScrollElement.removeEventListener('wheel', () => {});
  }
}
