import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, IconButton, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';

import { fetchThumbnalFromUrl } from 'services/parts';
import { editPart } from 'services/parts';

import { unassignPartFromPartList } from 'containers/PartListDetails/PartListDetailsActions';
import { editPartName } from 'containers/PartListDetails/PartListDetailsActions';

import Loading from 'components/Loading/Loading';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import InlineEditField from 'components/InlineEditField/InlineEditField';

import './ConfigCardHeader.scss';

import noImage from 'assets/img/no-preview.jpg';

export const ConfigCardHeader = ({ part, hideRemove }) => {
  const { t } = useTranslation('partconfigurations');
  const listId = useSelector(state => state.partList.data.id);

  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [partName, setPartName] = useState(part.name || null);
  const [partNameError, setPartNameError] = useState(false);
  const partNameInputRef = useRef(null);
  const [partArticleNr, setpartArticleNr] = useState(part.articleNr || null);
  const [partArticleNrError, setpartArticleNrError] = useState(false);
  const partArticleNrInputRef = useRef(null);
  const [partDrawingNr, setpartDrawingNr] = useState(part.drawingNr || null);
  const [partDrawingNrError, setpartDrawingNrError] = useState(false);
  const partDrawingNrInputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (part && part.articleNr) {
      setpartArticleNr(part.articleNr);
    }

    if (part && part.drawingNr) {
      setpartDrawingNr(part.drawingNr);
    }

    if (part && part.name) {
      setPartName(part.name);
    }
  }, [part]);

  // ----- PART ARTICLE NUMBER HANDLERS -----
  const handlepartArticleNrChange = event => {
    if (event.target.value.trim() === '') {
      setpartArticleNr('');
      setpartArticleNrError(true);
    } else {
      setpartArticleNr(event.target.value);
      setpartArticleNrError(false);
    }
  };

  const handlepartArticleNrSave = async cancel => {
    if (cancel) {
      setpartArticleNr(part.articleNr);
      return;
    }

    if (partArticleNr.length) {
      try {
        if (partArticleNr.trim() !== part.articleNr) {
          const newPart = await editPart(part.id, {
            articleNr: partArticleNr.trim(),
          });
          setpartArticleNr(partArticleNr);
          dispatch(editPartName(newPart.data));
          enqueueSnackbar(t('article_number_changed'), { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('article_number_failed'), { variant: 'error' });
      }
    } else {
      setpartArticleNrError(false);
      setpartArticleNr(part.articleNr);
    }
  };

  // ----- PART DRAWING NUMBER HANDLERS -----

  const handlepartDrawingNrChange = event => {
    if (event.target.value.trim() === '') {
      setpartDrawingNr('');
      setpartDrawingNrError(true);
    } else {
      setpartDrawingNr(event.target.value);
      setpartDrawingNrError(false);
    }
  };

  const handlepartDrawingNrSave = async cancel => {
    if (cancel) {
      setpartDrawingNr(part.drawingNr);
      return;
    }

    if (partDrawingNr.length) {
      try {
        if (partDrawingNr.trim() !== part.drawingNr) {
          const newPart = await editPart(part.id, {
            drawingNr: partDrawingNr.trim(),
          });
          setpartDrawingNr(partArticleNr);
          dispatch(editPartName(newPart.data));
          enqueueSnackbar(t('drawing_number_changed'), { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('drawing_number_failed'), { variant: 'error' });
      }
    } else {
      setpartDrawingNrError(false);
      setpartDrawingNr(part.drawingNr);
    }
  };

  // ----- PART NAME HANDLERS -----
  const handlePartNameChange = event => {
    if (event.target.value.trim() === '') {
      setPartName('');
      setPartNameError(true);
    } else {
      setPartName(event.target.value);
      setPartNameError(false);
    }
  };

  const handlePartNameSave = async cancel => {
    if (cancel) {
      setPartName(part.name);
      return;
    }

    if (partName.length) {
      try {
        if (partName.trim() !== part.name) {
          const newPart = await editPart(part.id, {
            name: partName.trim(),
          });
          setPartName(partName);
          dispatch(editPartName(newPart.data));
          enqueueSnackbar(t('name_changed'), { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t('name_failed'), { variant: 'error' });
      }
    } else {
      setPartNameError(false);
      setPartName(part.name);
    }
  };

  useEffect(() => {
    async function getThumbnail() {
      if (!part) {
        setThumbnailSrc('');
      } else {
        setLoading(true);
        await loadThumbnail();
        setLoading(false);
      }
    }

    getThumbnail();
  }, [part]);

  const loadThumbnail = async () => {
    const response = await fetchThumbnalFromUrl(part.id, part.thumbnail, {
      width: 500,
      height: 500,
    });

    if (response) {
      setThumbnailSrc(response.thumbnail);
    }
  };

  const removePartFromList = async partId => {
    dispatch(unassignPartFromPartList({ listId, partId }));
  };

  const confirmRemove = (isConfirmed, config) => {
    setOpenConfirm(false);

    if (isConfirmed) {
      setLoading(true);
      removePartFromList(config);
    }
  };

  const editClicked = id => {
    document.dispatchEvent(
      new CustomEvent('editClick', {
        detail: { editId: id },
      })
    );
  };

  return <>
    <Grid container>
      <Grid item xs={2}>
        {loading ? (
          <Loading pageHeight={258} />
        ) : (
          <img
            src={thumbnailSrc || noImage}
            alt="THUMBNAIL"
            className="cc-header-thumb"
          />
        )}
      </Grid>
      <Grid item xs={9}>
        <h4>
          <InlineEditField
            className="cc-header-info"
            text={part.partName || part.name || '-'}
            type="input"
            id="partName"
            redirectRoute={`/part/${part.id}`}
            done={handlePartNameSave}
            onEditClick={editClicked}
          >
            <TextField
              autoFocus
              fullWidth
              value={partName || ''}
              inputRef={partNameInputRef}
              error={partNameError}
              inputProps={{
                type: 'text',
                name: `partName-${part.id}`,
                id: `part-name-input-${part.id}`,
                placeholder: 'Part name',
              }}
              onChange={handlePartNameChange}
            >
              <Link to={`/part/${part.id}`}>
                {part.partName || part.name}
              </Link>
            </TextField>
          </InlineEditField>
        </h4>

        <InlineEditField
          className="cc-header-info"
          text={`${t('article_number')}: ${
            part.partArticleNr || part.articleNr || '-'
          }`}
          type="input"
          id="articleNr"
          placeholder={t('article_number')}
          done={handlepartArticleNrSave}
          onEditClick={editClicked}
        >
          <TextField
            autoFocus
            fullWidth
            value={partArticleNr || ''}
            inputRef={partArticleNrInputRef}
            error={partArticleNrError}
            inputProps={{
              type: 'text',
              name: `partArticleNr-${part.id}`,
              id: `part-articleNr-input-${part.id}`,
              placeholder: t('article_number'),
            }}
            onChange={handlepartArticleNrChange}
          />
        </InlineEditField>

        <InlineEditField
          className="cc-header-info"
          text={`${t('drawing_number')}: ${
            part.partDrawingNr || part.drawingNr || '-'
          }`}
          type="input"
          id="drawingNr"
          placeholder={t('drawing_number')}
          done={handlepartDrawingNrSave}
          onEditClick={editClicked}
        >
          <TextField
            autoFocus
            fullWidth
            value={partDrawingNr || ''}
            inputRef={partDrawingNrInputRef}
            error={partDrawingNrError}
            inputProps={{
              type: 'text',
              name: `partDrawingNr-${part.id}`,
              id: `part-drawingNr-input-${part.id}`,
              placeholder: t('drawing_number'),
            }}
            onChange={handlepartDrawingNrChange}
          />
        </InlineEditField>
      </Grid>
      <Grid container item xs={1} justifyContent="flex-end">
        {!hideRemove && (
          <span>
            <IconButton
              className="cc-remove-part"
              onClick={() => setOpenConfirm(true)}
              size="large">
              <DeleteIcon />
            </IconButton>
          </span>
        )}

        <ConfirmModal
          title={t('t_remove_part_title')}
          description={t('t_remove_part_description')}
          open={openConfirm}
          confirm={isConfirmed => {
            confirmRemove(isConfirmed, part.id);
          }}
        />
      </Grid>
    </Grid>
  </>;
};
