import React, {Component} from 'react';
import {
  Autocomplete,
  Box,
  Fade,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Close, Person} from '@mui/icons-material';
import './UpdatePartListContactModal.scss';

class UpdatePartListContactModal extends Component {
  constructor(props) {
    super(props);
  }

  closeModal = async () => {
    this.props.setOpen(false);
  };

  render() {
    const {open, users, contactUserId, setContactUserId, t} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true}>
        <Fade in={open} timeout={250}>
          <Box className={'update-part-list-contact-modal'}>
            <Paper sx={{height: '100%'}}>
              <IconButton
                size={'small'}
                className={'close-update-part-list-contact-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                className={'update-part-list-contact-modal-content'}
              >
                <Grid xs={12}>
                  <Typography>{t('plpd_update_contact')}</Typography>
                </Grid>
                <Grid xs={12} alignItems={'flex-end'} sx={{width: '100%'}}>
                  <Autocomplete
                    options={users}
                    fullWidth
                    disableClearable
                    getOptionLabel={option => `${option.lastName || '?'}, ${option.firstName || '?'}; ${option.email}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={users.find(user => user.id === contactUserId)}
                    onChange={(event, value) => setContactUserId(value.id)}
                    renderInput={params => <TextField
                      {...params}
                      size={'small'}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position={'start'}><Person fontSize={'small'} /></InputAdornment>,
                      }}
                    />}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }
}

export default withTheme(UpdatePartListContactModal);
