import React from 'react';

import {Breadcrumbs as MuiBreadcrumbs, Unstable_Grid2 as Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {generateRandomNumberForId} from 'utils/helpers';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useTranslation} from 'react-i18next';

export default function Breadcrumbs({breadcrumbs, containered, actions}) {
  const {t} = useTranslation([
    'partstorage',
    'partlists',
    'sharedparts',
    'settings',
    'filespage',
    'orders',
    'estimated_part_lists',
  ]);

  return (
    <div
      style={{
        padding: 10,
        paddingRight: 16,
        paddingLeft: 16,
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 32,
      }}
      className={containered && 'MuiContainer-root MuiContainer-maxWidthLg'}
    >
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid>
          <MuiBreadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs.map((item, index, arr) => {
              if (index === arr.length - 1 || arr.length === 1) {
                return (
                  <Typography
                    key={index + generateRandomNumberForId()}
                    color="textPrimary"
                  >
                    {arr.length === 1 ? (<h3 style={{margin: 0}}>{t(item.text)}</h3>) : (t(item.text))}
                  </Typography>
                );
              }

              return (
                <Link
                  key={index + generateRandomNumberForId()}
                  color="inherit"
                  to={item.path}
                >
                  {t(item.text)}
                </Link>
              );
            })}
          </MuiBreadcrumbs>
        </Grid>
        <Grid>
          {actions}
        </Grid>
      </Grid>
    </div>
  );
}
