import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import EmptyPartSearchList from 'components/Errors/NoItemsError/EmptyPartSearchList';
import PaginationLayout from 'components/ItemLists/ItemsListLayouts/PaginationLayout';
import Loading from 'components/Loading/Loading';
import { PartListConfigItems } from 'components/ItemLists/PartListConfigItems/PartListConfigItems';

export const PartListNewConfigsView = ({
  loading,
  data,
  fetchAll,
  handleListPageChange,
  handleToggleConfigSelect,
  selectableConfigs,
  canAddConfig,
  hideRemove,
}) => {
  const partList = useSelector(state => state.partList);

  return (
    <Grid item sm={12}>
      {loading ? (
        <Loading />
      ) : data && data.page && data.page.length < 1 ? (
        <EmptyPartSearchList />
      ) : (
        <PaginationLayout
          pagination={fetchAll ? data.pagination : partList.data.pagination}
          getPage={handleListPageChange}
        >
          <PartListConfigItems
            items={data}
            toggleConfigSelect={handleToggleConfigSelect}
            fetchAll={fetchAll}
            selectableConfigs={selectableConfigs}
            canAddConfig={canAddConfig}
            hideRemove={hideRemove}
          />
        </PaginationLayout>
      )}
    </Grid>
  );
};
