import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Radio,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Collapse,
  Chip,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import localizedCountries from 'localized-countries';
import {City, Country} from 'country-state-city';
import {allCountries} from 'country-region-data';

import './OrderCheckoutModal.scss';

class OrderCheckoutModalAddressForm extends Component {
  constructor(props) {
    super(props);
    const localizedCountriesForUserLanguage = localizedCountries(require(`localized-countries/data/${props.i18n.language}`));
    this.state = {
      localizedCountriesForUserLanguage,
      addressesFetched: false,
      selectedAddress: 0,
      saveAddress: false,
    };
  }

  async componentDidMount() {
    if (this.props.userAddresses.filter(address => address.addressType === (this.props.billingAddress ? 'billing' : 'shipping')).length && !this.state.selectedAddress) {
      await this.setAddress(0);
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.userAddresses.filter(address => address.addressType === (prevProps.billingAddress ? 'billing' : 'shipping')).length !==
      this.props.userAddresses.filter(address => address.addressType === (this.props.billingAddress ? 'billing' : 'shipping')).length) {
      await this.setAddress(0);
    }
  }

  setOtherAddress = async () => {
    this.setState({
      selectedAddress: this.props.userAddresses.filter(address => address.addressType === (this.props.billingAddress ? 'billing' : 'shipping')).length,
    });

    // Set all address fields to empty strings
    await this.props.handleCountrySelection('DE', this.props.billingAddress);
    this.props.handleRegionSelection('BE', this.props.billingAddress);
    this.props.handleAddressChange({
      addressLine1: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      saveAddress: false,
    }, this.props.billingAddress);
  };

  setAddress = async index => {
    await this.setState({
      selectedAddress: index,
    });

    const address = this.props.userAddresses.filter(address => address.addressType === (this.props.billingAddress ? 'billing' : 'shipping'))[index];

    // Set address fields to those of the address at this index
    await this.props.handleCountrySelection(address.countryKey, this.props.billingAddress);

    let selectedRegionLabel = address.state;
    // If the address does not have a state, try to find the region by the city name
    if (!selectedRegionLabel?.length) {
      const region = City.getCitiesOfCountry(address.countryKey).find(city => city.name === address.city)?.stateCode;
      const regionLabel = allCountries.find(country => country[1] === address.countryKey)[2].find(r => r[1] === region)?.[0];
      selectedRegionLabel = regionLabel || address.city;
    }

    this.props.handleAddressChange({
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      zipCode: address.zipCode,
      city: address.city,
      selectedRegionLabel,
      saveAddress: false,
    }, this.props.billingAddress);
  };

  render() {
    const {
      address: {
        addressLabel,
        addressLine1,
        addressLine2,
        city,
        zipCode,
        selectedRegion,
        selectedCountry,
        saveAddress,
      },
      handleAddressChange,
      handleRegionSelection,
      handleCountrySelection,
      billingAddress,
      t,
      userAddresses,
    } = this.props;

    const filteredUserAddresses = userAddresses.filter(address => address.addressType === (billingAddress ? 'billing' : 'shipping'));
    const countries = Country.getAllCountries().filter(country => ['DE', 'AT', 'CH', 'BE', 'FR', 'IT', 'SE', 'GB'].includes(country.isoCode));

    return (
      <Table size={'small'}>
        <TableBody>
          {filteredUserAddresses.map((address, index) => (
            <TableRow style={{borderBottom: 0}}>
              <TableCell style={{width: '10%', verticalAlign: 'center', borderBottom: 0, paddingRight: 6}}>
                <Radio
                  style={{padding: 0, color: '#00acc1'}}
                  onClick={() => this.setAddress(index)}
                  checked={this.state.selectedAddress === index}
                />
              </TableCell>
              <TableCell style={{width: '90%', borderBottom: 0, paddingLeft: 0}}>
                <Tooltip
                  title={
                    <Grid container xs={12} justifyContent={'flex-start'} alignItems={'center'}>
                      <Grid item xs={12}><Typography>{address.addressLine1}</Typography></Grid>
                      {address.addressLine2 && <Grid item xs={12}><Typography>{address.addressLine2}</Typography></Grid>}
                      <Grid item xs={12}><Typography>{address.zipCode} {address.city}</Typography></Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {address.state && `${address.state}, `}{this.state.localizedCountriesForUserLanguage.get(address.countryKey)}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  placement={'right'}
                  arrow
                >
                  <Chip label={address.addressLabel || address.addressLine1} color={'primary'} sx={{color: 'white', fontSize: '1rem'}} />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell style={{width: '10%', verticalAlign: 'top', borderBottom: 0, paddingBottom: 0, paddingRight: 6}}>
              <Radio
                style={{padding: 0, color: '#00acc1'}}
                onClick={this.setOtherAddress}
                checked={this.state.selectedAddress === filteredUserAddresses.length}
              />
            </TableCell>
            <TableCell style={{width: '90%', borderBottom: 0, paddingBottom: 0, paddingLeft: 6}}>
              <Typography>{t('other_address')}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{borderBottom: 0, padding: 0, paddingTop: 6}} colSpan={2}>
              <Collapse in={this.state.selectedAddress === filteredUserAddresses.length}>
                <TableRow style={{width: '100%'}}>
                  <TableCell style={{width: '8%', borderBottom: 0}} />
                  <TableCell style={{width: '92%', borderBottom: 0, paddingTop: 2}}>
                    <Grid item xs={12} container justifyContent={'flex-start'} spacing={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              label={`${t('address_line')} 1`}
                              value={addressLine1}
                              onChange={({target: {value}}) => handleAddressChange({addressLine1: value}, billingAddress)}
                              variant={'standard'}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              label={`${t('address_line')} 2`}
                              value={addressLine2}
                              onChange={({target: {value}}) => handleAddressChange({addressLine2: value}, billingAddress)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              label={t('zip_code')}
                              value={zipCode}
                              onChange={({target: {value}}) => handleAddressChange({zipCode: value}, billingAddress)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              label={t('city')}
                              value={city}
                              onChange={({target: {value}}) => handleAddressChange({city: value}, billingAddress)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant={'standard'} fullWidth>
                            <InputLabel id={'region-label'}>{t('state_region')}</InputLabel>
                            <Select
                              required
                              error={!selectedRegion}
                              labelId={'region-label'}
                              value={selectedRegion}
                              onChange={({target: {value}}) => handleRegionSelection(value, billingAddress)}
                              fullWidth
                              variant={'standard'}
                            >
                              {allCountries.find(country => country[1] === selectedCountry)[2].map(region => (
                                <MenuItem value={region[1]} key={region[1]}>
                                  {region[0]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl variant={'standard'} fullWidth>
                            <InputLabel id={'country-label'}>{t('country')}</InputLabel>
                            <Select
                              required
                              labelId={'country-label'}
                              value={selectedCountry}
                              onChange={({target: {value}}) => handleCountrySelection(value, billingAddress)}
                              variant={'standard'}
                            >
                              {countries.map(country => (
                                <MenuItem value={country.isoCode} key={country.isoCode}>
                                  {this.state.localizedCountriesForUserLanguage.get(country.isoCode)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label={t('save_address')}
                            control={<Checkbox checked={saveAddress} onClick={() => handleAddressChange({saveAddress: !saveAddress}, billingAddress)} />}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Collapse in={saveAddress}>
                            <FormControl fullWidth>
                              <TextField
                                required={saveAddress}
                                error={saveAddress && !addressLabel || this.props.userAddresses.some(a => a.addressLabel === addressLabel?.trim())}
                                helperText={saveAddress && !addressLabel ? t('address_label_required') : this.props.userAddresses.some(a => a.addressLabel === addressLabel?.trim()) ? t('address_label_exists') : ''}
                                label={`${t('address_label')}`}
                                value={addressLabel}
                                onChange={({target: {value}}) => handleAddressChange({addressLabel: value}, billingAddress)}
                                variant={'standard'}
                              />
                            </FormControl>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default withTranslation('order_checkout_modal')(OrderCheckoutModalAddressForm);
