import React, { useEffect } from 'react';
// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
// styles for buttons on sweetalert
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);

const SuccessModal = ({
  open,
  title,
  description,
  btnText = 'Done',
  onConfirm,
}) => {
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (open) {
      successAlert();
    } else {
      hideAlert();
    }
  }, [open]);

  const handleClick = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title={title}
        onConfirm={() => handleClick()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        confirmBtnText={btnText}
      >
        {description}
      </SweetAlert>
    );
  };
  return <div>{alert}</div>;
};

export default SuccessModal;
