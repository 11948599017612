import React, {Component} from 'react';
import {TextField, Tooltip} from '@mui/material';
import {withTranslation} from 'react-i18next';
import {ResizeDetector} from '../../utils/helpers';

class ClickToEditTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textFieldFocused: false,
      textFieldHovered: false,
    };
  }

  setTextFieldFocused = textFieldFocused => {
    this.setState({
      textFieldFocused,
    });
  };

  setTextFieldHovered = textFieldHovered => {
    this.setState({
      textFieldHovered,
    });
  };

  onBlur = value => {
    this.setState({
      textFieldFocused: false,
      textFieldHovered: false,
    });

    this.props.onBlur(value);
  };

  render() {
    const {onChange, value, fontWeight, fontSize, fontFamily, disableUnderline, maxLength, inputType, t} = this.props;

    return (
      <Tooltip
        title={this.state?.actualWidth > this.state?.width ? `${value} (${t('cmn_click_to_edit')})` : t('cmn_click_to_edit')}
        followCursor
        open={!this.state.textFieldFocused && this.state.textFieldHovered}
      >
        <div
          style={{
            display: 'inline-block',
            margin: 0,
            padding: 0,
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              width: 'max-content',
              maxWidth: '100%',
              whiteSpace: 'pre',
              height: 0,
              visibility: 'hidden',
              fontWeight: fontWeight || 'inherit',
              fontSize: fontSize || 'inherit',
              fontFamily: fontFamily || 'inherit',
            }}
          >
            <ResizeDetector handleHeight={false} onResize={width => this.setState({width: width + 2})} />
            {value}
          </div>
          <div
            style={{
              width: 'max-content',
              maxWidth: 'max-content',
              whiteSpace: 'pre',
              height: 0,
              visibility: 'hidden',
              fontWeight: fontWeight || 'inherit',
              fontSize: fontSize || 'inherit',
              fontFamily: fontFamily || 'inherit',
            }}
          >
            <ResizeDetector handleHeight={false} onResize={width => this.setState({actualWidth: width + 2})} />
            {value}
          </div>
          <TextField
            style={{display: 'inline'}}
            fullWidth
            variant={'standard'}
            InputProps={{
              style: {
                width: this.state.width,
                fontWeight: fontWeight || 'inherit',
                fontSize: fontSize || 'inherit',
                fontFamily: fontFamily || 'inherit',
              },
              disableUnderline: disableUnderline || !this.state.textFieldFocused,
            }}
            inputProps={{
              style: {
                paddingBottom: 3,
                textOverflow: 'ellipsis',
              },
              maxLength,
            }}
            placeholder="-"
            value={value}
            type={inputType || 'text'}
            onChange={({target: {value}}) => onChange(value)}
            onFocus={() => this.setTextFieldFocused(true)}
            onMouseOver={() => this.setTextFieldHovered(true)}
            onMouseLeave={() => this.setTextFieldHovered(false)}
            onBlur={({target: {value}}) => this.onBlur(value)}
          />
        </div>
      </Tooltip>
    );
  }
}

export default withTranslation('common')(ClickToEditTextField);
