import React from 'react';
import { Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/SharedParts/SearchInput/SearchInput';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
const SharedPartsEmptyPage = () => {
  const { t } = useTranslation('sharedparts');
  const classes = useStyles();
  return (
    <Container>
      <Grid item alignItems="center" className={'share-parts-page'}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <h2>{t('shareparts_page_title')}</h2>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <SearchInput disabled />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 100 }}>
          <h2>{t('shareparts_empty_page_title')}</h2>
          <p>{t('shareparts_empty_page_desc')}</p>
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 15 }}>
            <div className={classes.root}>
              <Button variant="outlined" color="primary" href={'/part-storage'}>
                {t('shareparts_go_part_storage_btn')}
              </Button>
              <Button variant="contained" color="primary" href={'/part-lists'}>
                {t('shareparts_go_part_lists_btn')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SharedPartsEmptyPage;
