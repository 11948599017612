import React from 'react';
import '../parts-modals.scss';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from 'components/CustomButtons/Button.js';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(styles);

export const DeletePartModal = ({
  loading,
  open,
  withButton = true,
  confirm,
}) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  const hideAlert = isConfirmed => {
    if (typeof confirm === 'function') {
      confirm(isConfirmed);
    }
    if (withButton) {
      setAlert(null);
    }
  };
  const deleteModal = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_general_delete')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={
          classes.button + ' ' + classes.danger + ' ' + 'md-disabled-button'
        }
        cancelBtnCssClass={classes.button + ' ' + classes.cancel}
        title={t('mdl_delete_part_title')}
        disabled
      >
        {t('mdl_delete_part_description')}
      </SweetAlert>
    );
  };

  return (
    <div>
      {withButton && (
        <>
          {alert}
          <Button color={'danger'} onClick={deleteModal}>
            <DeleteIcon />
            {t('mdl_delete_button')}
          </Button>
        </>
      )}
      {!withButton && open ? (
        <SweetAlert
          warning
          showCancel
          closeOnClickOutside={false}
          onConfirm={() => hideAlert(true)}
          onCancel={() => hideAlert(false)}
          confirmBtnText={t('mdl_general_delete')}
          cancelBtnText={t('mdl_general_cancel')}
          confirmBtnCssClass={
            classes.button +
            ' ' +
            classes.danger +
            ' ' +
            (loading && 'md-disabled-button')
          }
          cancelBtnCssClass={
            classes.button +
            ' ' +
            classes.cancel +
            ' ' +
            (loading && ' md-disabled-button')
          }
          title={t('mdl_delete_part_title')}
          disabled={loading}
        >
          {t('mdl_delete_part_description')}
        </SweetAlert>
      ) : (
        <>
          {loading && (
            <div className="delete-file-modal-indicator">
              <CircularProgress />
            </div>
          )}
        </>
      )}
    </div>
  );
};
