import React from 'react';
import './Loading.scss';
import { Grid, Typography } from '@mui/material';
import BoxLogo from 'assets/img/box-logo.svg';

export default function Loading({ pageHeight = 900 }) {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}
      className={'loading-container'}
      style={{ height: pageHeight }}
    >
      <Grid
        item
        md={4}
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h3" component="h4">
          <div className="lds-css ng-scope">
            <div className={'lds-eclipse'}>
              <div></div>
            </div>
            <img alt="loading" src={BoxLogo} className={'loading-logo'}></img>
          </div>
        </Typography>
      </Grid>
    </Grid>
  );
}

export function LoadingWithoutHeight() {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}
      className={'center'}
    >
      <Grid
        item
        md={4}
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h3" component="h4">
          <div className="lds-css ng-scope">
            <div className={'lds-eclipse'}>
              <div/>
            </div>
            <img alt="loading" src={BoxLogo} className={"loading-logo"}/>
          </div>
        </Typography>
      </Grid>
    </Grid>
  );
}
