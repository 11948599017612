import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { NavLink } from 'react-router-dom';

const PartListRow = ({ row, columns }) => {
  /**
   * @description Render table cell with simple raw value display
   */
  const renderTableCellWithValue = (column, value) => {
    return (
      <TableCell key={column.id} align={column.align}>
        {column.label === 'pdl_column_name' ? (
          <NavLink to={`/part-lists/${row.id}`}>{value}</NavLink>
        ) : column.format && typeof value === 'number' ? (
          column.format(value)
        ) : (
          value
        )}
      </TableCell>
    );
  };

  return (
    <TableRow hover tabIndex={-1} key={'lists-part-' + row.id}>
      {columns.map(column => {
        return renderTableCellWithValue(column, row[column.id]);
      })}
    </TableRow>
  );
};

export default PartListRow;
