import React, { useEffect } from 'react';
import Pagination from 'components/Pagination/Pagination';
import { Container, Grid } from '@mui/material';

export const PaginationComponent = ({ paginationObject, getPage }) => {
  const getPaginationItems = () => {
    let items = null;
    if (paginationObject) {
      items = [];
      for (const x of Array(paginationObject.totalPageCount).keys()) {
        const activePage = paginationObject.pagination
          ? paginationObject.pagination.currentPage === x + 1
          : paginationObject.currentPage === x + 1;
        items.push({
          text: x + 1,
          value: x + 1,
          active: activePage,
          onClick: getPage,
        });
      }
    }
    return items;
  };

  const [page, setPage] = React.useState(null);

  useEffect(() => {
    setPage(getPaginationItems());
  }, [paginationObject]);

  return (
    <Container className="pagination">
      <Grid container justifyContent={'center'}>
        <Pagination
          pages={
            page
              ? [
                  { text: '<<', active: false, onClick: getPage },
                  ...page,
                  { text: '>>', active: false, onClick: getPage },
                ]
              : [{ text: 1, active: true }]
          }
          color="info"
        />
      </Grid>
    </Container>
  );
};
