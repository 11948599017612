var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Alert, AlertTitle, Box, Button, Fade, IconButton, Modal, Typography, Unstable_Grid2 as Grid, Paper } from '@mui/material';
import './ConfiguratorModal.scss';
import { Cancel, Close, Download, NavigateBefore, NavigateNext, OpenInNew, SettingsSuggest } from '@mui/icons-material';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useTranslation, withTranslation } from 'react-i18next';
import { downloadFile, fetchFile } from '../../../services/parts';
import PartInformation from './PartInformation';
import de_DE from '@react-pdf-viewer/locales/lib/de_DE.json';
import GeneralConfigurationInformation from './GeneralConfigurationInformation';
import Processes from './Processes';
import { fetchMaterials } from '../../../services/materials';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { cancelManufacturingAnalysis, triggerManufacturingAnalysis } from '../../../services/configs';
import { AxiosError } from 'axios';
const MySwal = withReactContent(Swal);
const PDFViewer = ({ file }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar: (Toolbar) => (_jsx(Toolbar, { children: (props) => {
                const { Zoom, ZoomIn, ZoomOut } = props;
                return (_jsxs(Grid, { container: true, justifyContent: 'center', sx: { width: '100%' }, children: [_jsx(Grid, { children: _jsx(ZoomOut, {}) }), _jsx(Grid, { children: _jsx(Zoom, {}) }), _jsx(Grid, { children: _jsx(ZoomIn, {}) })] }));
            } })),
    });
    const { i18n } = useTranslation();
    return file ? (_jsx(Worker, { workerUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js', children: _jsx(Viewer, { fileUrl: file, plugins: [defaultLayoutPluginInstance], defaultScale: SpecialZoomLevel.PageWidth, localization: i18n.language === 'de' ? de_DE : undefined }) })) : (_jsx(_Fragment, {}));
};
class ConfiguratorModal extends Component {
    constructor(props) {
        super(props);
        this.closeModal = () => {
            var _a, _b;
            (_b = (_a = this.props).onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
        this.updatePartInState = (part) => {
            var _a;
            this.props.updatePartConfigInState(Object.assign(Object.assign({}, (_a = this.props.partConfigInEditMode) === null || _a === void 0 ? void 0 : _a.partConfig), { part }));
        };
        this.promptInvalidateMA = () => __awaiter(this, void 0, void 0, function* () {
            const { isConfirmed } = yield MySwal.fire({
                title: _jsx(Typography, { variant: 'h5', children: this.props.t('pc_mdl_change_material_ma_alert') }),
                icon: 'warning',
                confirmButtonText: '✓',
                showCancelButton: true,
                cancelButtonText: this.props.t('cancel'),
                reverseButtons: true,
                confirmButtonColor: '#32bee1',
            });
            return isConfirmed;
        });
        this.startManufacturingAnalysis = () => __awaiter(this, void 0, void 0, function* () {
            const { partConfigInEditMode, t } = this.props;
            const { isConfirmed } = yield MySwal.fire({
                title: _jsx(Typography, { variant: 'h5', children: this.props.t('pc_mdl_manufacturing_analysis_check') }),
                icon: 'question',
                confirmButtonText: t('go'),
                showCancelButton: true,
                cancelButtonText: t('cancel'),
                reverseButtons: true,
                confirmButtonColor: '#32bee1',
                showLoaderOnConfirm: true,
                preConfirm() {
                    return __awaiter(this, void 0, void 0, function* () {
                        var _a, _b;
                        try {
                            yield triggerManufacturingAnalysis(partConfigInEditMode.partConfig.id);
                        }
                        catch (err) {
                            if (err instanceof AxiosError) {
                                Swal.showValidationMessage(((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || (err === null || err === void 0 ? void 0 : err.message));
                            }
                            else {
                                Swal.showValidationMessage(t('ma_error'));
                            }
                        }
                    });
                },
            });
            if (isConfirmed) {
                this.props.updatePartConfigTeaserInState(Object.assign(Object.assign({}, partConfigInEditMode.partConfigTeaser), { maRunning: true }));
                yield MySwal.fire({
                    icon: 'success',
                    confirmButtonText: '✓',
                    confirmButtonColor: '#32bee1',
                });
            }
        });
        this.cancelManufacturingAnalysis = () => __awaiter(this, void 0, void 0, function* () {
            const { partConfigInEditMode, t } = this.props;
            const { isConfirmed } = yield MySwal.fire({
                title: _jsx(Typography, { variant: 'h5', children: t('cancel_ma_header') }),
                text: t('cancel_ma_body'),
                icon: 'warning',
                confirmButtonText: t('yes'),
                showCancelButton: true,
                cancelButtonText: t('cancel'),
                reverseButtons: true,
                confirmButtonColor: '#32bee1',
                showLoaderOnConfirm: true,
                preConfirm() {
                    return __awaiter(this, void 0, void 0, function* () {
                        var _a, _b;
                        try {
                            yield cancelManufacturingAnalysis(partConfigInEditMode.partConfig.id);
                        }
                        catch (err) {
                            if (err instanceof AxiosError) {
                                Swal.showValidationMessage(((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || (err === null || err === void 0 ? void 0 : err.message));
                            }
                            else {
                                Swal.showValidationMessage(t('cancel_ma_error'));
                            }
                        }
                    });
                },
            });
            if (isConfirmed) {
                this.props.updatePartConfigTeaserInState(Object.assign(Object.assign({}, partConfigInEditMode.partConfigTeaser), { maRunning: false }));
                yield MySwal.fire({
                    icon: 'success',
                    confirmButtonText: '✓',
                    confirmButtonColor: '#32bee1',
                });
            }
        });
        this.state = {
            hasTechnicalDrawing: true,
            technicalDrawing: undefined,
            materials: [],
        };
    }
    downloadTechnicalDrawing() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const { partConfigInEditMode } = this.props;
            const partConfigTechnicalDrawing = (_d = (_c = (_b = (_a = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig) === null || _a === void 0 ? void 0 : _a.part) === null || _b === void 0 ? void 0 : _b.partAttachments) === null || _c === void 0 ? void 0 : _c.find(pa => { var _a, _b; return ((_b = (_a = pa.attachment) === null || _a === void 0 ? void 0 : _a.attachmentType) === null || _b === void 0 ? void 0 : _b.key) === 'technical_drawing'; })) === null || _d === void 0 ? void 0 : _d.attachment;
            let fileURL = partConfigTechnicalDrawing === null || partConfigTechnicalDrawing === void 0 ? void 0 : partConfigTechnicalDrawing.fileURL;
            // If the file isn't loaded into memory yet, download it
            if (partConfigTechnicalDrawing && !fileURL) {
                try {
                    const file = yield downloadFile(partConfigTechnicalDrawing.id);
                    const blob = new Blob([file], {
                        type: 'application/pdf',
                    });
                    fileURL = URL.createObjectURL(blob);
                    this.props.updatePartConfigInState(Object.assign(Object.assign({}, partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig), { part: Object.assign(Object.assign({}, (_e = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig) === null || _e === void 0 ? void 0 : _e.part), { partAttachments: (_h = (_g = (_f = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig) === null || _f === void 0 ? void 0 : _f.part) === null || _g === void 0 ? void 0 : _g.partAttachments) === null || _h === void 0 ? void 0 : _h.map(pa => {
                                if (pa.attachment.id === partConfigTechnicalDrawing.id) {
                                    return Object.assign(Object.assign({}, pa), { attachment: Object.assign(Object.assign({}, partConfigTechnicalDrawing), { fileURL }) });
                                }
                                return pa;
                            }) }) }));
                }
                catch (err) { }
            }
            this.setState({
                technicalDrawing: fileURL,
                hasTechnicalDrawing: !(partConfigInEditMode && !partConfigTechnicalDrawing) && !!fileURL,
            });
        });
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.downloadTechnicalDrawing();
            const { data: materials } = yield fetchMaterials({
                filter: { include: { materialNames: true, materialCategory: { include: { materialFeatures: true } }, materialHeatStates: true } },
            });
            this.setState({ materials });
        });
    }
    componentDidUpdate(prevProps) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.props.partConfigInEditMode !== prevProps.partConfigInEditMode) {
                if (this.props.partConfigInEditMode)
                    yield this.downloadTechnicalDrawing();
                else
                    this.setState({ hasTechnicalDrawing: true });
            }
        });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const { t, open, partConfigInEditMode } = this.props;
        const partConfig = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig;
        const part = partConfig === null || partConfig === void 0 ? void 0 : partConfig.part;
        const partFiles = [
            (_d = (_c = (_b = (_a = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig) === null || _a === void 0 ? void 0 : _a.part) === null || _b === void 0 ? void 0 : _b.partAttachments) === null || _c === void 0 ? void 0 : _c.find(pa => { var _a; return ((_a = pa.attachment.attachmentType) === null || _a === void 0 ? void 0 : _a.key) === 'technical_drawing'; })) === null || _d === void 0 ? void 0 : _d.attachment,
            (_h = (_g = (_f = (_e = partConfigInEditMode === null || partConfigInEditMode === void 0 ? void 0 : partConfigInEditMode.partConfig) === null || _e === void 0 ? void 0 : _e.part) === null || _f === void 0 ? void 0 : _f.partAttachments) === null || _g === void 0 ? void 0 : _g.find(pa => { var _a, _b, _c; return ((_a = pa.attachment.attachmentType) === null || _a === void 0 ? void 0 : _a.key) === 'cad_file' && ((_c = (_b = pa.attachment.currentFile) === null || _b === void 0 ? void 0 : _b.fileFormat) === null || _c === void 0 ? void 0 : _c.extension) !== 'stl'; })) === null || _h === void 0 ? void 0 : _h.attachment,
        ].filter((attachment) => Boolean(attachment));
        return _jsx(Modal, { open: open, closeAfterTransition: true, className: 'configurator-modal-root', children: _jsx(Fade, { in: open, timeout: 250, children: _jsx("div", { className: 'configurator-modal', children: _jsxs(Paper, { className: 'configurator-modal-paper', children: [_jsx(IconButton, { size: 'small', className: 'close-configurator-modal-button', onClick: this.closeModal, children: _jsx(Close, {}) }), partConfigInEditMode && (_jsxs(Box, { sx: {
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    gridTemplateRows: '8px calc(100% - 72px) 40px',
                                    rowGap: '16px',
                                    columnGap: '16px',
                                    height: '100%',
                                    padding: '16px',
                                }, children: [_jsx(Box, {}), _jsx(Box, {}), _jsx(Box, { textAlign: 'center', sx: { overflowY: 'auto' }, children: this.state.hasTechnicalDrawing ? (_jsx(PDFViewer, { file: this.state.technicalDrawing })) : (_jsx("div", { style: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                width: '100%',
                                            }, children: _jsxs(Alert, { severity: 'warning', sx: { textAlign: 'left', maxWidth: '60%' }, children: [_jsx(AlertTitle, { children: t('no_technical_drawing_header') }), t('no_technical_drawing_body'), _jsx("br", {}), _jsx(Button, { variant: 'contained', size: 'small', sx: { marginTop: '1em' }, endIcon: _jsx(OpenInNew, {}), children: t('go_to_part') })] }) })) }), ((_j = partConfigInEditMode.partConfigTeaser) === null || _j === void 0 ? void 0 : _j.maRunning) ? (_jsx(Box, { sx: {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            width: '100%',
                                        }, children: _jsxs(Alert, { severity: 'info', variant: 'outlined', sx: { textAlign: 'left', maxWidth: '60%' }, children: [_jsx(AlertTitle, { children: t('ma_running_header') }), t('ma_running_body')] }) })) : (_jsxs(Box, { sx: {
                                            overflowY: 'scroll',
                                            '& > :not(:last-child)': {
                                                marginBottom: '8px',
                                            },
                                        }, children: [_jsx(PartInformation, { part: part, updatePartInState: this.updatePartInState, useSuspense: false, manufacturingAnalysisRunning: !!((_k = partConfigInEditMode.partConfigTeaser) === null || _k === void 0 ? void 0 : _k.maRunning) }), _jsx(GeneralConfigurationInformation, { partConfigInEditMode: partConfigInEditMode, updatePartConfigInState: this.props.updatePartConfigInState, materials: this.state.materials, promptInvalidateMA: this.promptInvalidateMA }), _jsx(Processes, { partConfigInEditMode: partConfigInEditMode, updatePartConfigInState: this.props.updatePartConfigInState })] })), _jsx(Box, { textAlign: 'center', children: _jsx(Grid, { container: true, justifyContent: 'center', sx: { width: '100%' }, spacing: 1, children: partFiles.map(attachment => {
                                                var _a, _b, _c, _d;
                                                return (_jsx(Grid, { xs: 'auto', children: _jsxs(Button, { variant: 'contained', color: 'secondary', endIcon: _jsx(Download, {}), size: 'small', sx: {
                                                            whiteSpace: 'nowrap',
                                                        }, onClick: () => { var _a, _b; return fetchFile(attachment.id, attachment.name, (_b = (_a = attachment.currentFile) === null || _a === void 0 ? void 0 : _a.fileFormat) === null || _b === void 0 ? void 0 : _b.extension); }, children: [(_a = attachment.name) === null || _a === void 0 ? void 0 : _a.trim().substring(0, 10), ((_b = attachment.name) === null || _b === void 0 ? void 0 : _b.trim().length) > 10 ? '…' : '', " (", (_d = (_c = attachment.currentFile) === null || _c === void 0 ? void 0 : _c.fileFormat) === null || _d === void 0 ? void 0 : _d.extension, ")"] }) }, `attachment-${attachment.id}-download-btn`));
                                            }) }) }), _jsx(Box, { textAlign: 'right', children: _jsxs(Grid, { container: true, sx: { width: '100%' }, justifyContent: 'flex-end', spacing: 1, children: [((_l = partConfigInEditMode.partConfigTeaser) === null || _l === void 0 ? void 0 : _l.maRunning) ? (_jsx(Grid, { xs: 'auto', children: _jsx(Button, { variant: 'contained', color: 'error', startIcon: _jsx(Cancel, {}), onClick: this.cancelManufacturingAnalysis, children: t('cancel_manufacturing_analysis') }) })) : (_jsx(Grid, { xs: 'auto', children: _jsx(Button, { variant: 'contained', color: 'success', startIcon: _jsx(SettingsSuggest, {}), onClick: this.startManufacturingAnalysis, children: t('manufacturing_analysis') }) })), _jsx(Grid, { xs: 'auto', children: _jsx(Button, { variant: 'contained', color: 'primary', disabled: !this.props.previousPart, startIcon: _jsx(NavigateBefore, {}), onClick: this.props.previousPart, children: t('previous_part') }) }), _jsx(Grid, { xs: 'auto', children: _jsx(Button, { variant: 'contained', color: 'primary', disabled: !this.props.nextPart, endIcon: _jsx(NavigateNext, {}), onClick: this.props.nextPart, children: t('next_part') }) })] }) })] }))] }) }) }) });
    }
}
export default withTranslation(['partconfigurator'])(ConfiguratorModal);
