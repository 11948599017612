import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFirstPageLists } from 'store/general/generalActions';

import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  CircularProgress, 
  Modal,
  Paper,
} from "@mui/material";

import { CreateNewPartListModal } from '../CreateNewPartListModal/CreateNewPartListModal';
import { PaginationComponent } from 'containers/Pagination/Pagination';
import ListModalItem from './ListModalItem/ListModalItem';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import '../parts-modals.scss';
import './ListModal.scss';
import {logEvent} from 'utils/firebase';

export const ListModal = ({
  isIconButton,
  buttonSize,
  saveLoadingMsg,
  getListPage,
  onConfirm,
  onListRefresh,
  onCancel,
}) => {
  const { t } = useTranslation('modals');
  const [alert, setAlert] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const listsForModal = useSelector(state => state.general.lists);
  const [openNewListModal, setOpenNewListModal] = React.useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const listOptions = { limit: 4, order: 'createdAt DESC' };
  const dispatch = useDispatch();

  // MODAL GENERAL METHODS
  const openModal = () => {
    setAlert(true);
  };

  const endConfirmLoading = () => {
    setIsConfirmLoading(false);
    clearSelectedItems();
    dispatch(
      getFirstPageLists({
        filter: { ...listOptions },
      })
    );
    setAlert(false);
  };

  const handleAlertConfirm = () => {
    if (typeof onConfirm === 'function') {
      setIsConfirmLoading(true);
      logEvent('click_add_part_to_list');
      onConfirm({
        selectedLists: selectedItems,
        endConfirmLoading: endConfirmLoading,
      });
    }
  };

  const handleAlertCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    dispatch(getFirstPageLists({ filter: { ...listOptions } }));
    clearSelectedItems();
    setAlert(null);
  };

  // CREATE NEW LIST MODAL
  const handleNewListCancel = () => {
    setOpenNewListModal(false);
  };

  const handleCreateNewList = () => {
    setOpenNewListModal(true);
  };

  const handleNewListConfirm = () => {
    if (
      listsForModal.pagination.currentPage === 1 &&
      typeof onListRefresh == 'function'
    ) {
      // in order new list to be visible we need to
      // send new request for the first page, if user is on other page
      // with clicking on first page request will be send automatically
      onListRefresh();
    }
    setOpenNewListModal(false);
  };

  // SELECTED ITEMS
  const handleOnSelectChange = ({ isSelected, item, toggleSelect }) => {
    if (isSelected) {
      setSelectedItems([
        ...selectedItems,
        { id: item.id, name: item.name, toggleSelect },
      ]);
    } else {
      let items = selectedItems.filter(x => x.id !== item.id);
      setSelectedItems(items);
    }
  };

  const clearSelectedItems = () => {
    selectedItems.forEach(item => {
      if (item) {
        item.toggleSelect(false);
      }
    });
    setSelectedItems([]);
  };

  /**
   * @description Check if list item is included in selected items list
   */
  const isItemAlreadySelected = listItem => {
    return selectedItems.filter(item => item.id === listItem.id).length > 0;
  };

  React.useEffect(() => {}, [listsForModal]);

  return <>
    <Modal
      open={alert}
    >
      <div style={{
        width: '550px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <Paper>
          <Grid container spacing={2} justifyContent={'center'} direction={'column'} alignItems={'center'}>
            <Grid item alignContent={'center'}><h3>{t('mdl_list_title')}</h3></Grid>
            <Grid item>
              <Button onClick={handleCreateNewList}>
                <PlaylistAddIcon />
                {t('mdl_list_create_button')}
              </Button>
            </Grid>
            <Grid item>
              {!isConfirmLoading && (
                <Grid container className={'list-modal-body-content'}>
                  {listsForModal.page && listsForModal.page.length ? (
                    listsForModal.page.map(listItem => {
                      return (
                        <ListModalItem
                          item={listItem}
                          onSelect={handleOnSelectChange}
                          selected={isItemAlreadySelected(listItem)}
                          key={listItem.id}
                        />
                      );
                    })
                  ) : (
                    <Grid item>
                      <p>{t('mdl_list_no_lists')}</p>
                    </Grid>
                  )}
                  <PaginationComponent
                    paginationObject={listsForModal.pagination}
                    getPage={getListPage}
                  />
                </Grid>
              )}
              {isConfirmLoading && (
                <div className="list-modal-indicator">
                  <CircularProgress />
                  <div className={'list-modal-indicator__label'}>
                    {saveLoadingMsg || t('mdl_saving_lists_loading') + '...'}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item>
              <Button variant={'contained'} onClick={handleAlertCancel} style={{margin: 10, marginRight: 5, color: 'white'}}>
                {t('mdl_general_cancel')}
              </Button>
              <Button variant={'contained'} onClick={handleAlertConfirm} style={{margin: 10, marginLeft: 5, color: 'white'}}>
                {t('mdl_general_confirm')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Modal>
    {isIconButton ? (
      <IconButton size={buttonSize || 'medium'} onClick={openModal}>
        <PlaylistAddIcon />
      </IconButton>
    ) : (
      <Tooltip title={t('mdl_list_button')}>
        <Button
          variant="outlined"
          size={buttonSize || 'medium'}
          onClick={openModal}
        >
          <PlaylistAddIcon />
        </Button>
      </Tooltip>
    )}

    <CreateNewPartListModal
      confirm={handleNewListConfirm}
      onCancel={handleNewListCancel}
      open={openNewListModal}
    />
  </>;
};
