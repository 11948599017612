import {useDispatch, useSelector} from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import {clearSnackbar} from 'store/snackbar/snackbarActions';
import {Close} from '@mui/icons-material';
import {Alert} from '@mui/material';

export default function Knackbar() {
  const dispatch = useDispatch();

  const {snackbarMessage, snackbarOpen, snackbarSeverity} = useSelector(
    state => state.snackbar,
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        severity={snackbarSeverity}
        elevation={6}
        variant="filled"
        action={
          <IconButton onClick={handleClose} size="small" color="inherit">
            <Close />
          </IconButton>
        }
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
