import React, {useContext, useState, useEffect} from 'react';
import {Stepper, Step, StepButton, Button, Tooltip} from '@mui/material';
import {Close, HelpOutline} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import SweetAlert from 'react-bootstrap-sweetalert';

import {ConfigForm} from './ConfigForm/ConfigForm';

import {ConfigContext} from '../../ConfigContext';
import {
  CHANGE_STEP,
  CREATE_SUBSTEP,
  UPDATE_TEMP_DATA,
} from '../../ConfigTypes';
import InfoIcon from '@mui/icons-material/Info';

import './ConfigContent.scss';
import {useTranslation} from 'react-i18next';
import {getInfoMsgByStepId} from '../../Mappers/InfoMessageMapper';
import {triggerManufacturingAnalysis} from '../../ConfigActions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {requestConfigurationSupport} from 'services/configs';
import Swal from 'sweetalert2';
import {useSelector} from 'react-redux';
import {logEvent} from 'utils/firebase';

const useStyles = makeStyles(styles);

export const ConfigContent = ({
  steps,
  currentStep,
  currentSubstep,
  closeModal,
}) => {
  const {t} = useTranslation('partconfigurator');
  const classes = useStyles();
  const {state, dispatch} = useContext(ConfigContext);
  const partListId = useSelector(state => state.partList.data.id);
  const [infoMsg, setInfoMsg] = useState('');
  const [alert, setAlert] = useState(false);
  const [maLoading, setMaLoading] = useState(false);
  const [maError, setMaError] = useState(null);

  useEffect(() => {
    const currentMsg = getInfoMsgByStepId(currentStep, t);
    setInfoMsg(currentMsg);
  }, [currentStep]);

  const totalSteps = () => {
    return steps.length;
  };

  const isFirstStep = () => {
    return currentStep === 0;
  };

  const isLastStep = () => {
    return currentStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (!isLastStep()) {
      if (
        !state.substeps.filter(item => item.stepId === currentStep + 1).length
      ) {
        dispatch({
          type: CREATE_SUBSTEP,
          payload: {
            id: state.substeps.filter(item => item.stepId === currentStep + 1)
              .length,
            stepId: currentStep + 1,
            name: t(
              `partconfigurator:${
                state.steps.find(item => item.id === currentStep + 1).empty_key
              }`,
            ),
            value: null,
          },
        });
      }

      dispatch({
        type: CHANGE_STEP,
        payload: {currentStep: currentStep + 1},
      });
    } else {
      closeModal();
    }
  };

  const handleBack = () => {
    if (!isFirstStep()) {
      dispatch({
        type: CHANGE_STEP,
        payload: {currentStep: currentStep - 1},
      });
    }
  };

  const manufacturingAnalysis = async () => {
    try {
      setMaLoading(true);
      const {data} = await triggerManufacturingAnalysis(
        state.tempData.configId,
      );
      dispatch({
        type: UPDATE_TEMP_DATA,
        payload: {
          batchSizes: data.batchSizes,
        },
      });
      setMaLoading(false);
      setAlert(false);
      await Swal.fire({
        icon: 'success',
      });
      closeModal(true);
      logEvent('click_begin_manufacturing_analysis');
    } catch (e) {
      setMaLoading(false);
      setMaError(
        (e.response && e.response.data && e.response.data.message) || e.message,
      );
    }
  };

  const handleStep = step => () => {
    dispatch({
      type: CHANGE_STEP,
      payload: {currentStep: step},
    });
  };

  const hasRunningMA = () => {
    return (
      state &&
      state.tempData &&
      state.tempData.batchSizes &&
      state.tempData.batchSizes.some(batchSize => batchSize.maRunning)
    );
  };

  const requestSupport = async () => {
    await Swal.fire({
      input: 'textarea',
      inputPlaceholder: t('partconfigurator:how_can_we_help'),
      confirmButtonColor: '#00acc1',
      allowEnterKey: false,
      inputValidator: value => {
        if (!value) {
          return t('message_cannot_be_empty');
        }
      },
      preConfirm: async message => {
        try {
          Swal.showLoading();
          await requestConfigurationSupport(state.tempData.configId, partListId, message);
          await Swal.fire({
            icon: 'success',
            showConfirmButton: false,
            timer: 3000,
          });
          logEvent('click_request_configuration_support');
        } catch (e) {
          console.error(e);
          Swal.showValidationMessage(`${e}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <div className="content">
      <div className="config-content-top">
        <Stepper nonLinear activeStep={currentStep} className="stepper">
          {steps.map((step, index) => (
            <Step key={step.key}>
              <StepButton
                color="secondary"
                onClick={handleStep(index)}
                disabled={
                  !state.substeps.filter(item => item.stepId === step.id).length
                }
              />
            </Step>
          ))}
        </Stepper>
        <Tooltip title={t('partconfigurator:request_support')}>
          <Button className="help-button" onClick={requestSupport}>
            <HelpOutline />
          </Button>
        </Tooltip>
        <Tooltip title={t('partconfigurator:close')}>
          <Button className="close-button" onClick={closeModal}>
            <Close />
          </Button>
        </Tooltip>
      </div>

      <div className="config-form">
        <ConfigForm
          {...{
            stepId: currentStep,
            stepData: steps.find(step => step.id === currentStep),
            currentSubstep,
          }}
        />
      </div>
      <div className="config-controls">
        <div className={'config-controls-info'}>
          <InfoIcon color="primary" />
          <span>{infoMsg}</span>
        </div>
        <Button
          variant={'outlined'}
          onClick={() => {
            setMaError('');
            setAlert(true);
          }}
        >
          {`${t('partconfigurator:pc_mdl_manufacturing_analysis')}${
            hasRunningMA() ? ' 🏃‍♀️' : ''
          }`}
        </Button>
        <div className={'config-controls-nav'}>
          <Button
            variant="outlined"
            disabled={isFirstStep()}
            onClick={handleBack}
          >
            {t('partconfigurator:previous')}
          </Button>
          <Button variant="outlined" onClick={handleNext}>
            {isLastStep()
              ? t('partconfigurator:close')
              : t('partconfigurator:next')}
          </Button>
        </div>
      </div>
      {alert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t('go')}
          cancelBtnText={t('cancel')}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
          cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
          title={
            hasRunningMA()
              ? t(
                'partconfigurator:pc_mdl_manufacturing_analysis_check_ma_running',
              )
              : t('partconfigurator:pc_mdl_manufacturing_analysis_check')
          }
          onConfirm={manufacturingAnalysis}
          onCancel={() => setAlert(false)}
          focusCancelBtn
        >
          {t('partconfigurator:pc_mdl_manufacturing_analysis_check_sub')}
          {maLoading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
          {maError && <p style={{color: 'red'}}>{maError}</p>}
        </SweetAlert>
      )}
    </div>
  );
};
