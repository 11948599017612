import React, {Component} from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import './SharePartListModal.scss';
import {CheckCircleOutline, Close, ContentCopyTwoTone} from '@mui/icons-material';
import {withTranslation} from 'react-i18next';
import {validatePassword} from '../../../utils/validationsHelpers';
import {generate} from 'generate-password';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import 'moment/min/locales.min';
import {createSharedLink} from '../../../services/shared-lists';
import {withSnackbar} from 'notistack';
import {toDisplayDate} from '../../../utils/datetimeHelpers';
import {createList} from '../../../services/part-lists';

class SharePartListModal extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      shareName: '',
      accessibility: '',
      accessibilityPassword: '',
      passwordErrored: false,
      expiryDatetime: moment().add(30, 'days'),
      loading: false,
      sharedPartListLink: null,
    };

    this.state = {
      ...this.defaultState,
    };

    moment.locale(props.i18n.language);
  }

  setAccessibility = accessibility => {
    if (accessibility !== 'false') {
      return this.setState({
        accessibility,
        accessibilityPassword: accessibility === 'true' ? '' : generate({
          length: 16,
          numbers: true,
          symbols: true,
          strict: true,
        }),
        passwordErrored: false,
      });
    }

    this.setState({
      accessibility,
      accessibilityPassword: '',
    });
  };

  setAccessibilityPassword = accessibilityPassword => {
    const {error} = validatePassword(accessibilityPassword);

    this.setState({
      accessibilityPassword,
      passwordErrored: error,
    });
  };

  setExpiryDatetime = expiryDatetime => {
    this.setState({
      expiryDatetime,
    });
  };

  handleLinkExpiryCheckbox = checked => {
    this.setState({
      expiryDatetime: checked ? null : moment().add(30, 'days'),
    });
  };

  createSharedPartListLink = async () => {
    this.setState({
      loading: true,
    });

    try {
      let partListId = this.props.partListId;
      let shareName = this.props.partListName;

      if (this.props.createPartList) {
        const {data: createdPartList} = await createList({
          name: this.state.shareName,
          partIds: this.props.partIds,
        });
        partListId = createdPartList.id;
        shareName = this.state.shareName;
      }

      const {data} = await createSharedLink({
        partListId,
        shareName,
        password: this.state.accessibilityPassword.length ? this.state.accessibilityPassword : null,
        expiryDatetime: this.state.expiryDatetime,
      });

      this.setState({
        sharedPartListLink: data,
      });
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('mdl_general_error_title'), {
        variant: 'error',
      });
    }

    this.setState({
      loading: false,
    });
  };

  handleClose = () => {
    this.props.setOpen(false);
    this.setState({
      ...this.defaultState,
    });
  };

  render() {
    const {t, createPartList} = this.props;

    return <>
      <Modal open={this.props.open} closeAfterTransition>
        <Fade in={this.props.open}>
          <div className={'share-part-list-modal'}>
            <Paper className={'share-part-list-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-share-part-list-modal-button'}
                onClick={this.handleClose}
              >
                <Close />
              </IconButton>
              {!this.state.sharedPartListLink &&
                <Grid
                  container
                  rowSpacing={2}
                  justifyContent={'center'}
                  className={'share-part-list-modal-content'}
                >
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'h5'}>
                      <b>{t('mdl_shared_link_creat_link_title')}</b>
                    </Typography>
                  </Grid>
                  {createPartList ?
                    <Grid item xs={12} container rowSpacing={1}>
                      <Grid item xs={12}>
                        <Typography variant={'body1'}>{t('mdl_shared_link_share_name_title')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {t('mdl_shared_link_share_name_desc')}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={this.state.shareName}
                          onChange={({target: {value}}) => this.setState({shareName: value})}
                          placeholder={t('mdl_shared_link_share_name_title')}
                          sx={{width: '50%'}}
                        />
                      </Grid>
                    </Grid> : <></>
                  }
                  <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant={'body1'}>
                        {t('mdl_shared_link_accessibility_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {t('mdl_shared_link_accessibility_desc')}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{width: '50%'}} variant={'standard'}>
                        <InputLabel>{t('mdl_shared_link_select_accessibility')}</InputLabel>
                        <Select
                          variant={'standard'}
                          value={this.state.accessibility}
                          onChange={({target: {value}}) => this.setAccessibility(value)}
                          disabled={this.state.loading}
                        >
                          <MenuItem value={'false'}>{t('mdl_shared_link_accessibility_no_pass')}</MenuItem>
                          <MenuItem value={'true'}>{t('mdl_shared_link_accessibility_set_pass')}</MenuItem>
                          <MenuItem value={'generated'}>{t('mdl_shared_link_generate_pass')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {
                        (this.state.accessibility === 'true' || this.state.accessibility === 'generated') &&
                        <TextField
                          fullWidth
                          value={this.state.accessibilityPassword}
                          disabled={this.state.accessibility !== 'true' || this.state.loading}
                          onChange={({target: {value}}) => this.setAccessibilityPassword(value)}
                          label={this.state.accessibility === 'true' ?
                            t('mdl_shared_link_accessibility_set_pass') :
                            t('mdl_shared_link_generated_pass_lbl')
                          }
                          helperText={this.state.accessibility === 'true' ? t('mdl_shared_link_password_info') : ''}
                          error={this.state.passwordErrored}
                        />
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant={'body1'}>
                        {t('mdl_shared_link_link_valid_until_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {t('mdl_shared_link_link_valid_until_desc')}
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          value={this.state.expiryDatetime}
                          onChange={this.setExpiryDatetime}
                          renderInput={params => <TextField {...params} />}
                          minDateTime={moment()}
                          label={t('mdl_shared_link_expiry_date_time')}
                          disabled={this.state.expiryDatetime === null || this.state.loading}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox
                          checked={this.state.expiryDatetime === null}
                          onChange={({target: {checked}}) => this.handleLinkExpiryCheckbox(checked)}
                        />}
                        label={t('mdl_shared_link_accessibility_no_expiration')}
                        style={{color: 'black'}}
                        disabled={this.state.loading}
                      />
                    </Grid>
                    <Grid
                      item xs={12} alignSelf={'center'} style={{
                      textAlign: 'center',
                      position: 'fixed',
                      bottom: 0,
                      width: 'calc(100% - 40px)',
                      paddingBottom: 24,
                      margin: 0,
                    }}
                    >
                      <Button
                        variant={'contained'}
                        style={{color: 'white'}}
                        disabled={
                          createPartList && !this.state.shareName?.length ||
                          this.state.accessibility === '' ||
                          (this.state.accessibility !== 'false' && validatePassword(this.state.accessibilityPassword).error) ||
                          this.state.loading
                        }
                        onClick={this.createSharedPartListLink}
                      >
                        {t('mdl_shared_link_create')}
                        {this.state.loading && (
                          <CircularProgress
                            style={{marginLeft: 5, color: '#fff'}}
                            size={20}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>}
              {this.state.sharedPartListLink &&
                <Grid
                  container
                  rowSpacing={3}
                  justifyContent={'center'}
                  alignItems={'center'}
                  className={'share-part-list-modal-content'}
                >
                  <Grid item>
                    <CheckCircleOutline fontSize={'large'} style={{color: '#5ed05e'}} />
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'h5'}>
                      <b>{t('mdl_copy_link_title')}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>
                      {t('mdl_copy_link_desc')}
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant={'body1'}><b>{t('mdl_copy_link_lbl')}:</b></Typography>
                    <TextField
                      value={`${window.location.host}/download/${this.state.sharedPartListLink.urlId}`}
                      InputProps={{
                        readOnly: true,
                      }}
                      style={{width: '95%'}}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={t('mdl_copy_link_btn_lbl')}>
                    <span>
                      <IconButton
                        onClick={() => navigator.clipboard.writeText(`${window.location.hostname}/download/${this.state.sharedPartListLink.urlId}`)}
                      >
                        <ContentCopyTwoTone />
                      </IconButton>
                    </span>
                    </Tooltip>
                  </Grid>
                  {this.state.sharedPartListLink.password && <>
                    <Grid item xs={11}>
                      <Typography variant={'body1'}><b>{t('mdl_shared_enter_password_lbl')}:</b></Typography>
                      <TextField
                        value={this.state.sharedPartListLink.password}
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{width: '95%'}}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={t('mdl_copy_link_copy_password_btn_lbl')}>
                      <span>
                        <IconButton onClick={() => navigator.clipboard.writeText(this.state.sharedPartListLink.password)}>
                          <ContentCopyTwoTone />
                        </IconButton>
                      </span>
                      </Tooltip>
                    </Grid>
                  </>}
                  <Grid item xs={12}>
                    <Typography variant={'body1'}><b>Link Valid Until:</b></Typography>
                    <Typography variant={'body1'}>
                      {this.state.sharedPartListLink.expiryDatetime
                        ? toDisplayDate(this.state.sharedPartListLink.expiryDatetime)
                        : t('mdl_copy_link_never_expires')}
                    </Typography>
                  </Grid>
                </Grid>}
            </Paper>
          </div>
        </Fade>
      </Modal>
    </>;
  }
}

export default withSnackbar(withTranslation('modals')(SharePartListModal));
