import API from './api';
import {KnestPartsAPI} from './api';
import {encode} from 'base64-arraybuffer';
import {getToken} from './auth';

export const fetchParts = async params =>
  await API.get({
    url: '/part-teasers',
    params,
  });

export const fetchPart = async (id, params) =>
  await API.get({
    url: `/parts/${id}`,
    params,
  });

export const fetchFeatures = async params =>
  await KnestPartsAPI.get({
    url: `/part-features`,
    params,
  });

export const putPartFeature = (id, partFeatureKey, data) =>
  KnestPartsAPI.put({
    url: `/parts/${id}/part-features/${partFeatureKey}`,
    data,
  });

export const fetchPartConfigs = async (id, params) =>
  await API.get({
    url: `/parts/${id}/part-configs`,
    params,
  });

export const fetchPartConfig = async (id, params) =>
  await API.get({
    url: `/part-configs/${id}`,
    params,
  });

export const editPart = async (id, data) =>
  await API.patch({
    url: `/parts/${id}`,
    data,
  });

export const fetchPdfPreview = id =>
  API.get({
    url: `/files/${id}/download`,
    params: {},
    responseType: 'arraybuffer',
  })
    .then(response => {
      const file = new Blob([response.data], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(
        fileURL,
        'popUpWindow',
        'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
      );
    })
    .catch(error => {
      console.error(error.response.data);
    });

export const fetchFileAsArraybuffer = async id => {
  return API.get({
    url: `/attachments/${id}/files/current`,
    params: {},
    responseType: 'arraybuffer',
  });
};

export const fetchFile = async (id, name, extension) =>
  await API.get({
    url: `/attachments/${id}/files/current`,
    params: {},
    responseType: 'blob',
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.${extension}`);
      link.setAttribute('id', `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      document.getElementById(`${name}.${extension}`).remove();
    })
    .catch(error => {
      console.error(error);
    });

export const fetchThumbnalFromUrl = async (partId, url, size) => {
  if (url) {
    return await API.getWithUrl({
      url,
      params: {
        width: size.width,
        height: size.height,
      },
      responseType: 'arraybuffer',
    })
      .then(response => {
        if (!response) return Promise.reject();
        const file = encode(response.data);
        return {id: partId, thumbnail: `data:image/png;base64, ${file}`};
      })
      .catch(() => {});
  }
};

export const fetchThumbnal = async id =>
  await API.get({
    url: `/attachments/${id}/files/current/thumbnail`,
    params: {},
    responseType: 'blob',
  })
    .then(response => {
      const file = encode(response.data);
      return `data:image/png;base64, ${file}`;
    })
    .catch(error => {
      console.error(error.response.data);
    });

export const fetchPartAttachments = async id => {
  return API.get({
    url: `/parts/${id}/attachment-teasers`,
  });
};

/**
 * @description Fetch all list teasers for one part
 * @param {*} id
 */
export const fetchListForPart = async id =>
  await API.get({
    url: `/parts/${id}/part-list-teasers`,
  });

export const createPart = async data =>
  await API.post({
    url: `/parts`,
    data,
  });

export const deletePart = async partId =>
  API.delete({
    url: `/parts/${partId}`,
  });

export const deleteParts = async partIds =>
  API.delete({
    url: `/parts`,
    data: {where: {id: {inq: partIds}}},
  });

export const constructPartThumbnailUrl = partId => {
  const userToken = getToken();
  return `${process.env.REACT_APP_PARTS_API}/parts/${partId}/thumbnail?access_token=${userToken.access_token}`;
};

export const downloadFile = async (id, responseType = 'arraybuffer') => {
  const {data} = await API.get({
    url: `/attachments/${id}/files/current`,
    responseType,
  });
  return data;
};
