import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Alert, Grid, Grow, Typography, Icon, Autocomplete, Chip, TextField} from '@mui/material';
import {putBatchSize, deleteBatchSize} from 'services/configs';
import {withSnackbar} from 'notistack';
import _ from 'lodash';

class AddBatchSizes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sameBatchSizesSelected: null,
      loading: false,
    };
  }

  handleBatchSizeChangeForConfigs = async (partConfigs, reason, option) => {
    const {refreshPartList, enqueueSnackbar, t} = this.props;
    await this.setState({loading: true});

    try {
      await Promise.all(partConfigs.map(async partConfig => await this.handleBatchSizeChangeForConfig(partConfig, reason, option)));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t('unknown_error'), {
        variant: 'error',
      });
    }

    await refreshPartList();
    await this.setState({loading: false});
  };

  setSameBatchSizesSelected = sameBatchSizesSelected => {
    if (sameBatchSizesSelected === true && this.props.partConfigs.some(pc => pc.batchSizes.length)) {
      return;
    }

    this.setState({
      sameBatchSizesSelected,
    });
  };

  handleBatchSizeChangeForConfig = async (partConfig, reason, option) => {
    switch (reason) {
      case 'createOption':
        return this.addBatchSizeToConfig(partConfig, option);
      case 'removeOption':
        return this.removeBatchSizeFromConfig(partConfig, option);
      default:
        return;
    }
  };

  addBatchSizeToConfig = async (partConfig, batchSize) => {
    if (!/^\d+[\d ]*$/g.test(batchSize)) {
      throw new Error('Invalid input. Input must match /^\d+[\d ]*$/g');
    }

    const _lineItems = batchSize.trim().split(' ').map(e => parseInt(e));
    const _batchSize = _.sum(_lineItems);

    const _existingBatchSize = partConfig.batchSizes.find(bs => bs.batchSize === _batchSize);

    return putBatchSize(partConfig.id, {
      id: _existingBatchSize?.id,
      batchSize: _batchSize,
      lineItemsBreakdown: _lineItems.length > 1 ? _lineItems : null,
    });
  };

  removeBatchSizeFromConfig = async (partConfig, batchSize) => {
    return deleteBatchSize(partConfig.id, partConfig.batchSizes.find(bs => bs.batchSize === batchSize.batchSize).id);
  };

  renderChipLabel = batchSize => {
    let chipLabel = `${batchSize.batchSize}`;

    if (batchSize.lineItemsBreakdown) {
      const {t} = this.props;
      chipLabel += `; ${t('line_items')}: [${batchSize.lineItemsBreakdown.join(', ')}]`;
    }

    return chipLabel;
  };

  render() {
    const {partConfigs, t} = this.props;

    const firstConfigBatchSizes = partConfigs[0].batchSizes;

    // Common batch sizes match on both batch sizes and line items breakdown
    const commonBatchSizes = firstConfigBatchSizes.reduce((acc, bs) => {
      if (
        partConfigs.slice(1)
          .map(pc => pc.batchSizes)
          .every(batchSizeArr => batchSizeArr.some(_bs => _bs.batchSize === bs.batchSize && _.isEqual(_bs.lineItemsBreakdown, bs.lineItemsBreakdown)))
      ) acc.push(bs);

      return acc;
    }, []);

    return (
      <Grow in={true} timeout={650}>
        <Grid container rowSpacing={2}>
          {partConfigs?.length > 1 && <Grid item xs={12}>
            <Alert
              variant={'filled'}
              icon={<Icon color={'inherit'}>all_inclusive</Icon>}
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'body1'}>{t('batch_sizes_for_all_parts')}</Typography>
                </Grid>
                <Grid item xs={12} style={{width: '100%'}}>
                  <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    disabled={this.state.loading}
                    options={[]}
                    value={commonBatchSizes}
                    renderTags={(value, getTagProps) =>
                      value.map((bs, index) =>
                        <Chip
                          variant={'filled'}
                          label={this.renderChipLabel(bs)}
                          {...getTagProps({index})}
                        />)
                    }
                    renderInput={params => <TextField {...params} variant={'standard'} color={'secondary'} label={t('type_batch_size_and_press_enter')} />}
                    style={{width: 'min(50vw, 700px)'}}
                    onChange={(event, newValue, reason, {option}) => this.handleBatchSizeChangeForConfigs(partConfigs, reason, option)}
                  />
                </Grid>
              </Grid>
            </Alert>
          </Grid>}
          {partConfigs?.map(partConfig => (
            <Grid item xs={12} key={partConfig.id}>
              <Alert
                variant={'outlined'}
                icon={<Icon color={'inherit'}>precision_manufacturing</Icon>}
              >
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>{partConfig.part.name}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{width: '100%'}}>
                    <Autocomplete
                      multiple
                      freeSolo
                      disableClearable
                      disabled={this.state.loading}
                      options={[]}
                      value={partConfig.batchSizes}
                      renderTags={(value, getTagProps) =>
                        value.map((bs, index) =>
                          <Chip
                            variant={'filled'}
                            label={this.renderChipLabel(bs)}
                            {...getTagProps({index})}
                          />)
                      }
                      renderInput={params => <TextField {...params} variant={'standard'} label={t('type_batch_size_and_press_enter')} />}
                      style={{width: 'min(50vw, 700px)'}}
                      onChange={(event, newValue, reason, {option}) => this.handleBatchSizeChangeForConfigs([partConfig], reason, option)}
                    />
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
          ))}
        </Grid>
      </Grow>
    );
  }
}

export default withSnackbar(withTranslation('automatic_configuration')(AddBatchSizes));
