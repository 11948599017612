import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Modal, Paper, TextField } from "@mui/material";
import { createList } from 'services/part-lists';
import CircularProgress from '@mui/material/CircularProgress';
import './CreateNewPartListModal.scss';
import {logEvent} from 'utils/firebase';

export const CreateNewPartListModal = ({ confirm, open, onCancel }) => {
  const { t } = useTranslation(['modals', 'errors']);
  const [listName, setListName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [listNameError, setListNameError] = useState({
    error: false,
    msg: '',
  });

  useEffect(() => {
    if (!open) {
      setListName('');
      resetValidation();
    }
  }, [open]);

  const closeAlert = () => {
    if (typeof onCancel === 'function') {
      onCancel(false);
    }
  };

  const resetValidation = () => {
    setListNameError({ error: false, msg: '' });
  };

  const confirmAlert = async () => {
    setLoading(false);
    resetValidation();
    if (listName.trim().length === 0) {
      setListNameError({ error: true, msg: t('errors:error_field_required') });
      return;
    }
    setLoading(true);
    const result = await createList({ name: listName.trim() }).catch(() => {
      setListNameError({
        error: true,
        msg: t('errors:error_server_create_list'),
      });
    });

    logEvent('new_partlist');

    setLoading(false);
    if (typeof confirm === 'function') {
      confirm({ ...result.data });
    }
  };

  const handleListNameInput = value => {
    if(value.trim() === ''){
    setListNameError({ error: true, msg: t('errors:error_field_required') });
    setListName('');
    } else {
      setListName(value);
    }
  };

  return <>
    <Modal open={open}>
      <div style={{
        width: '400px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <Paper style={{
          padding: 15,
        }}>
          <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={3}>
            <Grid item>
              <h3>{t("mdl_create_list_title")}</h3>
            </Grid>
            <Grid item>
              {isLoading && (
                <>
                  <br />
                  <CircularProgress />
                </>
              )}
              <TextField
                label={t('mdl_create_list_name_inp')}
                error={listNameError.error}
                type="text"
                fullWidth
                helperText={listNameError.msg}
                value={listName || ''}
                onChange={event => {
                  setListNameError({ error: false, msg: '' });
                  handleListNameInput(event.target.value);
                }}
                style={{
                  width: 350,
                }}
              />
            </Grid>
            <Grid item>
              <Button variant={'contained'} onClick={closeAlert} style={{margin: 10, marginRight: 5}}>
                {t('mdl_general_cancel')}
              </Button>
              <Button variant={'contained'} onClick={confirmAlert} style={{margin: 10, marginLeft: 5}}>
                {t('mdl_create_confirm_lbl')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Modal>
  </>;
};
