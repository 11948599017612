import API from './api';
import {fetchCurrentUserInfo} from './users';

export const getAllSuppliers = async () => {
  const userInfo = await fetchCurrentUserInfo();
  
  if (!userInfo?.roles?.some(role => role.name === 'impersonation')) {
    return [];
  }
  
  return API.get({
    url: '/suppliers',
  });
}