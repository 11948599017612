import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {fetchNotifications, seenNotifications, clearAllNotifications, clearOneNotification} from 'services/notifications';
import alertIcon from 'assets/img/alert.png';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ClearIcon from '@mui/icons-material/Clear';
import {useTranslation} from 'react-i18next';
import {
  Badge,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {logEvent} from '../../utils/firebase';

const useStyle = makeStyles(theme => ({
  paper: {
    transformOrigin: 'top right',
  },
  list: {
    width: theme.spacing(50),
    maxHeight: theme.spacing(60),
    overflow: 'auto',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

function NotificationsMenu({history}) {
  const classes = useStyle();
  const {t} = useTranslation('notifications');
  const [open, setOpen] = React.useState(false);
  const [hoverId, setHoverId] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const anchorRef = React.useRef(null);

  useEffect(() => {
    getNotifications();

    const interval = setInterval(() => {
      getNotifications();
    }, 90000); // every 90 seconds

    return () => clearInterval(interval);
  }, []);

  const getNotifications = () => fetchNotifications({filter: {include: 'notificationType', order: 'createdAt DESC'}})
    .then(response => setNotifications(response.data.page))
    .catch(console.error);

  const handleToggle = () => {
    if (open) {
      setHoverId(null);

      if (newNotifications()) {
        seenNotifications(notifications.map(notification => notification.id))
          .then(() => {
            getNotifications();
          });
      }
    }
    logEvent('clicked_kms_notifications');
    setOpen(prevOpen => !prevOpen);
  };

  const newNotifications = () => notifications && notifications.filter(notification => !notification.seenAt).length;

  const notificationColor = notification => notification.seenAt ? 'textSecondary' : 'inherit';

  const notificationOnClick = notification => history.push(`/${notification.entityName.replace('_', '-')}/${notification.entityId}`);

  const notificationEmoji = notification => {
    const type = notification.notificationType.type;
    if (type === 'warning') return `⚠️`;
    return ``;
  };

  const _clearAllNotifications = () => {
    clearAllNotifications()
      .then(getNotifications);
  };

  const _clearOneNotification = notification => {
    clearOneNotification(notification.id)
      .then(getNotifications);
  };

  const _mapNotifications = notifications => {
    return notifications.map((notification, index) => (
        <React.Fragment key={notification.id}>
          <ListItem
            alignItems="flex-start"
            button
            className={classes.listItem}
            onClick={() => notificationOnClick(notification)}
            onMouseEnter={() => setHoverId(notification.id)}
          >
            <Typography color={notificationColor(notification)} gutterBottom>
              {
                `${notificationEmoji(notification)} ${t(`notifications:${notification.notificationType.key}`, {
                  entity_name: t(`notifications:${notification.entityName}`),
                  entity_id: notification.entityId,
                })}`
              }
            </Typography>
            {notification.createdAt && (
              <Typography variant="caption" color="textSecondary">
                {new Date(notification.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </Typography>
            )}
            <ListItemSecondaryAction>
              <div style={{width: '50ox'}}>
                {notification.id === hoverId &&
                  <IconButton onClick={() => _clearOneNotification(notification)} size="small">
                    <ClearIcon />
                  </IconButton>
                }
              </div>
            </ListItemSecondaryAction>
          </ListItem>
          {index < notifications.length - 1 ? (
            <Divider className={classes.divider} />
          ) : null}
        </React.Fragment>
      ),
    );
  };

  return <>
    <Button
      ref={anchorRef}
      onClick={() => !open && handleToggle()}
      className={classes.topBarButton}
      aria-controls="notification-menu"
    >
      <StyledBadge badgeContent={newNotifications()} color="secondary">
        <img src={alertIcon} alt="alert" style={{maxWidth: '50px', maxHeight: '50px'}} />
      </StyledBadge>
    </Button>

    <Popper
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-end"
      transition
      disablePortal
      role={undefined}
    >
      {({TransitionProps}) => (
        <ClickAwayListener
          onClickAway={handleToggle}
        >
          <Grow in={open} {...TransitionProps}>
            <Paper className={classes.paper}>
              {
                notifications.length ?
                  <List className={classes.list}>
                    {_mapNotifications(notifications)}
                    <Divider className={classes.divider} />
                    <ListItem>
                      <Typography variant="caption">
                        {t('notifications:all_there_is')}
                      </Typography>
                      <ListItemSecondaryAction>
                        <Tooltip title={t('notifications:clear_all')}>
                          <Button onClick={_clearAllNotifications}>
                            <ClearAllIcon />
                          </Button>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List> :
                  <List className={classes.list}>
                    <ListItem alignItems="flex-start" className={classes.listItem}>
                      <Typography variant="caption">
                        {t('notifications:no_notifications')}
                      </Typography>
                    </ListItem>
                  </List>
              }
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  </>;
}

export default NotificationsMenu;
