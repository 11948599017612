import React from 'react';
import TextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import './SharedLinkUrlInput.scss';
import IconButton from '@mui/material/IconButton';
import { copyToClipboard } from 'utils/InputHelpers';

const SharedLinkUrlInput = ({ id, url }) => {
  const handleCopyBtnClick = () => {
    copyToClipboard(id);
  };

  return (
    <div>
      <TextField
        className={'url-link-inp'}
        id={id}
        label="URL"
        variant="filled"
        value={url}
        InputProps={{
          readOnly: true,
        }}
      />
      <IconButton
        aria-label="copy"
        style={{ marginLeft: 3, marginTop: 5 }}
        onClick={handleCopyBtnClick}
        size="large">
        <FileCopyIcon />
      </IconButton>
    </div>
  );
};

export default SharedLinkUrlInput;
