export const GET_PART_LIST = 'get-part-list';
export const GET_PART_ATTACHMENTS = 'get-part-list-attachments';
export const GET_PART_LIST_ERROR = 'get-part-list-error';
export const GET_PART_LIST_SUCCESS = 'get-part-list-success';
export const GET_LIST_PARTS = 'get-list-parts';
export const GET_LIST_PARTS_SUCCESS = 'get-list-parts-success';
export const GET_LIST_PARTS_ERROR = 'get-list-parts-error';
export const PART_DETAILS_PARTS_CLEANUP = 'part-details-parts-cleanup';
export const PART_DETAILS_CLEANUP = 'part-details-cleanup';
export const PART_LIST_NAME_UPDATE = 'part-list-name-update';
export const PART_NAME_UPDATE = 'part-name-update';
export const UNASSIGN_PART_ATTACHMENT = 'unassigned-part-attachment';
export const UNASSIGN_PART_ATTACHMENT_SUCCESS =
  'unassigned-part-attachment-success';
export const UNASSIGN_PART_ATTACHMENT_ERROR =
  'unassigned-part-attachment-error';
export const UNASSIGN_PART_ATTACHMENT_CLEAN =
  'unassigned-part-attachment-clean';
export const ADD_EMPTY_CONFIG_TO_PART = 'list-part-create-empty-part';
export const ADD_EMPTY_CONFIG_TO_PART_SUCCESS =
  'list-part-create-empty-part-success';
export const ADD_EMPTY_CONFIG_TO_PART_ERROR =
  'list-part-create-empty-part-error';
export const SELECTED_PART = 'get-list-part-select';
export const UNSELECT_PART = 'get-list-part-unselect';
export const UNASSIGN_PART_FROM_LIST = 'get-list-parts-unassign-part-from-list';
export const UNASSIGN_PART_SUCCESS = 'get-list-parts-unassign-part-success';
export const UNASSIGN_PART_ERROR = 'get-list-parts-unassign-part-error';
export const UNASSIGN_PART_CLEANUP = 'get-list-parts-unassign-part-cleanup';
export const SELECT_CONFIG = 'part-list-details-config-select';
export const REMOVE_CONFIG = 'part-list-details-config-remove';
export const CLEAR_SELECTED_CONFIGS = 'part-list-clear-selected-configs';
export const CLONE_CONFIG_TO_ALL_LIST_LOADING =
  'part-list-details-clone-config-to-all-loading';
export const CLONE_CONFIG_TO_ALL_LIST_SUCCESS =
  'part-list-details-clone-config-to-all-success';
export const CLONE_CONFIG_TO_ALL_LIST_ERROR =
  'part-list-details-clone-config-to-all-error';
export const CLONE_CONFIG_TO_SELECTED_CONFIGS_LOADING =
  'part-list-details-clone-config-to-selected-loading';
export const CLONE_CONFIG_TO_SELECTED_CONFIGS_SUCCESS =
  'part-list-details-clone-config-to-selected-success';
export const CLONE_CONFIG_TO_SELECTED_CONFIGS_ERROR =
  'part-list-details-clone-config-to-selected-error';
