import {
  CHANGE_STEP,
  CREATE_STEP,
  UPDATE_STEPS,
  CHANGE_SUBSTEP,
  CREATE_SUBSTEP,
  UPDATE_SUBSTEP,
  REMOVE_SUBSTEP,
  REMOVING_POST_PROCESS_SUBSTEP,
  REMOVING_MAIN_PROCESS_SUBSTEP,
  REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP,
  UPDATE_TEMP_DATA,
  RESET_STEPS,
  SAVE_DATA,
  UPDATE_SUMMARY_DATA,
} from './ConfigTypes';

export const initialState = {
  steps: [],
  substeps: [],
  currentStep: 0,
  currentSubstep: 0,
  deletePostProcessSubstep: false,
  deleteMainProcessSubstep: false,
  deleteProductRequirement: false,
  tempData: {
    name: '',
    partId: null,
    configId: null,
    material: '',
    materialIsUrl: null,
    comment: null,
    productRequirements: null,
    batchSizes: null,
    postProcesses: [],
  },
};

export const ConfigReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep || 0,
        currentSubstep: action.payload.currentSubstep || 0,
      };

    case CREATE_STEP:
      return {
        ...state,
        steps: [...state.steps, action.payload],
        currentStep: 0,
        currentSubstep: 0,
      };

    case UPDATE_STEPS:
      const updatedSteps = state.steps.map(step => {
        if (step.id === action.payload.id) {
          return action.payload;
        } else {
          return step;
        }
      });

      return {
        ...state,
        steps: [...updatedSteps],
        currentStep: action.payload.id || 0,
        currentSubstep: action.payload.substepId || 0,
      };

    case CHANGE_SUBSTEP:
      return {
        ...state,
        currentStep: action.payload.currentStep || 0,
        currentSubstep: action.payload.currentSubstep || 0,
      };

    case CREATE_SUBSTEP:
      return {
        ...state,
        substeps: [...state.substeps, action.payload],
      };

    case UPDATE_SUBSTEP:
      const updatedSubsteps = state.substeps.map(substep => {
        if (
          substep.id === action.payload.id &&
          substep.stepId === action.payload.stepId
        ) {
          return action.payload;
        } else {
          return substep;
        }
      });

      return {
        ...state,
        substeps: [...updatedSubsteps],
        currentSubstep: action.payload.id || 0,
      };

    case REMOVING_POST_PROCESS_SUBSTEP:
      return {
        ...state,
        deletePostProcessSubstep: action.payload.isDeleting,
      };

    case REMOVING_MAIN_PROCESS_SUBSTEP:
      return {
        ...state,
        deleteMainProcessSubstep: action.payload.isDeleting,
      };

    case REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP:
      return {
        ...state,
        deleteProductRequirement: action.payload.isDeleting,
      };

    case REMOVE_SUBSTEP:
      let newSubsteps = [];
      let substepCount = 0;

      state.substeps.forEach(substep => {
        if (substep.stepId !== action.payload.Id) {
          newSubsteps.push(substep);
        } else {
          if (substep.id !== action.payload.substepId) {
            substep.id = substepCount;
            newSubsteps.push({ ...substep });
            substepCount++;
          }
        }
      });

      return {
        ...state,
        substeps: [...newSubsteps],
      };

    case UPDATE_TEMP_DATA:
      return {
        ...state,
        tempData: {
          ...state.tempData,
          ...action.payload,
        },
      };

    case UPDATE_SUMMARY_DATA: {
      return {
        ...state,
        summaryData: { ...action.payload },
      };
    }

    case SAVE_DATA:
      return {
        ...initialState,
      };

    case RESET_STEPS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
