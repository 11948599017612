import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import API from 'services/api';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { IconButton } from '@mui/material';

export const ManufacturingAnalysisTable = props => {
  const [setupAndProcessTimeValues, setSetupAndProcessTimeValues] = useState(
    {}
  );
  const { t } = useTranslation('partconfigurator');

  const {
    rowStyle = {},
    tableStyle = {
      maxWidth: 750,
      width: '100%'
    },
    tableContainerStyle = {
      paddingBottom: 20,
    },
    data,
    cellStyle = {},
    editable = false,
    configId,
    partConfigProcessId,
  } = props;

  useEffect(() => {
    if (data) {
      const mappedValues = data.reduce((acc, row) => {
        acc[row.batchSize.id] = {
          setupTime: parseFloat(row.setupTime),
          setupTimeAuto: parseFloat(row.setupTimeAuto),
          processTime: parseFloat(row.processTime),
          processTimeAuto: parseFloat(row.processTimeAuto),
        };
        return acc;
      }, {});

      setSetupAndProcessTimeValues(mappedValues);
    }
  }, []);

  const handleBlur = async (
    key,
    value,
    batchSizeId,
    configId,
    partConfigProcessId
  ) => {
    const data = {};
    data[key] = value;

    await API.put({
      url: `/part-configs/${configId}/part-config-processes/${partConfigProcessId}/batch-size/${batchSizeId}/manufacturing-analysis`,
      data,
    });
  };

  const handleChange = (key, value, batchSizeId) => {
    setSetupAndProcessTimeValues(prevState => {
      const newState = { ...prevState };
      newState[batchSizeId][key] = value;

      return newState;
    });
  };
  
  const handleReset = async (configId, partConfigProcessId, batchSizeId) => {
    setSetupAndProcessTimeValues(prevState => {
      const newState = { ...prevState };
      newState[batchSizeId].setupTime = newState[batchSizeId].setupTimeAuto;
      newState[batchSizeId].processTime = newState[batchSizeId].processTimeAuto;

      return newState;
    });
    
    await API.post({
      url: `/part-configs/${configId}/part-config-processes/${partConfigProcessId}/batch-size/${batchSizeId}/manufacturing-analysis/reset`
    });
  }

  return data && data.length ? (
    <TableContainer style={tableContainerStyle}>
      {
        editable && (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 10,
          }}>
            <InfoIcon /><i style={{paddingLeft: 10}}>{t('only_edit_st_pt_after_ma_message')}</i>
          </div>
        )
      }
      <Table size="small" style={tableStyle}>
        <TableHead>
          <TableRow style={rowStyle} key={partConfigProcessId}>
            <TableCell
              style={{
                ...cellStyle,
                width: '20%',
              }}
            >
              {t('batch_size')}
            </TableCell>
            {!editable && (
              <>
                <TableCell style={cellStyle} align={'center'}>
                  {t('setup_time')}
                </TableCell>
                <TableCell style={cellStyle} align={'center'}>
                  {t('process_time')}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length &&
            data.map(row => (
              <TableRow key={row.batchSize.id} style={rowStyle}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    ...cellStyle,
                    width: '10%',
                  }}
                >
                  {row.batchSize.batchSize}
                </TableCell>
                <TableCell style={cellStyle} align={'center'}>
                  {editable ? (
                    <TextField
                      label={t('setup_time')}
                      disabled={!row.setupTimeAuto}
                      type={'number'}
                      size={'small'}
                      variant={'standard'}
                      onBlur={event => handleBlur('setupTime', event.target.value, row.batchSize.id, configId, partConfigProcessId)}
                      onChange={event => handleChange('setupTime', event.target.value, row.batchSize.id)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ min: '0', step: '0.01' }}
                      value={setupAndProcessTimeValues[row.batchSize.id]?.setupTime}
                    />
                  ) : (
                    <>
                      {row.setupTime
                        ? parseFloat(row.setupTime).toFixed(2)
                        : '-'}
                    </>
                  )}
                </TableCell>
                <TableCell style={cellStyle} align={'center'}>
                  {editable ? (
                    <TextField
                      label={t('process_time')}
                      disabled={!row.processTimeAuto}
                      type={'number'}
                      size={'small'}
                      variant={'standard'}
                      onBlur={event => handleBlur('processTime', event.target.value, row.batchSize.id, configId, partConfigProcessId)}
                      onChange={event =>
                        handleChange('processTime', event.target.value, row.batchSize.id)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ min: '0', step: '0.01' }}
                      value={setupAndProcessTimeValues[row.batchSize.id]?.processTime}
                    />
                  ) : (
                    <>
                      {row.processTime
                        ? parseFloat(row.processTime).toFixed(2)
                        : '-'}
                    </>
                  )}
                </TableCell>
                {editable && (
                  <TableCell style={cellStyle} align={'center'}>
                    <Tooltip 
                      title={t('reset_to_auto_values')} 
                      PopperProps={{style: {zIndex: 999999}}}
                      arrow
                    >
                      <IconButton
                        disabled={!row.processTimeAuto || !row.setupTimeAuto}
                        onClick={() => handleReset(configId, partConfigProcessId, row.batchSize.id)}
                        size="large">
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
                {row.comment && (
                  <TableCell style={cellStyle} align={'center'}>
                    <Tooltip title={row.comment} PopperProps={{style: {zIndex: 999999}}}>
                      <IconButton size="large">
                        <FeedbackIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <></>
  );
};
