import React, { useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function SearchInput({ onChange, disabled }) {
  const { t } = useTranslation('sharedparts');
  const searchValue = useRef('');
  let searchTimeout = null;
  const handleChange = event => {
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchValue.current = event.currentTarget.value.trim();
    searchTimeout = setTimeout(() => {
      if (typeof onChange === 'function') {
        onChange(searchValue.current);
      }
    }, 400);
  };

  return (
    <Grid container>
      <Grid item sm={6}>
        <TextField
          disabled={disabled}
          type="text"
          placeholder={t('shareparts_search_title')}
          margin="normal"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
