import {
  transition,
  containerFluid,
} from 'assets/jss/material-dashboard-pro-react.js';
import { alpha } from '@mui/material/styles';

const adminStyle = theme => ({
  wrapper: {
    position: 'relative',
    marginTop: '64px !important',
    height: 'calc(100vh - 64px)',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    bottom: 0,
    top: 130,
    padding: 20,
    overflow: 'auto',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      left: 80,
    },
    ...transition,
    maxHeight: 'calc(100% - 64px)',
    width: 'calc(100% - 80px)',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '0',
    padding: '30px 15px',
    height: '100%',
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px',
  },
  topNavigation: {
    background: '#FFF',
  },
  topNavigationLogo: {
    height: '34px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#0000005e',
  },
  inputRoot: {
    color: '#000',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    background: '#00000012',
  },
  grow: {
    flexGrow: 1,
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

export default adminStyle;
