import {
  GET_ALL_PROCESSES,
  GET_ALL_PROCESSES_ERROR,
  GET_ALL_PROCESSES_SUCCESS,
  GET_CURRENT_USER_INFO,
  GET_CURRENT_USER_INFO_ERROR,
  GET_CURRENT_USER_INFO_SUCCESS,
  GET_FIRST_LISTS,
  GET_FIRST_LISTS_ERROR,
  GET_FIRST_LISTS_SUCCESS,
  GET_MATERIALS,
  GET_MATERIALS_ERROR,
  GET_MATERIALS_MORE,
  GET_MATERIALS_MORE_ERROR,
  GET_MATERIALS_MORE_SUCCESS,
  GET_MATERIALS_SUCCESS,
  GET_PROCESSES,
  GET_PROCESSES_ERROR,
  GET_PROCESSES_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_ERROR,
  GET_SUPPLIERS_SUCCESS,
} from './generalTypes';

const INITIAL_STATE = {
  loading: true,
  error: null,
  currentUserInfo: null,
  currentUserInfoLoading: false,
  currentUserInfoError: null,
  materials: {},
  materialsLoading: false,
  materialsError: null,
  processes: [],
  processesLoading: true,
  processesError: null,
  allProcesses: [],
  allProcessesLoading: true,
  allProcessesError: null,
  lists: [],
  listsLoading: true,
  listsError: null,
  suppliers: [],
  suppliersLoading: true,
  suppliersError: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER_INFO:
      return {
        ...state,
        currentUserInfoLoading: true,
      };
    case GET_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        currentUserInfo: action.payload,
        currentUserInfoLoading: false,
      };
    case GET_CURRENT_USER_INFO_ERROR:
      return {
        ...state,
        currentUserInfoError: action.payload,
        currentUserInfoLoading: false,
      };
    /**
     * MATERIALS
     */
    case GET_MATERIALS:
      return {...state, materialsLoading: true};
    case GET_MATERIALS_SUCCESS:
      return {...state, materials: action.payload, materialsLoading: false};
    case GET_MATERIALS_ERROR:
      return {
        ...state,
        materialsError: action.payload,
        materialsLoading: false,
      };
    case GET_MATERIALS_MORE:
      return {...state, materialsLoading: true};
    case GET_MATERIALS_MORE_SUCCESS:
      return {
        ...state,
        materials: {
          page: [...state.materials.page, ...action.payload.page],
          pagination: action.payload.pagination,
        },
        materialsLoading: false,
      };
    case GET_MATERIALS_MORE_ERROR:
      return {
        ...state,
        materialsError: action.payload,
        materialsLoading: false,
      };

    /**
     * Processes
     */
    case GET_PROCESSES:
      return {...state, processesLoading: true};
    case GET_PROCESSES_SUCCESS:
      return {...state, processes: action.payload, processesLoading: false};
    case GET_PROCESSES_ERROR:
      return {
        ...state,
        processesError: action.payload,
        processesLoading: false,
      };
    case GET_ALL_PROCESSES:
      return {...state, allProcessesLoading: true};
    case GET_ALL_PROCESSES_SUCCESS:
      return {...state, allProcesses: action.payload, allProcessesLoading: false};
    case GET_ALL_PROCESSES_ERROR:
      return {...state, allProcessesError: action.payload, allProcessesLoading: false};

    /**
     * Lists
     */
    case GET_FIRST_LISTS:
      return {...state, listsLoading: true};
    case GET_FIRST_LISTS_SUCCESS:
      return {...state, listsLoading: false, lists: action.payload};
    case GET_FIRST_LISTS_ERROR:
      return {...state, listsLoading: false, listsError: action.payload};

    /**
     * Suppliers
     */
    case GET_SUPPLIERS:
      return {...state, suppliersLoading: true};
    case GET_SUPPLIERS_SUCCESS:
      return {...state, suppliersLoading: false, suppliers: action.payload};
    case GET_SUPPLIERS_ERROR:
      return {...state, suppliersLoading: false, suppliersError: action.payload};
      
    default:
      return state;
  }
};
