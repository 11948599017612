import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Box, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography} from '@mui/material';
import {TableRowSelect} from './TableRowSelect/TableRowSelect';
import {sortProcesses} from 'components/Modals/ConfigsModal/utils/processesHelper';
import StatusChip from 'components/StatusChip/StatusChip';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {fetchMaterials} from 'services/materials';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {ManufacturingAnalysisTable} from '../../ManufacturingAnalysisTable/ManufacturingAnalysisTable';
import './TableRow.scss';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    display: 'flex',
  },
}))(TableRow);

const generateStatusCell = (row, style) => {
  return (
    <TableCell style={style}>
      <StatusChip data={row} justify="flex-start" />
    </TableCell>
  );
};

const generateCell = (data, style) => {
  if (!data) {
    return <TableCell style={style}>{''}</TableCell>;
  } else if (typeof data === 'object' && data.name !== undefined) {
    return (
      <TableCell style={style} className="row-text-cell">
        <Tooltip title={data.name || ''} placement="bottom-start">
          <Typography variant="body2">{data.name}</Typography>
        </Tooltip>
      </TableCell>
    );
  } else if (typeof data !== 'object') {
    return (
      <TableCell style={style} className="row-text-cell">
        <Typography variant="body2">{data}</Typography>
      </TableCell>
    );
  }

  const entries = Object.entries(data);

  return (
    <TableCell style={style}>
      <Grid container>
        {entries.map(([key, value]) => (
          <>
            <Grid item xs={8}>
              <Box textAlign="left">{key}: </Box>
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end">
              <Box textAlign="left">
                {value instanceof Array ? value.join(', ') : value}
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </TableCell>
  );
};

const generateAnalysisCell = data => {
  return (
    <TableCell colSpan={2} style={{width: '40%'}}>
      <ManufacturingAnalysisTable
        data={data}
        editable={false}
        tableStyle={{}}
        rowStyle={{}}
        cellStyle={{
          borderBottom: 'none',
        }}
      />
    </TableCell>
  );
};

const generateActionsCell = (actions, configId) => {
  if (actions) {
    return (
      <TableCell align="center" style={{width: '15%'}}>
        {actions.map(action => {
          if (action.actionComponent) {
            return action.actionComponent;
          } else {
            return (
              <IconButton
                size="small"
                onClick={() => action.reaction(configId)}
                disabled={action.disabled}
                key={`${configId}-action-${parseInt(Math.random() * 1000)}`}
              >
                {action.icon({})}
              </IconButton>
            );
          }
        })}
      </TableCell>
    );
  } else {
    return <TableCell style={{width: '10%'}}></TableCell>;
  }
};

export const Row = ({row, actions, expanded, selectable, toggleConfigSelect}) => {
  const classes = useRowStyles();
  const {t} = useTranslation([
    'processes',
    'partconfigurations',
    'processfeatures',
  ]);

  const [open, setOpen] = useState(false);
  const [materialNames, setMaterialNames] = useState(null);
  const [partConfigProcesses, setPartConfigProcesses] = useState(
    sortProcesses(
      row.processes.filter(
        partConfigProcess => partConfigProcess && partConfigProcess.processId,
      ),
    ),
  );

  useEffect(() => {
    setPartConfigProcesses(
      sortProcesses(
        row.processes.filter(
          partConfigProcess => partConfigProcess && partConfigProcess.processId,
        ),
      ),
    );
  }, [row]);

  const getMaterialNames = async () => {
    if (!row.materialId) return;
    
    try {
      const {data: materials} = await fetchMaterials({filter: {where: {id: row.materialId}, include: {materialNames: true}}});
      const names = [materials[0]?.key, ...materials[0]?.materialNames.map(item => item.name)].filter(Boolean) 
      setMaterialNames(names.join(' / '));
    } catch (e) {
      console.error(e);
    }
  };

  const generateMaterialsNameCell = (batchSizes, materialName) => {
    if (batchSizes) {
      const sizes = batchSizes.map(item => item.batchSize);

      return (
        <TableCell style={{width: '30%'}}>
          <Grid container>
            <Grid item sm={6}>
              <Box textAlign="left">{`${t(
                'partconfigurations:t_batch_sizes',
              )}: `}</Box>
            </Grid>
            <Grid container item sm={6} justifyContent="flex-end">
              <Box textAlign="right">
                {sizes && sizes.length ? sizes.join(', ') : '-'}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box textAlign="left">{`${t(
                'partconfigurations:t_material_name',
              )}: `}</Box>
            </Grid>
            <Grid container item sm={6} justifyContent="flex-end">
              {materialName ? (
                <Tooltip
                  title={materialNames || materialName}
                  onOpen={() => getMaterialNames(materialName)}
                  onClose={() => setMaterialNames(null)}
                >
                  <Box textAlign="right">{materialName}</Box>
                </Tooltip>
              ) : (
                <Box textAlign="right">{'-'}</Box>
              )}
            </Grid>
          </Grid>
        </TableCell>
      );
    }

    return <TableCell style={{width: '30%'}}>{'-'}</TableCell>;
  };

  const processes = useSelector(state => state.general.allProcesses);

  useEffect(() => {
    if (expanded) {
      setOpen(expanded);
    }
  }, []);

  const renderRow = () => (
    <>
      <TableRow className={`${classes.root} collapse-row`} key={row.id}>
        <TableCell
          component="th"
          scope="row"
          className="collapse-table-id-cell"
          style={{width: '10%'}}
          onClick={() => setOpen(!open)}
        >
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.id}
        </TableCell>
        {generateCell(row, {width: '20%'})}
        {generateMaterialsNameCell(row.batchSizes, row.materialName)}
        {generateStatusCell(row, {width: '25%'})}
        {generateActionsCell(actions)}
        {selectable && (
          <TableRowSelect configId={row.id} selectable={selectable} toggleConfigSelect={toggleConfigSelect} />
        )}
      </TableRow>
      <TableRow key={`${row.id}-processes`}>
        <TableCell style={{padding: 0}} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="config">
              <TableBody>
                {partConfigProcesses && partConfigProcesses.length ? (
                  partConfigProcesses.map(
                    partConfigProcess =>
                      partConfigProcess && (
                        <StyledTableRow
                          key={partConfigProcess.id + new Date().getTime()}
                        >
                          <TableCell style={{width: '10%'}} />
                          {generateCell(
                            t(`processes:${processes?.find(item => item.id === partConfigProcess.processId)?.key}`),
                            {
                              verticalAlign: 'top',
                              width: '20%',
                            },
                          )}
                          {partConfigProcess.processFeatureValues &&
                          partConfigProcess.processFeatureValues.length
                            ? generateCell(
                              partConfigProcess.processFeatureValues.reduce(
                                (acc, curr) => {
                                  acc[t(`processfeatures:${curr.processFeatureTypeKey}`)] = curr.value?.trim().length && curr.value.trim() ||
                                    curr.valueAuto?.trim();
                                  return acc;
                                },
                                {},
                              ),
                              {
                                verticalAlign: 'bottom',
                                width: '30%',
                              },
                              4,
                            )
                            : generateCell('', {
                              verticalAlign: 'bottom',
                              width: '30%',
                            })}
                          {generateAnalysisCell(
                            partConfigProcess.manufacturingAnalysis,
                          )}
                        </StyledTableRow>
                      ),
                  )
                ) : (
                  <StyledTableRow>
                    {generateCell(
                      t(`partconfigurations:t_config_has_no_processes`),
                      {width: '100%', textAlign: 'center'},
                    )}
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );

  return renderRow();
};
