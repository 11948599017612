import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import style from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import {logoutUser} from 'services/auth';
import {useTranslation} from 'react-i18next';
import personHoodyIcon from 'assets/img/person_hoody.svg';
import {logEvent} from '../../utils/firebase';
import {useNavigate} from 'react-router-dom';

const useStyle = makeStyles(style);

function UserMenu() {
  const classes = useStyle();
  const {t} = useTranslation('navigation');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const signOut = () => {
    logEvent('kms_logout');
    logoutUser();
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleSettings = event => {
    handleClose(event);
    return navigate('/user-settings');
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return <>
    <Button
      ref={anchorRef}
      onClick={handleToggle}
      aria-controls="profile-menu"
      className={classes.topBarButton}
    >
      <img
        src={personHoodyIcon}
        alt="person-hoody"
        style={{maxWidth: '40px'}}
      />
    </Button>

    <Popper
      open={open}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      placement="bottom"
      className={classNames({
        [classes.popperClose]: !open,
        [classes.popperResponsive]: true,
        [classes.popperNav]: true,
      })}
    >
      {({TransitionProps}) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'bottom',
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <MenuItem
                  onClick={handleClose}
                  className={classes.menuItem}
                  style={{color: '#aaa'}}
                >
                  {t('profile')}
                </MenuItem>
                <MenuItem
                  onClick={handleSettings}
                  className={classes.menuItem}
                >
                  {t('settings')}
                </MenuItem>
                <MenuItem onClick={signOut} className={classes.menuItem}>
                  {t('logout')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>;
}

export default UserMenu;
