import API from './api';

export const assignAttachmentToPart = async (attachmentId, partId) =>
  await API.post({
    url: `/attachments/${attachmentId}/part/${partId}/assign`,
  });

export const unassignAttachmentFromPart = async (attachmentId, partId, force) =>
  await API.delete({
    url: `/attachments/${attachmentId}/part/${partId}/unassign?force=${!!force}`,
  });

export const reassignAttachmentToPart = async (attachmentId, partId, force) => {
  await API.post({
    url: `/attachments/${attachmentId}/part/${partId}/reassign?force=${!!force}`,
  });
};

export const patchAttachment = async (attachmentId, data) => {
  return API.patch({
    url: `/attachments/${attachmentId}`,
    data,
  });
};

export const deleteAttachment = async attachmentId =>
  API.delete({
    url: `/attachments/${attachmentId}`,
  });
