import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  FormControl,
  TextField,
} from '@mui/material';

import './OrderCheckoutModal.scss';

class OrderCheckoutModalInternalOrderNumber extends Component {
  render() {
    return (
      <FormControl fullWidth>
        <TextField
          required
          label={this.props.t('internal_order_number')}
          value={this.props.internalOrderNumber}
          onChange={({target: {value}}) => this.props.handleInternalOrderNumberChange(value)}
        />
      </FormControl>
    );
  }
}

export default withTranslation('order_checkout_modal')(OrderCheckoutModalInternalOrderNumber);
