import {
  GET_PARTS_AUTO,
  GET_PARTS_AUTO_SUCCESS,
  GET_PARTS_AUTO_ERROR,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
} from './TopBarTypes';

const INITIAL_STATE = {
  loading: false,
  parts: [],
  error: null,
  canImpersonateCompanies: false,
  companies: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PARTS_AUTO:
      return { ...state, loading: true };

    case GET_PARTS_AUTO_SUCCESS:
      return { ...state, parts: action.payload };

    case GET_PARTS_AUTO_ERROR:
      return { ...state, parts: action.payload };

    case GET_COMPANIES:
      return { ...state, companies: [] };

    case GET_COMPANIES_SUCCESS:
      return { ...state, companies: action.payload };

    case GET_COMPANIES_ERROR:
      return { ...state, companies: [] };

    default:
      return state;
  }
};
