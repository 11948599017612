import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';

import styles from 'assets/jss/material-dashboard-pro-react/components/paginationStyle.js';

const useStyles = makeStyles(styles);

export default function Pagination(props) {
  const classes = useStyles();
  // arrows are included as first and last in pages props array of numbers
  const { pages, color } = props;
  /**
   * @description current active page.
   * Note!: alue is index in the array
   * @type { active, text, value, onClick }
   */
  const FIRST_ACTIVE_PAGE = 1;
  const currentActive = React.useRef(null);

  /**
   * Represent like a symbol that inform user that have more pages
   */
  const abbreviation = {
    active: false,
    onClick: () => {},
    text: '...',
    value: '...',
  };

  React.useEffect(() => {
    if (pages) {
      if (pages.length === 0) {
        if (currentActive.current > 1) {
          if (typeof props.onClick === 'function') {
            props.onClick({
              currentTarget: { innerText: currentActive.current - 1 },
            });
          }
        }
      }
    }
    return () => {
      currentActive.current = null;
    };
  }, [pages]);

  const renderPages = () => {
    // prevent empty pages to be displayed

    if (!hasItems()) {
      return <div></div>;
    }
    selectCurrentPage();

    const previousPages = selectPreviousPages();
    const currentPage = [currentActive.current];
    const nextPages = selectNextPages();
    const pagesForRender = [...previousPages, ...currentPage, ...nextPages];

    return (
      <ul className={classes.pagination}>
        {pagesForRender.map((prop, key) => {
          const paginationLink = cx({
            [classes.paginationLink]: true,
            [classes[color]]: prop.active,
            [classes.disabled]: prop.disabled,
          });
          return (
            <li className={classes.paginationItem} key={key} value={prop.text}>
              {prop.onClick !== undefined ? (
                <Button
                  onClick={() => prop.onClick({ target: { value: prop.text } })}
                  value={prop.text}
                  className={paginationLink}
                >
                  {prop.text}
                </Button>
              ) : (
                <Button
                  onClick={() => alert("you've clicked " + prop.text)}
                  className={paginationLink}
                >
                  {prop.text}
                </Button>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const hasItems = () => {
    return pages.length > 2;
  };

  const selectCurrentPage = () => {
    if (hasItems()) {
      // find the active link
      const active = pages.filter(item => item.active);
      if (active.length) {
        currentActive.current = active[0];
      } else {
        // If list have only one page, take the page and make it active
        currentActive.current = pages[FIRST_ACTIVE_PAGE];
        currentActive.current.active = true;
      }
    }
  };

  /**
   * Render previous pages
   */
  const selectPreviousPages = () => {
    const LEFT_ARROW = 0;
    const FIRST_ITEM = 1;
    const INDEX_INCLUDES_ABBREVIATION = 4;
    let itemsForDisplay = [];
    if (hasItems()) {
      // left arrow is displayed only if user have previous pages to move on
      if (currentActive.current.value !== 1) {
        itemsForDisplay.push(pages[LEFT_ARROW]);
      }
      // include page numbers
      if (currentActive.current.value >= INDEX_INCLUDES_ABBREVIATION) {
        // include previous 2 pages and the first
        itemsForDisplay.push(pages[FIRST_ITEM]);
        itemsForDisplay.push(abbreviation);
        for (
          let index = currentActive.current.value - 2;
          index < currentActive.current.value;
          index++
        ) {
          const element = pages[index];
          itemsForDisplay.push(element);
        }
      } else {
        if (currentActive.current.value === FIRST_ACTIVE_PAGE) {
          return [];
        }

        // show all from first to third page
        for (
          let index = FIRST_ITEM;
          index < currentActive.current.value;
          index++
        ) {
          const selectedItem = pages[index];
          itemsForDisplay.push(selectedItem);
        }
      }
    }

    return itemsForDisplay;
  };

  const selectNextPages = () => {
    const RIGHT_ARROW = pages.length - 1;
    const LAST_ITEM_INDEX = pages.length - 2;
    // abbreviation "..."
    const INDEX_INCLUDES_ABBREVIATION = LAST_ITEM_INDEX - 3;
    let itemsForDisplay = [];
    if (hasItems()) {
      const CURRENT_ITEM_INDEX = currentActive.current.value;
      const ITEM_START = CURRENT_ITEM_INDEX + 1;
      if (CURRENT_ITEM_INDEX <= INDEX_INCLUDES_ABBREVIATION) {
        // start to count from next to current page
        for (let index = ITEM_START; index < ITEM_START + 2; index++) {
          const element = pages[index];
          itemsForDisplay.push(element);
        }
        itemsForDisplay.push(abbreviation);
        itemsForDisplay.push(pages[LAST_ITEM_INDEX]);
      } else {
        // show all pages till the end
        for (let index = ITEM_START; index <= LAST_ITEM_INDEX; index++) {
          const element = pages[index];
          itemsForDisplay.push(element);
        }
      }
      // arrows are included in this list
      if (currentActive.current.value !== LAST_ITEM_INDEX) {
        itemsForDisplay.push(pages[RIGHT_ARROW]);
      }
    }

    return itemsForDisplay;
  };

  return renderPages();
}

Pagination.defaultProps = {
  color: 'primary',
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['<<', '>>', '...']),
      ]).isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};
