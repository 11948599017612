import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, CircularProgress, Grid, Step, StepLabel, Stepper, Tooltip, Typography} from '@mui/material';
import {withTheme} from '@mui/styles';
import {withSnackbar} from 'notistack';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ArrangeAttachments from './ArrangeAttachments';
import AddBatchSizes from './AddBatchSizes';
import ReviewAndSubmit from './ReviewAndSubmit';
import {triggerManufacturingAnalysis, unassignPartsFromList} from 'services/part-lists';

const MySwal = withReactContent(Swal);

class AutomaticConfiguration extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      activeStep: 0,
    };
  }

  nextStep = async () => {
    // If we are in the arrange attachments step, and one or more of the parts don't have an attachments, warn the user and give them the option to remove them
    if (this.state.activeStep === 0 && this.props.createdPartList.partConfigs.some(pc => !pc.part?.attachments?.length)) {
      const {t, createdPartList, refreshCreatedPartList, theme} = this.props;

      const {dismiss} = await MySwal.fire({
        title: <Typography variant={'h5'}>{t('one_or_more_parts_have_no_attachments')}</Typography>,
        html: <Typography variant={'body1'}>{t('one_or_more_parts_have_no_attachments_details')}</Typography>,
        icon: 'warning',
        confirmButtonText: t('remove_empty_parts_and_continue'),
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: t('continue_anyways'),
        reverseButtons: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonColor: theme.palette.secondary.main,
        preConfirm: async () => {
          const partIds = createdPartList.partConfigs.filter(pc => !pc.part?.attachments?.length).map(pc => pc.partId);
          await unassignPartsFromList(createdPartList.id, partIds);
          await refreshCreatedPartList();
        },
      });
      
      if (dismiss === 'close') return;
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStep = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  partListValid = () => {
    return this.props.createdPartList.partConfigs.every(pc => pc?.batchSizes?.length > 0 && pc.part?.attachments?.length > 0);
  };

  submitForAutomaticConfiguration = async () => {
    await this.setState({
      loading: true,
    });

    try {
      await triggerManufacturingAnalysis(this.props.createdPartList.id);

      this.props.setConfigurationComplete(this.props.createdPartList.id);
    } catch (e) {
      console.error(e);
      await this.setState({
        loading: false,
      });
      this.props.enqueueSnackbar(this.props.t('unknown_error'), {
        variant: 'error',
      });
    }
  };

  render () {
    const {t, createdPartList, refreshCreatedPartList} = this.props;

    const steps = [
      {name: 'arrange_attachments', child: <ArrangeAttachments partList={createdPartList} refreshPartList={refreshCreatedPartList} />},
      {name: 'add_batch_sizes', child: <AddBatchSizes partConfigs={createdPartList?.partConfigs} refreshPartList={refreshCreatedPartList} />},
      {name: 'review_and_submit', child: <ReviewAndSubmit partList={createdPartList} />},
    ];

    return <>
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        style={{
          height: '100%',
          width: '100%',
          margin: 0,
          padding: 0,
        }}
      >
        <Grid item xs={12}>
          <Stepper activeStep={this.state.activeStep} style={{width: 'min(75%, 600px)'}}>
            {steps.map((step, index) => {
              return <Step key={step.name} completed={index < this.state.activeStep}>
                <StepLabel><Typography variant={'body1'}>{index === this.state.activeStep ? t(step.name) : ''}</Typography></StepLabel>
              </Step>;
            })}
          </Stepper>
        </Grid>
        <Grid
          item xs={12}
          container
          justifyContent={'center'}
          alignItems={'flex-start'}
          style={{width: '100%', height: 'calc(100% - 144px)', paddingTop: 24, paddingLeft: 0, overflowY: 'scroll'}}
        >
          <Grid item xs={12} children={steps[this.state.activeStep].child} style={{width: '90%', marginLeft: '5%', marginRight: '5%'}} />
        </Grid>
        <Grid item xs={12} container style={{width: '100%', height: 72, paddingLeft: 0}} justifyContent={'center'} alignItems={'center'} spacing={2}>
          <Grid item>
            <Button onClick={this.previousStep} disabled={!this.state.activeStep}>{t('previous')}</Button>
          </Grid>
          <Grid item>
            <Tooltip
              placement={'top'}
              title={t('cannot_submit_message')}
              disableHoverListener={!(this.state.activeStep + 1 === steps.length && !this.partListValid())}
            >
              <div>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  style={{color: 'white'}}
                  onClick={this.state.activeStep + 1 === steps.length ? this.submitForAutomaticConfiguration : this.nextStep}
                  disabled={this.state.loading || this.state.activeStep + 1 === steps.length && !this.partListValid()}
                >
                  {this.state.loading && <CircularProgress size={24} style={{marginRight: 10, color: 'white'}} /> || ''}
                  {this.state.activeStep + 1 === steps.length ? t('submit_for_automatic_configuration') : t('next')}
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>;
  };
}

export default withTheme(withSnackbar(withTranslation('automatic_configuration')(AutomaticConfiguration)));