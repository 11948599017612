import React, { useState, useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import './PartConfiguration.scss';
import { CollapseTable } from 'components/CollapseTable/CollapseTable';
import ConfigsModal from 'components/Modals/ConfigsModal/ConfigsModal';
import { useTranslation } from 'react-i18next';
import { fetchPartConfigs } from 'services/parts';
import Loading from 'components/Loading/Loading';

export default function PartConfigurations({ part }) {
  const { t } = useTranslation(['partconfigurations']);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [, setAddToListModal] = useState(false);
  const editConfigId = useRef(null);

  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchConfigData() {
    setLoading(true);
    setConfigs([]);
    const response = await fetchPartConfigs(part.id, {
      filter: {
        include: [
          {
            relation: 'processes',
            scope: {
              include: [
                'process',
                'processFeatureValues',
                {
                  relation: 'manufacturingAnalysis',
                  scope: {
                    include: 'batchSize',
                  },
                },
              ],
            },
          },
          'batchSizes',
        ],
      },
    });
    setConfigs(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchConfigData();
  }, []);

  return (
    <>
      <h3>{t('partconfigurations:part_configurations')}</h3>
      <Grid
        container
        item
        xs={12}
        className={'parts-config-section pd-section'}
      >
        {loading ? (
          <Loading pageHeight={400} />
        ) : (
          <CollapseTable
            editConfigId={editConfigId}
            openConfigModal={setEditModalOpen}
            openAddToListModal={setAddToListModal}
            configs={configs}
            refetchConfigs={fetchConfigData}
          />
        )}
      </Grid>
      <ConfigsModal
        part={part}
        open={editModalOpen}
        setOpen={setEditModalOpen}
        editConfigId={editConfigId}
        confirm={() => {
          editConfigId.current = null;
          fetchConfigData();
        }}
      />
    </>
  );
}
