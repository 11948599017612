import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { toDisplayDate } from 'utils/datetimeHelpers';
import { Grid, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ListModalItem = ({ item, onSelect, selected = false }) => {
  const [isSelected, setIsSelected] = useState(selected);
  const { t } = useTranslation('partlists');

  const handleSelect = () => {
    const select = !isSelected;
    setIsSelected(select);
    if (typeof onSelect == 'function') {
      onSelect({ isSelected: select, item: item, toggleSelect: toggleSelect });
    }
  };

  const toggleSelect = select => {
    setIsSelected(select);
  };

  return (
    <Grid
      container
      item
      alignItems={'center'}
      justifyContent={'flex-start'}
      key={item.id}
      className="list-modal-item"
    >
      <Grid item md={1}>
        <SettingsIcon />
      </Grid>
      <Grid item md={9} className={'list-description'}>
        <h4>{item.name}</h4>
        <p>{item.createdAt ? toDisplayDate(item.createdAt) : '-'}</p>
        <p>{`${item.partCount} ${t('number_of_parts')}`}</p>
      </Grid>
      <Grid item md={2}>
        <Checkbox
          color={'primary'}
          checked={isSelected}
          value={1}
          onChange={handleSelect}
          inputProps={{
            'aria-label': 'part checkbox',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListModalItem;
