import React from 'react';
import { Grid } from '@mui/material';
import { ConfigCard } from 'components/ConfigCard/ConfigCard';

export const PartListConfigItems = ({
  items,
  fetchAll,
  selectableConfigs,
  toggleConfigSelect,
  cloneModalMode,
  canAddConfig,
  hideRemove,
}) => {
  return (
    <>
      {items &&
        items.page &&
        items.page.map(item => {
          return (
            <Grid key={item.id} item xs={12}>
              <ConfigCard
                part={item}
                fetchAll={fetchAll}
                selectableConfigs={selectableConfigs}
                toggleConfigSelect={toggleConfigSelect}
                cloneModalMode={cloneModalMode}
                canAddConfig={canAddConfig}
                hideRemove={hideRemove}
              />
            </Grid>
          );
        })}
    </>
  );
};
