import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Container } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

import UserDetails from './UserDetails/UserDetails';
import UserPasswordChange from './UserPasswordChange/UserPasswordChange';

import { updateBreadcrumbs } from 'store/breadcrumbs/breadcrumbsActions';

import './UserSettings.scss';

export default function UserSettings() {
  const userData = useSelector(state => state.general.currentUserInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation('settings');

  useEffect(() => {
    dispatch(
      updateBreadcrumbs({
        level: 0,
        path: '/user-settings',
        text: 'settings:acs_user_details_title',
      })
    );
  }, []);

  return (
    <Container>
      <Grid container className="user-settings-form">
        <Grid item container sm={12}>
          <Grid item sm={12} className="user-section-title">
            <h3>
              <AccountCircleIcon /> {t('acs_user_details_title')}
            </h3>
          </Grid>
          <UserDetails user={userData} />
        </Grid>
        <Grid item container sm={12}>
          <Grid item sm={12} className="user-section-title">
            <h3>
              <LockIcon /> {t('acs_password_change_title')}
            </h3>
          </Grid>
          <UserPasswordChange />
        </Grid>
      </Grid>
    </Container>
  );
}
