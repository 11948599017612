export const CREATE_LIST = 'part-list-new/create-list';
export const CREATE_LIST_ERROR = 'part-list-new/create-list-error';
export const CREATE_LIST_SUCCESS = 'part-list-new/create-list-success';

export const UPDATE_LIST = 'part-list-new/update-list';
export const UPDATE_LIST_ERROR = 'part-list-new/update-list-error';
export const UPDATE_LIST_SUCCESS = 'part-list-new/update-list-success';

export const GET_PARTS = 'part-list-new/get-parts';
export const GET_PARTS_ERROR = 'part-list-new/get-parts-error';
export const GET_PARTS_SUCCESS = 'part-list-new/get-parts-success';

export const SEARCH_PARTS = 'part-list-new/search-parts';
export const SEARCH_PARTS_SUCCESS = 'part-list-new/search-parts-success';
export const SEARCH_PARTS_ERROR = 'part-list-new/search-parts-error';

export const SAVE_CONFIGS = 'part-list-new/save-configs';
export const SAVE_CONFIGS_SUCCESS = 'part-list-new/save-configs-success';
export const SAVE_CONFIGS_ERROR = 'part-list-new/save-configs-error';

export const ADD_CONFIG = 'part-list-new/add-config';
export const REMOVE_CONFIG = 'part-list-new/remove-config';

export const PART_STORAGE_CLEANUP = 'part-list-new-cleanup';
