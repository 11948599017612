import {
  NAVIGATE_BREADCRUMB,
  BREADCRUMB_PIN_AS_STICKY_HEADER,
} from './breadcrumbsTypes';

export const updateBreadcrumbs = crumb => {
  return dispatch => {
    dispatch({ type: NAVIGATE_BREADCRUMB, payload: crumb });
  };
};

/**
 * Set BREADCRUMB as sticky header
 * @param {bool} pinAsStickyHeader
 */
export const pinAsStickyHeader = pinAsStickyHeader => {
  return dispatch => {
    dispatch({
      type: BREADCRUMB_PIN_AS_STICKY_HEADER,
      payload: pinAsStickyHeader,
    });
  };
};
