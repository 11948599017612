import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {patchOrder, getOrderTeaserById} from 'services/orders';

import DownloadFile from '../../components/Download/DownloadFile';
import OrderDetailsCards from '../../components/OrderDetails/OrderDetailsCards';
import OrderDetailsTable from '../../components/OrderDetails/OrderDetailsTable';

import {
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import {Description} from '@mui/icons-material';

import {LoadingWithoutHeight} from 'components/Loading/Loading';

import {updateBreadcrumbs} from 'store/breadcrumbs/breadcrumbsActions';
import {withRouter} from '../../utils/helpers';

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: orderName => dispatch(
      updateBreadcrumbs({
        level: 1,
        path: window.location.pathname,
        text: orderName,
      }),
    ),
  };
};

const connectOrderDetails = connect(null, mapDispatchToProps);

class OrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: this.props.params.id,
      order: null,
      loading: true,
    };
  }

  async componentDidMount() {
    // Get Orders
    const {data: order} = await getOrderTeaserById(this.state.orderId, {
      filter: {
        include: {
          orderedPartListTeaserOrderedAttachments: {
            include: {
              orderedAttachment: {
                include: {
                  attachmentType: true,
                  fileFormat: true,
                },
              },
            },
          },
          orderedPartConfigTeasers: {
            include: {
              orderedPartConfigProcesses: {
                include: {
                  process: true,
                  orderedManufacturingAnalysisTeaser: true,
                },
              },
              orderedPart: true,
            },
          },
        },
      },
    });

    this.props.setBreadcrumbs(order.name);

    this.setState({
      order,
      loading: false,
    });
  }

  componentDidUpdate() {
    if (this.props.params.id !== this.state.orderId) {
      this.setState({
        orderId: this.props.params.id,
        order: null,
        loading: true,
      }, this.componentDidMount);
    }
  }

  updateOrder = async payload => {
    const {data: order} = await patchOrder(this.state.order.id, payload);
    this.setState({order: {...this.state.order, ...order}});
    return order;
  };

  render() {
    const {t} = this.props;

    return (
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          justifyContent={'center'}
          alignItems={'center'}
          style={{paddingBottom: 110}}
        >
          {
            this.state.loading ? (
              <Grid item xs={12}>
                <LoadingWithoutHeight />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent={'space-between'}>
                  <Grid item xs={12} md={4}>
                    <Typography variant={'h4'}>{t('order_details:section_title')}</Typography>
                  </Grid>
                  <Grid container item spacing={3} justifyContent={'flex-end'} xs={12} md={6}>
                    <Grid item style={{textAlign: 'right'}}>
                      <DownloadFile
                        model={this.state.order}
                        ComponentToRender={Button}
                        ComponentToRenderProps={{
                          variant: 'outlined',
                        }}
                        ComponentToRenderChildren={<><Description style={{paddingRight: 5}} />{t('download_estimates_pdf')}</>}
                        fileType="estimates_pdf"
                      />
                    </Grid>
                    {this.state.order.orderConfirmationId &&
                      <Grid item style={{textAlign: 'right'}}>
                        <DownloadFile
                          model={this.state.order}
                          ComponentToRender={Button}
                          ComponentToRenderProps={{
                            variant: 'outlined',
                          }}
                          ComponentToRenderChildren={<><Description style={{paddingRight: 5}} />{t('download_order_confirmation_pdf')}</>}
                          fileType="order_confirmation"
                        />
                      </Grid>}
                    {this.state.order.invoiceId &&
                      <Grid item style={{textAlign: 'right'}}>
                        <DownloadFile
                          model={this.state.order}
                          ComponentToRender={Button}
                          ComponentToRenderProps={{
                            variant: 'outlined',
                          }}
                          ComponentToRenderChildren={<><Description style={{paddingRight: 5}} />{t('download_invoice_pdf')}</>}
                          fileType="invoice"
                        />
                      </Grid>}
                  </Grid>
                  <Grid item container xs={12} spacing={3} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <OrderDetailsCards order={this.state.order} updateOrder={this.updateOrder} />
                    <OrderDetailsTable order={this.state.order} />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withTranslation(['orders', 'order_details'])(connectOrderDetails(OrderDetails)));
