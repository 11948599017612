import {
  CREATE_LIST,
  CREATE_LIST_ERROR,
  CREATE_LIST_SUCCESS,
  UPDATE_LIST,
  UPDATE_LIST_ERROR,
  UPDATE_LIST_SUCCESS,
  GET_PARTS,
  GET_PARTS_ERROR,
  GET_PARTS_SUCCESS,
  PART_STORAGE_CLEANUP,
  SEARCH_PARTS,
  SEARCH_PARTS_SUCCESS,
  SEARCH_PARTS_ERROR,
  SAVE_CONFIGS,
  SAVE_CONFIGS_SUCCESS,
  SAVE_CONFIGS_ERROR,
  ADD_CONFIG,
  REMOVE_CONFIG,
} from './NewPartListTypes';

import { fetchParts } from 'services/parts';
import { createList, editList, saveConfigsToList } from 'services/part-lists';

export const createNewList = () => {
  return async dispatch => {
    dispatch({
      type: CREATE_LIST,
    });

    try {
      const response = await createList({
        name: `List${new Date().getTime()}`,
      });

      dispatch({
        type: CREATE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_LIST_ERROR,
        payload: error,
      });
    }
  };
};

export const updateListDetails = (listId, details) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_LIST,
    });

    try {
      const response = await editList(listId, {
        ...details,
      });

      dispatch({
        type: UPDATE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LIST_ERROR,
        payload: error,
      });
    }
  };
};

export const getParts = (filter = null) => {
  return async dispatch => {
    dispatch({
      type: GET_PARTS,
    });

    try {
      const response = await fetchParts(filter);
      dispatch({
        type: GET_PARTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARTS_ERROR,
        payload: error,
      });
    }
  };
};

export const getPartsSearch = (filter = null) => {
  return async dispatch => {
    dispatch({
      type: SEARCH_PARTS,
    });

    try {
      const response = await fetchParts(filter);
      dispatch({
        type: SEARCH_PARTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_PARTS_ERROR,
        payload: error,
      });
    }
  };
};

export const saveConfigs = (listName, partConfigIdsList) => {
  return async dispatch => {
    dispatch({
      type: SAVE_CONFIGS,
    });

    try {
      const listResponse = await createList({
        name: listName,
      });

      await saveConfigsToList(listResponse.data.id, partConfigIdsList);

      dispatch({
        type: SAVE_CONFIGS_SUCCESS,
        payload: listResponse.data,
      });
    } catch (error) {
      dispatch({
        type: SAVE_CONFIGS_ERROR,
        payload: error,
      });
    }
  };
};

export const selectConfig = configId => {
  return dispatch => {
    dispatch({ type: ADD_CONFIG, payload: configId });
  };
};

export const removeConfig = configId => {
  return dispatch => {
    dispatch({ type: REMOVE_CONFIG, payload: configId });
  };
};

export const doCleanup = () => {
  return async dispatch => {
    dispatch({ type: PART_STORAGE_CLEANUP });
  };
};

export const clearNewListSelections = () => {
  return async dispatch => {
    dispatch({ type: PART_STORAGE_CLEANUP });
  };
}