import React, {useEffect} from 'react';
import './CustomFormInput.scss';
import {Autocomplete, FormControl, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import {Check, Close} from '@mui/icons-material';

export default function CustomFormInput ({
  dataType,
  onDataChange,
  value,
  label,
  name,
  inpKey,
  id,
  onBlur,
  unitKey,
  selectedValue,
  selectOptions,
  required,
}) {
  useEffect(() => {}, [value]);

  const setUnitLbl = unitKey => {
    if (unitKey === 'percent') {
      return '%';
    }
    return unitKey;
  };

  const handleNumberDataChange = ({num, inpKey, dataType, unitKey}) => {
    const number = parseFloat(num);
    if (isNaN(number) && value.toString().length > 1) {
      return;
    }

    if (number.length > 0) {
      if (unitKey === 'percent') {
        if (number <= 0) {
          num = 0;
        } else if (number > 100) {
          num = 100;
        }
      }
    }

    if (typeof onDataChange === 'function') {
      onDataChange({value: num, inpKey, dataType, unitKey});
    }
  };

  const renderInput = data_type => {
    switch (data_type) {
      case 'text':
      case 'string':
        return (
          <div className={`fm-custom-inp`} style={{width: '80%'}}>
            <CustomInput
              className={'input-field'}
              labelText={label}
              inputProps={{
                multiline: data_type === 'text',
                name: name,
                type: 'text',
                value: value,
                error: !!(required && !value),
                onChange: event => {
                  onDataChange({
                    value: event.target.value,
                    id,
                    inpKey,
                    dataType,
                    unitKey,
                  });
                },
                onBlur: event =>
                  onBlur({
                    value: event.target.value,
                    inpKey,
                    dataType,
                    unitKey,
                  }),
                endAdornment: !!unitKey ? <InputAdornment position={'end'}>{setUnitLbl(unitKey)}</InputAdornment> : undefined,
              }}
              id={id}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </div>
        );
      case 'double':
        return (
          <div className={`fm-custom-inp`} style={{width: '80%'}}>
            <CustomInput
              className={'input-field'}
              labelText={label}
              inputProps={{
                name: name,
                type: 'number',
                step: 'any',
                value: value,
                error: !!(required && !value),
                onChange: event => {
                  handleNumberDataChange({
                    num: event.target.value,
                    inpKey,
                    dataType,
                    unitKey,
                  });
                },
                onBlur: event =>
                  onBlur({
                    value: event.target.value,
                    inpKey,
                    dataType,
                    unitKey,
                  }),
                endAdornment: !!unitKey ? <InputAdornment position={'end'}>{setUnitLbl(unitKey)}</InputAdornment> : undefined,
              }}
              id={id}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </div>
        );
      case 'int':
        return (
          <div className={`fm-custom-inp`} style={{width: '80%'}}>
            <CustomInput
              className={'input-field'}
              labelText={label}
              inputProps={{
                name: name,
                type: 'number',
                step: '1',
                value: value,
                error: !!(required && !value),
                onChange: event => {
                  handleNumberDataChange({
                    num: event.target.value,
                    id,
                    inpKey,
                    dataType,
                    unitKey,
                  });
                },
                onBlur: event =>
                  onBlur({
                    value: event.target.value,
                    inpKey,
                    dataType,
                    unitKey,
                  }),
                endAdornment: !!unitKey ? <InputAdornment position={'end'}>{setUnitLbl(unitKey)}</InputAdornment> : undefined,
              }}
              id={id}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </div>
        );
      case 'bool':
        const boolValue = value?.toString().toLowerCase();

        return (
          <Grid container spacing={2} direction={'row'} alignItems={'center'}>
            <Grid item xs={6}>
              <Typography
                variant={'body1'}
                style={{color: !!(required && !boolValue?.length) ? 'red' : 'inherit'}}
              >
                {label}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={boolValue}
                exclusive
                onChange={(event, value) => {
                  if (value) {
                    onDataChange({value: value === 'true', id, inpKey, dataType});
                  }
                }}
                size={'small'}
              >
                <ToggleButton value="true">
                  <Check />
                </ToggleButton>
                <ToggleButton value="false">
                  <Close />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        );
      case 'select':
        return (
          <div style={{width: '80%', marginTop: 21}}>
            <FormControl fullWidth>
              <Autocomplete
                options={selectOptions}
                renderInput={params =>
                  <TextField
                    {...params}
                    label={label}
                    error={!!(required && !value)}
                  />
                }
                value={selectedValue}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, value) => {
                  onDataChange({
                    value: value.value,
                    id,
                    inpKey,
                    dataType,
                  });
                }}
                disableClearable
              />
            </FormControl>
          </div>
        );
    }
  };

  return (
    <Grid item xs={6}>
      {renderInput(dataType)}
    </Grid>
  );
}
