import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PartListRow from './PartListRow';
import { Box } from '@mui/material';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
});

export function ListsForPartTable({ lists }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation('partdetails');

  const columns = [
    { id: 'name', label: 'pdl_column_name' },
    { id: 'part_count', label: 'pdl_column_part_count', minWidth: 100 },
    { id: 'config_count', label: 'pdl_column_config_count', minWidth: 100 },
  ];

  function createData({ id, name, partCount, partConfigCount }) {
    return {
      id,
      name,
      part_count: parseInt(partCount),
      config_count: partConfigCount,
    };
  }

  const rows =
    lists.page.map(listItem => {
      return createData(listItem);
    }) || [];

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderRow = (row, columns) => {
    return (
      <PartListRow key={'part-list-row' + row.id} row={row} columns={columns} />
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => renderRow(row, columns))
            ) : (
              <TableRow hover tabIndex={-1} key={`htable-empty-row-1`}>
                <TableCell colSpan={3}>
                  <Box textAlign="center">{t('pdl_empty_lists')}</Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

const PartLists = ({ partLists }) => {
  const { t } = useTranslation('partdetails');

  return (
    <>
      <h3>{t('pdl_part_lists_title')}</h3>
      <Paper className={'pd-section'}>
        <ListsForPartTable lists={partLists} />
      </Paper>
    </>
  );
};

export default PartLists;
