import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import './BatchSizesForm.scss';
import {withSnackbar} from 'notistack';
import Swal from 'sweetalert2';
import {
  IconButton,
  Grid,
  Tooltip,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Alert} from '@mui/material';
import {withTheme} from '@mui/styles';
import withReactContent from 'sweetalert2-react-content';
import {saveBatchSize} from 'components/Modals/ConfigsModal/ConfigActions';
import {deleteBatchSize} from 'services/configs';
import {logEvent} from 'utils/firebase';
import _ from 'lodash';

const MySwal = withReactContent(Swal);

class BatchSizesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sizes: [],
      editDisabled: [],
    };
  }

  async componentDidMount() {
    if (this.props.batchSizes?.length) {
      await this.setState({
        sizes: this.props.batchSizes.map(bs => ({
          ...bs,
          referencePrice: bs.referencePrice ? parseFloat(bs.referencePrice) : null,
          originalBatchSize: bs.batchSize,
        })),
        editDisabled: this.props.batchSizes.map(() => true),
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if ((prevProps.batchSizes !== this.props.batchSizes) && prevProps.batchSizes === null) {
      await this.setState({
        sizes: this.props.batchSizes.map(bs => ({
          ...bs,
          referencePrice: bs.referencePrice ? parseFloat(bs.referencePrice) : null,
          frameworkSupplierCostPerUnit: bs.frameworkSupplierCostPerUnit ? parseFloat(bs.frameworkSupplierCostPerUnit) : null,
          frameworkAppliedMargin: bs.frameworkAppliedMargin ? parseFloat(bs.frameworkAppliedMargin) : null,
          originalBatchSize: bs.batchSize,
        })),
        editDisabled: this.props.batchSizes.map(() => true),
      });
    }
  }

  handleChange = (value, index) => {
    this.setState({
      sizes: this.state.sizes.map((bs, i) => (i === index ? {...bs, batchSize: value} : bs)),
    });
  };

  handleFieldChange = async (field, value, index, commit = false) => {
    await this.setState({
      sizes: this.state.sizes.map((bs, i) => (i === index ? {...bs, [field]: value} : bs)),
    });

    if (commit && this.state.editDisabled[index]) {
      await this.saveEdit(index);
    }
  };

  deleteBatchSizeInstance = async (index, force) => {
    const updatedBatchSizeInstances = this.state.sizes.filter((bs, i) => i !== index);
    const batchSizeInstanceId = this.state.sizes[index].id;

    // If the instance exists on the database
    if (batchSizeInstanceId) {
      try {
        await deleteBatchSize(this.props.configId, batchSizeInstanceId, force);
        logEvent('click_delete_batch_size');
      } catch (e) {
        console.error(e);

        if (e?.response?.status === 420) {
          const {isConfirmed} = await MySwal.fire({
            title: <Typography variant={'h5'}>{this.props.t('pc_mdl_remove_batch_size_ma_alert')}</Typography>,
            icon: 'warning',
            confirmButtonText: '✓',
            showCancelButton: true,
            cancelButtonText: this.props.t('cancel'),
            reverseButtons: true,
            confirmButtonColor: this.props.theme.palette.primary.main,
          });

          if (isConfirmed) {
            await this.deleteBatchSizeInstance(index, true);
          }

          return;
        }

        this.props.enqueueSnackbar(this.props.t('pc_mdl_remove_batch_size_error'), {
          variant: 'error',
        });
      }
    }

    // Remove instance from state
    await this.setState({
      sizes: updatedBatchSizeInstances,
      editDisabled: this.state.editDisabled.filter((ed, i) => i !== index),
    });

    // Update store
    this.props.updateBatchSizes(this.state.sizes.filter(bs => !!bs.id));
  };

  saveEdit = async index => {
    try {
      if (!/^\d+[\d ]*$/g.test(this.state.sizes[index]?.batchSize)) {
        throw new Error('Invalid input. Input must match /^\d+[\d ]*$/g');
      }

      let lineItemsBreakdown = null;
      let batchSize = this.state.sizes[index].batchSize;

      if (typeof this.state.sizes[index].batchSize === 'string') {
        lineItemsBreakdown = this.state.sizes[index].batchSize.trim().split(' ').map(e => parseInt(e));
        batchSize = _.sum(lineItemsBreakdown);
      }

      const {frameworkContractsEnabled} = this.props;

      const {data} = await saveBatchSize(
        this.props.configId,
        {
          id: this.state.sizes[index].id,
          batchSize,
          lineItemsBreakdown: lineItemsBreakdown?.length > 1 ? lineItemsBreakdown : null,
          referencePrice: this.state.sizes[index].referencePrice ? this.state.sizes[index].referencePrice : null,
          frameworkSupplierCostPerUnit: frameworkContractsEnabled && this.state.sizes[index].frameworkSupplierCostPerUnit
            ? this.state.sizes[index].frameworkSupplierCostPerUnit
            : null,
          frameworkAppliedMargin: frameworkContractsEnabled && this.state.sizes[index].frameworkAppliedMargin ? this.state.sizes[index].frameworkAppliedMargin : null,
          frameworkSelectedSupplierId: frameworkContractsEnabled && this.state.sizes[index].frameworkSelectedSupplierId
            ? this.state.sizes[index].frameworkSelectedSupplierId
            : null,
        },
      );

      const updatedBatchSizeInstances = this.state.sizes.map((bs, i) => (i === index ? {...bs, ...data, originalBatchSize: data.batchSize} : bs));

      // Add the data to the state
      await this.setState({
        sizes: updatedBatchSizeInstances,
        editDisabled: this.state.editDisabled.map((ed, i) => (i === index ? true : ed)),
      });

      // Update store
      this.props.updateBatchSizes(this.state.sizes.filter(bs => !!bs.id));
      logEvent('save_batch_size');
    } catch (e) {
      console.error(e);
      this.props.enqueueSnackbar(this.props.t('batch_size_saving_error'), {
        variant: 'error',
      });
    }
  };

  cancelEdit = index => {
    this.setState({
      sizes: this.state.sizes.map((bs, i) => i === index ? {...bs, batchSize: bs.originalBatchSize} : bs),
      editDisabled: this.state.editDisabled.map((ed, i) => (i === index ? true : ed)),
    });
  };

  renderInput = (item, index) => {
    if (!this.state.editDisabled[index]) {
      return item.batchSize;
    }

    let label = `${item.batchSize}`;

    if (item.lineItemsBreakdown) {
      const {t} = this.props;
      label += `; ${t('automatic_configuration:line_items')}: [${item.lineItemsBreakdown.join(', ')}]`;
    }

    return label;
  };

  handleAddBatchSize = () => {
    this.setState({
      sizes: [...this.state.sizes, {batchSize: null, originalBatchSize: null}],
      editDisabled: [...this.state.editDisabled, false],
    });
  };

  render() {
    const {t, referencePricesEnabled, frameworkContractsEnabled, suppliers, userHasImpersonationRights} = this.props;

    return <Grid container rowSpacing={2}>
      {this.state.sizes.map((item, index) => {
        return <Grid item xs={12}>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'flex-start'}
            spacing={2}
          >
            <Grid item xs={2}>
              <TextField
                placeholder={t('partconfigurator:empty_batch_size')}
                label={' '}
                type={'string'}
                fullWidth={true}
                value={this.renderInput(item, index)}
                disabled={this.state.editDisabled[index]}
                style={{
                  color: 'black',
                }}
                onChange={event => this.handleChange(event.target.value, index)}
              />
            </Grid>
            {referencePricesEnabled && <Grid item xs={2}>
              <TextField
                label={`${t('partconfigurator:reference_price')} [€]`}
                type={'number'}
                fullWidth={true}
                value={item.referencePrice}
                style={{
                  color: 'black',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => this.handleFieldChange('referencePrice', event.target.value, index)}
                onBlur={event => this.handleFieldChange('referencePrice', event.target.value, index, true)}
                disabled={frameworkContractsEnabled && !!item.frameworkSupplierCostPerUnit}
              />
            </Grid>}
            {frameworkContractsEnabled && userHasImpersonationRights && <>
              <Grid item xs={2}>
                <TextField
                  label={`${t('partconfigurator:price_per_piece')} [€]`}
                  type={'number'}
                  fullWidth={true}
                  value={item.frameworkSupplierCostPerUnit}
                  style={{
                    color: 'black',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => this.handleFieldChange('frameworkSupplierCostPerUnit', event.target.value, index)}
                  onBlur={event => this.handleFieldChange('frameworkSupplierCostPerUnit', event.target.value, index, true)}
                  disabled={!!item.referencePrice}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={`${t('partconfigurator:applied_margin')} [%]`}
                  type={'number'}
                  fullWidth={true}
                  value={item.referencePrice || !item.frameworkSupplierCostPerUnit ? '' : item.frameworkAppliedMargin}
                  style={{
                    color: 'black',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => this.handleFieldChange('frameworkAppliedMargin', event.target.value, index)}
                  onBlur={event => this.handleFieldChange('frameworkAppliedMargin', event.target.value, index, true)}
                  disabled={!!item.referencePrice || !item.frameworkSupplierCostPerUnit}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={suppliers.filter(s => s.active).map(s => s.id)}
                  getOptionLabel={option => suppliers.find(s => option === s.id)?.companyName}
                  value={!item.referencePrice && item.frameworkSupplierCostPerUnit && item.frameworkSelectedSupplierId || null}
                  onChange={(event, value) => this.handleFieldChange('frameworkSelectedSupplierId', value, index, true)}
                  renderInput={params =>
                    <TextField
                      {...params}
                      label={`${t('partconfigurator:selected_supplier')}`}
                      fullWidth={true}
                      style={{
                        color: 'black',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }
                  disabled={!!item.referencePrice || !item.frameworkSupplierCostPerUnit}
                />
              </Grid>
            </>}
            {this.state.editDisabled[index] ? (
              <Grid item>
                <IconButton
                  onClick={() => this.deleteBatchSizeInstance(index)}
                  style={{
                    color: 'red',
                  }}
                  size="large"
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                <IconButton
                  onClick={() => this.saveEdit(index)}
                  style={{
                    color: 'green',
                  }}
                  size="large"
                >
                  <CheckCircleIcon />
                </IconButton>
                {item.id ? (
                  <IconButton
                    onClick={() => this.cancelEdit(index)}
                    style={{
                      color: 'red',
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => this.deleteBatchSizeInstance(index)}
                    style={{
                      color: 'red',
                    }}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )}
              </Grid>
            )}
            {item.maComment && (
              <Grid item xs={referencePricesEnabled ? 5 : 7}>
                <Tooltip title={t('batch_size_rejected_tooltip')}>
                  <Alert severity="warning">{t(`reject_reasons:${item.maComment}`) === `reject_reasons:${item.maComment}` ? item.maComment : t(
                    `reject_reasons:${item.maComment}`)}</Alert>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>;
      })}
      <Grid item>
        <IconButton onClick={this.handleAddBatchSize} size="large">
          <AddCircleIcon style={{color: '#00acc1'}} />
        </IconButton>
      </Grid>
    </Grid>
      ;
  }
}

export default withTheme(withSnackbar(withTranslation(['partconfigurator', 'reject_reasons', 'automatic_configuration'])(BatchSizesForm)));
