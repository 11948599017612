import {
  GET_PART_LIST,
  GET_PART_LIST_ERROR,
  GET_PART_LIST_SUCCESS,
  GET_LIST_PARTS,
  GET_LIST_PARTS_ERROR,
  GET_LIST_PARTS_SUCCESS,
  PART_DETAILS_CLEANUP,
  PART_DETAILS_PARTS_CLEANUP,
  PART_LIST_NAME_UPDATE,
  PART_NAME_UPDATE,
  UNASSIGN_PART_ATTACHMENT,
  UNASSIGN_PART_ATTACHMENT_SUCCESS,
  UNASSIGN_PART_ATTACHMENT_ERROR,
  UNASSIGN_PART_ATTACHMENT_CLEAN,
  SELECTED_PART,
  UNSELECT_PART,
  UNASSIGN_PART_FROM_LIST,
  UNASSIGN_PART_SUCCESS,
  UNASSIGN_PART_ERROR,
  UNASSIGN_PART_CLEANUP,
  SELECT_CONFIG,
  REMOVE_CONFIG,
  CLONE_CONFIG_TO_ALL_LIST_LOADING,
  CLONE_CONFIG_TO_ALL_LIST_SUCCESS,
  CLONE_CONFIG_TO_ALL_LIST_ERROR,
  CLEAR_SELECTED_CONFIGS,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_SUCCESS,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_ERROR,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_LOADING,
  ADD_EMPTY_CONFIG_TO_PART,
  ADD_EMPTY_CONFIG_TO_PART_SUCCESS,
  ADD_EMPTY_CONFIG_TO_PART_ERROR,
} from './PartListDetailsTypes';
import {
  fetchPartList,
  fetchPartsForList,
  unassignPartFormList,
  cloneToAllPartConfigsInList,
  cloneToSomePartConfigsInList,
  saveConfigsToList,
} from '../../services/part-lists';
import { unassignAttachmentFromPart } from 'services/attachments';
import { createEmptyConfig } from 'services/configs';

export const getPartList = (id = null, params = null) => {
  return async dispatch => {
    dispatch({
      type: GET_PART_LIST,
    });

    try {
      const responseList = await fetchPartList(id, params);
      dispatch({
        type: GET_PART_LIST_SUCCESS,
        payload: responseList.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PART_LIST_ERROR,
        payload: error,
      });
    }
  };
};

export const setPartList = partList => {
  return async dispatch => {
    dispatch({
      type: GET_PART_LIST_SUCCESS,
      payload: partList,
    })
  }
}

export const getPartsForList = (params = null) => {
  return async dispatch => {
    dispatch({ type: PART_DETAILS_PARTS_CLEANUP });
    
    dispatch({
      type: GET_LIST_PARTS,
    });

    try {
      const responseListParts = await fetchPartsForList(params.listId, {
        filter: { ...params.filter },
      });

      dispatch({ type: PART_DETAILS_PARTS_CLEANUP });

      dispatch({
        type: GET_LIST_PARTS_SUCCESS,
        payload: responseListParts.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIST_PARTS_ERROR,
        payload: error,
      });
    }
  };
};

export const doCleanup = () => {
  return dispatch => {
    dispatch({ type: PART_DETAILS_CLEANUP });
  };
};

export const doPartsCleanup = () => {
  return dispatch => {
    dispatch({ type: PART_DETAILS_PARTS_CLEANUP });
  };
};

export const unassignPartAttachment = ({ partId, attachmentId }) => {
  return async dispatch => {
    dispatch({ type: UNASSIGN_PART_ATTACHMENT });

    try {
      await unassignAttachmentFromPart(attachmentId, partId);
      dispatch({
        type: UNASSIGN_PART_ATTACHMENT_SUCCESS,
        payload: { partId, attachmentId },
      });
    } catch (error) {
      dispatch({
        type: UNASSIGN_PART_ATTACHMENT_ERROR,
        payload: error,
      });
    }
  };
};

export const unassignPartAttachmentCleanup = () => {
  return async dispatch => {
    dispatch({ type: UNASSIGN_PART_ATTACHMENT_CLEAN });
  };
};

export const getPaginatedPartsForList = (params = null) => {
  return async dispatch => {
    dispatch({ type: PART_DETAILS_PARTS_CLEANUP });

    dispatch({
      type: GET_LIST_PARTS,
    });

    try {
      const responseListParts = await fetchPartsForList(params.listId, {
        ...params,
      });

      dispatch({
        type: GET_LIST_PARTS_SUCCESS,
        payload: responseListParts.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIST_PARTS_ERROR,
        payload: error,
      });
    }
  };
};

export const editListName = listName => {
  return { type: PART_LIST_NAME_UPDATE, listName };
};

export const editPartName = part => {
  return { type: PART_NAME_UPDATE, payload: { ...part } };
};

/**
 * PART UNASSIGN AND SELECTION
 */

export const selectPart = part => {
  return async dispatch => {
    dispatch({
      type: SELECTED_PART,
      payload: { part },
    });
  };
};

export const unselectPart = part => {
  return async dispatch => {
    dispatch({
      type: UNSELECT_PART,
      payload: { part },
    });
  };
};

export const selectConfig = configId => {
  return async dispatch => {
    dispatch({
      type: SELECT_CONFIG,
      payload: configId,
    });
  };
};

export const removeConfig = configId => {
  return async dispatch => {
    dispatch({
      type: REMOVE_CONFIG,
      payload: configId,
    });
  };
};

export const unassignPartFromPartList = ({ listId, partId }) => {
  return async dispatch => {
    dispatch({
      type: UNASSIGN_PART_FROM_LIST,
    });

    try {
      await unassignPartFormList({ listId, partId });
      dispatch({
        type: UNASSIGN_PART_SUCCESS,
        payload: { listId, partId },
      });
      dispatch({ type: UNASSIGN_PART_CLEANUP });
    } catch (error) {
      dispatch({
        type: UNASSIGN_PART_ERROR,
        payload: error,
      });
    }
  };
};

export const addEmptyConfigInPart = (partId, listId) => {
  return async dispatch => {
    try {
      dispatch({ type: ADD_EMPTY_CONFIG_TO_PART });
      const result = await createEmptyConfig(partId);

      if (listId) {
        await saveConfigsToList(listId, [result.data.id]);
      }

      dispatch({ type: ADD_EMPTY_CONFIG_TO_PART_SUCCESS });
    } catch (error) {
      dispatch({ type: ADD_EMPTY_CONFIG_TO_PART_ERROR });
    }
  };
};

export const cloneConfigToAllPartsInList = ({ listId, partConfigId }) => {
  return async dispatch => {
    try {
      dispatch({ type: CLONE_CONFIG_TO_ALL_LIST_LOADING });
      await cloneToAllPartConfigsInList({ id: listId, partConfigId });
      dispatch({ type: CLONE_CONFIG_TO_ALL_LIST_SUCCESS });
      // refresh all list of parts to show cloned configs
      doCleanup();
      getPartList();
    } catch (error) {
      dispatch({ type: CLONE_CONFIG_TO_ALL_LIST_ERROR });
    }
  };
};

export const saveSelectedConfigs = ({
  listId,
  partConfigId,
  destinationConfigIds,
}) => {
  return async dispatch => {
    dispatch({ type: CLONE_CONFIG_TO_SELECTED_CONFIGS_LOADING });
    try {
      await cloneToSomePartConfigsInList({
        id: listId,
        partConfigId,
        destinationConfigIds,
      });
      dispatch({ type: CLONE_CONFIG_TO_SELECTED_CONFIGS_SUCCESS });
      dispatch(clearSelectedConfig());
      doCleanup();
      getPartList();
    } catch (error) {
      dispatch({ type: CLONE_CONFIG_TO_SELECTED_CONFIGS_ERROR });
    }
  };
};

export const clearSelectedConfig = () => {
  return async dispatch => {
    dispatch({
      type: CLEAR_SELECTED_CONFIGS,
    });
  };
};
