import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import localizedCountries from 'localized-countries';
import {
  Grid,
  IconButton,
  Modal,
  Paper,
  Fade,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Alert,
  AlertTitle,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import {
  Close,
  Schedule,
  ShoppingCart,
  Settings,
  Receipt,
  CheckCircleOutline,
  HighlightOff,
  ConfirmationNumber,
  LocalShipping,
  Business,
  Check,
} from '@mui/icons-material';
import moment from 'moment';

import OrderCheckoutModalCalendarPicker from './OrderCheckoutModalCalendarPicker';
import OrderCheckoutModalAddressForm from './OrderCheckoutModalAddressForm';
import OrderCheckoutModalInternalOrderNumber from './OrderCheckoutModalInternalOrderNumber';
import OrderCheckoutModalSelectedPartsTable from './OrderCheckoutModalSelectedPartsTable';

import './OrderCheckoutModal.scss';
import {toCurrency} from '../../../utils/numberHelpers';
import {placeOrderWithKreatize, getAddressesFromAPI, createAddress} from '../../../services/orders';
import {editList} from '../../../services/part-lists';
import {logEvent} from 'utils/firebase';
import {calculateDeliveryDateFromLeadTime, withRouter} from '../../../utils/helpers';
import {allCountries} from 'country-region-data';

class OrderCheckoutModal extends Component {
  localizedCountriesForUserLanguage = localizedCountries(require(`localized-countries/data/${this.props.i18n.language}`));

  constructor(props) {
    super(props);

    moment.locale(props.i18n.language);

    const isInternalUser = props.currentUserInfo?.roles?.some(role => role.name === 'impersonation');
    const minDeliveryDate = isInternalUser ? moment() : calculateDeliveryDateFromLeadTime(props.leadTime);

    this.state = {
      loading: false,
      activeStep: 0,
      isInternalUser,

      invalidDate: false,
      selectedDate: minDeliveryDate,
      minDeliveryDate,

      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        zipCode: '',
        selectedCountry: 'DE',
        selectedCountryLabel: this.localizedCountriesForUserLanguage.get('DE'),
        selectedRegion: 'BE',
        selectedRegionLabel: 'Berlin',
        saveAddress: false,
      },
      billingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        zipCode: '',
        selectedCountry: 'DE',
        selectedCountryLabel: this.localizedCountriesForUserLanguage.get('DE'),
        selectedRegion: 'BE',
        selectedRegionLabel: 'Berlin',
        saveAddress: false,
      },
      userAddresses: [],
      isDirectShipment: false,

      internalOrderNumber: props.partList.internalOrderNumber,

      agreedToConditions: false,
      orderPlaced: false,
      order: null,
      orderError: false,
    };
  }

  async componentDidMount() {
    try {
      const {data: addresses} = await getAddressesFromAPI();
      this.setState({userAddresses: addresses});
    } catch {}
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.leadTime !== this.props.leadTime && !this.state.isInternalUser) {
      const minDeliveryDate = calculateDeliveryDateFromLeadTime(this.props.leadTime);
      this.setState({
        selectedDate: minDeliveryDate,
        minDeliveryDate,
      });
    }
  }

  nextStep = () => {
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStep = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
      orderError: false,
    });
  };

  handleOrder = async () => {
    this.setState({
      loading: true,
    });

    try {
      // Patch the part list prior to placing the order, if the internal order number was updated.
      if (this.state.internalOrderNumber.trim() !== this.props.partList.internalOrderNumber) {
        await editList(this.props.partList.id, {internalOrderNumber: this.state.internalOrderNumber.trim()});
      }

      if (this.state.address.saveAddress) {
        const {data: newAddress} = await createAddress({
          addressLabel: this.state.address.addressLabel,
          addressLine1: this.state.address.addressLine1,
          addressLine2: this.state.address.addressLine2 || null,
          zipCode: this.state.address.zipCode,
          city: this.state.address.city,
          state: this.state.address.selectedRegionLabel,
          country: {
            connect: {
              key: this.state.address.selectedCountry,
            },
          },
          addressType: 'shipping',
        });

        this.setState({
          userAddresses: [
            ...this.state.userAddresses,
            newAddress,
          ],
        });
      }

      if (this.state.billingAddress.saveAddress) {
        const {data: newAddress} = await createAddress({
          addressLabel: this.state.billingAddress.addressLabel,
          addressLine1: this.state.billingAddress.addressLine1,
          addressLine2: this.state.billingAddress.addressLine2 || null,
          zipCode: this.state.billingAddress.zipCode,
          city: this.state.billingAddress.city,
          state: this.state.billingAddress.selectedRegionLabel,
          country: {
            connect: {
              key: this.state.billingAddress.selectedCountry,
            },
          },
          addressType: 'billing',
        });

        this.setState({
          userAddresses: [
            ...this.state.userAddresses,
            newAddress,
          ],
          billingAddress: {
            ...this.state.billingAddress,
            saveAddress: false,
          },
        });
      }

      const {data: order} = await placeOrderWithKreatize(
        this.props.partList.id,
        this.props.selectedBatchSizeIds,
        this.state.selectedDate,
        {
          addressLine1: this.state.address.addressLine1,
          addressLine2: this.state.address.addressLine2 || undefined,
          zipCode: this.state.address.zipCode,
          city: this.state.address.city,
          state: this.state.address.selectedRegionLabel,
          country: this.state.address.selectedCountry,
        },
        {
          addressLine1: this.state.billingAddress.addressLine1,
          addressLine2: this.state.billingAddress.addressLine2 || undefined,
          zipCode: this.state.billingAddress.zipCode,
          city: this.state.billingAddress.city,
          state: this.state.billingAddress.selectedRegionLabel,
          country: this.state.billingAddress.selectedCountry,
        },
        this.state.isDirectShipment,
      );

      logEvent('purchase', {
        value: this.props.totalCost,
        currency: 'EUR',
        transaction_id: order.id,
        from_locked_estimate: false,
      });

      this.setState({
        order,
        orderPlaced: true,
        orderError: false,
        loading: false,
        activeStep: this.state.activeStep + 1,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        orderError: true,
        orderPlaced: false,
        loading: false,
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handleDateSelection = date => {
    // If the day is a weekend, then pick the next Monday
    if ([6, 7].includes(date.isoWeekday())) date.add(1, 'week').isoWeekday(1);

    this.setState({
      invalidDate: false,
      selectedDate: date,
    });
  };

  handleDateSelectionError = error => {
    if (error) this.setState({invalidDate: true});
  };

  handleCountrySelection = (selectedCountry, billingAddress = false) => {
    this.setState({
      [billingAddress ? 'billingAddress' : 'address']: {
        ...this.state[billingAddress ? 'billingAddress' : 'address'],
        selectedCountry,
        selectedCountryLabel: this.localizedCountriesForUserLanguage.get(selectedCountry),
        selectedRegion: '',
        selectedRegionLabel: '',
      },
    });
  };

  handleRegionSelection = (selectedRegion, billingAddress = false) => {
    const label = allCountries.find(country => country[1] === this.state[billingAddress ? 'billingAddress' : 'address'].selectedCountry)[2].find(region => region[1] === selectedRegion)?.[0];
    this.setState({
      [billingAddress ? 'billingAddress' : 'address']: {
        ...this.state[billingAddress ? 'billingAddress' : 'address'],
        selectedRegion,
        selectedRegionLabel: label,
      },
    });
  };

  handleAddressChange = (newData, billingAddress = false) => {
    this.setState({
      [billingAddress ? 'billingAddress' : 'address']: {
        ...this.state[billingAddress ? 'billingAddress' : 'address'],
        ...newData,
      },
    });
  };

  goToOrder = () => {
    this.props.navigate(`/orders/${this.state.order.id}`);
  };

  handleModalClose = () => {
    const {onClose} = this.props;

    this.setState({
      activeStep: 0,
      agreedToConditions: false,
      orderPlaced: false,
      order: null,
      orderError: false,
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        zipCode: '',
        selectedCountry: 'DE',
        selectedCountryLabel: this.localizedCountriesForUserLanguage.get('DE'),
        selectedRegion: 'BE',
        selectedRegionLabel: 'Berlin',
        saveAddress: false,
      },
      isDirectShipment: false,
    });

    onClose();
  };

  render() {
    const {open, configs, selectedBatchSizeIds, totalCost, i18n, t} = this.props;

    return (
      <Modal open={open} closeAfterTransition>
        <Fade in={open}>
          <div className={'order-checkout-modal'}>
            <Paper className={'order-checkout-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-order-checkout-modal-button'}
                onClick={this.handleModalClose}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                justifyContent={'center'}
                className={'order-checkout-modal-content'}
              >
                <Grid item xs={12}>
                  <Typography variant={'h5'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ShoppingCart style={{marginRight: 5}} /> {t('checkout')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stepper orientation={'vertical'} activeStep={this.state.activeStep}>
                    <Step>
                      <StepLabel>
                        <Typography variant={'body1'}><b>{t('choose_desired_delivery_date')}</b></Typography>
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <OrderCheckoutModalCalendarPicker
                              selectedDate={this.state.selectedDate}
                              handleDateSelection={this.handleDateSelection}
                              handleDateSelectionError={this.handleDateSelectionError}
                              minDate={this.state.minDeliveryDate}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              color={'primary'}
                              style={{color: '#fff'}}
                              variant={'contained'}
                              onClick={this.nextStep}
                              disabled={this.state.invalidDate}
                            >
                              {t('next')}
                            </Button>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Typography variant={'body1'}><b>{t('enter_delivery_address')}</b></Typography>
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={3} style={{maxWidth: 572}}>
                          <Grid item xs={12}>
                            <OrderCheckoutModalAddressForm
                              address={this.state.address}
                              userAddresses={this.state.userAddresses}
                              handleAddressChange={this.handleAddressChange}
                              handleRegionSelection={this.handleRegionSelection}
                              handleCountrySelection={this.handleCountrySelection}
                            />
                          </Grid>
                          {this.state.isInternalUser && (
                            <Grid item xs={12}>
                              <Alert color={'error'} variant={'outlined'} icon={false}>
                                <AlertTitle>{t('internal_is_direct_shipment_header')}</AlertTitle>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {t('internal_is_direct_shipment_description')}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <ToggleButtonGroup
                                      exclusive
                                      size={'small'}
                                      value={this.state.isDirectShipment}
                                      onChange={(_e, newValue) => this.setState({isDirectShipment: newValue})}
                                    >
                                      <ToggleButton value={true}>
                                        <Check />
                                      </ToggleButton>
                                      <ToggleButton value={false}>
                                        <Close />
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </Grid>
                                </Grid>
                              </Alert>
                            </Grid>
                          )}
                          <Grid item container xs={12} spacing={1}>
                            <Grid item>
                              <Button
                                onClick={this.previousStep}
                              >
                                {t('back')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{color: '#fff'}}
                                onClick={this.nextStep}
                                disabled={
                                  !(
                                    this.state.address.addressLine1 &&
                                    this.state.address.city &&
                                    this.state.address.zipCode &&
                                    this.state.address.selectedRegionLabel &&
                                    this.state.address.selectedCountry
                                  )
                                  || (this.state.address.saveAddress && !this.state.address.addressLabel)
                                }
                              >
                                {t('next')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Typography variant={'body1'}><b>{t('enter_billing_address')}</b></Typography>
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={3} style={{maxWidth: 572}}>
                          <Grid item xs={12}>
                            <OrderCheckoutModalAddressForm
                              address={this.state.billingAddress}
                              userAddresses={this.state.userAddresses}
                              billingAddress
                              handleAddressChange={this.handleAddressChange}
                              handleRegionSelection={this.handleRegionSelection}
                              handleCountrySelection={this.handleCountrySelection}
                            />
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item>
                              <Button
                                onClick={this.previousStep}
                              >
                                {t('back')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant={'contained'}
                                color={'primary'}
                                style={{color: '#fff'}}
                                onClick={this.nextStep}
                                disabled={
                                  !(
                                    this.state.billingAddress.addressLine1 &&
                                    this.state.billingAddress.city &&
                                    this.state.billingAddress.zipCode &&
                                    this.state.billingAddress.selectedRegionLabel &&
                                    this.state.billingAddress.selectedCountry
                                  )
                                  || (this.state.billingAddress.saveAddress && !this.state.billingAddress.addressLabel)
                                }
                              >
                                {t('next')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Typography variant={'body1'}><b>{t('enter_internal_order_number')}</b></Typography>
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={3} style={{maxWidth: 572}}>
                          <Grid item xs={6}>
                            <OrderCheckoutModalInternalOrderNumber
                              internalOrderNumber={this.state.internalOrderNumber}
                              handleInternalOrderNumberChange={internalOrderNumber => this.setState({internalOrderNumber: internalOrderNumber})}
                            />
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item>
                              <Button
                                onClick={this.previousStep}
                              >
                                {t('back')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color={'primary'}
                                style={{color: '#fff'}}
                                variant={'contained'}
                                onClick={this.nextStep}
                                disabled={!this.state.internalOrderNumber}
                              >
                                {t('next')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel>
                        <Typography variant={'body1'}><b>{t('review_and_checkout')}</b></Typography>
                      </StepLabel>
                      <StepContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Settings fontSize={'small'} style={{marginRight: '10px'}} /><Typography variant={'body1'}>{t('selected_configurations')}:</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{marginLeft: 30}}>
                            <OrderCheckoutModalSelectedPartsTable
                              configs={configs}
                              selectedBatchSizeIds={selectedBatchSizeIds}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{paddingTop: 10}}>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Schedule fontSize={'small'} style={{marginRight: '10px'}} />
                                <Typography variant={'body1'} style={{marginRight: '5px'}}>{t('desired_delivery_date')}:</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: 30}}>
                              <Typography variant={'body1'}>{this.state.selectedDate.locale(i18n.language).format('LL')}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <LocalShipping fontSize={'small'} style={{marginRight: '10px'}} /><Typography variant={'body1'}>{t('delivery_address')}:</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: 30}}>
                              <Typography>{this.state.address.addressLine1}</Typography>
                              <Typography>{this.state.address.addressLine2}</Typography>
                              <Typography>{this.state.address.zipCode} {this.state.address.city}</Typography>
                              <Typography>{this.state.address.selectedRegionLabel}, {this.state.address.selectedCountryLabel}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Business fontSize={'small'} style={{marginRight: '10px'}} /><Typography variant={'body1'}>{t('billing_address')}:</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: 30}}>
                              <Typography>{this.state.billingAddress.addressLine1}</Typography>
                              <Typography>{this.state.billingAddress.addressLine2}</Typography>
                              <Typography>{this.state.billingAddress.zipCode} {this.state.billingAddress.city}</Typography>
                              <Typography>{this.state.billingAddress.selectedRegionLabel}, {this.state.billingAddress.selectedCountryLabel}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <ConfirmationNumber fontSize={'small'} style={{marginRight: '10px'}} /><Typography variant={'body1'}>{t('internal_order_number')}:</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: 30}}>
                              <Typography variant={'body1'}>{this.state.internalOrderNumber}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Receipt fontSize={'small'} style={{marginRight: '10px'}} /><Typography variant={'body1'}>{t('total')}:</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} style={{marginLeft: 30}}>
                              <Typography variant={'h5'}>{toCurrency(totalCost)}</Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1} alignItems={'center'}>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.agreedToConditions}
                                    onClick={() => this.setState({agreedToConditions: !this.state.agreedToConditions})}
                                    color={'primary'}
                                  />
                                }
                                label={<Typography style={{color: 'black'}}>{t('agree_to_order')}</Typography>}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} spacing={1}>
                            <Grid item>
                              <Button
                                onClick={this.previousStep}
                              >
                                {t('back')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={this.handleOrder}
                                style={{color: '#fff'}}
                                disabled={this.state.loading || !this.state.agreedToConditions}
                              >
                                {t('order')}
                                {this.state.loading && (
                                  <CircularProgress
                                    style={{marginLeft: 5, color: '#fff'}}
                                    size={20}
                                  />
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StepContent>
                    </Step>
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container alignItems={'center'} direction={'column'} spacing={3}>
                    {this.state.orderPlaced && (
                      <>
                        <Grid item>
                          <CheckCircleOutline fontSize={'large'} style={{color: '#5ed05e'}} />
                        </Grid>
                        <Grid item>
                          <Typography variant={'h5'}>{t('thank_you')}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'body1'} align={'center'}>{t('order_being_processed')}</Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            style={{color: '#fff'}}
                            onClick={this.goToOrder}
                          >
                            {t('go_to_order')}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {this.state.orderError && (
                      <>
                        <Grid item>
                          <HighlightOff fontSize={'large'} style={{color: '#ff0000'}} />
                        </Grid>
                        <Grid item>
                          <Typography variant={'h5'}>{t('uh_oh')}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'body1'} align={'center'}>{t('order_error')}</Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={'contained'}
                            onClick={this.previousStep}
                          >
                            {t('go_back')}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withRouter(withTranslation('order_checkout_modal')(OrderCheckoutModal));
