import React, {useState, useGlobal} from 'reactn';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Button from 'components/CustomButtons/Button.js';
import {CloudUpload} from '@mui/icons-material';

import {AppBar, Hidden, Toolbar, Tooltip, Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import UserMenu from '../Menus/UserMenu';
import NotificationsMenu from '../Menus/NotificationsMenu';

import {LanguageToggle} from 'components/LanguageToggle/LanguageToggle';
import {UploadModal, ImpersonationModal} from 'components/Modals';

import logo from 'assets/img/kms_logo_short.svg';
import './TopBar.scss';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import ImpersonationMenu from '../Menus/ImpersonationMenu';
import {stopImpersonating, startImpersonating} from '../../services/users';
import AppSearch from '../AppSearch/AppSearch';

const useStyles = makeStyles(styles);

export default props => {
  const classes = useStyles();
  const {t} = useTranslation(['common', 'navigation']);

  const general = useSelector(status => status.general);

  const [isUploadModalOpen, setUploadModalOpen] = useGlobal('isOpenUploadModal');
  const [isImpersonationModalOpen, setImpersonationModalOpen] = useState(
    false,
  );

  const refreshPage = () => {
    window.location.reload();
  };

  const impersonate = async companyAssetId => {
    await startImpersonating(companyAssetId);
    refreshPage();
  };

  const stopImpersonatingCompany = async () => {
    await stopImpersonating();
    refreshPage();
  };

  const confirmUploadModal = () => {
    setUploadModalOpen(false);
    window.dispatchEvent(new Event('filesUploaded'));
  };

  return (
    <AppBar className="top-navigation" color="default" position="fixed">
      <Toolbar>
        <Hidden mdDown>
          <NavLink to={`/`}>
            <Typography variant="h6">
              <img className={'top-bar-logo'} src={logo} alt="Kreatize" />
            </Typography>
          </NavLink>
          <div className={classes.search}>
            <AppSearch {...props} />
          </div>
        </Hidden>
        <div className={classes.grow} />
        {general.currentUserInfo && general.currentUserInfo.impersonating && (
          <div style={{textAlign: 'center'}}>
            ⚠️ {t('navigation:nav_you_are_currently_impersonating')} :{' '}
            {general.currentUserInfo.impersonatedCompany.companyName}
            {general.currentUserInfo.impersonatedCompany.features?.some(feature => feature.companyFeatureKey === 'reference_prices_enabled' && feature.value === 'true') && (
              <>
                <br />
                <small>{t('navigation:nav_company_has_reference_prices_enabled')}</small>
              </>
            )}
          </div>
        )}
        <div className={classes.grow} />
        <div>
          <Tooltip className="upload-tooltip" title={t('cmd_upload_tooltip')}>
            <span className="upload-button">
              <Button
                onClick={() => setUploadModalOpen(true)}
                color="success"
                size="sm"
                startIcon={<CloudUpload />}
              >
                {t('cmn_upload')}
              </Button>
            </span>
          </Tooltip>
          {general.currentUserInfo &&
            <LanguageToggle
              user={general.currentUserInfo}
            />
          }
          {general.currentUserInfo &&
            general.currentUserInfo.roles.some(role => role.name === 'impersonation') && (
              <ImpersonationMenu
                user={general.currentUserInfo}
                setImpersonationModalOpen={setImpersonationModalOpen}
              />
            )}
          <UserMenu user={general.currentUserInfo} />
          <NotificationsMenu />
        </div>
      </Toolbar>
      <UploadModal
        open={isUploadModalOpen}
        setOpen={setUploadModalOpen}
        confirm={confirmUploadModal}
      />
      <ImpersonationModal
        user={general.currentUserInfo}
        open={isImpersonationModalOpen}
        setOpen={setImpersonationModalOpen}
        impersonate={impersonate}
        stopImpersonating={stopImpersonatingCompany}
      />
    </AppBar>
  );
};
