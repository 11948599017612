import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { SharedLinksTableActionsEnum } from 'models/enums/SharedLinksTableActionsEnum';

const SharedTableActionMenu = ({ onSelect, disabled }) => {
  const { t } = useTranslation('sharedparts');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = value => {
    setAnchorEl(null);
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  };
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        color="primary"
        disabled={disabled}
      >
        {t('shareparts_actions')} <ExpandMoreIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: 40 }}
      >
        <MenuItem
          onClick={() =>
            handleClose(SharedLinksTableActionsEnum.DeactivateLink.val)
          }
        >
          {t('shareparts_deactivate_link')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClose(SharedLinksTableActionsEnum.ReactivateLink.val)
          }
        >
          {t('shareparts_reactivate_link')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleClose(SharedLinksTableActionsEnum.DeleteLink.val)
          }
        >
          {t('shareparts_delete_link')}
        </MenuItem>
      </Menu>
    </>
  );
};
export default SharedTableActionMenu;
