import React from 'react';
import {Modal} from '@mui/material';
import {ConfigLayout} from './ConfigLayout/ConfigLayout';
import './ConfigsModal.scss';

const ConfigsModal = ({
  part,
  open,
  setOpen,
  confirm,
  editConfigId,
  history,
}) => {
  const closeModal = () => {
    history.push({search: null});
    setOpen(false);
    confirm(true);
  };

  return (
    <Modal open={open} onClose={closeModal} disableEnforceFocus={true}>
      <div className="config-modal">
        <ConfigLayout
          part={part}
          closeModal={closeModal}
          editConfigId={editConfigId}
        />
      </div>
    </Modal>
  );
};

export default ConfigsModal;
