import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

// core components
import Grid from '@mui/material/Grid';

import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js';

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <Grid container>
        <Grid item md={12}>
          <h2 className={classes.title}>{'404'}</h2>
          <h3 className={classes.subTitle}>{'Page not found.'}</h3>
          <h4 className={classes.description}>
            {"The page you are looking for doesn't exist or has been moved."}
          </h4>
        </Grid>
      </Grid>
    </div>
  );
}
