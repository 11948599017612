const FormType = {
  General: 0,
  MainProcess: 1,
  PostProcess: 2,
  ProductRequirements: 3,
};

/**
 * @param {*} stepId
 * @param {"partconfigurator.json"} t
 */
export const getInfoMsgByStepId = (stepId, t) => {
  switch (stepId) {
    case FormType.General:
      return t('pc_mdl_info_general');
    case FormType.MainProcess:
      return t('pc_mdl_info_main_process');
    case FormType.PostProcess:
      return t('pc_mdl_info_post_process');
    case FormType.ProductRequirements:
      return t('pc_mdl_info_product_req_process');
    case FormType.Comment:
      return t('pc_mdl_info_comment');
    case FormType.Summary:
      return t('pc_mdl_info_summary_process');
    default:
      return '';
  }
};
