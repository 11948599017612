import React, { useReducer, useEffect } from 'react';
import { ConfigSidebar, ConfigContent } from '../ConfigComponents';
import { ConfigReducer, initialState } from '../ConfigReducer';
import {
  CREATE_STEP,
  RESET_STEPS,
  UPDATE_TEMP_DATA,
  CREATE_SUBSTEP,
  CHANGE_STEP,
} from '../ConfigTypes';
import { ConfigContext } from '../ConfigContext';
import { StepType } from '../Models/StepTypeEnum';

import './ConfigLayout.scss';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const ConfigLayout = ({ part, closeModal, editConfigId }) => {
  const [state, dispatch] = useReducer(ConfigReducer, initialState);
  const partProcesses = useSelector(state => state.general.processes);
  const { t } = useTranslation(['processes', 'partconfigurator']);

  useEffect(() => {
    async function getConfig(configId) {
      return Axios.get(
        `${process.env.REACT_APP_PARTS_API}/part-configs/${configId}`,
        {
          params: {
            filter: {
              include: [
                'batchSizes',
                {
                  relation: 'processes',
                  scope: {
                    include: [
                      'process',
                      'processFeatureValues',
                      {
                        relation: 'manufacturingAnalysis',
                        scope: {
                          include: 'batchSize',
                        },
                      },
                    ],
                  },
                },
                {
                  part: {
                    attachments: [
                      'attachmentType',
                      { currentFile: 'fileFormat' },
                    ],
                  },
                },
                {
                  attachments: [
                    'attachmentType',
                    { currentFile: 'fileFormat' },
                  ],
                },
              ],
            },
          },
        }
      );
    }
    async function createConfig(data) {
      let response;

      if (!editConfigId.current) {
        response = await Axios.post(
          `${process.env.REACT_APP_PARTS_API}/part-configs`,
          {
            name: data.name,
            partId: data.partId,
          }
        );
        response = await getConfig(response.data.id);
      } else {
        response = await getConfig(editConfigId.current);
      }

      if (response.status && response.status >= 200 && response.status < 300) {
        const partAttachments = response.data.part && response.data.part.attachments || [];
        
        const configAttachmentsNotBlanked = response.data.attachments?.filter(a => a.attachmentType.key !== 'blanked_technical_drawing');
        const configAttachmentsBlanked  = response.data.attachments?.filter(a => a.attachmentType.key === 'blanked_technical_drawing');
        const blankedAttachment = configAttachmentsBlanked.pop();
        const configAttachments = blankedAttachment ? [...configAttachmentsNotBlanked, blankedAttachment] : configAttachmentsNotBlanked;

        dispatch({
          type: UPDATE_TEMP_DATA,
          payload: {
            configId: response.data.id,
            name: response.data.name,
            materialName: response.data.materialName,
            materialIsUrl: response.data.materialIsUrl,
            materialProvided: response.data.materialProvided,
            mirrored: response.data.mirrored,
            processes: response.data.processes,
            batchSizes: response.data.batchSizes,
            attachments: [...partAttachments, ...configAttachments],
            comment: response.data.comment,
          },
        });

        // COMMENT
        dispatch({
          type: CREATE_SUBSTEP,
          payload: {
            id: 0,
            stepId: StepType.GENERAL.val,
            name: t('partconfigurator:general'),
            type: StepType.GENERAL.val,
          },
        });

        dispatch({
          type: CHANGE_STEP,
          payload: {
            currentStep: 0,
            currentSubstep: 0,
          },
        });

        if (response.data.processes) {
          let mainProcesses = [];
          let postProcesses = [];
          let productRequirements = [];

          response.data.processes.forEach(item => {
            switch (item.process.class) {
              case 'process':
                mainProcesses.push(item);
                break;
              case 'post_process':
                postProcesses.push(item);
                break;
              case 'production_requirement':
                productRequirements.push(item);
                break;
              default:
                break;
            }
          });

          dispatch({
            type: UPDATE_TEMP_DATA,
            payload: {
              mainProcesses: mainProcesses.map(item => {
                return {
                  processSelection: partProcesses.find(
                    element => element.id === item.processId
                  ),
                  process: item,
                  formData: {},
                };
              }),
              postProcesses: postProcesses.map(item => {
                return {
                  processSelection: partProcesses.find(
                    element => element.id === item.processId
                  ),
                  process: item,
                  formData: {},
                };
              }),
              productRequirements: productRequirements.map(item => {
                return {
                  processSelection: partProcesses.find(
                    element => element.id === item.processId
                  ),
                  process: item,
                  formData: {},
                };
              }),
            },
          });

          mainProcesses.forEach((item, index) => {
            dispatch({
              type: CREATE_SUBSTEP,
              payload: {
                id: index,
                stepId: StepType.MAIN_PROCESS.val,
                name: t(
                  `processes:${
                    partProcesses.find(element => element.id === item.processId)
                      .key
                  }`
                ),
                type: StepType.MAIN_PROCESS.val,
              },
            });
          });

          // POST PROCESS
          postProcesses.forEach((item, index) => {
            dispatch({
              type: CREATE_SUBSTEP,
              payload: {
                id: index,
                stepId: StepType.POST_PROCESS.val,
                name: t(
                  `processes:${
                    partProcesses.find(element => element.id === item.processId)
                      .key
                  }`
                ),
                type: StepType.POST_PROCESS.val,
              },
            });
          });

          // PRODUCT REQUIREMENTS
          productRequirements.forEach((item, index) => {
            dispatch({
              type: CREATE_SUBSTEP,
              payload: {
                id: index,
                stepId: StepType.PRODUCT_REQUIREMENTS.val,
                name: t(
                  `processes:${
                    partProcesses.find(element => element.id === item.processId)
                      .key
                  }`
                ),
                type: StepType.PRODUCT_REQUIREMENTS.val,
              },
            });
          });
        }

        return response.data;
      } else {
        return { error: `Error: ${response.status}`, code: response.status };
      }
    }

    createConfig({
      partId: part.id,
    })
      .then(res => {
        dispatch({
          type: UPDATE_TEMP_DATA,
          payload: {
            name: res.name,
            configId: res.id,
          },
        });
      })
      .catch(error => {
        // TODO: Handle create config error
        console.error(`create config error: ${error}`);
      });

    dispatch({
      type: CREATE_STEP,
      payload: {
        id: StepType.GENERAL.val,
        key: 'general',
        empty_key: 'general',
        limit: 0,
        type: StepType.GENERAL.val,
      },
    });

    const mainNum = StepType.MAIN_PROCESS.val;
    dispatch({
      type: CREATE_STEP,
      payload: {
        id: mainNum,
        key: 'main_process',
        add_key: 'add_main_process',
        empty_key: 'empty_main_process',
        limit: 5,
        type: mainNum,
      },
    });
    dispatch({
      type: CREATE_STEP,
      payload: {
        id: StepType.POST_PROCESS.val,
        key: 'post_processes',
        add_key: 'add_post_process',
        empty_key: 'empty_post_process',
        limit: 10,
        type: StepType.POST_PROCESS.val,
      },
    });
    dispatch({
      type: CREATE_STEP,
      payload: {
        id: StepType.PRODUCT_REQUIREMENTS.val,
        key: 'product_requirements',
        add_key: 'add_product_requirements',
        empty_key: 'empty_product_requirements',
        limit: 10,
        type: StepType.PRODUCT_REQUIREMENTS.val,
      },
    });
    dispatch({
      type: UPDATE_TEMP_DATA,
      payload: {
        partId: part.id,
      },
    });

    return () => {
      dispatch({ type: RESET_STEPS });
    };
  }, []);

  return (
    <ConfigContext.Provider value={{ state, dispatch }}>
      <div className="layout">
        <ConfigSidebar {...{ ...state, part }} />
        <ConfigContent
          {...{
            steps: state.steps,
            currentStep: state.currentStep,
            currentSubstep: state.currentSubstep,
            closeModal,
          }}
        />
      </div>
    </ConfigContext.Provider>
  );
};
