import React, {Component} from 'react';
import {Alert, Grid, IconButton} from '@mui/material';
import {InsertDriveFile, RemoveCircleOutline} from '@mui/icons-material';

class DraggedSourceAttachment extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    const {attachment} = this.props;

    return (
      <Alert
        severity={'info'}
        variant={'filled'}
        icon={false}
      >
        <Grid container columnSpacing={1} justifyContent={'center'} alignItems={'center'}>
          <Grid item><IconButton size={'small'} style={{color: 'white'}} disabled><InsertDriveFile style={{color: 'white'}} /></IconButton></Grid>
          <Grid item>{attachment.name}.{attachment.currentFile.fileFormat.extension}</Grid>
          <Grid item><IconButton size={'small'} style={{color: 'white'}} disabled><RemoveCircleOutline /></IconButton></Grid>
        </Grid>
      </Alert>
    );
  }
}

export default DraggedSourceAttachment;