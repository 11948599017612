import React, { useEffect, useState, useContext } from 'react';
import {
  ProcessForm,
  GeneralForm,
} from '../../ConfigForms';

import { ConfigContext } from 'components/Modals/ConfigsModal/ConfigContext';
import { StepType } from 'components/Modals/ConfigsModal/Models/StepTypeEnum';

const FormType = {
  General: 0,
  MainProcess: 1,
  PostProcess: 2,
  ProductRequirements: 3,
};

export const ConfigForm = ({ stepId, stepData }) => {
  const [form, setForm] = useState(null);

  const { state } = useContext(ConfigContext);

  useEffect(() => {
    switch (stepId) {
      case FormType.General:
        setForm(<GeneralForm />);
        break;
      case FormType.MainProcess:
        setForm(
          <ProcessForm
            processType={StepType.MAIN_PROCESS.name}
            processes={state.tempData[StepType.MAIN_PROCESS.name]}
          />
        );
        break;
      case FormType.PostProcess:
        setForm(
          <ProcessForm
            processType={StepType.POST_PROCESS.name}
            processes={state.tempData[StepType.POST_PROCESS.name]}
          />
        );
        break;
      case FormType.ProductRequirements:
        setForm(
          <ProcessForm
            processType={StepType.PRODUCT_REQUIREMENTS.name}
            processes={state.tempData[StepType.PRODUCT_REQUIREMENTS.name]}
          />
        );
        break;
      default:
        setForm(<div>Error</div>);
        break;
    }
  }, [stepId]);

  return !!stepData && <div>{form}</div>;
};
