import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Grid,
  IconButton,
  Modal,
  Paper,
  Fade,
  Typography,
  Radio,
  FormControlLabel,
  Card,
  CardContent,
  Chip,
  Checkbox,
  Collapse,
  Button, CircularProgress,
} from '@mui/material';
import {
  Close,
  ContentCopy,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import {withSnackbar} from 'notistack';

import './CloneConfigModal.scss';
import PartConfigDetailsTable from '../../PartListDetails/PartConfigDetailsTable';
import {constructPartThumbnailUrl} from '../../../services/parts';
import noPreview from '../../../assets/img/no-preview.jpg';
import {cloneToAllPartConfigsInList, cloneToSomePartConfigsInList} from '../../../services/part-lists';

class ConfigRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfigDetails: false,
      thumbnailId: uuidv4(),
    };
  }

  toggleShowConfigDetails = () => {
    this.setState({
      showConfigDetails: !this.state.showConfigDetails,
    });
  };

  setThumbnailToNoPreviewAvailable = () => {
    document.getElementById(this.state.thumbnailId).src = noPreview;
  };

  render() {
    const {disableSelect, partConfig, t, selected, onCheckboxChange} = this.props;

    const mainProcesses = partConfig?.partConfigProcesses.filter(p => p.process.class === 'process').map(p => p.process.key) || [];
    const batchSizes = partConfig?.batchSizes.map(bs => bs.batchSize) || [];

    return <Card elevation={2}>
      <CardContent style={{padding: 16}}>
        <Grid
          container
          rowSpacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item xs={2} container rowSpacing={1} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={2}>
              <IconButton size={'small'} onClick={this.toggleShowConfigDetails}>
                {this.state.showConfigDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <img
                id={this.state.thumbnailId}
                src={constructPartThumbnailUrl(partConfig.partId)}
                alt="Part Config Thumbnail"
                style={{
                  height: 120,
                  maxWidth: '100%',
                  objectFit: 'contain',
                  padding: 8,
                }}
                onError={this.setThumbnailToNoPreviewAvailable}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} container justifyContent={'space-between'} rowSpacing={1}>
            <Grid item xs={12} container alignItems={'center'} spacing={1}>
              <Grid item sx={{maxWidth: 1 / 3}}>
                <div
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  {partConfig.part.name}
                </div>
              </Grid>
              <Grid item sx={{maxWidth: 1 / 3}}>
                <div
                  style={{
                    border: '1px solid #656D79',
                    borderRadius: 16,
                    fontWeight: 550,
                    fontSize: '0.8125rem',
                    padding: '2px 8px',
                    color: '#656D79',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  <span style={{textOverflow: 'ellipses'}}>
                    {t('partconfigurations:article_number')}:&nbsp;{partConfig.part.articleNr || '-'}
                  </span>
                </div>
              </Grid>
              <Grid item sx={{maxWidth: 1 / 3}}>
                <div
                  style={{
                    border: '1px solid #656D79',
                    borderRadius: 16,
                    fontWeight: 550,
                    fontSize: '0.8125rem',
                    padding: '2px 8px',
                    color: '#656D79',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                  }}
                >
                  <span style={{whiteSpace: 'nowrap'}}>
                    {t('partconfigurations:drawing_number')}:&nbsp;{partConfig.part.drawingNr || '-'}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item xs={4} style={{fontStyle: 'italic', fontSize: '1rem'}}>
                {t('partconfigurations:t_config')}: {partConfig.name?.trim().length ? partConfig.name.trim() : partConfig.id}
              </Grid>
              <Grid item xs={8} alignItems={'center'} justifyContent={'flex-end'} container columnSpacing={2}>
                {!disableSelect && (
                  <Checkbox
                    checked={selected}
                    onChange={({target: {checked}}) => onCheckboxChange(checked)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems={'center'}>
              <Grid item>
                <Chip
                  variant={'filled'}
                  color={'primary'}
                  label={`${t('partconfigurations:t_material_name')}: ${partConfig.materialName || '-'}`}
                  style={{color: 'white', fontWeight: 550}}
                />
              </Grid>
              <Grid item>
                <Chip
                  variant={'filled'}
                  color={'primary'}
                  label={`${t('partconfigurations:t_batch_sizes')}: ${batchSizes.length ? batchSizes.join(', ') : '-'}`}
                  style={{color: 'white', fontWeight: 550}}
                />
              </Grid>
              <Grid item>
                <Chip
                  variant={'filled'}
                  color={'primary'}
                  label={`${t('partconfigurations:t_main_processes')}: ${mainProcesses.length
                    ? mainProcesses.map(p => t(`processes:${p}`)).join(', ')
                    : '-'}`}
                  style={{color: 'white', fontWeight: 550}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{padding: 0}}>
            <Collapse in={this.state.showConfigDetails}>
              <div style={{paddingTop: 16}}>
                <PartConfigDetailsTable partConfig={partConfig} hideManufacturingAnalysis={true} />
              </div>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>;
  }
}

class CloneConfigModal extends Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      loading: false,
      cloneToAll: true,
      selectedConfigIds: [],
    };

    this.state = {
      ...this.defaultState,
    };
  }

  handleConfigCheckboxChange = (configId, checked) => {
    this.setState({
      selectedConfigIds: checked ?
        [...this.state.selectedConfigIds, configId] :
        this.state.selectedConfigIds.filter(id => id !== configId),
    });
  };

  closeModal = (refreshPartList = false) => {
    this.props.onClose(refreshPartList);
    this.setState(this.defaultState);
  };

  cloneConfig = async () => {
    const {partListId, partConfigToClone, enqueueSnackbar, t} = this.props;

    this.setState({loading: true});

    try {
      if (this.state.cloneToAll) {
        await cloneToAllPartConfigsInList({
          id: partListId,
          partConfigId: partConfigToClone.id,
        });
      } else {
        await cloneToSomePartConfigsInList({
          id: partListId,
          partConfigId: partConfigToClone.id,
          destinationConfigIds: this.state.selectedConfigIds,
        });
      }

      this.closeModal(true);
    } catch (e) {
      console.error(e);
      this.setState({loading: false});
      enqueueSnackbar(t('mdl_general_error_title'), {
        variant: 'error',
      });
    }
  };

  render() {
    const {open, t, partConfigToClone: partConfig, allPartConfigs} = this.props;

    return (
      <Modal open={open} closeAfterTransition>
        <Fade in={open}>
          <div className={'clone-config-modal'}>
            <Paper className={'clone-config-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-clone-config-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                rowSpacing={2}
                justifyContent={'center'}
                alignContent={'space-between'}
                className={'clone-config-modal-content'}
              >
                <Grid item xs={12} container rowSpacing={2} justifyContent={'center'} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <Typography variant={'h5'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <ContentCopy style={{marginRight: 5}} /> {t('mdl_clone_config_title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}>
                      {t('mdl_clone_config_description')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ConfigRow partConfig={partConfig} t={t} disableSelect={true} />
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Radio />}
                        label={t('mdl_clone_to_all')}
                        checked={this.state.cloneToAll}
                        onClick={() => this.setState({cloneToAll: true})}
                        sx={{color: 'black'}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Radio />} label={t('mdl_clone_to_some')}
                        checked={!this.state.cloneToAll}
                        onClick={() => this.setState({cloneToAll: false})}
                        sx={{color: 'black'}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={!this.state.cloneToAll}>
                        <div style={{paddingTop: 16}}>
                          <Grid container rowSpacing={2}>
                            {allPartConfigs?.filter(pc => pc.id !== partConfig.id).map(pc => (
                              <Grid item xs={12} key={pc.id}>
                                <ConfigRow
                                  partConfig={pc}
                                  t={t}
                                  selected={this.state.selectedConfigIds.includes(pc.id)}
                                  onCheckboxChange={checked => this.handleConfigCheckboxChange(pc.id, checked)}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} paddingBottom={2} sx={{textAlign: 'center'}}>
                  <Button
                    variant={'contained'}
                    onClick={this.cloneConfig}
                    disabled={(!this.state.cloneToAll && !this.state.selectedConfigIds.length) || this.state.loading}
                  >
                    {this.state.cloneToAll ? t('mdl_clone_to_all_button') : t('mdl_clone_to_some_button')}
                    {this.state.loading && (
                      <CircularProgress
                        style={{marginLeft: 5, color: '#fff'}}
                        size={20}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withSnackbar(withTranslation('modals')(CloneConfigModal));
