import {
  GET_PART_LIST,
  GET_PART_LIST_SUCCESS,
  GET_PART_LIST_ERROR,
  GET_LIST_PARTS_SUCCESS,
  GET_LIST_PARTS_ERROR,
  GET_LIST_PARTS,
  PART_DETAILS_PARTS_CLEANUP,
  PART_DETAILS_CLEANUP,
  PART_LIST_NAME_UPDATE,
  PART_NAME_UPDATE,
  UNASSIGN_PART_ATTACHMENT,
  UNASSIGN_PART_ATTACHMENT_SUCCESS,
  UNASSIGN_PART_ATTACHMENT_ERROR,
  UNASSIGN_PART_ATTACHMENT_CLEAN,
  SELECTED_PART,
  UNSELECT_PART,
  UNASSIGN_PART_FROM_LIST,
  UNASSIGN_PART_SUCCESS,
  UNASSIGN_PART_ERROR,
  UNASSIGN_PART_CLEANUP,
  SELECT_CONFIG,
  REMOVE_CONFIG,
  CLONE_CONFIG_TO_ALL_LIST_LOADING,
  CLONE_CONFIG_TO_ALL_LIST_SUCCESS,
  CLONE_CONFIG_TO_ALL_LIST_ERROR,
  CLEAR_SELECTED_CONFIGS,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_LOADING,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_SUCCESS,
  CLONE_CONFIG_TO_SELECTED_CONFIGS_ERROR,
  ADD_EMPTY_CONFIG_TO_PART,
  ADD_EMPTY_CONFIG_TO_PART_ERROR,
  ADD_EMPTY_CONFIG_TO_PART_SUCCESS,
} from './PartListDetailsTypes';

const INITIAL_STATE = {
  data: {},
  selectedConfigs: [],
  partsForList: { page: [] },
  loading: true,
  error: null,
  unassignAttachmentLoading: false,
  unassignAttachmentError: false,
  unassignAttachmentSuccess: false,

  /**
   * Use in lists/tables when you need to store selected value
   * in order to perform some action after some time.
   */
  selectedPart: null,
  unassignPartLoading: false,
  unassignPartSuccess: false,
  unassignPartError: false,
  cloneConfigToAllPartsLoading: false,
  cloneConfigToAllPartsSuccess: false,
  cloneConfigToAllPartsError: false,
  cloneToSomeConfigsLoading: false,
  cloneToSomeConfigsSuccess: false,
  cloneToSomeConfigsError: false,
  addEmptyConfigLoading: false,
  addEmptyConfigSuccess: false,
  addEmptyConfigError: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PART_LIST:
      return { ...state, loading: true };

    case GET_PART_LIST_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case GET_PART_LIST_ERROR:
      return { ...state, error: action.payload, loading: false };

    case GET_LIST_PARTS:
      return { ...state, loading: true };

    case GET_LIST_PARTS_SUCCESS:
      const oldParts = state.partsForList.page ? state.partsForList.page : [];
      const newParts = action.payload.page;
      return {
        ...state,
        partsForList: {
          page: oldParts.concat(newParts),
          pagination: action.payload.pagination,
        },
        loading: false,
        error: null,
      };

    case GET_LIST_PARTS_ERROR:
      return { ...state, error: action.payload, loading: false };

    case PART_DETAILS_CLEANUP:
      return {
        data: {},
        partsForList: { page: [] },
        loading: true,
        error: null,
      };

    case PART_DETAILS_PARTS_CLEANUP:
      return {
        ...state,
        partsForList: { page: [] },
      };

    case PART_LIST_NAME_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          name: action.listName,
        },
      };

    case PART_NAME_UPDATE:
      const updatedParts = state.partsForList.page.map(item => {
        if (item.id === action.payload.id) {
          return { ...action.payload };
        } else {
          return item;
        }
      });

      return {
        ...state,
        partsForList: { ...state.partsForList, page: [...updatedParts] },
      };

    // ADD EMPTY CONFIG
    case ADD_EMPTY_CONFIG_TO_PART:
      return {
        ...state,
        addEmptyConfigLoading: true,
        addEmptyConfigSuccess: false,
        addEmptyConfigError: false,
      };

    case ADD_EMPTY_CONFIG_TO_PART_SUCCESS:
      return {
        ...state,
        addEmptyConfigLoading: false,
        addEmptyConfigSuccess: true,
        addEmptyConfigError: false,
      };

    case ADD_EMPTY_CONFIG_TO_PART_ERROR:
      return {
        ...state,
        addEmptyConfigLoading: false,
        addEmptyConfigSuccess: false,
        addEmptyConfigError: true,
      };

    // UNASSIGN ATTACHMENTS
    case UNASSIGN_PART_ATTACHMENT:
      return {
        ...state,
        unassignAttachmentLoading: true,
      };
    case UNASSIGN_PART_ATTACHMENT_SUCCESS:
      return {
        ...state,
        unassignAttachmentLoading: false,
        unassignAttachmentSuccess: true,
      };
    case UNASSIGN_PART_ATTACHMENT_ERROR:
      return {
        ...state,
        unassignAttachmentLoading: false,
        unassignAttachmentSuccess: false,
        unassignAttachmentError: true,
      };
    case UNASSIGN_PART_ATTACHMENT_CLEAN:
      return {
        ...state,
        selectedAttachment: null,
        unassignAttachmentLoading: false,
        unassignAttachmentSuccess: false,
        unassignAttachmentError: false,
      };

    // UNASSIGN PART
    case UNASSIGN_PART_FROM_LIST:
      return {
        ...state,
        unassignPartLoading: true,
        unassignPartSuccess: false,
        unassignPartError: false,
      };
    case UNASSIGN_PART_SUCCESS:
      const newPartsList = state.partsForList.page.filter(part => {
        return part.id !== action.payload.partId;
      });

      return {
        ...state,
        partsForList: {
          page: newPartsList,
          pagination: { ...state.partsForList.pagination },
        },
        unassignPartLoading: false,
        unassignPartSuccess: true,
      };
    case UNASSIGN_PART_ERROR:
      return {
        ...state,
        unassignPartLoading: false,
        unassignPartError: true,
      };
    case UNASSIGN_PART_CLEANUP:
      return {
        ...state,
        unassignPartLoading: false,
        unassignPartError: false,
        unassignPartSuccess: false,
        selectedPart: null,
      };
    case SELECTED_PART:
      return {
        ...state,
        selectedPart: action.payload,
      };
    case UNSELECT_PART:
      return {
        ...state,
        selectedPart: null,
      };

    /**
     * CLONING CONFIGS
     */

    case CLONE_CONFIG_TO_ALL_LIST_LOADING:
      return {
        ...state,
        cloneConfigToAllPartsLoading: true,
        cloneConfigToAllPartsSuccess: false,
        cloneConfigToAllPartsError: false,
      };
    case CLONE_CONFIG_TO_ALL_LIST_SUCCESS:
      return {
        ...state,
        cloneConfigToAllPartsLoading: false,
        cloneConfigToAllPartsSuccess: true,
        cloneConfigToAllPartsError: false,
      };
    case CLONE_CONFIG_TO_ALL_LIST_ERROR:
      return {
        ...state,
        cloneConfigToAllPartsLoading: false,
        cloneConfigToAllPartsSuccess: false,
        cloneConfigToAllPartsError: true,
      };

    case SELECT_CONFIG:
      if (typeof state.selectedConfigs === 'undefined') {
        return { ...state };
      }
      const isExist = state.selectedConfigs.findIndex(
        configId => configId === action.payload
      );
      if (isExist !== -1) {
        return { ...state };
      }
      return {
        ...state,
        selectedConfigs: [...state.selectedConfigs, action.payload],
      };
    case REMOVE_CONFIG:
      return {
        ...state,
        selectedConfigs: state.selectedConfigs.filter(
          configId => configId !== action.payload
        ),
      };

    case CLEAR_SELECTED_CONFIGS: {
      return {
        ...state,
        selectedConfigs: [],
      };
    }

    /**
     * CLONE TO SOME CONFIGS
     */
    case CLONE_CONFIG_TO_SELECTED_CONFIGS_LOADING:
      return {
        ...state,
        cloneToSomeConfigsLoading: true,
        cloneToSomeConfigsSuccess: false,
        cloneToSomeConfigsError: false,
      };
    case CLONE_CONFIG_TO_SELECTED_CONFIGS_SUCCESS:
      return {
        ...state,
        cloneToSomeConfigsLoading: false,
        cloneToSomeConfigsSuccess: true,
        cloneToSomeConfigsError: false,
      };
    case CLONE_CONFIG_TO_SELECTED_CONFIGS_ERROR:
      return {
        ...state,
        cloneToSomeConfigsLoading: false,
        cloneToSomeConfigsSuccess: false,
        cloneToSomeConfigsError: true,
      };

    default:
      return state;
  }
};
