import {
  CREATE_LIST,
  CREATE_LIST_ERROR,
  CREATE_LIST_SUCCESS,
  UPDATE_LIST,
  UPDATE_LIST_ERROR,
  UPDATE_LIST_SUCCESS,
  GET_PARTS,
  GET_PARTS_SUCCESS,
  GET_PARTS_ERROR,
  PART_STORAGE_CLEANUP,
  SEARCH_PARTS,
  SEARCH_PARTS_SUCCESS,
  SEARCH_PARTS_ERROR,
  SAVE_CONFIGS,
  SAVE_CONFIGS_SUCCESS,
  SAVE_CONFIGS_ERROR,
  ADD_CONFIG,
  REMOVE_CONFIG,
} from './NewPartListTypes';

const INITIAL_STATE = {
  data: {},
  list: null,
  selectedConfigs: [],
  loading: true,
  error: null,
  redirect: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // --------------------------------------

    case CREATE_LIST:
      return { ...state, loading: true };

    case CREATE_LIST_SUCCESS:
      return {
        ...state,
        list: { ...action.payload },
        loading: false,
      };

    case CREATE_LIST_ERROR:
      return { ...state, error: action.payload, loading: false };

    // --------------------------------------

    case UPDATE_LIST:
      return { ...state, loading: false };

    case UPDATE_LIST_SUCCESS:
      return {
        ...state,
        list: { ...action.payload },
        loading: false,
      };

    case UPDATE_LIST_ERROR:
      return { ...state, error: action.payload, loading: false };

    // --------------------------------------

    case GET_PARTS:
      return { ...state, loading: true };

    case GET_PARTS_SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
        loading: false,
      };

    case GET_PARTS_ERROR:
      return { ...state, error: action.payload, loading: false };

    // --------------------------------------

    case SEARCH_PARTS:
      return { ...state, data: {}, loading: true };

    case SEARCH_PARTS_SUCCESS:
      return {
        ...state,
        data: { ...action.payload },
        error: null,
        loading: false,
      };

    case SEARCH_PARTS_ERROR:
      return { ...state, error: action.payload, loading: false };

    // --------------------------------------

    case SAVE_CONFIGS:
      return { ...state, data: {}, loading: true };

    case SAVE_CONFIGS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        redirect: true,
        list: action.payload,
      };

    case SAVE_CONFIGS_ERROR:
      return { ...state, error: action.payload, loading: false };

    // --------------------------------------

    case ADD_CONFIG:
      return {
        ...state,
        selectedConfigs: [...state.selectedConfigs, action.payload],
      };

    case REMOVE_CONFIG:
      return {
        ...state,
        selectedConfigs: state.selectedConfigs.filter(
          item => item !== action.payload
        ),
      };

    case PART_STORAGE_CLEANUP:
      return {
        data: {},
        loading: true,
        selectedConfigs: [],
        error: null,
      };

    default:
      return state;
  }
};
