const snackbarReducer = (state = {}, action) => {
  switch (action.type) {
    case "SNACKBAR":
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarSeverity: action.severity,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: null,
      };
    default:
      return state;
  }
};

export default snackbarReducer;