export const GET_PART = 'get-part-pd';
export const GET_PART_ERROR = 'get-part-error-pd';
export const GET_PART_SUCCESS = 'get-part-success-pd';
export const GET_FEATURES = 'get-part-features';
export const GET_FEATURES_SUCCESS = 'get-part-features-success';
export const GET_FEATURES_ERROR = 'get-part-features-error';
export const GET_LISTS_FOR_PART = 'get-lists-for-part';
export const GET_LISTS_FOR_PART_SUCCESS = 'get-lists-for-part-success';
export const GET_LISTS_FOR_PART_ERROR = 'get-lists-for-part-error';
export const GET_PART_ATTACHMENTS = 'get-part-attachments';
export const GET_PART_ATTACHMENTS_SUCCESS = 'get-part-attachments-success';
export const GET_PART_ATTACHMENTS_ERROR = 'get-part-attachments-error';
export const PART_DETAILS_CLEANUP = 'part-details-cleanup';
export const EDIT_PART_NAME = 'edit-part-name';
export const EDIT_PART_NAME_SUCCESS = 'edit-part-name-success';
export const EDIT_PART_NAME_ERROR = 'edit-part-name-error';
export const UNASSIGN_PART_ATTACHMENT = 'unassigned-part-attachment';
export const UNASSIGN_PART_ATTACHMENT_SUCCESS =
  'unassigned-part-attachment-success';
export const UNASSIGN_PART_ATTACHMENT_ERROR =
  'unassigned-part-attachment-error';
export const UNASSIGN_PART_ATTACHMENT_CLEAN =
  'unassigned-part-attachment-clean';
