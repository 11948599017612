import React, {useEffect} from 'react';
import PartDetailsComponent from '../../components/PartDetails/PartDetails';
import Loading from '../../components/Loading/Loading';
import {
  doCleanup,
  getListsForPart,
  getPart,
  getPartAttachments,
} from './PartDetailsActions';
import {getFirstPageLists} from '../../store/general/generalActions';
import {useDispatch, useSelector} from 'react-redux';
import ErrorPage from 'views/Pages/ErrorPage';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {assignPartsToList} from 'services/part-lists';
import {updateBreadcrumbs} from 'store/breadcrumbs/breadcrumbsActions';
import {useParams} from 'react-router-dom';

export const PartDetails = () => {
  const {id: partId} = useParams();
  const part = useSelector(state => state.part);
  const general = useSelector(state => state.general);
  const dispatch = useDispatch();
  const listOptions = {limit: 4, order: 'createdAt DESC'};
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation(['modals', 'errors']);

  const isDataLoading = () => {
    return part.loading || part.listsForPartLoading || part.attachmentsLoading;
  };

  const getListPage = event => {
    let paginationObject = general.lists.pagination;

    if (
      event.target.value === '<<' &&
      paginationObject.currentPage > 0
    ) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: paginationObject.currentPage - 1},
        }),
      );
    } else if (
      event.target.value === '>>' &&
      paginationObject.currentPage < paginationObject.totalPageCount
    ) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: paginationObject.currentPage + 1},
        }),
      );
    } else if (
      event.target.value === '>>' &&
      paginationObject.currentPage === paginationObject.totalPageCount
    ) {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: paginationObject.currentPage},
        }),
      );
    } else {
      dispatch(
        getFirstPageLists({
          filter: {...listOptions, page: event.target.value},
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getListsForPart(partId));
    dispatch(getPart(partId, {filter: {include: ['features', {partConfigs: {batchSizes: 'manufacturingAnalyses'}}]}}));
    dispatch(getFirstPageLists({filter: {...listOptions}}));
    dispatch(getPartAttachments(partId));
    return () => {
      dispatch(doCleanup());
    };
  }, [partId]);

  useEffect(() => {
    if (part.data && part.data.name) {
      dispatch(
        updateBreadcrumbs({
          level: 2,
          path: window.location.pathname,
          text: part.data.name,
        }),
      );
    }
  }, [part.data]);

  const handleListModalDataRefresh = () => {
    dispatch(getFirstPageLists({filter: {...listOptions}}));
  };

  /**
   * @description Evaluate result from saving the parts in the lists and
   * show appropriate message to the user
   * @param {*} notSavedList
   * @param selectedLists
   */
  const selectedListConfirmMessage = (notSavedList, selectedLists) => {
    // All lists are not saved
    if (notSavedList.length === selectedLists.length) {
      enqueueSnackbar(t('errors:error_lists_not_saved_all'), {
        variant: 'error',
      });
    } else {
      // Part of the list are not saved
      if (
        notSavedList.length > 0 &&
        notSavedList.length < selectedLists.length
      ) {
        notSavedList.forEach(listName => {
          enqueueSnackbar(t('errors:error_list_not_saved') + ' ' + listName, {
            variant: 'error',
          });
        });
        enqueueSnackbar(t('mdl_parts_assigned_to_lists_partial_success'), {
          variant: 'success',
        });
      } else if (notSavedList.length === 0) {
        // All lists are saved successfully
        enqueueSnackbar(t('mdl_part_assigned_to_lists_success'), {
          variant: 'success',
        });
      }
    }
  };

  let assignPartsToListRequestCount = 0;
  const handleSelectedListsConfirm = async ({
    selectedLists,
    endConfirmLoading,
  }) => {
    // prevent aby action id parts are
    const selectedItems = [{id: parseInt(partId), name: ''}];
    let hasApiError = false;
    let notSavedList = []; // names of the lists that have API error
    const partIds = selectedItems.map(item => {
      return item.id;
    });

    for (const list of selectedLists) {
      try {
        const result = await assignPartsToList({listId: list.id, partIds});
        assignPartsToListRequestCount += 1;
        if (result.isAxiosError) {
          notSavedList.push(list.name);
        }
      } catch (error) {
        assignPartsToListRequestCount += 1;
        notSavedList.push(list.name);
        hasApiError = true;
      }
      // fire the messages and other events after all
      // of the lists are finished with async requests
      if (
        assignPartsToListRequestCount === selectedLists.length ||
        hasApiError
      ) {
        assignPartsToListRequestCount = 0; // rest the counter
        selectedListConfirmMessage(notSavedList, selectedLists);
        // Close modal loading
        endConfirmLoading();
      }
    }
  };

  if (isDataLoading()) {
    return <Loading />;
  } else if (part.data && part.attachments) {
    return (
      <>
        <PartDetailsComponent
          part={part.data}
          attachments={part.attachments.page}
          general={general}
          listsForPart={part.listsForPart}
          getListPage={getListPage}
          onRefreshModalList={handleListModalDataRefresh}
          onSelectedListsConfirm={handleSelectedListsConfirm}
        />
      </>
    );
  } else {
    return <ErrorPage />;
  }
};
