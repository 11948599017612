import React from 'react';
import TextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import './SharedLinkPasswordInput.scss';
import IconButton from '@mui/material/IconButton';
import { copyToClipboard } from 'utils/InputHelpers';

export default function SharedLinkPasswordInp({ id, password, onResetClick }) {
  const { t } = useTranslation('sharedparts');
  const handleCopyBtnClick = () => {
    copyToClipboard('pass-inp-' + id);
  };

  const handleResetBtnClick = () => {
    if (typeof onResetClick === 'function') {
      onResetClick(id);
    }
  };

  return (
    <div>
      <TextField
        className={'url-link-inp'}
        id={'pass-inp-' + id}
        InputProps={{
          readOnly: true,
        }}
        label={t('shareparts_password')}
        variant="filled"
        value={password}
      />
      <IconButton
        aria-label="copy"
        style={{ marginLeft: 3, marginTop: 5 }}
        onClick={handleCopyBtnClick}
        size="large">
        <FileCopyIcon />
      </IconButton>
      <IconButton
        aria-label="copy"
        style={{ marginLeft: 3, marginTop: 5 }}
        onClick={handleResetBtnClick}
        size="large">
        <ReplayIcon />
      </IconButton>
    </div>
  );
}
