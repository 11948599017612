import API, {KnestPartsAPI} from './api';
import {encode} from 'base64-arraybuffer';

export const fetchLists = async (params, useKnestAPI = false) =>
  await (useKnestAPI ? KnestPartsAPI : API).get({
    url: '/part-list-teasers',
    params,
  });

export const getPartListTeasersCount = async params =>
  await API.get({
    url: '/part-list-teasers/count',
    params,
  });

/**
 * Create new part lists
 * @param {*} params
 */
export const createList = async ({name, partIds}) =>
  await API.post({
    url: '/part-lists',
    data: {name, partIds},
  });

export const editList = async (id, data) =>
  await KnestPartsAPI.patch({
    url: `/part-lists/${id}`,
    data,
  });

export const saveConfigsToList = async (listId, partConfigIdsList) =>
  await API.post({
    url: `/part-lists/${listId}/part-configs/assign`,
    data: {partConfigIdsList},
  });

/**
 * @description Assign parts to list in bundle update
 * @param {*} param0
 */
export const assignPartsToList = async ({listId, partIds = []}) =>
  await API.post({
    url: `/part-lists/${listId}/parts/assign`,
    data: {partIds},
  });

/**
 * @description Assign single part to list
 * @param {*} param0
 */
export const assignPartToList = async ({listId, partId}) =>
  await API.post({
    url: `/part-lists/${listId}/part/${partId}/assign`,
  });

export const assignConfigsToList = async ({listId, partConfigIdsList = []}) =>
  await API.post({
    url: `/part-lists/${listId}/part-configs/assign`,
    data: {partConfigIdsList},
  });

export const fetchPartList = async (id, params = undefined, getTeasers = true, useKnestAPI = false) =>
  await (useKnestAPI ? KnestPartsAPI : API).get({
    url: `/${getTeasers ? 'part-list-teasers' : 'part-lists'}/${id}`,
    params,
  });

export const fetchPartListEstimate = async (id, batchSizeIds = []) =>
  KnestPartsAPI.get({
    url: `/part-lists/${id}/estimates`,
    params: {
      batchSizeIds,
    },
  });

export const fetchPartListLeadTimeEstimate = async (id, batchSizeIds = []) =>
  API.get({
    url: `/part-lists/${id}/estimate/lead-time`,
    params: {
      batchSizeIds,
    },
  });

export const fetchPartsForList = async (id, params) =>
  await API.get({
    url: `/part-lists/${id}/part-teasers`,
    params,
  });

export const fetchConfigsForList = async (id, params) =>
  await API.get({
    url: `/part-lists/${id}/part-configs`,
    params,
  });

export const unassignPartFormList = async ({listId, partId}) =>
  await API.delete({
    url: `/part-lists/${listId}/parts/unassign`,
    data: {
      partIds: [partId],
    },
  });

export const unassignPartsFromList = async (partListId, partIds) => {
  await API.delete({
    url: `/part-lists/${partListId}/parts/unassign`,
    data: {
      partIds,
    },
  });
};

export const unassignConfigFromList = async ({listId, configId}) =>
  await API.delete({
    url: `/part-lists/${listId}/part-configs/unassign`,
    data: {
      partConfigIds: [configId],
    },
  });

export const fetchFirstPageLists = async filter =>
  await API.get({
    url: `/part-list-teasers`,
    params: filter,
  });

export const fetchThumbnalFromUrl = async (listId, url) =>
  await API.getWithUrl({
    url,
    params: {},
    responseType: 'arraybuffer',
  })
    .then(response => {
      const file = encode(response.data);
      return {id: listId, thumbnail: `data:image/png;base64, ${file}`};
    })
    .catch(error => {
      if (error.status === 503) {
        return {
          id: listId,
          thumbnail: require('assets/img/generating-preview.jpg'),
        };
      }
    });

/**
 * @description Request offer for part
 */
export const requestListOffer = (id, message) =>
  API.post({
    url: `/part-lists/${id}/request-offer-with-kreatize`,
    data: {message},
  });

export const fetchPartListFiles = async id =>
  await API.get({
    url: `/part-lists/${id}/download`,
    params: {},
    responseType: 'blob',
  })
    .then(response => {
      createDownloadHref(response);
    })
    .catch(error => {
      console.error(error);
    });

export const cloneToAllPartConfigsInList = async ({id, partConfigId}) =>
  await API.post({
    url: `/part-lists/${id}/part-configs/${partConfigId}/clone/all`,
    data: {id, partConfigId},
  });

export const cloneToSomePartConfigsInList = async ({
  id,
  partConfigId,
  destinationConfigIds,
}) =>
  await API.post({
    url: `/part-lists/${id}/part-configs/${partConfigId}/clone`,
    data: {id, partConfigId, destinationConfigIds},
  });

export const triggerManufacturingAnalysis = async partListId =>
  await API.post({
    url: `/part-lists/${partListId}/manufacturing-analysis`,
  });

export const deletePartList = async partListId =>
  API.delete({
    url: `/part-lists/${partListId}`,
  });

export const downloadPartListCalculatedCosts = async (partListId, batchSizeIds, downloadCsv = false, selectedBatchSizesOnly = false) => {
  const response = await KnestPartsAPI.get({
    url: `/part-lists/${partListId}/${downloadCsv ? 'estimates/csv' : 'estimates/pdf'}`,
    params: {
      batchSizeIds,
      selectedBatchSizesOnly,
    },
    responseType: 'blob',
  });
  return createDownloadHref(response);
};

export const createDownloadHref = response => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;

  // Extract filename from header
  const filename = response.headers['content-disposition']
    .split(';')
    .find(n => n.includes('filename='))
    .replace('filename="', '')
    .replace('"', '')
    .trim();

  link.setAttribute('download', decodeURIComponent(filename));
  link.setAttribute('id', filename);
  document.body.appendChild(link);
  link.click();
  document.getElementById(filename).remove();
};

export const rearrangePartConfigs = (id, sortedPartConfigIds) => {
  return API.post({
    url: `/part-lists/${id}/part-configs/rearrange`,
    data: {
      sortedPartConfigIds,
    },
  });
};

export const requestPartListQuote = async (id, sendEmail) => {
  return KnestPartsAPI.post({
    url: `/part-lists/${id}/trigger-rfq`,
    params: {sendEmail},
  });
};
