import React, { useState, useEffect } from 'react';
// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
// styles for buttons on sweetalert
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { generateRandomPassword } from 'utils/helpers';
import { validatePassword } from 'utils/validationsHelpers';
import { Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Select, MenuItem } from '@mui/material';
const useStyles = makeStyles(styles);

const selectStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SharedLinkResetPasswordModal = ({ open, onConfirm, onCancel }) => {
  const classes = useStyles();
  const selectClasses = selectStyles();
  const [, setAlert] = React.useState(null);
  const { t } = useTranslation(['modals', 'errors']);

  // Password accessibility
  const [accessibility, setAccessibility] = useState('');
  const [accessibilityError, setAccessibilityError] = useState(false);
  const [disableModalButtons] = useState(false);

  // Password input
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState('');
  const [disablePassword, setDisablePassword] = useState(false);
  const [passwordError, setPasswordError] = React.useState({
    error: false,
    msg: '',
  });

  const accessibilityOptions = {
    setPassword: 0,
    generatePassword: 1,
  };

  useEffect(() => {
    if (open) {
      successAlert();
    } else {
      hideAlert();
    }
  }, [open]);

  /**
   * FORM
   */

  const handleAccessibilitySelection = event => {
    const accessibilityVal = event.target.value;
    // set value
    setAccessibility(accessibilityVal);
    setAccessibilityError(false);
    setPasswordError({
      error: false,
      msg: '',
    });

    // password setup
    switch (accessibilityVal) {
      case accessibilityOptions.setPassword:
        setPassword(null);
        setShowPassword(true);
        setDisablePassword(false);
        break;
      case accessibilityOptions.generatePassword:
        setPassword(generateRandomPassword());
        setShowPassword(true);
        setDisablePassword(true);
        break;
      default:
        break;
    }
  };

  const passwordLabel = () => {
    return disablePassword
      ? t('mdl_shared_reset_generated_pass_lbl')
      : t('mdl_shared_enter_password_lbl');
  };

  const resetForm = () => {
    setAccessibility('');
    setAccessibilityError(false);
    setShowPassword(false);
    setPassword(null);
    setPasswordError({
      error: false,
      msg: '',
    });
  };

  const validateForm = () => {
    let isFormValid = true;
    // user must select accessibility option
    if (accessibility === '') {
      setAccessibilityError(true);
      isFormValid = false;
    }

    // if user select to enter the password manually
    // validate input
    if (accessibility === accessibilityOptions.setPassword) {
      const validation = validatePassword(password);
      setPasswordError(validation);
      if (validation.error) {
        isFormValid = false;
      }
    }
    return isFormValid;
  };

  /**
   * EVENTS
   */
  const hideAlert = () => {
    resetForm();
    setAlert(null);
  };

  const confirmAlert = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    if (typeof onConfirm === 'function') {
      onConfirm(password);
    }
  };

  const handleCancel = () => {
    if (typeof onConfirm === 'function') {
      onCancel();
    }
  };

  const successAlert = () => {
    setAlert();
  };
  return (
    <>
      {open && (
        <SweetAlert
          showCancel
          disabled={disableModalButtons}
          title={t('mdl_shared_reset_link_title')}
          closeOnClickOutside={false}
          onConfirm={() => confirmAlert()}
          onCancel={() => handleCancel()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.cancel}
          cancelBtnText={t('mdl_general_cancel')}
          confirmBtnText={t('mdl_general_reset')}
        >
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <div style={{ padding: '0 15px' }}>
              <FormControl className={selectClasses.formControl}>
                <InputLabel id="accessibility-label">
                  {t('mdl_shared_reset_pass_lbl')}
                </InputLabel>
                <Select
                  id="accessibility-cbx"
                  labelId="accessibility-label"
                  value={accessibility}
                  onChange={handleAccessibilitySelection}
                  className={selectStyles.selectEmpty}
                >
                  <MenuItem value={accessibilityOptions.setPassword}>
                    {t('mdl_shared_reset_new_pass_lbl')}
                  </MenuItem>
                  <MenuItem value={accessibilityOptions.generatePassword}>
                    {t('mdl_shared_reset_generate_pass_lbl')}
                  </MenuItem>
                </Select>
              </FormControl>
              {accessibilityError && (
                <div
                  className={'inp-error'}
                  style={{ color: 'red', marginTop: 5 }}
                >
                  {t('mdl_shared_reset_password_select_error')}
                </div>
              )}
            </div>
          </Grid>
          {showPassword && (
            <>
              <br></br>
              <div style={{ padding: '0 15px' }}>
                <TextField
                  disabled={disablePassword}
                  label={passwordLabel()}
                  error={passwordError.error}
                  type="text"
                  fullWidth
                  value={password || ''}
                  onChange={event => {
                    setPassword(event.target.value);
                  }}
                />
                {accessibility === accessibilityOptions.setPassword &&
                passwordError.error ? (
                  <div
                    style={{ fontSize: 12, color: 'red', textAlign: 'left' }}
                  >
                    {t('mdl_shared_link_password_info')}
                  </div>
                ) : (
                  <div style={{ fontSize: 12, textAlign: 'left' }}>
                    {t('mdl_shared_link_password_info')}
                  </div>
                )}
              </div>
            </>
          )}
        </SweetAlert>
      )}
    </>
  );
};

export default SharedLinkResetPasswordModal;
