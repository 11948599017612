import {combineReducers} from '@reduxjs/toolkit';

import TopBarReducer from 'components/TopBar/TopBarReducer';
import PartDetailsReducer from 'containers/PartDetails/PartDetailsReducer';
import PartListDetailsReducer from 'containers/PartListDetails/PartListDetailsReducer';
import GeneralReducer from './store/general/generalReducer';
import BreadcrumbsReducer from './store/breadcrumbs/breadcrumbsReducer';
import SharedPartsReducer from 'containers/SharedParts/SharedPartsReducer';
import NewPartListReducer from 'containers/PartList/NewPartListReducer';
import SnackbarReducer from 'store/snackbar/snackbarReducer';

export default combineReducers({
  part: PartDetailsReducer,
  partList: PartListDetailsReducer,
  search: TopBarReducer,
  general: GeneralReducer,
  sharedParts: SharedPartsReducer,
  breadcrumbs: BreadcrumbsReducer,
  newPartList: NewPartListReducer,
  snackbar: SnackbarReducer,
});
