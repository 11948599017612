import API from './api';

export const fetchNotifications = (params = null) =>
  API.get({
    url: '/notifications',
    params,
  });

export const seenNotifications = (notificationIds, params = null) =>
  API.patch({
    url: '/notifications',
    data: {
      notificationIds,
    },
    params,
  });

export const clearAllNotifications = (params = null) =>
  API.delete({
    url: '/notifications',
    params,
  });

export const clearOneNotification = (id, params = null) =>
  API.delete({
    url: `/notifications/${id}`,
    params,
  });
