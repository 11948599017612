import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Trans, useTranslation} from 'react-i18next';
import Select from 'react-select';
import EcoIcon from '@mui/icons-material/LocalFlorist';
import CustomFormLayout from 'components/CustomForm/CustomFormLayout';
import CustomFormInput from 'components/CustomForm/CustomFormInput';
import Loading from 'components/Loading/Loading';
import {UPDATE_SUBSTEP, UPDATE_TEMP_DATA} from 'components/Modals/ConfigsModal/ConfigTypes';
import {ConfigContext} from 'components/Modals/ConfigsModal/ConfigContext';
import {updateProcessFeaturesValues} from 'services/configs';
import {saveConfigProcess} from 'components/Modals/ConfigsModal/services/processServices';
import {StepType} from 'components/Modals/ConfigsModal/Models/StepTypeEnum';
import {logEvent} from 'utils/firebase';
import './CustomProcessForm.scss';

export const CustomProcessForm = ({
  processClass,
  processType,
  currentProcessRef,
  stepType,
  selectedProcess,
  onProcessSelection,
  processList,
  formData,
  setFormData,
  processFormLoading,
  setProcessFormLoading,
  showForm,
  placeholder,
}) => {
  const {t} = useTranslation(['partconfigurator', 'processes']);
  const {dispatch, state} = useContext(ConfigContext);
  // process
  const processes = useSelector(state => state.general.processes);

  // toast messages
  const {enqueueSnackbar} = useSnackbar();
  // inputs
  const MIN_NUMBER = 0;
  const MAX_NUMBER = 500000;
  const MAX_CHARS = 500;

  /**
   * FORM INPUT CHANGE
   */

  /**
   * Update form input on back-end
   */
  const updateFormInput = inputData => {
    const process =
      processList[state.currentSubstep] &&
      processList[state.currentSubstep].process;

    // if input does not have id yet, remove the id property to be valid object for the back-end
    if (inputData.id === 0) {
      delete inputData.id;
    }

    if (process) {
      updateProcessFeaturesValues(state.tempData.configId, process.id, {
        ...inputData,
      })
        .then(res => {
          const inputData = {...res.data};

          // update current form
          if (inputData !== undefined) {
            const updatedFormData = {...formData};
            updatedFormData[inputData.processFeatureTypeKey].id = inputData.id;
            setFormData({...updatedFormData});
          }
        })
        .catch(() => {
          enqueueSnackbar(t('pc_mdl_update_data_form_error'), {
            variant: 'error',
          });
        });

      return;
    }

    enqueueSnackbar(t('pc_mdl_update_data_form_error'), {
      variant: 'error',
    });
  };

  const handleDataInputChange = ({value, inpKey, dataType}) => {
    // update the value local
    const newForm = {...formData};
    // check limitations and correct
    if (dataType === 'int' || dataType === 'double') {
      if (value < MIN_NUMBER) {
        value = MIN_NUMBER;
      } else if (value > MAX_NUMBER) {
        value = MAX_NUMBER;
      }
      newForm[inpKey].value = value;
    }

    // Max number of chars
    if (dataType === 'string' && value.length < MAX_CHARS) {
      newForm[inpKey].value = value;
    }

    if (dataType === 'text' && value.length < MAX_CHARS) {
      newForm[inpKey].value = value;
    }

    if (dataType === 'select') {
      newForm[inpKey].value = value;
      const inputData = newForm[inpKey];
      updateFormInput({...inputData});
    }

    setFormData(newForm);

    if (dataType === 'datetime' || dataType === 'bool') {
      newForm[inpKey].value = value;
      const inputData = newForm[inpKey];
      updateFormInput({...inputData});
    }
  };

  const handleDataInputBlur = ({value, inpKey}) => {
    // update the value local
    const newForm = {...formData};
    newForm[inpKey].value = value;
    setFormData(newForm);
    const inputData = newForm[inpKey];
    updateFormInput({...inputData});
  };

  const handleChange = selectedProcessOption => {
    // prevent selecting the same process
    if (selectedProcess && selectedProcess.id === selectedProcessOption.id) {
      return;
    }

    if (!state.tempData.configId) {
      return;
    }
    // clear the form and show loading
    onProcessSelection(selectedProcessOption);
    setProcessFormLoading(true);

    dispatch({
      type: UPDATE_SUBSTEP,
      payload: {
        id: state.currentSubstep,
        stepId: state.currentStep,
        name:
          selectedProcessOption && t(`processes:${selectedProcessOption.key}`),
        type: stepType,
      },
    });

    const typeOfProcess =
      stepType === StepType.MAIN_PROCESS.val ? 'main' : 'post';
    if (!Object.keys(currentProcessRef.current).length) {
      currentProcessRef.current = {
        processSelection: {...selectedProcessOption},
      };
      // NEW selected process
      saveConfigProcess(
        state.tempData.configId,
        {...selectedProcessOption},
        null,
        typeOfProcess,
        onNewProcessSuccess,
        onUpdateFormDataError,
      );
    } else {
      // updated/change selected process
      currentProcessRef.current = {
        ...currentProcessRef.current,
        processSelection: {...selectedProcessOption},
      };

      saveConfigProcess(
        state.tempData.configId,
        {...selectedProcessOption},
        currentProcessRef.current.process.id,
        typeOfProcess,
        onUpdateSuccess,
        onUpdateFormDataError,
      );
    }
  };

  /**
   * CREATE NEW PROCESS
   */
  const onNewProcessSuccess = ({processSelection, process, formData}) => {
    updateState({
      processSelection,
      process,
      formData,
    });
    setProcessFormLoading(false);
    logEvent('add_new_process');
  };

  /**
   * UPDATE PROCESS
   */
  const onUpdateSuccess = ({processSelection, process, formData}) => {
    updateState({
      processSelection,
      process,
      formData,
    });
    logEvent('update_existing_process');
  };

  const onUpdateFormDataError = () => {
    enqueueSnackbar(t('pc_mdl_update_data_form_error'), {
      variant: 'error',
    });
    setProcessFormLoading(false);
  };

  const updateState = ({processSelection, process, formData}) => {
    const processData = {
      processSelection,
      process,
      formData: Object.keys(formData).length ? formData : {},
    };

    let payloadData = {};
    if (
      stepType === StepType.MAIN_PROCESS.val ||
      stepType === StepType.POST_PROCESS.val ||
      stepType === StepType.PRODUCT_REQUIREMENTS.val
    ) {
      let newProcesses = [...processList];
      newProcesses[state.currentSubstep] = {...processData};
      payloadData = {
        [processType]: [...newProcesses],
      };
    }

    dispatch({
      type: UPDATE_TEMP_DATA,
      payload: {...payloadData},
    });

    setFormData({...processData.formData});
  };

  const getOptionLabel = ({key, ecoFriendliness}) => {
    const ecoFriendlinessIcon = () => {
      if (ecoFriendliness === 0) return <><EcoIcon style={{display: 'inline', color: '#ff0000'}} /></>;
      if (ecoFriendliness === 1) return <EcoIcon style={{display: 'inline', color: '#ebeb00'}} />;
      if (ecoFriendliness === 2) return <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />;
      if (ecoFriendliness === 3) return <>
        <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
        <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
      </>;
      if (ecoFriendliness === 4) return <>
        <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
        <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
        <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
      </>;
    };

    return <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>{t(`processes:${key}`)}</div>
      <div style={{paddingLeft: '10px', height: '24px'}}>{ecoFriendlinessIcon()}</div>
    </div>;
  };

  const filterProcessesResults = ({data}, searchText) =>
    t(`processes:${data.key}`).toLowerCase().includes(searchText.toLowerCase());

  return (
    <div className="config-post-select-container">
      <div style={{width: '100%', marginBottom: 20}}>
        <div style={{padding: '10px', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
          <Trans
            i18nKey={'partconfigurator:eco_friendliness_legend'}
            components={[
              <EcoIcon style={{display: 'inline', color: '#ff0000'}} />,
              <EcoIcon style={{display: 'inline', color: '#ebeb00'}} />,
              <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />,
            ]}
          />
        </div>
        <Select
          name="processes"
          className="config-post-select"
          placeholder={placeholder}
          isClearable={false}
          isSearchable
          options={processes
            .filter(process => process.class === processClass)
            .sort((a, b) =>
              t(`processes:${a.key}`).localeCompare(t(`processes:${b.key}`)),
            )}
          getOptionLabel={option => getOptionLabel(option)}
          getOptionValue={option => option}
          value={selectedProcess}
          filterOption={filterProcessesResults}
          onChange={handleChange}
        />
      </div>
      {processFormLoading && (
        <div className={'config-tab-loading'}>
          <Loading pageHeight={300} />
        </div>
      )}
      {!processFormLoading && showForm && (
        <div style={{width: '100%'}}>
          <CustomFormLayout>
            {Object.keys(formData).length ? (
              Object.keys(formData).map(key => (
                <CustomFormInput
                  required={formData[key].required}
                  key={formData[key].processFeatureTypeKey}
                  inpKey={formData[key].processFeatureTypeKey}
                  id={formData[key].processFeatureTypeKey}
                  dataType={formData[key].dataTypeKey}
                  label={`${t(`processfeatures:${formData[key].processFeatureTypeKey}`)}
                    ${formData[key].valueAuto?.trim() ?
                    ` (Auto: ${formData[key].valueAuto.trim()}${formData[key].unitKey ? formData[key].unitKey === 'percent'
                        ? '%'
                        : ` ${formData[key].unitKey}`
                      : ''})`
                    : ''}`
                  }
                  unitKey={formData[key].unitKey}
                  gridXs={6}
                  gridSm={6}
                  value={['boolean', 'string', 'text'].includes(typeof formData[key].value) ? formData[key].value : formData[key].valueAuto}
                  selectedValue={
                    formData[key].value && formData[key].value.length > 0
                      ? {
                        value: formData[key].value,
                        label: t('processfeatures:' + formData[key].value),
                      }
                      : ''
                  }
                  selectOptions={
                    !formData[key].selectOptions
                      ? []
                      : formData[key].selectOptions.map(item => {
                        return {
                          value: item.value,
                          label: t('processfeatures:' + item.label),
                        };
                      })
                  }
                  onDataChange={handleDataInputChange}
                  onBlur={handleDataInputBlur}
                />
              ))
            ) : (
              <></>
            )}
          </CustomFormLayout>
        </div>
      )}
    </div>
  );
};
