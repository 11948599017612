import userflow from 'userflow.js';

export default function initializeUserflow (name, email, locale_code = 'de') {
  if (!process.env.REACT_APP_USERFLOW_TOKEN) return;

  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
  userflow.identify(email, {
    name,
    email,
    locale_code, 
  })
};