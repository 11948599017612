import React, { useState } from 'react';
import { Checkbox } from '@mui/material';

const SharedCheckboxCell = ({ item, onSelect }) => {
  const [isSelected, setIsSelected] = useState(false);
  const handleSelect = () => {
    const select = !isSelected;
    setIsSelected(select);
    if (typeof onSelect == 'function') {
      onSelect({ isSelected: select, item: item, select: updateSelect });
    }
  };

  const updateSelect = value => {
    setIsSelected(value);
  };

  return (
    <Checkbox
      color={'primary'}
      checked={isSelected}
      value={1}
      onChange={handleSelect}
      inputProps={{
        'aria-label': 'part checkbox',
      }}
    />
  );
};

export default SharedCheckboxCell;
