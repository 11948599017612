import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import style from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import {useTranslation} from 'react-i18next';
import genderIcon from 'assets/img/gender.svg';

const useStyle = makeStyles(style);

function ImpersonationMenu({setImpersonationModalOpen}) {
  const classes = useStyle();
  const {t} = useTranslation('navigation');
  const anchorRef = React.useRef(null);

  return <>
    <Tooltip title={t('nav_impersonation_tooltip')}>
      <span>
        <Button
          ref={anchorRef}
          onClick={() => setImpersonationModalOpen(true)}
          aria-controls="profile-menu"
          className={classes.topBarButton}
        >
          <img src={genderIcon} alt="gender" style={{maxWidth: '40px'}} />
        </Button>
      </span>
    </Tooltip>
  </>;
}

export default ImpersonationMenu;
