import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Chip,
  Snackbar,
  Tooltip,
  Paper,
} from '@mui/material';
import {Close, Description, MoreVert} from '@mui/icons-material';
import {Alert} from '@mui/material';

import EstimatesPDF from '../Download/DownloadFile';

import noPreview from 'assets/img/no-preview.jpg';
import warning from 'assets/img/warning.gif';
import {withTranslation} from 'react-i18next';

import {toDisplayDate} from '../../utils/datetimeHelpers';
import {constructOrderThumbnailUrl} from '../../services/orders';

class OrderCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuAnchorElement: null,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarSeverity: '',
    };
  }

  openMenu = event => {
    this.setState({
      menuAnchorElement: event.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({
      menuAnchorElement: null,
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarSeverity: '',
    });
  };

  setThumbnailToNoPreviewAvailable = orderedPartListId => {
    document.getElementById(`order-${orderedPartListId}-thumbnail`).src = noPreview;
  };

  render() {
    const {t, order} = this.props;

    return (
      <>
        <Paper className={'part-list-card'} style={{justifyContent: 'center'}}>
          <Card style={{width: '100%', boxShadow: 'none', borderRadius: 15}}>
            <CardHeader
              title={
                order.name.trim().length > 18 ?
                  <NavLink
                    to={`/orders/${order.id}`} style={{
                    textDecoration: 'none',
                    color: '#000',
                  }}
                  >
                    <Tooltip title={order.name} placement={'bottom'} arrow>
                      <div>{order.name.substr(0, 17).trim()}&hellip;</div>
                    </Tooltip>
                  </NavLink> :
                  <NavLink
                    to={`/orders/${order.id}`} style={{
                    textDecoration: 'none',
                    color: '#000',
                  }}
                  >
                    <div>{order.name.trim()}</div>
                  </NavLink>
              }
              subheader={<div style={{fontSize: 'small'}}>{t('order_number')}: {order.kmsId}</div>}
            />
            <NavLink to={`/orders/${order.id}`}>
              <CardContent style={{textAlign: 'center', margin: 0, padding: 0}}>
                <img
                  id={`order-${order.id}-thumbnail`}
                  src={constructOrderThumbnailUrl(order.id)}
                  alt="Order Thumbnail"
                  style={{
                    height: 200,
                    maxWidth: '100%',
                    backgroundSize: 'contain',
                  }}
                  onError={() => this.setThumbnailToNoPreviewAvailable(order.id)}
                />
              </CardContent>
            </NavLink>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Chip
                color={'success'}
                style={{color: 'white', marginTop: '20px'}}
                label={t(`status_${order.statusKey}`)}
              />
            </div>
            <CardContent style={{paddingBottom: '15px'}}>
              <Typography
                variant={'inherit'}
              >
                {t('ordered_on')}: {toDisplayDate(order.exportedAt)} <br />
              </Typography>
              <Typography
                noWrap
                variant={'inherit'}
                style={{display: 'block'}}
              >
                {t('internal_order_number')}: {order.internalOrderNumber || '-'}
              </Typography>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={'auto'} />
                <Grid item xs={'auto'} container alignItems={'center'} justifyContent={'flex-end'}>
                  {
                    order.delayed &&
                    !['delivered', 'invoice_sent', 'invoice_paid'].includes(order.statusKey) &&
                    <Grid item>
                      <Tooltip title={t('order_delayed')} placement={'bottom'} arrow>
                        <img src={warning} alt={'warning'} style={{width: '20px', height: '20px'}} />
                      </Tooltip>
                    </Grid>
                  }
                  <Grid item>
                    <IconButton onClick={this.openMenu} size={'small'}><MoreVert /></IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Menu
              anchorEl={this.state.menuAnchorElement}
              keepMounted
              open={!!this.state.menuAnchorElement}
              onClose={this.closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              elevation={2}
            >
              <EstimatesPDF
                model={order}
                fileType={'estimates_pdf'}
                ComponentToRender={MenuItem}
                ComponentToRenderProps={{key: 'download_estimates_pdf'}}
                ComponentToRenderChildren={<><Description style={{paddingRight: 5}} /><Typography>{t('download_estimates_pdf')}</Typography></>}
              />
            </Menu>
          </Card>
        </Paper>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <Alert
            severity={this.state.snackbarSeverity}
            elevation={6}
            variant={'filled'}
            action={<IconButton onClick={this.closeSnackbar} size="small" color="inherit"><Close /></IconButton>}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default withTranslation('orders')(OrderCard);
