import React, {Component} from 'react';
import {CircularProgress, Grid, Grow} from '@mui/material';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {withTranslation} from 'react-i18next';
import {withSnackbar} from 'notistack';
import DraggablePartConfig from './DraggablePartConfig';
import {rearrangePartConfigs} from '../../services/part-lists';
import ConfiguratorModal from '../Modals/ConfiguratorModal/ConfiguratorModal';
import {withRouter} from '../../utils/helpers';

class PartListDetailsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      configuratorModalOpen: false,
      partConfigInEditModeIndex: null,
      nextPart: null,
      previousPart: null,
    };
  }

  componentDidMount() {
    if (this.props.searchParams.get('editPartConfig')) {
      this.openConfiguratorModal();
    }
  }

  openConfiguratorModal = () => {
    const {searchParams} = this.props;

    const editPartConfig = parseInt(searchParams.get('editPartConfig'));
    const partConfigIndex = Number.isInteger(editPartConfig) ? this.props.partList?.partListPartConfigs.findIndex(plpc => plpc.partConfig.id === editPartConfig) : null;

    if (partConfigIndex >= 0) {
      this.setState({
        configuratorModalOpen: true,
        partConfigInEditModeIndex: partConfigIndex,
        nextPart: this.props.partList.partListPartConfigs[partConfigIndex + 1]
          ? () => this.props.setSearchParams([
            ...Array.from(this.props.searchParams).filter(([key]) => key !== 'editPartConfig'),
            ['editPartConfig', this.props.partList.partListPartConfigs[partConfigIndex + 1].partConfig.id.toString()],
          ])
          : null,
        previousPart: this.props.partList.partListPartConfigs[partConfigIndex - 1]
          ? () => this.props.setSearchParams([
            ...Array.from(this.props.searchParams).filter(([key]) => key !== 'editPartConfig'),
            ['editPartConfig', this.props.partList.partListPartConfigs[partConfigIndex - 1].partConfig.id.toString()],
          ])
          : null,
      });
    } else {
      this.closeConfiguratorModal();
    }
  };

  closeConfiguratorModal = () => {
    this.setState({
      configuratorModalOpen: false,
      partConfigInEditMode: null,
    });

    this.props.setSearchParams(Array.from(this.props.searchParams).filter(([key]) => key !== 'editPartConfig'));
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props.searchParams.get('editPartConfig') && prevProps.searchParams.get('editPartConfig') !== this.props.searchParams.get('editPartConfig')) {
      this.openConfiguratorModal();
    }
  }

  onDragEnd = async ({source, destination}) => {
    if (!destination || source.index === destination.index) return;

    const updatedPartConfigs = [...this.props.partList.partListPartConfigs];
    const originalPartConfigs = [...this.props.partList.partListPartConfigs];

    updatedPartConfigs.splice(source.index, 1);
    updatedPartConfigs.splice(destination.index, 0, this.props.partList.partListPartConfigs[source.index]);
    this.props.setPartListPartConfigs(updatedPartConfigs);

    try {
      // Set the sort in the BE
      const sortedPartConfigIds = updatedPartConfigs.map(plpc => plpc.partConfig.id);
      await rearrangePartConfigs(this.props.partList.id, sortedPartConfigIds);
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('general_error_title'), {
        variant: 'error',
      });

      // Restore the original sort
      this.props.setPartListPartConfigs(originalPartConfigs);
    }
  };

  render() {
    const {partList, refreshPartList} = this.props;

    const allPartConfigs = partList?.partListPartConfigs.map(plpc => plpc.partConfig);

    return <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          display: this.state.loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(150, 150, 150, .3)',
          zIndex: 999999,
        }}
      >
        <CircularProgress
          color={'primary'}
          size={60}
        />
      </div>
      <Grow in={true} timeout={650}>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={`${partList.id}`}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Grid
                    container
                    rowSpacing={2}
                  >
                    {partList?.partListPartConfigs.map((plpc, index) => (
                      <DraggablePartConfig
                        key={plpc.partConfig.id}
                        partListId={partList.id}
                        allPartConfigs={allPartConfigs}
                        partConfig={plpc.partConfig}
                        partConfigTeaser={plpc.partConfigTeaser}
                        index={index}
                        updatePartConfigInState={this.props.updatePartConfigInState}
                        updatePartConfigTeaserInState={this.props.updatePartConfigTeaserInState}
                        refreshPartList={refreshPartList}
                      />
                    ))}
                  </Grid>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Grow>
      <ConfiguratorModal
        open={this.state.configuratorModalOpen}
        partConfigInEditMode={this.props.partList.partListPartConfigs[this.state.partConfigInEditModeIndex]}
        onClose={this.closeConfiguratorModal}
        nextPart={this.state.nextPart}
        previousPart={this.state.previousPart}
        updatePartConfigInState={this.props.updatePartConfigInState}
        updatePartConfigTeaserInState={this.props.updatePartConfigTeaserInState}
      />
    </div>;
  }
}

export default withRouter(withSnackbar(withTranslation('partlistdetails')(PartListDetailsOverview)));
