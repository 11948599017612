import React, {Component} from 'react';
import {Button, Container, Grid, Tooltip, Typography} from '@mui/material';
import {LoadingWithoutHeight} from 'components/Loading/Loading';
import {deletePartList, editList, fetchPartList} from 'services/part-lists';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {updateBreadcrumbs} from 'store/breadcrumbs/breadcrumbsActions';
import {AttachFile, BarChart, DeleteForever, FormatListBulleted, Person, Publish, Share} from '@mui/icons-material';
import UploadModal from 'components/Modals/UploadModal/UploadModal';
import SharePartListModal from 'components/Modals/SharePartListModal/SharePartListModal';
import {withSnackbar} from 'notistack';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withTheme} from '@mui/styles';
import ArrangeAttachments from '../../components/AutomaticConfiguration/ArrangeAttachments';
import PartListDetailsOverview from '../../components/PartListDetails/PartListDetailsOverview';
import ClickToEditTextField from '../../components/ClickToEditTextField/ClickToEditTextField';
import {setPartList} from './PartListDetailsActions';
import {toDisplayDate} from '../../utils/datetimeHelpers';
import {getCompanyUsers} from '../../services/general';
import UpdatePartListContactModal from '../../components/Modals/UpdatePartListContactModal/UpdatePartListContactModal';
import {withRouter} from '../../utils/helpers';

const MySwal = withReactContent(Swal);

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: (partListName, kmsId) => dispatch(
      updateBreadcrumbs({
        level: 1,
        path: window.location.pathname,
        text: `${partListName} (${kmsId})`,
      }),
    ),
    setPartListInGlobalStore: partList => dispatch(
      setPartList(partList),
    ),
  };
};

const mapStateToProps = state => ({
  currentUserInfo: state.general?.currentUserInfo,
});

const connectPartListDetails = connect(mapStateToProps, mapDispatchToProps);

class PartListDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partListId: this.props.params.id,
      loading: true,
      partList: null,
      partListName: '',
      uploadModalOpen: false,
      sharePartListModalOpen: false,
      updatePartListContactModalOpen: false,
      viewAttachments: false,
      users: [],
    };
  }

  refreshPartList = async (setLoading = false) => {
    this.setState({
      loading: setLoading,
    });

    // Get the part list
    const {data: partList} = await fetchPartList(this.state.partListId, {
      filter: {
        include: {
          contactUser: true,
          partListPartConfigs: {
            select: {
              partConfigTeaser: true,
              partConfig: {
                include: {
                  part: {
                    include: {
                      partPartFeatures: true,
                      partAttachments: {
                        orderBy: {
                          attachment: {createdAt: 'desc'},
                        },
                        select: {
                          attachment: {
                            include: {attachmentType: true, currentFile: {include: {fileFormat: true}}},
                          },
                        },
                      },
                    },
                  },
                  batchSizes: true,
                  partConfigProcesses: {
                    orderBy: {rank: 'asc'},
                    include: {
                      process: true,
                      manufacturingAnalyses: {
                        include: {
                          batchSize: true,
                        },
                      },
                      processFeatureValues: true,
                    },
                  },
                  partConfigMaterialFeatures: true,
                },
              },
            },
          },
        },
      },
    }, false, true);

    // Update the state
    this.setState({
      partList,
      partListName: partList.name,
      loading: false,
    });

    this.props.setPartListInGlobalStore(partList);
    this.props.setBreadcrumbs(partList.name, `KMS-PL-${this.state.partListId}`);
  };

  async componentDidMount() {
    await this.refreshPartList(true);

    const {data: contactUsers} = await getCompanyUsers();
    this.setState({
      users: contactUsers,
    });
  }

  componentDidUpdate() {
    if (this.props.params.id !== this.state.partListId) {
      this.setState({
        partListId: this.props.params.id,
      }, () => this.refreshPartList(true));
    }
  }

  setUploadModalState = uploadModalOpen => {
    this.setState({
      uploadModalOpen,
    });
  };

  setSharePartListModalState = sharePartListModalOpen => {
    this.setState({
      sharePartListModalOpen,
    });
  };

  setUpdatePartListContactModalState = updatePartListContactModalOpen => {
    this.setState({
      updatePartListContactModalOpen,
    });
  };

  deletePartList = async () => {
    const {t, theme} = this.props;

    await MySwal.fire({
      title: <Typography variant={'h5'}>{t('plpd_delete_list_header')}</Typography>,
      html: <Typography variant={'body1'}>{t('plpd_delete_list_description')}</Typography>,
      icon: 'warning',
      confirmButtonText: t('plpd_delete'),
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: t('general_cancel'),
      reverseButtons: true,
      showCloseButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: theme.palette.error.main,
      cancelButtonColor: theme.palette.secondary.main,
      preConfirm: async () => {
        try {
          await deletePartList(this.state.partListId);
          this.props.navigate(`/part-lists`);
        } catch (err) {
          console.error(err);
          this.props.enqueueSnackbar(this.props.t('general_error_title'), {
            variant: 'error',
          });
          return false;
        }
      },
    });
  };

  toggleViewAttachments = () => {
    this.setState({
      viewAttachments: !this.state.viewAttachments,
    });
  };

  setPartListName = async (partListName, saveChanges = false) => {
    this.setState({
      partListName,
    });

    if (saveChanges) {
      try {
        await editList(this.state.partList.id, {
          name: partListName,
        });

        this.setState({
          partList: {...this.state.partList, name: partListName},
        });

        this.props.setBreadcrumbs(this.state.partList.name, `KMS-PL-${this.state.partListId}`);
      } catch (err) {
        console.error(err);
        this.props.enqueueSnackbar(this.props.t('general_error_title'), {
          variant: 'error',
        });

        this.setState({
          partListName: this.state.partList.name,
        });
      }
    }
  };

  setPartListPartConfigs = partListPartConfigs => {
    this.setState({
      partList: {
        ...this.state.partList,
        partListPartConfigs,
      },
    });
  };

  updatePartConfigInState = (partConfig) => {
    this.setState({
      partList: {
        ...this.state.partList,
        partListPartConfigs: this.state.partList.partListPartConfigs.map(plpc => plpc.partConfig.id === partConfig.id ? {...plpc, partConfig} : plpc),
      },
    });
  };

  updatePartConfigTeaserInState = (partConfigTeaser) => {
    this.setState({
      partList: {
        ...this.state.partList,
        partListPartConfigs: this.state.partList.partListPartConfigs.map(plpc => plpc.partConfigTeaser.id === partConfigTeaser.id ? {...plpc, partConfigTeaser} : plpc),
      },
    });
  };

  setContactUserId = async contactUserId => {
    try {
      await editList(this.state.partList.id, {
        contactUserId,
      });

      this.setState({
        partList: {
          ...this.state.partList,
          contactUserId,
          contactUser: this.state.users.find(user => user.id === contactUserId),
        },
      });
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('general_error_title'), {
        variant: 'error',
      });
    }
  };

  render() {
    const {t, navigate} = this.props;

    return <Container maxWidth={false} key={this.state.partListId}>
      <Grid
        container
        spacing={3}
        justifyContent={'center'}
        alignItems={'center'}
        style={{paddingBottom: 110}}
      >
        {this.state.loading ? (
          <Grid item xs={12}>
            <LoadingWithoutHeight />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid item xs={12} sm={5} container alignItems={'center'}>
                <Grid item xs={12}>
                  <ClickToEditTextField
                    value={this.state.partListName}
                    onChange={value => this.setPartListName(value)}
                    onBlur={value => this.setPartListName(value, true)}
                    fontSize={'1.8725rem'}
                    fontWeight={520}
                    maxLength={255}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'body1'}>{t('plpd_created_on')}: {toDisplayDate(this.state.partList.createdAt)}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={7} container justifyContent={'flex-end'} alignItems={'flex-end'}>
                <Grid item xs={'auto'}>
                  <Button
                    variant={'contained'}
                    style={{color: 'white', marginRight: 7}}
                    color={'primary'}
                    onClick={() => navigate(`/part-lists/${this.state.partListId}/estimate`)}
                    startIcon={<BarChart />}
                  >
                    {t('plpd_analysis_dashboard')}
                  </Button>
                </Grid>
                {this.props.currentUserInfo?.roles.some(role => role.name === 'impersonation') && this.props.currentUserInfo.impersonating && <Grid item xs={'auto'}>
                  <Tooltip title={t('plpd_update_contact')}>
                  <span>
                    <Button
                      style={{marginRight: 7}}
                      variant={'outlined'}
                      onClick={() => this.setUpdatePartListContactModalState(true)}
                    >
                      <Person />
                      {this.state.partList?.contactUser &&
                        <Typography maxWidth={150} ml={1} noWrap variant={'button'}>
                          {`${this.state.partList?.contactUser?.lastName || '?'}, ${this.state.partList?.contactUser?.firstName || '?'} (${this.state.partList?.contactUser?.email || '?'})`}
                        </Typography>}
                    </Button>
                  </span>
                  </Tooltip>
                </Grid>}
                <Grid item xs={'auto'}>
                  <Tooltip title={t('plpd_add_more_parts')}>
                  <span>
                    <Button
                      style={{marginRight: 7}}
                      variant={'outlined'}
                      onClick={() => this.setUploadModalState(true)}
                    >
                      <Publish />
                    </Button>
                  </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={'auto'}>
                  <Tooltip title={t('plpd_share')}>
                  <span>
                    <Button
                      style={{marginRight: 7}}
                      variant={'outlined'}
                      onClick={() => this.setSharePartListModalState(true)}
                    >
                      <Share />
                    </Button>
                  </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={'auto'}>
                  <Tooltip title={t('plpd_delete')}>
                  <span>
                    <Button
                      variant={'outlined'}
                      onClick={this.deletePartList}
                    >
                      <DeleteForever />
                    </Button>
                  </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3} justifyContent={'center'}>
              <Grid item xs={6}>
                <Typography variant={'h5'}>{this.state.viewAttachments ? t('plpd_attachments') : t('plpd_overview')}</Typography>
              </Grid>
              <Grid item xs={6} style={{textAlign: 'right'}}>
                <Button
                  variant={'outlined'}
                  onClick={this.toggleViewAttachments}
                  startIcon={this.state.viewAttachments ? <FormatListBulleted /> : <AttachFile />}
                >
                  {this.state.viewAttachments ? t('plpd_overview') : t('plpd_attachments')}
                </Button>
              </Grid>
              {this.state.viewAttachments ? (
                <Grid item xs={12}>
                  <ArrangeAttachments
                    partList={this.state.partList}
                    refreshPartList={this.refreshPartList}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <PartListDetailsOverview
                    partList={this.state.partList}
                    refreshPartList={this.refreshPartList}
                    setPartListPartConfigs={this.setPartListPartConfigs}
                    updatePartConfigInState={this.updatePartConfigInState}
                    updatePartConfigTeaserInState={this.updatePartConfigTeaserInState}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <UploadModal
        open={this.state.uploadModalOpen}
        setOpen={this.setUploadModalState}
        confirm={this.refreshPartList}
        url={`/part-lists/${this.state.partListId}/upload`}
        listUpload
      />
      <SharePartListModal
        open={this.state.sharePartListModalOpen}
        setOpen={this.setSharePartListModalState}
        partListId={this.state.partListId}
        partListName={this.state.partList?.name}
      />
      <UpdatePartListContactModal
        users={this.state.users}
        t={t}
        open={this.state.updatePartListContactModalOpen}
        setOpen={this.setUpdatePartListContactModalState}
        contactUserId={this.state.partList?.contactUserId}
        setContactUserId={this.setContactUserId}
      />
    </Container>;
  }
}

export default withRouter(withTheme(withSnackbar(withTranslation(['partlistdetails', 'modals'])(connectPartListDetails(PartListDetails)))));
