import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Grid, Fab, Tooltip, CircularProgress, Hidden, Paper, Typography, Button, LinearProgress, Alert} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Autorenew, PictureAsPdf, ShoppingCart} from '@mui/icons-material';

import PartListEstimatorTable from './PartListEstimatorTable';
import Loading from 'components/Loading/Loading';
import Logo from 'assets/img/kms_logo_multiline_right.svg';

import {
  downloadPartListCalculatedCosts,
  fetchPartList,
  fetchPartListEstimate,
  requestPartListQuote,
} from '../../services/part-lists';

import OrderCheckoutModal from '../../components/Modals/OrderCheckoutModal/OrderCheckoutModal';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withSnackbar} from 'notistack';
import {updateBreadcrumbs} from '../../store/breadcrumbs/breadcrumbsActions';
import {connect} from 'react-redux';
import {logEvent} from 'utils/firebase';
import {calculateDeliveryDateFromLeadTime, withRouter} from '../../utils/helpers';
import {toDisplayDate} from '../../utils/datetimeHelpers';
import './PartListEstimator.scss';

const MySwal = withReactContent(Swal);

const mapStateToProps = state => ({
  currentUserInfo: state.general?.currentUserInfo,
});

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: name => {
      dispatch(
        updateBreadcrumbs({
          level: 1,
          path: window.location.pathname.split('/estimate')[0],
          text: name,
        }),
      );
      dispatch(
        updateBreadcrumbs({
          level: 2,
          path: window.location.pathname,
          text: 'Estimate',
        }),
      );
    },
  };
};

const connectPartListEstimatorTable = connect(mapStateToProps, mapDispatchToProps);

export class PartListEstimator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      globalLoading: true,
      loading: true,
      estimateLocking: false,
      csvLoading: false,
      partListId: this.props.params.id,
      batchSizeIds: [],
      leadTime: null,
      orderCheckoutModalOpen: false,
      latestPartListQuoteId: null,
      latestPartListQuoteStatus: null,
      latestPartListQuoteExpiryDatetime: null,
      latestPartListQuoteExpired: false,
    };
  }

  async reload(globalLoading = true) {
    this.setState({
      loading: true,
      globalLoading,
    });

    try {
      const {data} = await fetchPartListEstimate(this.state.partListId, this.state.batchSizeIds);
      const {data: partList} = await fetchPartList(this.state.partListId);

      this.setState({
        ...data,
        partList,
        batchSizeIds: data.selectedBatchSizeIds,
        numberOfBatchSizes: data.selectedBatchSizeIds.length,
        leadTime: data.leadTime,
      }, () => this.props.setBreadcrumbs(this.state.partList?.name));
    } catch (e) {
      console.error(e);
    }
    this.setState({
      loading: false,
      globalLoading: false,
    });
  }

  async componentDidMount() {
    await this.reload();
  }

  async setBatchSizeIds(batchSizeIds) {
    this.setState({
      batchSizeIds,
    }, async () => this.reload(false));
  }

  hasData() {
    return !!this.state.numberOfBatchSizes;
  }

  handleOrderPartList() {
    logEvent('begin_checkout', {value: this.state.totalCost});
    this.setState({
      orderCheckoutModalOpen: true,
    });
  }

  closeOrderCheckoutModal = () => {
    this.setState({
      orderCheckoutModalOpen: false,
    });
  };

  handleEstimateDownload = async (format = 'pdf', selectedBatchSizesOnly = true) => {
    await this.setState({
      csvLoading: format === 'csv',
      pdfLoading: format === 'pdf',
    });

    try {
      await downloadPartListCalculatedCosts(this.state.partListId, this.state.batchSizeIds, format === 'csv', selectedBatchSizesOnly);
    } catch (e) {
      console.error(e);
      this.props.enqueueSnackbar(this.props.t('errors:error_server_error_empty'), {variant: 'error'});
    }

    this.setState({
      csvLoading: false,
      pdfLoading: false,
    });
  };

  requestQuote = async () => {
    // Add confirmation dialogue to ask user if they want an email notification when the quote is ready
    const {isConfirmed, isDismissed} = await MySwal.fire({
      title: this.props.t('estimator:request_quote_confirmation_title'),
      text: this.props.t('estimator:request_quote_confirmation_text'),
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: this.props.t('estimator:request_quote_confirmation_send_email_button'),
      denyButtonText: this.props.t('estimator:request_quote_confirmation_no_email_button'),
      confirmButtonColor: this.props.theme.palette.success.main,
      denyButtonColor: this.props.theme.palette.primary.main,
      showCloseButton: true,
      customClass: {
        actions: 'request-quote-swal-actions',
      },
    });

    if (isDismissed) return;

    this.setState({globalLoading: true});
    try {
      await requestPartListQuote(this.state.partListId, isConfirmed);

      await this.reload();
    } catch (e) {
      console.error(e);

      this.props.enqueueSnackbar(this.props.t('errors:error_request_quote'), {
        variant: 'error',
        autoHideDuration: 8000,
        style: {
          maxWidth: 500,
        },
      });
      this.setState({globalLoading: false});
    }
  };

  render() {
    const {t} = this.props;

    return (
      <div>
        <Grid container justifyContent={'center'} alignItems={'center'} pt={0} p={3} mb={5} spacing={3}>
          <Grid container item xs={12} sm={8} spacing={2}>
            <Grid item xs={12}><Typography variant={'h4'}><b>{t('estimator:title')}</b></Typography></Grid>
            <Grid item xs={12}><Typography variant={'h5'}>{t('estimator:sub_title')}</Typography></Grid>
            <Grid item xs={12}><Typography variant={'body1'}>{t('estimator:sub_sub_title')}</Typography></Grid>
          </Grid>
          <Hidden smDown>
            <Grid item style={{textAlign: 'right'}} sm={4}>
              <img src={Logo} alt="logo" style={{width: '60%', minWidth: '200px', maxWidth: '300px'}} />
            </Grid>
          </Hidden>
          {this.state.globalLoading
            ? <Loading pageHeight={300} />
            : (
              !this.state.latestPartListQuoteId
                ? <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} spacing={2} mt={6}>
                  <Grid item xs={12} textAlign={'center'} style={{maxWidth: 450}}>
                    <Typography variant={'h5'}>{t('estimator:no_quote_description')}</Typography>
                  </Grid>
                  <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
                    <Button variant={'contained'} onClick={() => this.requestQuote()}>{t('estimator:request_quote')}</Button>
                  </Grid>
                </Grid>
                : (
                  !this.state.configs?.length ?
                    <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} spacing={2} mt={6} style={{maxWidth: 450}}>
                      <Grid item xs={12} textAlign={'center'}>
                        <Typography variant={'h5'}>{t('estimator:quoting_in_progress_description')}</Typography>
                      </Grid>
                      <Grid item xs={12} justifyContent={'center'} textAlign={'center'} mt={3}>
                        <LinearProgress />
                      </Grid>
                    </Grid> :
                    <>
                      <Grid item xs={12} container justifyContent={'center'} spacing={2}>
                        {
                          this.state.latestPartListQuoteExpired ?
                            <Grid container item xs={'auto'}>
                              <Paper style={{padding: '10px 15px', borderRadius: '10px', boxShadow: '0 3px 10px -2px rgba(0,0,0,0.47)', textAlign: 'center'}}>
                                <Grid item xs={12}>
                                  <Typography variant={'body1'} color={'error'}><b>{t('estimator:quote_expired_on')}: {toDisplayDate(this.state.latestPartListQuoteExpiryDatetime)}</b></Typography>
                                </Grid>
                                <Grid item xs={12} pt={1}>
                                  <Button variant={'contained'} color={'error'} onClick={() => this.requestQuote()}>{t('estimator:request_new_quote')}</Button>
                                </Grid>
                              </Paper>
                            </Grid> :
                            <>
                              <Grid container item xs={'auto'}>
                                <Paper style={{padding: '10px 15px', borderRadius: '10px', boxShadow: '0 3px 10px -2px rgba(0,0,0,0.47)', textAlign: 'center'}}>
                                  <Grid item xs={12}>
                                    <Typography variant={'body1'}><b>{this.state.loading ? t('estimator:estimating_lead_time') : this.state.leadTime ? `${t('estimator:lead_time', {lead_time: this.state.leadTime})}` : t(
                                      'estimator:no_lead_time')}</b></Typography>
                                  </Grid>
                                  {
                                    this.state.leadTime ?
                                      <Grid item xs={12}>
                                        <Typography variant={'body2'}><i>{t('estimator:earliest_delivery_date')}: {toDisplayDate(calculateDeliveryDateFromLeadTime(this.state.leadTime))}</i></Typography>
                                      </Grid> : <></>
                                  }
                                </Paper>
                              </Grid>
                              <Grid container item xs={'auto'}>
                                <Paper style={{padding: '10px 15px', borderRadius: '10px', boxShadow: '0 3px 10px -2px rgba(0,0,0,0.47)', textAlign: 'center'}}>
                                  <Grid item xs={12}>
                                    <Typography variant={'body1'}><b>{t('estimator:quote_valid_until')}: {toDisplayDate(this.state.latestPartListQuoteExpiryDatetime)}</b></Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <p><LinearProgress color={'success'} style={{marginTop: 8}} /></p>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </>
                        }
                      </Grid>
                      {this.state.latestPartListQuoteStatus !== 'quoting_completed' && (
                        <Grid item xs={12}>
                          <Paper>
                            <Alert severity={'info'}><b>{t('estimator:quoting_still_in_progress_message')}</b></Alert>
                          </Paper>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <PartListEstimatorTable
                          partList={this.state.partList}
                          configs={this.state.configs}
                          latestPartListQuoteStatus={this.state.latestPartListQuoteStatus}
                          totalCost={this.state.totalCost}
                          batchSizeIds={this.state.batchSizeIds}
                          setBatchSizeIds={batchSizeIds => this.setBatchSizeIds(batchSizeIds)}
                          loading={this.state.loading}
                        />
                      </Grid>
                      {this.hasData() &&
                        <Grid
                          item xs={12} style={{
                          position: 'fixed',
                          bottom: '0',
                          padding: '12px',
                        }}
                        >
                          <Tooltip arrow={true} title={t('estimator:tooltip_download')}>
                            <span>
                              <Fab
                                onClick={() => this.handleEstimateDownload()}
                                disabled={this.state.pdfLoading}
                                style={{
                                  backgroundColor: '#32bee1',
                                  margin: 5,
                                }}
                              >
                                {this.state.pdfLoading ?
                                  <CircularProgress style={{color: 'white'}} /> :
                                  <PictureAsPdf style={{color: 'white'}} />
                                }
                              </Fab>
                            </span>
                          </Tooltip>
                          {this.state.latestPartListQuoteStatus === 'quoting_completed' &&
                            <Tooltip arrow={true} title={t('estimator:request_new_quote')}>
                              <span>
                                <Fab
                                  onClick={() => this.requestQuote()}
                                  disabled={this.state.loading}
                                  style={{
                                    backgroundColor: '#32bee1',
                                    margin: 5,
                                  }}
                                >
                                  {this.state.loading ?
                                    <CircularProgress style={{color: 'white'}} /> :
                                    <Autorenew style={{color: 'white'}} />
                                  }
                                </Fab>
                              </span>
                            </Tooltip>
                          }
                          {/*<Tooltip arrow={true} title={t('estimator:tooltip_download_csv')}>*/}
                          {/*  <span>*/}
                          {/*    <Fab*/}
                          {/*      onClick={() => this.handleEstimateDownload()}*/}
                          {/*      disabled={this.state.csvLoading}*/}
                          {/*      style={{*/}
                          {/*        backgroundColor: '#32bee1',*/}
                          {/*        margin: 5,*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      {this.state.csvLoading ?*/}
                          {/*        <CircularProgress style={{color: 'white'}} /> :*/}
                          {/*        <Menu style={{color: 'white'}} />*/}
                          {/*      }*/}
                          {/*    </Fab>*/}
                          {/*  </span>*/}
                          {/*</Tooltip>*/}
                          {/*<Tooltip arrow={true} title={t('estimator:tooltip_download_csv_selected_batch_sizes_only')}>*/}
                          {/*  <span>*/}
                          {/*    <Fab*/}
                          {/*      onClick={() => this.handleEstimateDownload(true)}*/}
                          {/*      disabled={this.state.csvLoading}*/}
                          {/*      style={{*/}
                          {/*        backgroundColor: '#32bee1',*/}
                          {/*        margin: 5,*/}
                          {/*      }}*/}
                          {/*    >*/}
                          {/*      {this.state.csvLoading ?*/}
                          {/*        <CircularProgress style={{color: 'white'}} /> :*/}
                          {/*        <PlaylistAddCheck style={{color: 'white'}} />*/}
                          {/*      }*/}
                          {/*    </Fab>*/}
                          {/*  </span>*/}
                          {/*</Tooltip>*/}
                          <Tooltip arrow={true} title={t('estimator:order_part_list')}>
                            <span>
                              <Fab
                                onClick={() => this.handleOrderPartList()}
                                disabled={this.state.estimateLocking || !this.state.batchSizeIds.length || !this.state.leadTime}
                                style={{
                                  backgroundColor: '#32bee1',
                                  margin: 5,
                                }}
                              >
                                {this.state.loading ?
                                  <CircularProgress style={{color: 'white'}} /> :
                                  <ShoppingCart style={{color: 'white'}} />
                                }
                              </Fab>
                            </span>
                          </Tooltip>
                          <OrderCheckoutModal
                            open={this.state.orderCheckoutModalOpen}
                            onClose={this.closeOrderCheckoutModal}
                            configs={this.state.configs}
                            selectedBatchSizeIds={this.state.batchSizeIds}
                            totalCost={this.state.totalCost}
                            partList={this.state.partList}
                            leadTime={this.state.leadTime}
                            currentUserInfo={this.props.currentUserInfo}
                          />
                        </Grid>
                      }
                    </>
                )
            )
          }
        </Grid>
      </div>
    );
  }
}

export default withRouter(withSnackbar(withTranslation(['estimator', 'errors'])(connectPartListEstimatorTable(withTheme(PartListEstimator)))));
