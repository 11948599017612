/**
 * Calculate the free substep to be selected
 * @param {*} processes
 * @param {*} currentSubstep
 */
export const getFreeSubstepSpot = (processes, currentSubstep) => {
  const removeSpotCanBeApply = processes.length - 1 <= currentSubstep;
  const stepToChange = removeSpotCanBeApply
    ? currentSubstep
    : currentSubstep - 1;

  return stepToChange;
};

export const getProcessesCount = processes => {
  if (typeof processes === 'undefined' || processes === null) {
    return 0;
  }
  return processes.length;
};
