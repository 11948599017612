import React, {Component, createRef} from 'react';
import {
  TextField,
  InputAdornment,
  CircularProgress,
  Grid,
  Icon,
  Box,
  Typography,
  Popper,
  Autocomplete,
} from '@mui/material';
import {Search} from '@mui/icons-material';
import {withTranslation} from 'react-i18next';

import {globalSearch} from '../../services/general';
import {withRouter} from '../../utils/helpers';

class AppSearch extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();

    this.state = {
      loading: false,
      inputValue: '',
      searchResults: [],
    };
  }

  renderOption = (props, option) => {
    const {icon, url} = this.getIconAndUrl(option);

    return (
      <li {...props} key={`${option.class}-${option.id}`}>
        <Grid container alignItems={'center'} spacing={1} onClick={() => this.goToOptionUrl(url)}>
          <Grid item>
            <Icon>{icon}</Icon>
          </Grid>
          <Grid item xs>
            <Typography variant={'body2'}>{this.props.t(`cmn_${option.class}`)}</Typography>
            <Typography variant={'body1'}>{option.name && option.name.trim() || option.id}</Typography>
            {
              option.parentClass &&
              <Box fontWeight={400} fontSize={12} fontStyle={'italic'}>{this.props.t('cmn_from')} {this.props.t(`cmn_${option.parentClass}`)} {option.parentName}</Box>
            }
          </Grid>
        </Grid>
      </li>
    );
  };

  goToOptionUrl = url => {
    this.props.navigate(url);
  };

  getIconAndUrl = option => {
    let icon, url;
    switch (option.class) {
      case 'part':
        icon = 'precision_manufacturing';
        url = `/part/${option.id}`;
        break;
      case 'part_list':
        icon = 'dynamic_feed';
        url = `/part-lists/${option.id}`;
        break;
      case 'part_config':
        icon = 'settings';
        url = `/part/${option.parentId}?configId=${option.id}`;
        break;
      case 'attachment':
        icon = 'description';
        url = `/part/${option.parentId}`;
        break;
      case 'ordered_part_list':
        icon = 'shopping_cart';
        url = `/orders/${option.id}`;
        break;
    }

    return {icon, url};
  };

  handleInputChange = async (event, inputValue) => {
    if (inputValue === '') {
      return this.setState({
        loading: false,
        searchResults: [],
      });
    }

    try {
      await this.setState({
        loading: true,
      });

      const {data: {page: searchResults}} = await globalSearch({
        filter: {
          where: {combinedProperties: {ilike: `%${inputValue}%`}},
          limit: 25,
        },
      });

      await this.setState({
        loading: false,
        searchResults,
      });
    } catch (err) {
      console.error(err);
      await this.setState({
        loading: false,
      });
    }
  };

  handleOnEnterKey = (event, option) => {
    if (event.key === 'Enter' && option) {
      const {url} = this.getIconAndUrl(option);
      this.goToOptionUrl(url);
    }
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <Autocomplete
          freeSolo
          style={{width: 300}}
          loading={this.state.loading}
          loadingText={t('cmn_loading')}
          filterOptions={x => x}
          onInputChange={this.handleInputChange}
          onChange={this.handleOnEnterKey}
          options={this.state.searchResults}
          getOptionLabel={option => option.name || `${option.id}`}
          renderInput={params => (
            <TextField
              {...params}
              variant={'outlined'}
              size={'small'}
              ref={node => this.inputRef.current = node}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position={'end'} style={{marginLeft: 0}}>
                    {this.state.loading ? <CircularProgress color={'inherit'} size={20} style={{marginRight: 10}} /> : null}
                    <Search />
                  </InputAdornment>
                ),
                placeholder: `${t('cmn_search')}...`,
                type: 'search',
                style: {paddingRight: 12},
              }}
            />
          )}
          PopperComponent={props => (<Popper {...props} placement={'bottom-start'} style={{width: 600}} />)}
          renderOption={this.renderOption}
        />
      </>
    );
  }
}

export default withRouter(withTranslation('common')(AppSearch));
