import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import './CustomModal.scss';
import makeStyles from '@mui/styles/makeStyles';

/**
 * @param {*} {
 *   title - Modal title
 *   open - Open flag
 *   setOpen - Open flag setter
 *   actions - Action buttons (must have text, onCLick and variant)
 *   children - Children elements (automatically added by React)
 *   customStyles - Custom inline styles for the modal
 *   modalClasses - String of classes for the modal
 *   onCloseClick - Function invoked on close
 *   scrollDirection - Scroll direction enabler (can be one of x, y, xy or undefined)
 *   showXCloseButton - Flag for X button
 *   closeOnClickOutside - Close on overlay click
 * }
 */
export const CustomModal = ({
  title,
  open,
  setOpen,
  actions,
  children,
  customStyles,
  modalClasses,
  onCloseClick,
  scrollDirection,
  showXCloseButton,
  closeOnClickOutside,
}) => {
  const [modal, setModal] = useState(null);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    // Set modal hook on open or children change
    if (open) {
      setModal(render());
    } else {
      setModal(null);
    }
  }, [open, children]);

  const handleCloseOnClickOutside = event => {
    // Check if closeOnClickOutside is true and if overlay is clicked
    if (closeOnClickOutside && event.target.classList.contains('cm-overlay')) {
      setOpen(false);
    }
  };

  const handleCloseIconClick = () => {
    // Invoke close callback on close
    if (typeof onCloseClick !== 'undefined') {
      onCloseClick();
    }
    setOpen(false);
  };

  // Set classes for scroll direction enabling
  const getScrollDirectionClass = direction => {
    switch (direction) {
      case 'x':
        return 'cm-scrollableX';
      case 'y':
        return 'cm-scrollableY';
      case 'xy':
        return 'cm-scrollable';
      default:
        return '';
    }
  };

  const render = () => {
    return (
      <div className="cm-overlay" onClick={handleCloseOnClickOutside}>
        <div className={'cm-modal ' + modalClasses} style={{ ...customStyles }}>
          <div className="cm-header">
            {title && <h3 className="cm-title">{title}</h3>}
            {showXCloseButton && (
              <div
                className={'cm-close'}
                onClick={() => handleCloseIconClick()}
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <div
            className={`cm-content ${getScrollDirectionClass(scrollDirection)}`}
          >
            {children}
          </div>
          {actions && actions.length && (
            <div className="cm-actions">
              {actions.map(action => {
                return (
                  <Button
                    className={`${classes.button} ${action.color}`}
                    variant={action.variant}
                    onClick={action.onClick}
                    disabled={action.disabled}
                  >
                    {action.text}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return <>{modal}</>;
};
