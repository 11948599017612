var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt from 'jwt-simple';
const { REACT_APP_OAUTH_API } = process.env;
const cookies = new Cookies();
const kauthCookieName = `${process.env.REACT_APP_ENVIRONMENT}_kauth`;
export const handleUnauthorized = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = getToken();
    if (!(token === null || token === void 0 ? void 0 : token.refresh_token)) {
        loginAndRedirect();
    }
    try {
        const { data } = yield Axios.post(`${REACT_APP_OAUTH_API}/oauth/token`, {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            refresh_token: token.refresh_token,
            grant_type: 'refresh_token',
        }, { skipAuthRefresh: true });
        const encodedToken = jwt.encode(data, 'secret');
        const domain = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'localhost' : '.kreatize.com';
        cookies.set(kauthCookieName, encodedToken, { domain, maxAge: 604800, path: '/' });
    }
    catch (error) {
        loginAndRedirect();
    }
});
export const loginAndRedirect = () => {
    window.location.assign(`${process.env.REACT_APP_KAUTH_URL}/login?goto=${window.location.href}`);
};
export const logoutUser = () => {
    window.location.assign(`${process.env.REACT_APP_KAUTH_URL}/logout?goto=${window.location.href}`);
};
export const isAuthenticated = () => {
    try {
        const tokenData = getToken();
        return !!tokenData.access_token;
    }
    catch (error) {
        return false;
    }
};
/**
 * @description Get current logged user info
 */
export const getCurrentUserInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Axios({
        method: 'get',
        url: `${REACT_APP_OAUTH_API}/auth/users/current/info?timestamp=${Date.now()}`,
        headers: { Authorization: `Bearer ${getToken().access_token}` },
    });
    return response.data;
});
export const getToken = () => {
    const authCookie = cookies.get(kauthCookieName);
    if (!authCookie) {
        return false;
    }
    return jwt.decode(authCookie, 'secret');
};
export const changePassword = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const customAxiosConfig = {
            headers: {
                Authorization: `Bearer ${getToken().access_token}`,
            },
            skipAuthRefresh: true,
        };
        const response = yield Axios.post(`${REACT_APP_OAUTH_API}/auth/users/current/change-password`, payload, customAxiosConfig);
        if (response.status >= 200 && response.status < 300) {
            logoutUser();
        }
    }
    catch (error) {
        //@ts-ignore
        return error.response.status;
    }
});
export const changeUserLanguage = (language) => __awaiter(void 0, void 0, void 0, function* () {
    return Axios.patch(`${REACT_APP_OAUTH_API}/auth/users/current/language/${language}`, {
        headers: { Authorization: `Bearer ${getToken().access_token}` },
        skipAuthRefresh: true,
    });
});
