import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

const DeleteSubstepModal = ({ open, confirm, title }) => {
  const { t } = useTranslation('partconfigurator');
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    if (open) {
      deleteModal();
    } else {
      setAlert(null);
    }
  }, [open]);

  const hideAlert = isConfirmed => {
    if (typeof confirm === 'function') {
      confirm(isConfirmed);
    }
  };

  const deleteModal = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('pc_mdl_delete_subprocess_yes')}
        cancelBtnText={t('pc_mdl_delete_subprocess_cancel')}
        confirmBtnCssClass={`${classes.button} ${classes.danger}`}
        cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
        title={title}
      >
        {/* {t('mdl_delete_description')} */}
      </SweetAlert>
    );
  };

  return <>{alert}</>;
};

export default DeleteSubstepModal;
