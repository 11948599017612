import { saveProcess } from '../ConfigActions';
import { getProcessFeaturesValues } from 'services/configs';
import { getProcessFeatures } from 'services/processes';
import { mapProcessFeaturesToFormData } from '../Mappers/ConfigMapper';

/**
 * Save the process and return form data on success callback
 * @param {*} configId
 * @param {*} processSelection
 * @param {*} partConfigProcessId (OPTIONAL)
 * @param {*} onSuccess
 * @param {*} onError
 */
export const saveConfigProcess = (
  configId,
  processSelection,
  partConfigProcessId,
  typeOfProcess = 'post',
  onSuccess,
  onError
) => {
  let processData = {
    processSelection,
    process: {},
    formData: {},
  };

  saveProcess(configId, processSelection.id, partConfigProcessId, typeOfProcess)
    .then(res => {
      const partConfigProcess = { ...res.data };
      processData.process = { ...partConfigProcess };
      getProcessFeatures(partConfigProcess.processId)
        .then(res => {
          if (!res.data.length) {
            onSuccess({ ...processData });
            return;
          }
          const processFormSpec = [...res.data];
          getProcessFeaturesValues(configId, partConfigProcess.id)
            .then(res => res.data.filter(pfv => processFormSpec.some(pf => pf.key === pfv.processFeatureTypeKey)))
            .then(data => {
              const processFormValues = [...data];
              const formDataObj = mapProcessFeaturesToFormData(
                processFormSpec,
                processFormValues,
                processSelection.id,
                configId
              );
              processData.formData = { ...formDataObj };
              processData.processSelection = { ...processSelection };
              onSuccess({ ...processData });
            })
            .catch(error => {
              onError(error);
              console.error(error);
            });
        })
        .catch(error => {
          onError(error);
          console.error(error);
        });
    })
    .catch(error => {
      onError(error);
      console.error(error);
    });
};

/**
 * Save the process and return form data on success callback
 * @param {*} configId
 * @param {*} processId
 * @param {*} currentProcessId (OPTIONAL)
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getConfigProcessForm = (
  configId,
  process,
  processSelection,
  onSuccess,
  onError
) => {
  let processData = {
    process,
    processSelection,
    formData: {},
  };

  getProcessFeatures(process.processId)
    .then(res => {
      const processFormSpec = [...res.data];

      getProcessFeaturesValues(configId, process.id)
        .then(res => res.data.filter(pfv => processFormSpec.some(pf => pf.key === pfv.processFeatureTypeKey)))
        .then(data => {
          const processFormValues = [...data];
          const formDataObj = mapProcessFeaturesToFormData(
            processFormSpec,
            processFormValues,
            process.processId,
            configId
          );

          processData.formData = { ...formDataObj };
          onSuccess({ ...processData });
        })
        .catch(error => {
          onError(error);
          console.error(error);
        });
    })
    .catch(error => {
      onError(error);
      console.error(error);
    });
};
