import moment from 'moment';

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019
 * @param {*} date @type string
 */
export const toDisplayDate = timestampDate => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const lang = localStorage.getItem('lang');
  return new Intl.DateTimeFormat(lang, options).format(new Date(timestampDate));
};

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019 06:40am
 * @param {*} date @type string
 */
export const toDisplayDatetime = timestampDate => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const lang = localStorage.getItem('lang');
  return new Intl.DateTimeFormat(lang, options).format(new Date(timestampDate));
};

/**
 * @description Official format for displaying dates
 */
export const getDisplayFormatString = () => {
  return 'Do MMM YYYY';
};

/**
 * @description Convert ISO timestamp into app defined sortable format.
 * it's easer date for manipulation and sorting
 * @example 2019-08-21T06:51:22+00:00 to 2019-08-21
 * @param {*} date @type string
 */
export const toSortableDate = timestampDate => {
  return moment(timestampDate).format('YYYY-MM-DD');
};

/**
 * @description Check if the date is expired by 'now' moment
 */
export const isDateExpiredByNow = expirationDate => {
  const now = moment();
  const expirationDateTime = moment(expirationDate);
  const differenceInDays = now.diff(expirationDateTime, 'days');
  return differenceInDays > 0;
};
