import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {logEvent} from 'utils/firebase';

import {Container, Grid, Button, TextField} from '@mui/material';

import {
  getParts,
  getPartsSearch,
  selectConfig,
  removeConfig,
  saveConfigs,
  doCleanup,
} from 'containers/PartList/NewPartListActions';
import {
  snackbar,
} from 'store/snackbar/snackbarActions';

import FilterMenu from 'components/FilterMenu/FilterMenu';

import './PartListNewComponent.scss';
import {ConfirmModal} from 'components/Modals/ConfirmModal/ConfirmModal';
import {PartListNewConfigsView} from './PartListNewConfigsView/PartListNewConfigsView';

const DEFAULT_FILTERS = {
  limit: 5,
  order: 'partCreatedAt DESC',
  page: 1,
};

const filterInputs = [
  {name: 'partName', label: 'flt_part_name'},
  {name: 'partArticleNr', label: 'flt_article_number'},
  {name: 'partDrawingNr', label: 'flt_drawing_number'},
];

const Component = ({
  history,
  editableName,
  fetchAll,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation('partlists');

  const addMode = new URLSearchParams(location.search).get('addMode');

  const {data, list, selectedConfigs, loading, redirect} = useSelector(
    state => state.newPartList,
  );

  const partListState = useSelector(state => state.partList);

  const [listName, setListName] = useState(partListState && partListState.data && partListState.data.name);
  const [searchFilters] = useState({});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [listNameError, setListNameError] = useState(null);
  const listNameInputRef = useRef(null);

  const [partFilters] = useState({
    filter: {
      ...DEFAULT_FILTERS,
    },
  });

  const [fields, setFields] = useState({});

  const isFirstLoad = useRef(true);
  const [, setFilters] = useState({
    order: 'partCreatedAt DESC',
  });

  const handleListNameChange = event => {
    if (event.target.value.trim() === '') {
      setListName('');
      setListNameError(true);
    } else {
      setListName(event.target.value);
      setListNameError(false);
    }
  };

  const handleListPageChange = event => {
    let pageFilter = {
      filter: {
        ...partFilters.filter,
        ...searchFilters.filter,
      },
    };

    switch (event.target.value) {
      case '<<':
        pageFilter.filter.page = data.pagination.currentPage - 1;
        dispatch(getParts(pageFilter));
        break;
      case '>>':
        pageFilter.filter.page = data.pagination.currentPage + 1;
        dispatch(getParts(pageFilter));
        break;
      default:
        pageFilter.filter.page = event.target.value;
        dispatch(getParts(pageFilter));
        break;
    }
  };

  const handleToggleConfigSelect = event => {
    if (event.toggle) {
      dispatch(selectConfig(event.configId));
    } else {
      dispatch(removeConfig(event.configId));
    }
  };

  const handleSaveConfigsToList = () => {
    if (!listName) {
      return dispatch(snackbar(t('pl_create_please_enter_list_name'), 'warning'));
    }
    logEvent('create_new_part_list');
    dispatch(saveConfigs(listName, selectedConfigs));
  };

  useEffect(() => {
    dispatch(getParts({...partFilters}));

    filterInputs.forEach(input => {
      setFields({
        ...fields,
        [input.name]: '',
      });
    });

    return () => {
      dispatch(doCleanup());
    };
  }, []);

  const handleCancelListCreate = isConfirmed => {
    if (isConfirmed) {
      history.push('/part-lists');
    }

    setOpenConfirmModal(false);
  };

  const searchParts = filters => {
    isFirstLoad.current = false;
    if (filters) setFilters({...filters.filter});
    dispatch(getPartsSearch(filters));
  };

  useEffect(() => {
    if (redirect && list && list.id) {
      history.push(`/part-lists/${list.id}`);
    }
  }, [redirect, list]);

  return (
    <div className={'details-container'}>
      <Container className={'details-container'}>
        <Grid container justifyContent="flex-end">
          <FilterMenu
            search={searchParts}
            inputs={filterInputs}
            fields={fields}
            setFields={setFields}
            isForParts
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            {editableName ? (
              <TextField
                autoFocus
                fullWidth
                value={listName}
                inputRef={listNameInputRef}
                error={listNameError}
                inputProps={{
                  type: 'text',
                  placeholder: t('name_for_new_part_list'),
                }}
                onChange={event => handleListNameChange(event)}
                className="list-name"
              />
            ) : (
              <h4>{`${t('pl_list_name')}: ${listName}`}</h4>
            )}
          </Grid>
          <Grid item xs={6} md={8} container justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => setOpenConfirmModal(true)}
              className="cl-action-buttons"
            >
              {t('pl_create_list_cancel_button')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleSaveConfigsToList}
              className="cl-action-buttons"
            >
              {addMode
                ? t('pl_add_to_list_button')
                : selectedConfigs && selectedConfigs.length
                  ? t('pl_create_list_button')
                  : t('pl_create_list_empty_button')}
            </Button>
          </Grid>
        </Grid>
        <Grid container style={{paddingTop: '12px'}}>
          <PartListNewConfigsView
            loading={loading}
            data={data}
            fetchAll={fetchAll}
            handleListPageChange={handleListPageChange}
            handleToggleConfigSelect={handleToggleConfigSelect}
            selectableConfigs
            canAddConfig
            hideRemove
          />
        </Grid>
      </Container>
      <ConfirmModal
        title={t('pl_create_list_cancel_title')}
        description={t('pl_create_list_cancel_description')}
        open={openConfirmModal}
        confirm={handleCancelListCreate}
      />
    </div>
  );
};

export const PartListNewComponent = Component;
