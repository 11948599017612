import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { createList } from 'services/part-lists';
import { assignPartsToList } from 'services/part-lists';

const useStyles = makeStyles(styles);

export const CreateShareListModal = ({
  open,
  confirm,
  setOpen,
  partIds,
  next,
}) => {
  const { t } = useTranslation(['modals', 'errors']);
  const classes = useStyles();

  const [listName, setListName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [listNameError, setListNameError] = useState({
    error: false,
    msg: '',
  });
  // Button will be disabled on form submit.
  const [, setDisableSaveBtn] = useState(false);

  useEffect(() => {
    if (!open) {
      setListName('');
      resetValidation();
    }

    if (partIds.length === 1) {
      setListName(partIds[0].name);
    }
  }, [open]);

  const closeAlert = () => {
    setOpen(false);
  };

  const resetValidation = () => {
    setListNameError({ error: false, msg: '' });
  };

  const confirmAlert = async () => {
    setLoading(false);
    resetValidation();
    if (listName.trim().length === 0) {
      setListNameError({
        error: true,
        msg: t('errors:error_share_list_field_required'),
      });
      return;
    }
    setDisableSaveBtn(true);
    setLoading(true);

    const createResult = await createList({ name: listName.trim() }).catch(
      () => {
        setListNameError({
          error: true,
          msg: t('errors:error_server_create_list'),
        });
        setDisableSaveBtn(false);
      }
    );

    if (createResult.status === 200) {
      const ids = partIds.map(part => part.id);
      const listData = createResult.data;
      await assignPartsToList({
        listId: listData.id,
        partIds: ids,
      }).catch(() => {
        setListNameError({
          error: true,
          msg: t('errors:error_server_create_list'),
        });
      });
    }

    setLoading(false);
    setDisableSaveBtn(false);
    confirm.current = { ...createResult.data };
    next();
  };

  const handleListNameInput = value => {
    setListName(value);
  };

  return (
    <>
      {open && (
        <SweetAlert
          disabled={isLoading}
          showCancel
          closeOnClickOutside={false}
          onConfirm={() => confirmAlert()}
          onCancel={() => closeAlert()}
          confirmBtnText={t('mdl_share_create_confirm_lbl')}
          cancelBtnText={t('mdl_general_cancel')}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.cancel}
          title={''}
        >
          <h3>{t('mdl_share_create_list_title')}</h3>
          <Grid item xs={12}>
            {isLoading && (
              <>
                <br></br>
                <CircularProgress />
              </>
            )}
            <TextField
              label={t('mdl_create_share_list_name_inp')}
              error={listNameError.error}
              type="text"
              fullWidth
              helperText={listNameError.msg}
              value={listName || ''}
              onChange={event => {
                setListNameError({ error: false, msg: '' });
                handleListNameInput(event.target.value);
              }}
            />
          </Grid>
        </SweetAlert>
      )}
    </>
  );
};
