import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Autocomplete, Grid, InputAdornment, TextField} from '@mui/material';
import {withSnackbar} from 'notistack';
import {editPart, fetchFeatures, putPartFeature} from '../../../services/parts';

class PartDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      articleNr: this.props.part.articleNr?.trim() || '-',
      drawingNr: this.props.part.drawingNr?.trim() || '-',
      partFeatures: [],
    };
  }

  async componentDidMount() {
    // Get part features with their select options
    try {
      const {data: features} = await fetchFeatures({filter: {include: {partFeatureSelectOptions: true}}});

      const partPartFeatures = {};
      features.map(feature => {
        partPartFeatures[feature.key] = this.props.part.features.find(ppf => ppf.partFeatureKey === feature.key) || {partFeatureKey: feature.key, value: null};
      });

      await this.setState({
        partFeatures: features,
        ...partPartFeatures,
        loading: false,
      });
    } catch (err) {
      console.error(err);
    }
  }

  editPart = async (fieldToEdit, value, saveChanges = false) => {
    await this.setState({
      [fieldToEdit]: saveChanges ? value.trim() || '-' : value,
    });

    if (saveChanges) {
      try {
        await editPart(this.props.part.id, {
          [fieldToEdit]: value.trim() || null,
        });
      } catch (err) {
        console.error(err);
        this.props.enqueueSnackbar(this.props.t('general_error_title'), {variant: 'error'});
      }
    }
  };

  editPartFeature = async (partFeatureKey, value, saveChanges = false) => {
    await this.setState({
      [partFeatureKey]: {
        ...this.state[partFeatureKey],
        value: value.trim(),
      },
    });

    if (saveChanges) {
      try {
        await putPartFeature(this.props.part.id, partFeatureKey, {
          value: value || null,
        });
      } catch (err) {
        console.error(err);
      }
    }

  };

  render() {
    const {t} = this.props;

    return <Grid container spacing={3} sx={{maxWidth: '50vw', paddingBottom: '24px'}}>
      <Grid item xs={12}>
        <h3>{t('pdi_part_details')}</h3>
      </Grid>
      {!this.state.loading && <>
        {/*Article & Drawing Number*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t('pdi_article_number')}
              value={this.state.articleNr}
              fullWidth
              onChange={e => this.editPart('articleNr', e.target.value, false)}
              onBlur={e => this.editPart('articleNr', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 255}}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('pdi_drawing_number')}
              value={this.state.drawingNr}
              fullWidth
              onChange={e => this.editPart('drawingNr', e.target.value, false)}
              onBlur={e => this.editPart('drawingNr', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              inputProps={{maxLength: 255}}
            />
          </Grid>
        </Grid>
        {/*Length, Width & Height*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:length')}
              fullWidth
              type={'number'}
              value={this.state['length']?.value || this.state['length']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'length')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('length', e.target.value, false)}
              onBlur={e => this.editPartFeature('length', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:width')}
              fullWidth
              type={'number'}
              value={this.state['width']?.value || this.state['width']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'width')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('width', e.target.value, false)}
              onBlur={e => this.editPartFeature('width', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:height')}
              fullWidth
              type={'number'}
              value={this.state['height']?.value || this.state['height']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'height')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('height', e.target.value, false)}
              onBlur={e => this.editPartFeature('height', e.target.value, true)}
            />
          </Grid>
        </Grid>
        {/*Thickness, Inner & Outer Diameter*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:thickness')}
              fullWidth
              type={'number'}
              value={this.state['thickness']?.value || this.state['thickness']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'thickness')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('thickness', e.target.value, false)}
              onBlur={e => this.editPartFeature('thickness', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:inner_diameter')}
              fullWidth
              type={'number'}
              value={this.state['inner_diameter']?.value || this.state['inner_diameter']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'inner_diameter')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('inner_diameter', e.target.value, false)}
              onBlur={e => this.editPartFeature('inner_diameter', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:outer_diameter')}
              fullWidth
              type={'number'}
              value={this.state['outer_diameter']?.value || this.state['outer_diameter']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'outer_diameter')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('outer_diameter', e.target.value, false)}
              onBlur={e => this.editPartFeature('outer_diameter', e.target.value, true)}
            />
          </Grid>
        </Grid>
        {/*Surface Area & Volume*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:surface_area')}
              fullWidth
              type={'number'}
              value={this.state['surface_area']?.value || this.state['surface_area']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'surface_area')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('surface_area', e.target.value, false)}
              onBlur={e => this.editPartFeature('surface_area', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:volume')}
              fullWidth
              type={'number'}
              value={this.state['volume']?.value || this.state['volume']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'volume')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('volume', e.target.value, false)}
              onBlur={e => this.editPartFeature('volume', e.target.value, true)}
            />
          </Grid>
        </Grid>
        {/*Weight & Shape*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:weight')}
              fullWidth
              type={'number'}
              value={this.state['weight']?.value || this.state['weight']?.valueAuto}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'weight')?.unitKey}`)}</InputAdornment>,
              }}
              InputLabelProps={{shrink: true}}
              onChange={e => this.editPartFeature('weight', e.target.value, false)}
              onBlur={e => this.editPartFeature('weight', e.target.value, true)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={this.state.partFeatures.find(pf => pf.key === 'shape')?.partFeatureSelectOptions.map(o => o.selectOptionKey) || []}
              getOptionLabel={option => t(`shapes:${option}`)}
              renderInput={params => (
                <TextField {...params} fullWidth label={t('partfeatures:shape')} />
              )}
              value={this.state['shape']?.value || this.state['shape']?.valueAuto || ''}
              onChange={(e, newValue) => this.editPartFeature('shape', newValue, true)}
              disableClearable
            />
          </Grid>
        </Grid>
      </>}
      <Grid item xs={12}>
        <h3>{t('pdi_part_tolerances')}</h3>
      </Grid>
      {!this.state.loading && <>
        {/*Edge Breaks*/}
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <h4>{t('partfeatures:inner_edge_break')}</h4>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:inner_edge_break_min')}
              value={this.state['inner_edge_break_min']?.value || this.state['inner_edge_break_min']?.valueAuto || ''}
              fullWidth
              type={'number'}
              onChange={e => this.editPartFeature('inner_edge_break_min', e.target.value, false)}
              onBlur={e => this.editPartFeature('inner_edge_break_min', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'inner_edge_break_min')?.unitKey}`)}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:inner_edge_break_max')}
              value={this.state['inner_edge_break_max']?.value || this.state['inner_edge_break_max']?.valueAuto || ''}
              fullWidth
              type={'number'}
              onChange={e => this.editPartFeature('inner_edge_break_max', e.target.value, false)}
              onBlur={e => this.editPartFeature('inner_edge_break_max', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'inner_edge_break_max')?.unitKey}`)}</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <h4>{t('partfeatures:outer_edge_break')}</h4>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:outer_edge_break_min')}
              value={this.state['outer_edge_break_min']?.value || this.state['outer_edge_break_min']?.valueAuto || ''}
              fullWidth
              type={'number'}
              onChange={e => this.editPartFeature('outer_edge_break_min', e.target.value, false)}
              onBlur={e => this.editPartFeature('outer_edge_break_min', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              InputProps={{
                step: 'any',
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'outer_edge_break_min')?.unitKey}`)}</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t('partfeatures:outer_edge_break_max')}
              value={this.state['outer_edge_break_max']?.value || this.state['outer_edge_break_max']?.valueAuto || ''}
              fullWidth
              type={'number'}
              onChange={e => this.editPartFeature('outer_edge_break_max', e.target.value, false)}
              onBlur={e => this.editPartFeature('outer_edge_break_max', e.target.value, true)}
              InputLabelProps={{shrink: true}}
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>{t(`partfeatures:unit_${this.state.partFeatures.find(pf => pf.key === 'outer_edge_break_max')?.unitKey}`)}</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </>}
    </Grid>;
  }
}

export default withSnackbar(withTranslation(['partdetails', 'services', 'processes', 'materials', 'partfeatures', 'shapes'])(PartDetailsForm));
