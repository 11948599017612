import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Popover, IconButton, TextField, Tooltip, Switch, FormControlLabel} from '@mui/material';

import Button from 'components/CustomButtons/Button.js';

import FilterListIcon from '@mui/icons-material/FilterList';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import './FilterMenu.scss';
import {useSearchParams} from 'react-router-dom';

export default function FilterMenu({
  inputs,
  search,
}) {
  const {t} = useTranslation(['partstorage', 'partdetails', 'filters']);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fields, setFields] = useState({});

  const open = Boolean(anchorEl);

  const [searchParams] = useSearchParams();
  const urlSearchParams = useRef(searchParams);

  useEffect(() => {
    checkQueryParams();
  }, [searchParams]);

  useEffect(() => {
    if (!checkQueryParams()) {
      submit();
    }
  }, []);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (name, value) => {
    if (!value) {
      const _fields = {...fields};
      delete _fields[name];
      setFields(_fields);
      return;
    }

    setFields({...fields, [name]: value});
  };

  const submit = fields => {
    setAnchorEl(null);
    search(fields);
  };

  const checkQueryParams = () => {
    if (urlSearchParams?.current?.has('filter')) {
      try {
        const _filter = JSON.parse(urlSearchParams.current.get('filter')?.trim());
        const _filterFields = _filter.and || _filter.or || [_filter];
        const _fields = {};
        for (const _field of _filterFields) {
          for (const _fieldKey in _field) {
            if (_fieldKey === 'statusKey') {
              _fields[_field[_fieldKey]] = true;
            } else {
              _fields[_fieldKey] = _field[_fieldKey];
            }
          }
        }
        if (Object.keys(_fields).length) {
          setFields(_fields);
          submit(_fields);
          return true;
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const reset = () => {
    setFields({});
    submit();
  };

  return <>
    <Tooltip title={t('partdetails:pdf_filters')}>
      <IconButton className="filter-button" onClick={handleOpen} size="large">
        <FilterListIcon className="filter-toggle-icon" />
      </IconButton>
    </Tooltip>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="filter-wrapper">
        {inputs.map((input, index) => (
          <div key={input.name + index} className="filter-input">
            {
              input.type === 'boolean' ?
                <FormControlLabel
                  control={<Switch
                    onChange={event =>
                      handleInputChange(input.name, event.target.checked)
                    }
                    checked={fields[input.name] || false}
                    color="default"
                  />}
                  label={t(`filters:${input.label}`)}
                /> :
                <TextField
                  id="float"
                  label={t(`filters:${input.label}`)}
                  onChange={event =>
                    handleInputChange(input.name, event.target.value)
                  }
                  value={fields[input.name]}
                  fullWidth
                />
            }
          </div>
        ))}
        <div className="filter-controls">
          <Button onClick={reset}>
            <RotateLeftIcon className="filter-icon" />
            {t('filter_button_reset')}
          </Button>
          <Button onClick={() => submit(fields)} color="info">
            <FilterListIcon className="filter-icon" />
            {t('filter_button_confirm')}
          </Button>
        </div>
      </div>
    </Popover>
  </>;
}
