import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {CircularProgress, Grid, Grow, Typography} from '@mui/material';
import {withTheme} from '@mui/styles';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import TargetPart from './DragAndDrop/TargetPart';
import {reassignAttachmentToPart, unassignAttachmentFromPart} from 'services/attachments';
import {createPart, editPart} from 'services/parts';
import {assignPartToList} from 'services/part-lists';
import {unassignPartFormList} from 'services/part-lists';
import {Alert} from '@mui/material';
import {withSnackbar} from 'notistack';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class ArrangeAttachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  refreshPartList = async () => {
    // Update the state
    this.props.refreshPartList();
  };

  onDragEnd = async ({draggableId, source, destination}) => {
    if (!destination || destination?.droppableId === source.droppableId) {
      return;
    }

    await this.setState({
      loading: true,
    });

    try {
      if (destination.droppableId === 'new_part') {
        const part = (this.props.partList.partConfigs || this.props.partList.partListPartConfigs.map(plpc => plpc.partConfig))
          .find(pc => pc.partId.toString() === source.droppableId).part;

        const name = (part.attachments || part.partAttachments.map(pa => pa.attachment))
          .find(a => a.id.toString() === draggableId).name;

        const {data: newPart} = await createPart({name});

        await assignPartToList({
          listId: this.props.partList.id,
          partId: newPart.id,
        });

        destination.droppableId = `${newPart.id}`;
      }
      // Reassign the attachment
      await reassignAttachmentToPart(draggableId, destination.droppableId, false);

      await this.refreshPartList();
    } catch (e) {
      if (e?.response?.status === 420) {
        const {isConfirmed} = await MySwal.fire({
          title: <Typography variant={'h5'}>{this.props.t('invalidate_ma_force')}</Typography>,
          icon: 'warning',
          confirmButtonText: '✓',
          showCancelButton: true,
          cancelButtonText: this.props.t('partlistdetails:general_cancel'),
          reverseButtons: true,
          confirmButtonColor: this.props.theme.palette.primary.main,
        });

        if (isConfirmed) {
          // Reassign the attachment
          await reassignAttachmentToPart(draggableId, destination.droppableId, true);
          await this.refreshPartList();
        }

        await this.setState({
          loading: false,
        });

        return;
      }

      console.error(e);
      this.props.enqueueSnackbar(this.props.t('unknown_error'), {
        variant: 'error',
      });
    }

    await this.setState({
      loading: false,
    });
  };

  removePartFromPartList = async partId => {
    await unassignPartFormList({
      listId: this.props.partList.id,
      partId,
    });

    await this.refreshPartList();
  };

  removeAttachmentFromPart = async (partId, attachmentId) => {
    try {
      await unassignAttachmentFromPart(attachmentId, partId);
    } catch (err) {
      if (err?.response?.status === 420) {
        const {isConfirmed} = await MySwal.fire({
          title: <Typography variant={'h5'}>{this.props.t('partlistdetails:plpd_invalidate_ma_force')}</Typography>,
          icon: 'warning',
          confirmButtonText: '✓',
          showCancelButton: true,
          cancelButtonText: this.props.t('partlistdetails:general_cancel'),
          reverseButtons: true,
          confirmButtonColor: this.props.theme.palette.primary.main,
        });

        if (isConfirmed) {
          // Reassign the attachment
          await unassignAttachmentFromPart(attachmentId, partId, true);
          await this.refreshPartList();
        }

        await this.setState({
          loading: false,
        });

        return;
      }

      console.error(err);
      this.props.enqueueSnackbar(this.props.t('unknown_error'), {
        variant: 'error',
      });
    }

    await this.refreshPartList();
  };

  /**
   * Renames a part
   * @throws {Error} ⚠️ This method will throw any errors it receives to the caller!
   */
  renamePart = async (partId, partName) => {
    try {
      await editPart(partId, {
        name: partName,
      });
    } catch (e) {
      console.error(e);
      this.props.enqueueSnackbar(this.props.t('unknown_error'), {
        variant: 'error',
      });
      throw e;
    }

    await this.refreshPartList();
  };

  render() {
    const {t, partList} = this.props;

    return <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          display: this.state.loading ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(150, 150, 150, .3)',
          zIndex: 999999,
        }}
      >
        <CircularProgress
          color={'primary'}
          size={60}
        />
      </div>
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Grow in={true} timeout={650}>
          <Grid container rowSpacing={2}>
            {(partList?.partConfigs ? partList.partConfigs : partList.partListPartConfigs.map(plpc => plpc.partConfig)).reduce((parts, partConfig) => {
              if (!parts.some(p => p.id === partConfig.partId)) parts.push(partConfig.part);
              return parts;
            }, []).map(part => (
              <Grid item xs={12} style={{margin: 0, paddingLeft: 0}} key={part.id}>
                <TargetPart
                  part={part}
                  removePartFromPartList={this.removePartFromPartList}
                  renamePart={this.renamePart}
                  removeAttachmentFromPart={this.removeAttachmentFromPart}
                />
              </Grid>
            ))}
            <Grid item xs={12} style={{margin: 0, paddingLeft: 0}}>
              <Droppable droppableId={'new_part'}>
                {(provided, snapshot) => (
                  <Alert
                    icon={false}
                    variant={snapshot.isDraggingOver ? 'filled' : 'outlined'}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: 72,
                      border: '1px dashed',
                    }}
                  >
                    <Typography variant={'body1'}>{t('create_new_part')}</Typography>
                    <span style={{display: 'none'}}>{provided.placeholder}</span>
                  </Alert>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Grow>
      </DragDropContext>
    </div>;
  }
}

export default withTheme(withSnackbar(withTranslation(['automatic_configuration', 'partlistdetails'])(ArrangeAttachments)));
