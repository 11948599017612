import React, {Component} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Card, CardContent, Chip, Collapse, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {CancelOutlined, ContentCopy, DragHandle, EditOutlined, KeyboardArrowDown, KeyboardArrowUp, OpenInNew, RemoveCircleOutline, AddCircleOutline} from '@mui/icons-material';
import {constructPartThumbnailUrl, editPart} from '../../services/parts';
import noPreview from '../../assets/img/no-preview.jpg';
import {withTranslation} from 'react-i18next';
import ClickToEditTextField from '../ClickToEditTextField/ClickToEditTextField';
import {withSnackbar} from 'notistack';
import CloneConfigModal from '../Modals/CloneConfigModal/CloneConfigModal';
import {withTheme} from '@mui/styles';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {unassignConfigFromList, assignConfigsToList, rearrangePartConfigs} from 'services/part-lists';
import {createEmptyConfig} from 'services/configs';
import PartConfigDetailsTable from './PartConfigDetailsTable';
import {cancelManufacturingAnalysis} from '../../services/configs';
import StatusChip from '../StatusChip/StatusChip';
import {NavLink} from 'react-router-dom';
import {ResizeDetector, withRouter} from '../../utils/helpers';

const MySwal = withReactContent(Swal);

class DraggablePartConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.partConfig.part.name?.trim(),
      articleNr: this.props.partConfig.part.articleNr?.trim() || '-',
      drawingNr: this.props.partConfig.part.drawingNr?.trim() || '-',
      configsModalOpen: false,
      cloneConfigModalOpen: false,
      enableSave: true,
      showConfigDetails: false,
      configDetailsDisplay: 'none',
      articleNrTextWidth: 0,
      drawingNrTextWidth: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partConfig !== this.props.partConfig) {
      this.setState({
        name: this.props.partConfig.part.name?.trim(),
        articleNr: this.props.partConfig.part.articleNr?.trim() || '-',
        drawingNr: this.props.partConfig.part.drawingNr?.trim() || '-',
      });
    }
  }

  setThumbnailToNoPreviewAvailable = partConfigId => {
    document.getElementById(`part-config-${partConfigId}-thumbnail`).src = noPreview;
  };

  editPart = async (fieldToEdit, value, saveChanges = false) => {
    this.setState({
      [fieldToEdit]: value,
    });

    if (saveChanges) {
      try {
        await editPart(this.props.partConfig.part.id, {
          [fieldToEdit]: value.replace('-', '') === '' ? null : value,
        });

        if (value === '') {
          this.setState({
            [fieldToEdit]: '-',
          });
        }

        this.props.updatePartConfigInState({
          ...this.props.partConfig,
          part: {
            ...this.props.partConfig.part,
            [fieldToEdit]: value,
          },
        });
      } catch (err) {
        console.error(err);
        this.props.enqueueSnackbar(this.props.t('general_error_title'), {
          variant: 'error',
        });

        // Restore the original value
        this.setState({
          [fieldToEdit]: this.props.partConfig.part[fieldToEdit]?.trim() || '-',
        });
      }
    }
  };

  toggleStateVar = stateVar => {
    this.setState({
      [stateVar]: !this.state[stateVar],
    });
  };

  handleAddingNewEmptyConfig = async () => {
    try {
      // Create a new empty configuration
      const {data: config} = await createEmptyConfig(this.props.partConfig.partId);
      // Assign that new config to the list
      await assignConfigsToList({
        listId: this.props.partListId,
        partConfigIdsList: [config.id],
      });
      // Rearrange the order of the part list
      const _allPartConfigIds = this.props.allPartConfigs.map(c => c.id);
      _allPartConfigIds.splice(_allPartConfigIds.indexOf(this.props.partConfig.id) + 1, 0, config.id);
      await rearrangePartConfigs(this.props.partListId, _allPartConfigIds);
      return this.props.refreshPartList();
    } catch (e) {
      console.error(e);
      this.props.enqueueSnackbar(this.props.t('general_error_title'), {
        variant: 'error',
      });
    }
  };

  handleRemoveConfigFromList = async () => {
    const {t, theme} = this.props;

    await MySwal.fire({
      title: <Typography variant={'h5'}>{t('partconfigurations:t_remove_config_title')}</Typography>,
      html: <Typography variant={'body1'}>{t('partconfigurations:t_remove_config_description')}</Typography>,
      icon: 'warning',
      confirmButtonText: t('modals:mdl_general_remove'),
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: t('general_cancel'),
      reverseButtons: true,
      showCloseButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: theme.palette.error.main,
      cancelButtonColor: theme.palette.secondary.main,
      preConfirm: async () => {
        try {
          await unassignConfigFromList({
            listId: this.props.partListId,
            configId: this.props.partConfig.id,
          });
          await this.props.refreshPartList();
        } catch (err) {
          console.error(err);
          this.props.enqueueSnackbar(this.props.t('general_error_title'), {
            variant: 'error',
          });
          return false;
        }
      },
    });
  };

  toggleShowConfigDetails = () => {
    this.setState({
      showConfigDetails: !this.state.showConfigDetails,
    });
  };

  setTextWidth = (property, width) => {
    this.setState({
      [`${property}TextWidth`]: width,
    });
  };

  openConfigurator = () => {
    const {searchParams, setSearchParams} = this.props;

    setSearchParams([
      ...Array.from(searchParams).filter(([key]) => key !== 'editPartConfig'),
      ['editPartConfig', this.props.partConfig.id.toString()],
    ]);
  };

  cancelManufacturingAnalysis = async () => {
    try {
      await cancelManufacturingAnalysis(this.props.partConfig.id);

      this.props.updatePartConfigTeaserInState({
        ...this.props.partConfigTeaser,
        maRunning: false,
      });
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('general_error_title'), {
        variant: 'error',
      });
    }
  };

  render() {
    const {partConfig, index, t, refreshPartList, partConfigTeaser, allPartConfigs, partListId} = this.props;

    const mainProcesses = partConfig?.partConfigProcesses.filter(p => p.process.class === 'process').map(p => p.process.key) || [];
    const batchSizes = partConfig?.batchSizes.map(bs => bs.batchSize) || [];

    return <Grid
      item
      xs={12}
      style={{margin: 0, paddingLeft: 0}}
      key={`part_config_${partConfig.id}`}
    >
      <Draggable draggableId={`${partConfig.id}`} index={index} key={partConfig.id}>
        {provided => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <CardContent style={{padding: 16}}>
              <Grid
                container
                rowSpacing={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid item xs={2} container rowSpacing={1} alignItems={'center'} justifyContent={'center'}>
                  <Grid item xs={2}>
                    <IconButton size={'small'} onClick={this.toggleShowConfigDetails}>
                      {this.state.showConfigDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <img
                      id={`part-config-${partConfig.id}-thumbnail`}
                      src={constructPartThumbnailUrl(partConfig.partId)}
                      alt="Part Config Thumbnail"
                      style={{
                        height: 120,
                        maxWidth: '100%',
                        backgroundSize: 'contain',
                      }}
                      onError={() => this.setThumbnailToNoPreviewAvailable(partConfig.id)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={10} container justifyContent={'space-between'} rowSpacing={1}>
                  <Grid item xs={12} container alignItems={'center'} spacing={1}>
                    <Grid item sx={{maxWidth: 5 / 18}}>
                      <ClickToEditTextField
                        value={this.state.name}
                        onChange={value => this.editPart('name', value)}
                        onBlur={value => this.editPart('name', value, true)}
                        fontSize={'1.2rem'}
                        fontWeight={500}
                        maxLength={255}
                      />
                    </Grid>
                    <Grid item sx={{maxWidth: 1 / 18}}>
                      <Tooltip title={t('partconfigurations:go_to_part')}>
                        <NavLink to={`/part/${partConfig.partId}`}><IconButton size={'small'}><OpenInNew /></IconButton></NavLink>
                      </Tooltip>
                    </Grid>
                    <Grid item sx={{maxWidth: 1 / 3}}>
                      <div
                        style={{
                          border: '1px solid #656D79',
                          borderRadius: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 550,
                          fontSize: '0.8125rem',
                          padding: '2px 8px',
                          color: '#656D79',
                        }}
                      >
                        <div>
                          <ResizeDetector handleHeight={false} onResize={width => this.setTextWidth('articleNr', width)} />
                          <span style={{whiteSpace: 'nowrap'}}>{t('partconfigurations:article_number')}:&nbsp;</span>
                        </div>
                        <div style={{maxWidth: `calc(100% - ${this.state.articleNrTextWidth}px)`}}>
                          <ClickToEditTextField
                            value={this.state.articleNr}
                            maxLength={255}
                            onChange={value => this.editPart('articleNr', value)}
                            onBlur={value => this.editPart('articleNr', value, true)}
                            disableUnderline
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item sx={{maxWidth: 1 / 3}}>
                      <div
                        style={{
                          border: '1px solid #656D79',
                          borderRadius: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontWeight: 550,
                          fontSize: '0.8125rem',
                          padding: '2px 8px',
                          color: '#656D79',
                        }}
                      >
                        <div>
                          <ResizeDetector handleHeight={false} onResize={width => this.setTextWidth('drawingNr', width)} />
                          <span style={{whiteSpace: 'nowrap'}}>{t('partconfigurations:drawing_number')}:&nbsp;</span>
                        </div>
                        <div style={{maxWidth: `calc(100% - ${this.state.drawingNrTextWidth}px)`}}>
                          <ClickToEditTextField
                            value={this.state.drawingNr}
                            maxLength={255}
                            onChange={value => this.editPart('drawingNr', value)}
                            onBlur={value => this.editPart('drawingNr', value, true)}
                            disableUnderline
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={4} style={{fontStyle: 'italic', fontSize: '1rem'}}>
                      {t('partconfigurations:t_config')}: {partConfig.name?.trim().length ? partConfig.name.trim() : partConfig.id}
                    </Grid>
                    <Grid item xs={8} alignItems={'center'} justifyContent={'flex-end'} container columnSpacing={2}>
                      <Grid item xs={'auto'}><Typography variant={'body1'}>
                        <StatusChip data={partConfigTeaser} size={'medium'} />
                      </Typography></Grid>
                      <Grid item xs={'auto'}>
                        {partConfigTeaser.maRunning ? (
                          <Tooltip title={t('partconfigurations:cancel_manufacturing_analysis')}>
                            <IconButton size={'small'} onClick={() => this.cancelManufacturingAnalysis()}><CancelOutlined /></IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('partconfigurations:edit_config')}>
                            <IconButton size={'small'} onClick={() => this.openConfigurator()}><EditOutlined /></IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={t('partconfigurations:clone_config')}>
                          <IconButton size={'small'} onClick={() => this.toggleStateVar('cloneConfigModalOpen')}><ContentCopy /></IconButton>
                        </Tooltip>
                        <Tooltip title={t('partconfigurations:add_new_empty_config')}>
                          <IconButton size={'small'} onClick={this.handleAddingNewEmptyConfig}><AddCircleOutline /></IconButton>
                        </Tooltip>
                        <Tooltip title={t('partconfigurations:delete_config')}>
                          <IconButton size={'small'} onClick={this.handleRemoveConfigFromList}><RemoveCircleOutline /></IconButton>
                        </Tooltip>
                        <Tooltip title={t('partconfigurations:drag_and_drop_to_rearrange')}>
                          <IconButton size={'small'} {...provided.dragHandleProps}><DragHandle /></IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={1} alignItems={'center'}>
                    <Grid item>
                      <Chip
                        variant={'filled'}
                        color={'primary'}
                        label={`${t('partconfigurations:t_material_name')}: ${partConfig.materialName || '-'}`}
                        style={{color: 'white', fontWeight: 550}}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        variant={'filled'}
                        color={'primary'}
                        label={`${t('partconfigurations:t_batch_sizes')}: ${batchSizes.length ? batchSizes.join(', ') : '-'}`}
                        style={{color: 'white', fontWeight: 550}}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        variant={'filled'}
                        color={'primary'}
                        label={`${t('partconfigurations:t_main_processes')}: ${mainProcesses.length
                          ? mainProcesses.map(p => t(`processes:${p}`)).join(', ')
                          : '-'}`}
                        style={{color: 'white', fontWeight: 550}}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{padding: 0}}>
                  <Collapse in={this.state.showConfigDetails}>
                    <div style={{paddingTop: 16}}>
                      <PartConfigDetailsTable partConfig={partConfig} />
                    </div>
                  </Collapse>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <CloneConfigModal
        open={this.state.cloneConfigModalOpen}
        onClose={refresh => {
          this.toggleStateVar('cloneConfigModalOpen');
          refresh && refreshPartList();
        }}
        partConfigToClone={partConfig}
        allPartConfigs={allPartConfigs}
        partListId={partListId}
      />
    </Grid>;
  }
}

export default withRouter(withTheme(withSnackbar(withTranslation(['partlistdetails', 'processes', 'partconfigurations', 'modals', 'estimator'])(DraggablePartConfig))));
