import React, { useState, useEffect } from 'react';
import Poppers from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import './ListCardActions.scss';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { ListCardActionsEnum } from '../../../models/enums/ListCardActionsEnum';
import {
  disableAppLayoutPageScroll,
  enableAppLayoutPageScroll,
} from 'utils/helpers';

export default function ListCardActions({ onActionClick, partsCount }) {
  const { t } = useTranslation('partlists');
  const [open, setOpen] = useState(null);
  useEffect(() => {
    return () => {
      enableAppLayoutPageScroll();
    };
  }, []);

  const handleToggle = event => {
    if (open && open.contains(event.target)) {
      setOpen(null);
      enableAppLayoutPageScroll();
    } else {
      setOpen(event.currentTarget);
      disableAppLayoutPageScroll();
    }
  };

  const handleClose = event => {
    const selectedVal = event.currentTarget.value;
    if (onActionClick) {
      // if part list is empty download is disabled
      if (
        selectedVal === ListCardActionsEnum.Download.val &&
        partsCount === 0
      ) {
        return;
      }
      if (selectedVal === ListCardActionsEnum.Share.val && partsCount === 0) {
        return;
      }
      onActionClick({ value: selectedVal });
    }
    setOpen(null);
    enableAppLayoutPageScroll();
  };

  return <>
    <IconButton
      className={'list-actions-button'}
      aria-owns={open ? 'menu-list-grow' : null}
      aria-haspopup="true"
      onClick={handleToggle}
      size="large">
      <SettingsIcon />
    </IconButton>
    <Poppers
      style={{ zIndex: 999 }}
      open={Boolean(open)}
      anchorEl={open}
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{
            transformOrigin: 'end top',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList role="menu">
                <MenuItem
                  className={
                    'list-actions-menu-item ' +
                    (partsCount === 0 ? 'disabled' : '')
                  }
                  onClick={handleClose}
                  value={ListCardActionsEnum.Download.val}
                >
                  {t('action_list_download')}
                </MenuItem>
                <MenuItem
                  className={'list-actions-menu-item'}
                  onClick={handleClose}
                  style={{ opacity: 0.33 }}
                  value={ListCardActionsEnum.Rename.val}
                >
                  {t('action_list_rename')}
                </MenuItem>
                <MenuItem
                  className={
                    'list-actions-menu-item ' +
                    (partsCount === 0 ? 'disabled' : '')
                  }
                  onClick={handleClose}
                  value={ListCardActionsEnum.Share.val}
                >
                  {t('action_list_share')}
                </MenuItem>
                <MenuItem
                  className={'list-actions-menu-item'}
                  onClick={handleClose}
                  value={ListCardActionsEnum.Delete.val}
                >
                  {t('action_list_delete')}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Poppers>
  </>;
}
