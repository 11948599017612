/**
 * @description Extract option specifications for select input
 */
const getSelectedOptions = formInputSpec => {
  if (formInputSpec.dataTypeKey === 'select') {
    const options = formInputSpec.selectOptions || [];
    const selectOptionsObj = [];
    options.forEach(item => {
      selectOptionsObj.push({ value: item.key, label: item.key });
    });
    return selectOptionsObj;
  }

  return [];
};

/**
 * @description Convert to form data object
 * @param {*} featuresSpec
 * @param {*} featuresValues
 * @param {*} processId
 * @param {*} partConfigProcessId
 */
export const mapProcessFeaturesToFormData = (
  featuresSpec,
  featuresValues,
  processId,
  partConfigProcessId
) => {
  if (!featuresSpec) {
    return {};
  }
  if (featuresSpec.length === 0) {
    return {};
  }

  let formDataObj = {};

  // First convert the features spec into form obj
  formDataObj = featuresSpec.reduce(
    (obj, formInputSpec) => (
      (obj[formInputSpec.key] = {
        id: 0,
        value: '',
        valueAuto: null,
        selectOptions: getSelectedOptions(formInputSpec),
        required: formInputSpec.required,
        partConfigProcessId,
        processId,
        processFeatureTypeKey: formInputSpec.key,
        dataTypeKey: formInputSpec.dataTypeKey,
        unitKey: formInputSpec.unitKey,
      }),
      obj
    ),
    {}
  );

  // apply the values to the spec form
  if (featuresValues) {
    featuresValues.forEach(formInput => {
      let formInputData = formDataObj[formInput.processFeatureTypeKey];
      formInputData.id = formInput.id;
      formInputData.value = formInput.value;
      formInputData.valueAuto = formInput.valueAuto;
    });
  }

  return formDataObj;
};
