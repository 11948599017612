export const CHANGE_STEP = 'CHANGE_STEP';
export const CREATE_STEP = 'CREATE_STEP';
export const UPDATE_STEPS = 'UPDATE_STEPS';

export const CHANGE_SUBSTEP = 'CHANGE_SUBSTEP';
export const CREATE_SUBSTEP = 'CREATE_SUBSTEP';
export const UPDATE_SUBSTEP = 'UPDATE_SUBSTEP';
export const REMOVE_SUBSTEP = 'REMOVE_SUBSTEP';
export const REMOVING_POST_PROCESS_SUBSTEP = 'REMOVING_POST_PROCESS_SUBSTEP';
export const REMOVING_MAIN_PROCESS_SUBSTEP = 'REMOVING_MAIN_PROCESS_SUBSTEP';
export const REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP =
  'REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP';

export const UPDATE_TEMP_DATA = 'UPDATE_TEMP_DATA';
export const SAVE_DATA = 'SAVE_DATA';
export const RESET_STEPS = 'RESET_STEPS';

export const UPDATE_SUMMARY_DATA = 'UPDATE_SUMMARY_DATA';
