export const GET_PROCESSES = 'general-get-processes';
export const GET_PROCESSES_SUCCESS = 'general-get-processes-success';
export const GET_PROCESSES_ERROR = 'general-get-processes-error';
export const GET_MATERIALS = 'general-get-materials';
export const GET_MATERIALS_SUCCESS = 'general-get-materials-success';
export const GET_MATERIALS_ERROR = 'general-get-materials-error';
export const GET_MATERIALS_MORE = 'general-get-materials-more';
export const GET_MATERIALS_MORE_SUCCESS = 'general-get-materials-more-success';
export const GET_MATERIALS_MORE_ERROR = 'general-get-materials-more-error';
export const GET_FIRST_LISTS = 'general-get-lists-first';
export const GET_FIRST_LISTS_SUCCESS = 'general-get-lists-first-success';
export const GET_FIRST_LISTS_ERROR = 'general-get-lists-first-error';
export const GET_CURRENT_USER_INFO = 'general-get-user-info';
export const GET_CURRENT_USER_INFO_SUCCESS = 'general-get-current-user-info-success';
export const GET_CURRENT_USER_INFO_ERROR = 'general-get-current-user-info-error';
export const GET_ALL_PROCESSES = 'general-get-all-processes';
export const GET_ALL_PROCESSES_SUCCESS = 'general-get-all-processes-success';
export const GET_ALL_PROCESSES_ERROR = 'general-get-all-processes-error';
export const GET_SUPPLIERS = 'general-get-suppliers';
export const GET_SUPPLIERS_SUCCESS = 'general-get-suppliers-success';
export const GET_SUPPLIERS_ERROR = 'general-get-suppliers-error';
