import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Box, FormControl } from '@mui/material';
import AsyncSelect from 'react-select/async';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

import {getCompanies} from '../../../services/users';

import '../parts-modals.scss';

export const ImpersonationModal = ({
  open,
  user,
  setOpen,
  stopImpersonating,
  impersonate,
}) => {
  const { t } = useTranslation(['impersonation']);
  const [company, setCompany] = useState(null);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    setCompany(user && user.impersonating);
  }, [user]);

  const handleCompanyChange = company => {
    setCompany(company);
  };
  
  const searchCompanies = async searchText => {
    if (searchText.length > 2) {
      const {data} = await getCompanies(searchText)
      return data.map(company => {return {label: company.companyName, value: company.assetId}});
    }
    
    return [];
  }

  return open && (
    <div>
      <SweetAlert
        title=""
        customClass={'tracking-modal'}
        closeOnClickOutside={false}
        onConfirm={() => {}}
        showConfirm={false}
      >
        <div>
          <Grid
            container
            spacing={3}
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
          >
            <Grid item xs={12}>
              <h5 style={{ fontWeight: 'bold' }}>{t('mdl_impersonation')}</h5>
            </Grid>
            {user && user.impersonating && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  direction={'column'}
                  justifyContent={'space-between'}
                  alignItems={'stretch'}
                >
                  <Grid item xs={12}>
                    <Box fontWeight={'fontWeightBold'}>
                      {t('mdl_you_are_currently_impersonating')}:{' '}
                      {user.impersonatedCompany.companyName}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={stopImpersonating}
                      variant={'contained'}
                      color={'secondary'}
                    >
                      {t('mdl_stop_impersonating')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl>
                <div style={{ width: '300px' }}>
                  <AsyncSelect
                    cacheOptions
                    placeholder={t('mdl_select_company_to_impersonate')}
                    loadOptions={searchCompanies}
                    onChange={handleCompanyChange}
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item>
                  <Button
                    onClick={() => setOpen(false)}
                    className={`${classes.button} ${classes.cancel}`}
                  >
                    {t('mdl_close')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!company}
                    onClick={() => impersonate(company.value)}
                    className={`${classes.button} ${classes.success}`}
                  >
                    {t('mdl_start_impersonating')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </SweetAlert>
    </div>
  );
};
