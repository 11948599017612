import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Typography} from '@mui/material';
import Button from 'components/CustomButtons/Button.js';

import {fetchSharedListZip} from 'services/shared-lists';

import './ShareLinkPage.scss';
import Loading from 'components/Loading/Loading';
import {useParams} from 'react-router-dom';
import kmsLogoOneLine from 'assets/img/kms_logo_oneline.svg';

export const ShareLinkPage = () => {
  const {t} = useTranslation('download');
  const {id} = useParams();

  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (id) downloadSharedList(id);
  }, [id]);

  const downloadSharedList = async urlId => {
    const status = await fetchSharedListZip(urlId);
    setStatus(status);
  };

  return <>
    {status ? (
      <Grid container className="download-container">
        <Grid container item sm={12} justifyContent="center"></Grid>
        <Grid container item sm={12} justifyContent="center">
          <img
            className="download-page-logo"
            style={{width: 500}}
            src={kmsLogoOneLine}
            alt="Logo"
          />
        </Grid>
        <Grid container item sm={12}>
          <Grid container item sm={12} justifyContent="center">
            <Typography textAlign="center" variant="h4">
              {t(`download_${status}_message`)}
            </Typography>
          </Grid>
          <Grid container item sm={12} justifyContent="center">
            <Button
              color="info"
              href={window.location.protocol + '//' + window.location.host}
              style={{maxHeight: 50}}
            >
              {t('download_redirect_button')}
            </Button>
          </Grid>
        </Grid>
        <Grid container item sm={12} justifyContent="center"></Grid>
      </Grid>
    ) : (
      <Loading />
    )}
  </>;
};
