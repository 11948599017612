import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import makeStyles from '@mui/styles/makeStyles';

import './ShareListModal.scss';
import '../../parts-modals.scss';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShareDetailsModal from '../ShareLinkStages/ShareDetailsModal/ShareDetailsModal';
import ShareLinkModal from '../ShareLinkStages/ShareLinkModal/ShareLinkModal';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
const useStyles = makeStyles(styles);

export const ShareListModal = ({
  open,
  setOpen,
  confirm,
  listId,
  listName,
}) => {
  const { t } = useTranslation('modals');

  const classes = useStyles();

  const [alert, setAlert] = useState(null);
  const listData = { id: listId, name: listName };
  const shareOptions = useRef(null);

  useEffect(() => {
    if (open) {
      shareModal();
    } else {
      closeAlert();
    }
  }, [open]);

  const hideAlert = response => {
    if (confirm) {
      confirm(response);
    }
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const shareDetailsModal = () => {
    setAlert(
      <ShareDetailsModal
        open={true}
        setOpen={setOpen}
        confirm={shareOptions}
        next={shareLinkModal}
        listData={{ current: listData }}
      />
    );
  };

  const shareLinkModal = () => {
    const data = {
      expiryDatetime: shareOptions.current.expiryDatetime,
      password: shareOptions.current.password,
      link: `${window.location.hostname}/download/${shareOptions.current.urlId}`,
    };
    setAlert(
      <ShareLinkModal
        open={true}
        setOpen={setOpen}
        confirm={setOpen}
        data={data}
      />
    );
  };

  const shareModal = () => {
    setAlert(
      <SweetAlert
        customClass="share-list-modal"
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('mdl_share_part_list_title')}
        closeOnClickOutside={false}
        showCloseButton={true}
        onConfirm={() => hideAlert()}
        onCancel={() => closeAlert()}
        showConfirm={false}
        showCancel={false}
        confirmBtnCssClass={'md-disabled-button'}
        disabled
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            inputProps={{
              disabled: true,
            }}
            labelText={t('mdl_share_with_label')}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText={t('mdl_share_msg_label')}
            inputProps={{
              multiline: true,
              rows: 2,
              disabled: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid container spacing={1} justifyContent={'center'}>
          <Grid item>
            <Button
              onClick={() => hideAlert(false)}
              className={`${classes.button} ${classes.cancel}`}
            >
              {t('mdl_general_cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled
              onClick={() => hideAlert(false)}
              className={`${classes.button} ${classes.success}`}
            >
              {t('mdl_share_send')}
            </Button>
          </Grid>
        </Grid>
        <Box component="div" m={3}>
          {t('mdl_share_link_description')}
        </Box>
        <Box component="div">
          <Button
            onClick={shareDetailsModal}
            className={`${classes.button} ${classes.success}`}
          >
            {t('mdl_share_link_create')}
          </Button>
        </Box>
      </SweetAlert>
    );
  };

  return <>{alert}</>;
};
