import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Grid,
  Typography
} from '@mui/material';

import './OrderCheckoutModal.scss';
import { toCurrency } from '../../../utils/numberHelpers';

class OrderCheckoutModalSelectedPartsTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {configs, selectedBatchSizeIds, t} = this.props;

    return (
      <Grid container justifyContent={'flex-start'} spacing={2}>
        {configs
          .filter(c => c.batchSizes.some(bs => selectedBatchSizeIds.includes(bs.id)))
          .map(config => {
            const batchSize = config.batchSizes.find(bs => selectedBatchSizeIds.includes(bs.id));
            return (
              <Grid container item xs={12} key={`config_${config.id}`}>
                <Grid item xs={12}><Typography variant={'body1'}><b>{t('config')} {config.name || config.id}, </b> {t('from_part')} {config.partName}</Typography></Grid>
                <Grid item xs={12} style={{marginLeft: 10}}><Typography variant={'body1'}>{t('batch_size')}: {batchSize.batchSize}</Typography></Grid>
                <Grid item xs={12} style={{marginLeft: 10}}><Typography variant={'body1'}>{t('total_cost_per_piece')}: {toCurrency(batchSize.totalCostPerPart)}</Typography></Grid>
                <Grid item xs={12} style={{marginLeft: 10}}><Typography variant={'body1'}>{t('total_cost')}: {toCurrency(config.totalCost)}</Typography></Grid>
              </Grid>
            )
          })
        }
      </Grid>
    );
  }
}

export default withTranslation('order_checkout_modal')(OrderCheckoutModalSelectedPartsTable);
