export const SHARED_LIST_GET = 'shared-list-get';
export const SHARED_LIST_GET_SUCCESS = 'shared-list-get-success';
export const SHARED_LIST_GET_ERROR = 'shared-list-get-error';
export const SHARED_INITIAL_LISTS_GET = 'shared-initial-lists-get';
export const SHARED_INITIAL_LISTS_GET_SUCCESS =
  'shared-initial-lists-get-success';
export const SHARED_INITIAL_LISTS_GET_ERROR = 'shared-initial-lists-get-error';
export const SHARED_LISTS_GET = 'shared-lists-get';
export const SHARED_LISTS_GET_SUCCESS = 'shared-lists-get-success';
export const SHARED_LISTS_GET_ERROR = 'shared-lists-get-error';
export const SHARED_LIST_CREATE = 'shared-list-create';
export const SHARED_LIST_CREATE_SUCCESS = 'shared-list-create-success';
export const SHARED_LIST_CREATE_ERROR = 'shared-list-create-error';
export const SHARED_LIST_UPDATE = 'shared-list-update';
export const SHARED_LIST_UPDATE_SUCCESS = 'shared-list-update-success';
export const SHARED_LIST_UPDATE_ERROR = 'shared-list-update-error';
export const SHARED_LIST_DELETE = 'shared-list-delete';
export const SHARED_LIST_DELETE_SUCCESS = 'shared-list-delete-success';
export const SHARED_LIST_DELETE_ERROR = 'shared-list-delete-error';
export const SHARED_LIST_CLEAR_ERROR = 'shared-list-clear-error';
