import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import Axios from 'axios';

const mapping = {
  processes: 'process',
  processfeatures: 'process_feature',
};

const constructLoadPath = (lng, ns) => {
  if (['processes', 'processfeatures'].includes(ns[0])) {
    return `${process.env.REACT_APP_PARTS_API}/translations/formatted?language=${lng[0]}&category=${mapping[ns[0]]}`;
  } else {
    return `/locales/${lng[0]}/${ns[0]}.json`;
  }
};

const getTranslationData = (_options, url, _payload, callback) => {
  Axios.get(url)
    .then((response) => {
      callback(null, {status: 200, data: response.data});
    })
    .catch(callback);
};

const i18nOptions = {
  lng: localStorage.getItem('lang') || process.env.REACT_APP_LANG,
  fallbackLng: 'en',
  ns: [
    'automatic_configuration',
    'common',
    'createlist',
    'download',
    'errors',
    'filespage',
    'fileuploaded',
    'filters',
    'impersonation',
    'login',
    'materials',
    'modals',
    'navigation',
    'orders',
    'order_details',
    'order_checkout_modal',
    'partconfigurator',
    'material_features',
    'material_heat_states',
    'partdetails',
    'partfeatures',
    'partinfodr',
    'partlistdetails',
    'partlists',
    'partstorage',
    'part_library',
    'processes',
    'processfeatures',
    'settings',
    'sharedparts',
    'sidebar',
    'uploadstatus',
    'welcomepage',
  ],
  backend: {
    loadPath: constructLoadPath,
    request: getTranslationData,
    lng: localStorage.getItem('lang') || process.env.REACT_APP_LANG,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init(i18nOptions);

export default i18n;
