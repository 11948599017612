import {
  GET_PART,
  GET_PART_ERROR,
  GET_PART_SUCCESS,
  GET_FEATURES,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_ERROR,
  GET_LISTS_FOR_PART,
  GET_LISTS_FOR_PART_SUCCESS,
  GET_LISTS_FOR_PART_ERROR,
  GET_PART_ATTACHMENTS,
  GET_PART_ATTACHMENTS_SUCCESS,
  GET_PART_ATTACHMENTS_ERROR,
  PART_DETAILS_CLEANUP,
  EDIT_PART_NAME,
  UNASSIGN_PART_ATTACHMENT,
  UNASSIGN_PART_ATTACHMENT_SUCCESS,
  UNASSIGN_PART_ATTACHMENT_ERROR,
  UNASSIGN_PART_ATTACHMENT_CLEAN,
} from './PartDetailsTypes';
import { fetchPart, fetchListForPart, fetchFeatures } from 'services/parts';
import { fetchPartAttachments } from 'services/parts';
import { unassignAttachmentFromPart } from 'services/attachments';

export const getPart = (id = null, params = null) => {
  return async dispatch => {
    dispatch({
      type: GET_PART,
    });

    try {
      const response = await fetchPart(id, params);
      dispatch({
        type: GET_PART_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PART_ERROR,
        payload: error,
      });
    }
  };
};

export const getFeatures = params => {
  return async dispatch => {
    dispatch({
      type: GET_FEATURES,
    });

    try {
      const response = await fetchFeatures(params);
      dispatch({
        type: GET_FEATURES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEATURES_ERROR,
        payload: error,
      });
    }
  };
};

export const getPartAttachments = (id = null, params = null) => {
  return async dispatch => {
    dispatch({
      type: GET_PART_ATTACHMENTS,
    });

    try {
      const response = await fetchPartAttachments(id, params);
      dispatch({
        type: GET_PART_ATTACHMENTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PART_ATTACHMENTS_ERROR,
        payload: error,
      });
    }
  };
};

export const getListsForPart = (id = null) => {
  return async dispatch => {
    dispatch({
      type: GET_LISTS_FOR_PART,
    });

    try {
      const responseList = await fetchListForPart(id);
      dispatch({
        type: GET_LISTS_FOR_PART_SUCCESS,
        payload: responseList.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LISTS_FOR_PART_ERROR,
        payload: error,
      });
    }
  };
};

export const updatePartName = partName => {
  return async dispatch => {
    dispatch({
      type: EDIT_PART_NAME,
      payload: partName,
    });
  };
};

export const unassignPartAttachment = ({ partId, attachmentId, force = false }) => {
  return async dispatch => {
    dispatch({ type: UNASSIGN_PART_ATTACHMENT });

    try {
      await unassignAttachmentFromPart(attachmentId, partId, force);
      dispatch({
        type: UNASSIGN_PART_ATTACHMENT_SUCCESS,
        payload: { partId, attachmentId },
      });
    } catch (error) {
      dispatch({
        type: UNASSIGN_PART_ATTACHMENT_ERROR,
        payload: error,
      });
    }
  };
};

export const unassignPartAttachmentCleanup = () => {
  return async dispatch => {
    dispatch({ type: UNASSIGN_PART_ATTACHMENT_CLEAN });
  };
};

export const doCleanup = () => {
  return dispatch => {
    dispatch({ type: PART_DETAILS_CLEANUP });
  };
};
