import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, TextField } from '@mui/material';

export default function UserDetails({ user }) {
  const { t } = useTranslation('settings');
  return user ? (
    <>
      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('acs_company_name')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField value={user.company.companyName} disabled fullWidth />
        </Grid>
      </Grid>
      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('acs_user_name')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField
            value={`${user.firstName} ${user.lastName}`}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('Email address')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField value={user.email} disabled fullWidth />
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}
