import React, {Component} from 'react';
import {Alert, TextField} from '@mui/material';
import Icon from '@mui/material/Icon';
import {Grid, IconButton} from '@mui/material';
import {Droppable} from 'react-beautiful-dnd';
import SourceAttachment from './SourceAttachment';
import DraggedSourceAttachment from './DraggedSourceAttachment';
import {RemoveCircleOutline} from '@mui/icons-material';

class TargetPart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalPartName: this.props?.part?.name,
      partName: this.props?.part?.name,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.part?.name !== this.props?.part?.name) {
      this.setState({
        originalPartName: this.props?.part?.name,
        partName: this.props?.part?.name,
      });
    }
  }

  handlePartNameChange = partName => {
    this.setState({
      partName,
    });
  };

  handlePartNameBlur = async partName => {
    if (partName?.length) {
      const {part, renamePart} = this.props;

      try {
        await renamePart(part.id, partName);

        return this.setState({
          partName,
          originalPartName: partName,
        });
      } catch (e) {}
    }

    // If any error occurred, or the part name was null, restore the original name
    return this.setState({
      partName: this.state.originalPartName,
    });
  };

  removeAttachmentFromPart = async attachmentId => {
    const {part, removeAttachmentFromPart} = this.props;
    await removeAttachmentFromPart(part.id, attachmentId);
  };

  render() {
    const {part, removePartFromPartList} = this.props;

    return (
      <Droppable
        direction={'horizontal'}
        droppableId={`${part.id}`}
        renderClone={(provided, snapshot, rubric) => (
          <div
            {...provided.draggableProps}
            ref={provided.ref}
          >
            <DraggedSourceAttachment attachment={(part.attachments || part.partAttachments?.map(pa => pa.attachment))[rubric.source.index]} />
          </div>
        )}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Alert
              variant={snapshot.isDraggingOver ? 'filled' : 'outlined'}
              icon={<Icon color={'inherit'}>precision_manufacturing</Icon>}
              action={<IconButton onClick={() => removePartFromPartList(part.id)} size="large"><RemoveCircleOutline /></IconButton>}
            >
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant={'standard'}
                    value={this.state.partName}
                    onChange={({target: {value}}) => this.handlePartNameChange(value)}
                    onBlur={({target: {value}}) => this.handlePartNameBlur(value)}
                    style={{width: 'min(40vw, 700px)'}}
                  />
                </Grid>
                <Grid
                  item xs={12} style={{
                  overflowX: 'scroll',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8,
                }}
                >
                  {(part.attachments || part.partAttachments?.map(pa => pa.attachment))?.map((attachment, index) => (
                    <div
                      style={{
                        flex: 'none',
                      }}
                    >
                      <SourceAttachment attachment={attachment} index={index} removeAttachmentFromPart={() => this.removeAttachmentFromPart(attachment.id)} />
                    </div>
                  ))}
                  <div>
                    <span>{provided.placeholder}</span>
                  </div>
                </Grid>
              </Grid>
            </Alert>
          </div>
        )}
      </Droppable>
    );
  }
}

export default TargetPart; 
