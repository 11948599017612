import React, {Component} from 'react';
import {Button, Checkbox, Container, Grid, Paper, Typography} from '@mui/material';
import {updateBreadcrumbs} from '../../store/breadcrumbs/breadcrumbsActions';
import {connect} from 'react-redux';
import FilterMenu from '../../components/FilterMenu/FilterMenu';
import {Deselect, Delete, PlaylistAdd, Share, TableView, ViewComfy} from '@mui/icons-material';
import {deleteParts, fetchParts} from '../../services/parts';
import PartCard from '../../components/PartCard/PartCard';
import InfiniteScroll from 'react-infinite-scroller';
import Loading, {LoadingWithoutHeight} from '../../components/Loading/Loading';
import {toDisplayDate, toSortableDate} from '../../utils/datetimeHelpers';
import {withTranslation} from 'react-i18next';
import {NoItemsError} from '../../components/Errors/NoItemsError/NoItemsError';
import {DataGrid} from '@mui/x-data-grid';
import {NavLink} from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {withTheme} from '@mui/styles';
import {AddToPartListModal, SharePartListModal} from '../../components/Modals';

const MySwal = withReactContent(Swal);

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: () => dispatch(
      updateBreadcrumbs({
        level: 0,
        path: window.location.pathname,
        text: 'partstorage:section_title',
      }),
    ),
  };
};

const connectParts = connect(null, mapDispatchToProps);

class PartStorage extends Component {
  constructor (props) {
    super(props);

    this.props.setBreadcrumbs();

    this.filterInputs = [
      {name: 'partName', label: 'flt_part_name'},
      {name: 'partArticleNr', label: 'flt_article_number'},
      {name: 'partDrawingNr', label: 'flt_drawing_number'},
    ];

    this.defaultDataState = {
      parts: [],
      page: 0,
      totalPageCount: 0,
      totalItemCount: 0,
      selectedPartIds: [],
    };

    this.state = {
      loading: true,
      showInfiniteScroll: window.localStorage.getItem('showInfiniteScroll') ? window.localStorage.getItem('showInfiniteScroll') === 'true' : false,
      fields: {},
      addToPartListModalOpen: false,
      sharePartListModalOpen: false,
      ...this.defaultDataState,
    };

    window.localStorage.setItem('showInfiniteScroll', this.state.showInfiniteScroll.toString());
  }

  refresh = async () => {
    await this.getPartTeasersAndUpdateState(1, true);
  };

  componentDidMount () {
    window.addEventListener('filesUploaded', this.refresh);
  }

  async componentDidUpdate (prevProps, prevState) {
    if (
      prevState.fields !== this.state.fields ||
      prevState.showInfiniteScroll !== this.state.showInfiniteScroll
    ) {
      try {
        await this.setState({
          ...this.defaultDataState,
        });

        await this.getPartTeasersAndUpdateState();
      } catch (err) {
        console.error(err);
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('filesUploaded', this.refresh);
  }

  getFilter = (page = 1) => {
    const {fields} = this.state;

    const where = {};

    if (fields && Object.keys(fields).length) {
      where.and = [];
      Object.keys(fields).map(field => {
        where.and.push({
          [field]: {ilike: `%${fields[field]}%`},
        });
      });
    }

    return {
      filter: {
        limit: 25,
        order: 'partCreatedAt DESC',
        page,
        where,
      },
    };
  };

  getPartTeasersAndUpdateState = async (page = 1, refresh = false) => {
    if (refresh) {
      this.setState({loading: true});
    }

    const {data: {page: parts, pagination: {totalPageCount, totalItemCount, currentPage}}} = await fetchParts(this.getFilter(page));

    this.setState({
      parts: refresh ? parts : this.state.showInfiniteScroll ? [...this.state.parts, ...parts] : parts,
      page: parts.length > 0 ? currentPage : 0,
      totalPageCount,
      totalItemCount,
      loading: false,
    });
  };

  toggleView = () => {
    window.localStorage.setItem('showInfiniteScroll', (!this.state.showInfiniteScroll).toString());

    this.setState({
      showInfiniteScroll: !this.state.showInfiniteScroll,
      loading: true,
      ...this.defaultDataState,
    });
  };

  onSelect = (partId, newState) => {
    this.setState({
      selectedPartIds: newState ? [...this.state.selectedPartIds, partId] : this.state.selectedPartIds.filter(id => id !== partId),
    });
  };

  deleteSelectedParts = async () => {
    if (this.state.selectedPartIds?.length) {
      const {t, theme} = this.props;

      const {isConfirmed} = await MySwal.fire({
        title: <Typography variant={'h5'}>{t('delete_parts_header')}</Typography>,
        html: <Typography variant={'body1'}>{t('delete_parts_details')}</Typography>,
        icon: 'warning',
        confirmButtonText: t('common:cmn_delete'),
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: t('common:cmn_cancel'),
        reverseButtons: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: theme.palette.error.main,
        cancelButtonColor: theme.palette.secondary.main,
        preConfirm: async () => {
          try {
            await deleteParts(this.state.selectedPartIds);
            this.setState({selectedPartIds: []});
          } catch (err) {
            console.error(err);
            this.props.enqueueSnackbar(this.props.t('general_error_title'), {
              variant: 'error',
            });
            return false;
          }
        },
      });

      isConfirmed && await this.refresh();
    }
  };

  setAddToPartListModalState = addToPartListModalOpen => {
    this.setState({addToPartListModalOpen});
  };

  setSharePartListModalState = sharePartListModalOpen => {
    this.setState({sharePartListModalOpen});
  };

  render () {
    const {t} = this.props;
    let currentDate = null;

    return (
      <Container maxWidth={false}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={8} container alignItems={'center'} spacing={2}>
              {this.state.selectedPartIds.length ?
                <>
                  <Grid item>
                    <Typography variant={'h5'}>{this.state.selectedPartIds.length} {t('common:cmn_parts_selected')}</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} onClick={() => this.setState({selectedPartIds: []})} startIcon={<Deselect />}>
                      {t('common:cmn_deselect_all')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} onClick={() => this.setAddToPartListModalState(true)}>
                      <PlaylistAdd />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} onClick={() => this.setSharePartListModalState(true)}>
                      <Share />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} onClick={this.deleteSelectedParts}>
                      <Delete />
                    </Button>
                  </Grid>
                </> : <></>
              }
            </Grid>
            <Grid item xs={4} container justifyContent={'flex-end'} spacing={2} alignItems={'center'}>
              <Grid item>
                <Button
                  variant={'outlined'}
                  startIcon={this.state.showInfiniteScroll ? <TableView /> : <ViewComfy />}
                  onClick={this.toggleView}
                >
                  {t('common:cmn_toggle_view')}
                </Button>
              </Grid>
              <Grid item>
                <FilterMenu
                  search={fields => this.setState({fields: fields || {}})}
                  inputs={this.filterInputs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{height: 'calc(100vh - 276px)'}}>
            {this.state.loading && this.state.showInfiniteScroll ? <Loading /> : this.state.showInfiniteScroll ? (
              <div style={{height: '100%', maxHeight: '100%', overflowY: 'auto'}}>
                <InfiniteScroll
                  hasMore={this.state.page < this.state.totalPageCount}
                  loadMore={() => this.getPartTeasersAndUpdateState(this.state.page + 1)}
                  loader={<LoadingWithoutHeight />}
                  useWindow={false}
                >
                  <Grid container>
                    {this.state.parts.map(part => {
                      const createdAt = toSortableDate(part.partCreatedAt);
                      let dateGridItem;

                      if (createdAt !== currentDate) {
                        currentDate = createdAt;
                        dateGridItem = <Grid item xs={12}>
                          <Typography variant={'h5'} gutterBottom style={{marginTop: 10}}>
                            {currentDate ? toDisplayDate(currentDate) : '-'}
                          </Typography>
                        </Grid>;
                      }

                      return (<>
                        {dateGridItem}
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`part-${part.id}`}>
                          <PartCard
                            part={part}
                            selected={this.state.selectedPartIds.includes(part.id)}
                            onCheckboxChange={newState => this.onSelect(part.id, newState)}
                          />
                        </Grid>
                      </>);
                    })}
                    {!this.state.parts.length ?
                      <Grid item xs={12}>
                        <NoItemsError pageType={'storage'} />
                      </Grid> :
                      <></>}
                  </Grid>
                </InfiniteScroll>
              </div>
            ) : (
              <Paper sx={{height: '100%'}}>
                <DataGrid
                  rows={this.state.parts}
                  columns={[
                    {field: 'id', headerName: 'ID', renderCell: params => `KMS-P-${params.value}`, flex: 0.25, sortable: false},
                    {
                      field: 'partName',
                      renderCell: params => <NavLink to={`/part/${params.row.id}`}>{params.value}</NavLink>,
                      headerName: this.props.t('part_name'),
                      flex: 1,
                      sortable: false,
                    },
                    {
                      field: 'partArticleNr',
                      renderCell: params => params.value ? params.value : '-',
                      headerName: this.props.t('article_nr'),
                      flex: 1,
                      sortable: false,
                    },
                    {
                      field: 'partDrawingNr',
                      renderCell: params => params.value ? params.value : '-',
                      headerName: this.props.t('drawing_nr'),
                      flex: 1,
                      sortable: false,
                    },
                    {
                      field: 'actions',
                      headerName: '',
                      renderCell: params => <Checkbox
                        checked={this.state.selectedPartIds.includes(params.row.id)}
                        onChange={({target: {checked}}) => this.onSelect(params.row.id, checked)}
                      />,
                      flex: 0.1,
                      sortable: false,
                      align: 'center',
                    },
                  ]}
                  page={this.state.page - 1}
                  paginationMode={'server'}
                  pageSize={25}
                  rowCount={this.state.totalItemCount}
                  rowsPerPageOptions={[25]}
                  disableSelectionOnClick
                  onPageChange={page => this.getPartTeasersAndUpdateState(page + 1)}
                  loading={this.state.loading}
                  disableColumnFilter
                  disableColumnMenu
                />
              </Paper>
            )}
          </Grid>
        </Grid>
        <AddToPartListModal
          selectedPartIds={this.state.selectedPartIds}
          open={this.state.addToPartListModalOpen}
          setOpen={this.setAddToPartListModalState}
        />
        <SharePartListModal
          open={this.state.sharePartListModalOpen}
          setOpen={this.setSharePartListModalState}
          partIds={this.state.selectedPartIds}
          createPartList={true}
        />
      </Container>
    );
  }
}

export default withTheme(withTranslation(['partstorage', 'common'])(connectParts(PartStorage)));