import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
const SharedLinkSearchError = () => {
  const { t } = useTranslation('sharedparts');
  return (
    <div style={{ padding: 10, marginTop: 60 }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <h2>{t('shareparts_search_empty_title')}</h2>
        <p>{t('shareparts_search_empty_desc')}</p>
      </Grid>
    </div>
  );
};

export default SharedLinkSearchError;
