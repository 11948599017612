import React, { useEffect } from 'react';
// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
// styles for buttons on sweetalert
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);

const SharedLinkDeactivateModal = ({ open, onConfirm, onCancel }) => {
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const { t } = useTranslation('modals');
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (open) {
      successAlert();
    } else {
      hideAlert();
    }
  }, [open]);

  const handleClick = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
  };

  const handleCancel = () => {
    if (typeof onConfirm === 'function') {
      onCancel();
    }
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        showCancel
        title={t('mdl_shared_deactivate_title')}
        closeOnClickOutside={false}
        onConfirm={() => handleClick()}
        onCancel={() => handleCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.cancel}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnText={t('mdl_general_deactivate')}
      >
        {t('mdl_shared_deactivate_description')}
      </SweetAlert>
    );
  };
  return <div>{alert}</div>;
};

export default SharedLinkDeactivateModal;
