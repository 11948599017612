import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import localizedCountries from 'localized-countries';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  LocalShipping,
  Info,
  Schedule,
  Receipt,
  HourglassEmpty,
  Home,
} from '@mui/icons-material';
import {Alert} from '@mui/material';

import {toDisplayDate} from '../../utils/datetimeHelpers';
import {toCurrency} from '../../utils/numberHelpers';
import InlineEditField from '../InlineEditField/InlineEditField';
import CardFooter from '../Card/CardFooter';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {withTheme} from '@mui/styles';
import {cancelOrder} from '../../services/orders';

const MySwal = withReactContent(Swal);

class OrderDetailsCards extends Component {
  constructor(props) {
    super(props);

    const localizedCountriesForUserLanguage = localizedCountries(require(`localized-countries/data/${props.i18n.language}`));

    this.state = {
      localizedCountriesForUserLanguage,
      internalOrderNumber: props.order.internalOrderNumber,
    };
  }

  async handleOrderChange(name, value, cancelled) {
    if (cancelled) {
      return this.setState({internalOrderNumber: this.props.order.internalOrderNumber});
    }

    try {
      const _trimmedValue = value.trim();
      await this.props.updateOrder({[name]: _trimmedValue});
      return this.setState({internalOrderNumber: _trimmedValue});
    } catch (e) {
      console.error(e);
      return this.setState({internalOrderNumber: this.props.order.internalOrderNumber});
    }
  }

  cancelOrder = async () => {
    const {t, theme} = this.props;
    const {isConfirmed, value} = await MySwal.fire({
      icon: 'warning',
      html: <Typography variant={'h5'}>{t('order_details:cancel_order_confirmation')}</Typography>,
      confirmButtonText: t('order_details:cancel_order'),
      cancelButtonText: t('modals:mdl_general_cancel'),
      allowOutsideClick: false,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: theme.palette.error.main,
      cancelButtonColor: theme.palette.secondary.main,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await cancelOrder(this.props.order.id);
          return 'success';
        } catch (err) {
          console.error(err);
          return 'error';
        }
      },
    });

    if (isConfirmed) {
      await MySwal.fire({
        icon: value === 'success' ? 'success' : 'error',
        title: value === 'success' ? t('order_details:order_cancelled') : t('order_checkout_modal:uh_oh'),
        html: value === 'success' ? <Typography variant={'h5'}>{t('order_details:order_cancelled_details')}</Typography> : <Typography variant={'h5'}>{t('order_checkout_modal:order_error')}</Typography>,
        confirmButtonColor: theme.palette.primary.main,
      });
      location.reload();
    }
  };

  render() {
    const {t, order} = this.props;

    return (
      <Grid item container direction={'row'} justifyContent={'center'} spacing={1}>
        {/*Order Information*/}
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{height: '100%'}}>
            <CardHeader
              style={{backgroundColor: '#aec'}}
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Info fontSize={'large'} style={{paddingRight: 10}} />{t('order_details:order_information')}
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item container xs={12} spacing={1}>
                  <Grid item><b>{t('order_details:order_name')}:</b></Grid>
                  <Grid item>{order.name}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item><b>{t('orders:order_number')}:</b></Grid>
                  <Grid item>{order.kmsId}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item><b>{t('orders:internal_order_number')}:</b></Grid>
                  <Grid item>
                    <InlineEditField
                      text={this.state.internalOrderNumber}
                      type="input"
                      placeholder=" "
                      displayVariant="body2"
                      done={(cancelled, value) => this.handleOrderChange('internalOrderNumber', value, cancelled)}
                    >
                      <TextField
                        autoFocus
                        fullWidth
                        value={this.state.internalOrderNumber}
                        inputProps={{
                          type: 'text',
                        }}
                        onChange={e => this.setState({internalOrderNumber: e.target.value})}
                      />
                    </InlineEditField>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item><b>{t('orders:ordered_on')}:</b></Grid>
                  <Grid item>{toDisplayDate(order.exportedAt)}</Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/*Order Status*/}
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{height: '100%'}}>
            <CardHeader
              style={{backgroundColor: '#bae5d5'}}
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <HourglassEmpty fontSize={'large'} style={{paddingRight: 10}} />{t('order_details:order_status')}
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}><b>{t(`orders:status_${order.statusKey}`)}</b></Grid>
                  <Grid item xs={12}>{t(`order_details:status_${order.statusKey}_details`)}</Grid>
                  {
                    order.delayed &&
                    !['delivered', 'invoice_sent', 'invoice_paid'].includes(order.statusKey) &&
                    <Grid item xs={12}>
                      <Alert severity={'warning'}>
                        <i>{t('orders:order_delayed')}</i>
                      </Alert>
                    </Grid>
                  }

                </Grid>
              </Grid>
            </CardContent>
            <CardFooter>
              <Grid container spacing={2} justifyContent={'center'}>
                {
                  new Date(order.createdAt) >= new Date(Date.now() - (24 * 60 * 60 * 1000)) &&
                  order.statusKey !== 'cancelled' &&
                  <Grid item xs={12}>
                    <Button
                      variant={'contained'}
                      size={'small'}
                      color={'error'}
                      onClick={() => this.cancelOrder()}
                    >
                      {t('order_details:cancel_order')}
                    </Button>
                  </Grid>
                }
              </Grid>
            </CardFooter>
          </Card>
        </Grid>
        {/*Delivery Details*/}
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{height: '100%'}}>
            <CardHeader
              style={{backgroundColor: '#aec'}}
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <LocalShipping fontSize={'large'} style={{paddingRight: 10}} />{t('order_details:delivery_details')}
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item container direction={'column'} xs={12} spacing={1}>
                  <Grid item container spacing={1} alignContent={'center'}>
                    <Grid item><Home fontSize={'small'} /></Grid>
                    <Grid item><b>{t('order_details:delivery_address')}:</b></Grid>
                  </Grid>
                  <Grid item>
                    {order.deliveryAddressLine1}<br />
                    {order.deliveryAddressLine2 && (
                      <>
                        {order.deliveryAddressLine2}
                        <br />
                      </>
                    )}
                    {order.deliveryZipCode}, {order.deliveryCity}<br />
                    {order.deliveryState}, {this.state.localizedCountriesForUserLanguage.get(order.deliveryCountry)}<br />
                  </Grid>
                </Grid>
                <Grid item container direction={'column'} xs={12} spacing={1}>
                  <Grid item container spacing={1} alignContent={'center'}>
                    <Grid item><Schedule fontSize={'small'} /></Grid>
                    <Grid item><b>{t('order_details:estimated_delivery_date')}:</b></Grid>
                  </Grid>
                  <Grid item>
                    {toDisplayDate(Math.max(new Date(order.desiredDeliveryDate), new Date(order.expectedDeliveryDate)))}
                  </Grid>
                </Grid>
                <Grid item container direction={'column'} xs={12} spacing={1}>
                  <Grid item><b>{t('order_details:tracking_information')}:</b></Grid>
                  <Grid item>
                    <Chip
                      color={'secondary'}
                      style={{color: 'white'}}
                      label={<b>{t('order_details:pending')}</b>}
                    />
                  </Grid>
                </Grid>
                {order.incoterms && <Grid item container direction={'column'} xs={12} spacing={1}>
                  <Grid item><b>{t('estimator:delivery_terms')}:</b></Grid>
                  <Grid item>
                    <Chip
                      color={'primary'}
                      style={{color: 'white'}}
                      label={<b>{t(`estimator:${order.incoterms}`)}</b>}
                    />
                  </Grid>
                </Grid>}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/*Order Sum*/}
        <Grid item xs={12} sm={6} md={3}>
          <Card style={{height: '100%'}}>
            <CardHeader
              style={{backgroundColor: '#ddf'}}
              title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Receipt fontSize={'large'} style={{paddingRight: 10}} />{t('order_details:order_sum')}
                </div>
              }
            />
            <CardContent>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:setup_time')} (min):</b></Grid>
                  <Grid item>{order.totalSetupTime ? parseFloat(order.totalSetupTime) : '-'}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:process_time')} (min):</b></Grid>
                  <Grid item>{order.totalProcessTime ? parseFloat(order.totalProcessTime) : '-'}</Grid>
                </Grid>
                <Grid item />
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:setup_cost')}:</b></Grid>
                  <Grid item>{toCurrency(order.totalSetupCost)}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:process_cost')}:</b></Grid>
                  <Grid item>{toCurrency(order.totalProcessCost)}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:material_cost')}:</b></Grid>
                  <Grid item>{toCurrency(order.totalMaterialCost)}</Grid>
                </Grid>
                <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:additional_cost')}:</b></Grid>
                  <Grid item>{toCurrency(order.totalMinPriceCost)}</Grid>
                </Grid>
                {order.shippingCost && <Grid item container xs={12} spacing={1} justifyContent={'space-between'}>
                  <Grid item><b>{t('estimator:estimated_shipping_costs')}:</b></Grid>
                  <Grid item>{toCurrency(order.shippingCost)}</Grid>
                </Grid>}
              </Grid>
            </CardContent>
            <CardActions style={{paddingRight: 15, marginTop: 15}}>
              <Grid container spacing={5} justifyContent={'flex-end'}>
                <Grid item><Typography variant={'h5'}><b>{t('estimator:total_cost')}</b></Typography></Grid>
                <Grid item><Typography variant={'h5'}>{toCurrency(order.totalCost)}</Typography></Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withTheme(withTranslation(['orders', 'order_details', 'estimator', 'modals', 'order_checkout_modal'])(OrderDetailsCards));
