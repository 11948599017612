import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Table from 'components/Table/Table.js';
import SharedCheckboxCell from 'components/SharedParts/SharedCheckboxCell/SharedCheckboxCell';
import SharedLinkUrlInput from 'components/SharedParts/SharedLinkUrlInput/SharedLinkUrlInput';
import SharedLinkPasswordInput from 'components/SharedParts/SharedLinkPasswordInput/SharedLinkPasswordInput';
import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import SharedLinkSearchError from 'components/SharedParts/SharedLinkSearchError/SharedLinkSearchError';
import {toDisplayDate} from 'utils/datetimeHelpers';
import {isDateExpiredByNow} from 'utils/datetimeHelpers';
import './SharedPartsTable.scss';
import {NavLink} from 'react-router-dom';

const useStyles = makeStyles({
  ...tableStyles,
  ...selectStyles,
});

const SharedPartsTable = ({
  list,
  onItemSelect,
  onLinkResetPassClick,
  isSearchMode,
}) => {
  const {t} = useTranslation('sharedparts');
  const classes = useStyles();

  /**
   * EVENTS
   */
  const handleCheckboxChange = data => {
    if (typeof onItemSelect === 'function') {
      onItemSelect(data);
    }
  };

  useEffect(() => {}, [list]);

  /**
   * TABLE COMPONENTS HELPERS
   */
  const getCheckbox = item => {
    return <SharedCheckboxCell item={item} onSelect={handleCheckboxChange} />;
  };

  const linkStatus = link => {
    if (isDateExpiredByNow(link.expiryDatetime)) {
      return (
        <div style={{color: 'orange', textAlign: 'left'}}>
          {t('shareparts_link_expired')}
        </div>
      );
    } else {
      return link.active ? (
        <div style={{color: 'green', textAlign: 'left'}}>
          {t('shareparts_link_active')}
        </div>
      ) : (
        <div style={{color: 'red', textAlign: 'left'}}>
          {t('shareparts_link_inactive')}
        </div>
      );
    }
  };

  const linkUrl = urlId => {
    return (
      <SharedLinkUrlInput
        id={urlId}
        url={window.location.host + '/download/' + urlId}
      />
    );
  };

  const passwordInp = (id, passwordValue) => {
    return (
      <SharedLinkPasswordInput
        id={id}
        password={passwordValue}
        onResetClick={onLinkResetPassClick}
      />
    );
  };

  const nameWithLink = (name, partListId) => {
    return (
      <NavLink
        to={'/part-lists/' + partListId}
        className={'shared-list-name-link'}
      >
        {name}
      </NavLink>
    );
  };

  const displayExpiryDate = date => {
    return date === null
      ? t('shareparts_link_never_expires')
      : toDisplayDate(date);
  };

  return (
    <>
      {!list.length && isSearchMode ? (
        <SharedLinkSearchError />
      ) : (
        <Paper style={{marginTop: 10}} className={'shared-parts-table'}>
          <Table
            tableHead={[
              '#',
              t('shareparts_name'),
              t('shareparts_link_created_on'),
              t('shareparts_valid_until'),
              t('shareparts_link_status'),
              t('shareparts_link'),
              t('shareparts_password'),
            ]}
            tableData={
              list
                ? list.map(sharedLink => {
                  return [
                    getCheckbox(sharedLink),
                    nameWithLink(sharedLink.shareName, sharedLink.partListId),
                    toDisplayDate(sharedLink.createdAt),
                    displayExpiryDate(sharedLink.expiryDatetime),
                    linkStatus(sharedLink),
                    linkUrl(sharedLink.urlId),
                    sharedLink.password
                      ? passwordInp(sharedLink.id, sharedLink.password)
                      : t('shareparts_accessible_without_pass'),
                  ];
                })
                : []
            }
            customCellClasses={[classes.center, classes.center, classes.left]}
            customClassesForCells={[0, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              'shr-tbl-name',
              'shr-tbl-create-on',
              'shr-tbl-valid-until',
              'shr-tbl-link-status',
              'shr-tbl-link',
              'shr-tbl-password',
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
          />
        </Paper>
      )}
    </>
  );
};

export default SharedPartsTable;
