import React, {Component} from 'react';
import {List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withTheme} from '@mui/styles';

const mapStateToProps = state => ({
  processes: state.general.allProcesses,
});

const connectPartConfigDetailsTable = connect(mapStateToProps);

class PartConfigDetailsTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {partConfig, t, theme, hideManufacturingAnalysis} = this.props;

    return <TableContainer component={Paper} elevation={2}>
      <Table style={{backgroundColor: theme.palette.action.hover}}>
        {(partConfig.processes || partConfig.partConfigProcesses)?.length ? (
          <>
            <TableHead>
              <TableRow>
                <TableCell colSpan={hideManufacturingAnalysis ? 2 : 3}><b>{t('partdetails:pdi_processes')}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(partConfig.processes || partConfig.partConfigProcesses).map(partConfigProcess => (
                <TableRow
                  key={`part-config-${partConfig.id}-process-${partConfigProcess.id}`}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    {t(`processes:${this.props.processes?.find(item => item.id === partConfigProcess.processId)?.key}`)}
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                    }}
                  >
                    {partConfigProcess.processFeatureValues?.length ? (
                      <List disablePadding>
                        {partConfigProcess.processFeatureValues.map(pfv => (
                          <ListItem disablePadding>
                            {t(`processfeatures:${pfv.processFeatureTypeKey}`)}: {pfv.value?.trim()?.length ? pfv.value.trim() : pfv.valueAuto?.trim()}
                          </ListItem>
                        ))}
                      </List>
                    ) : ''}
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell style={{textAlign: 'center'}}>
                {t(`partconfigurations:t_config_has_no_processes`)}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>;
  }
}

export default withTheme(withTranslation(['processes', 'processfeatures', 'partconfigurations', 'partdetails'])(connectPartConfigDetailsTable(PartConfigDetailsTable)));
