import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, InputAdornment, TextField } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import RegularButton from 'components/CustomButtons/Button';

import { validatePassword } from 'utils/validationsHelpers';
import { sendChangePassword } from 'services/users';

export default function UserPasswordChange() {
  const { t } = useTranslation(['settings, errors']);
  const [oldPassword, setOldPassword] = React.useState('');
  const [oldPasswordError, setOldPasswordError] = React.useState({
    error: false,
    msg: '',
  });
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordError, setNewPasswordError] = React.useState({
    error: false,
    msg: '',
  });
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState({
    error: false,
    msg: '',
  });

  const clearErrors = () => {
    setOldPasswordError({ error: false, msg: '' });
    setNewPasswordError({ error: false, msg: '' });
    setConfirmPasswordError({ error: false, msg: '' });
  };

  const changePassword = async () => {
    clearErrors();
    let isValid = true;

    if (!oldPassword) {
      isValid = false;
      setOldPasswordError({ error: true, msg: 'errors:error_password_required' });
    }

    const newPassResult = validatePassword(newPassword);
    if (newPassResult.error) {
      isValid = false;
      setNewPasswordError(newPassResult);
    }

    const confirmPassResult = validatePassword(confirmPassword);
    if (confirmPassResult.error) {
      isValid = false;
      setConfirmPasswordError(confirmPassResult);
    }

    if (
      !newPassResult.error &&
      !confirmPassResult.error &&
      newPassword !== confirmPassword
    ) {
      isValid = false;
      setConfirmPasswordError({
        error: true,
        msg: t('errors:error_passwords_not_match'),
      });
    }

    if (isValid && !!oldPassword) {
      var status = await sendChangePassword({
        currentPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmPassword,
      });

      if (status === 401) {
        setOldPasswordError({
          error: true,
          msg: t('errors:error_password_incorrect'),
        });
      }
    }
  };

  const clearField = type => {
    switch (type) {
      case 'old':
        setOldPassword('');
        setOldPasswordError({ error: false, msg: '' });
        break;
      case 'new':
        setNewPassword('');
        setNewPasswordError({ error: false, msg: '' });
        break;
      case 'confirm':
        setConfirmPassword('');
        setConfirmPasswordError({ error: false, msg: '' });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('settings:acs_old_password')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField
            error={oldPasswordError.error}
            type="password"
            fullWidth
            helperText={t(oldPasswordError.msg)}
            value={oldPassword}
            onChange={event => {
              setOldPassword(event.target.value);
            }}
            InputProps={{
              endAdornment: oldPassword && (
                <InputAdornment position="end">
                  <ClearIcon
                    className="clear-pass-button"
                    onClick={() => clearField('old')}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('settings:acs_new_password')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField
            error={newPasswordError.error}
            type="password"
            fullWidth
            helperText={t(newPasswordError.msg)}
            value={newPassword}
            onChange={event => {
              setNewPassword(event.target.value);
            }}
            InputProps={{
              endAdornment: newPassword && (
                <InputAdornment position="end">
                  <ClearIcon
                    className="clear-pass-button"
                    onClick={() => clearField('new')}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid item sm={12} container className="password-input">
        <Grid
          item
          sm={4}
          container
          alignItems="flex-start"
          className="password-input-label"
        >
          <span>{t('settings:acs_confirm_password')}:</span>
        </Grid>
        <Grid item sm={8} container alignItems="center">
          <TextField
            error={confirmPasswordError.error}
            type="password"
            fullWidth
            helperText={t(confirmPasswordError.msg)}
            value={confirmPassword}
            onChange={event => {
              setConfirmPassword(event.target.value);
            }}
            InputProps={{
              endAdornment: confirmPassword && (
                <InputAdornment position="end">
                  <ClearIcon
                    className="clear-pass-button"
                    onClick={() => clearField('confirm')}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        sm={12}
        container
        alignItems="center"
        style={{ marginTop: 40 }}
      >
        <RegularButton
          disabled={false}
          color="success"
          onClick={changePassword}
        >
          {t('settings:acs_save_logout')}
        </RegularButton>
      </Grid>
    </>
  );
}
