import React from 'react';
import { PartListNewComponent } from 'components/PartList/PartListNewComponent';

export const NewPartList = () => {
  return (
    <>
      <PartListNewComponent fetchAll editableName />
    </>
  );
};
