import React, {useEffect} from 'react';
import {fetchPartConfig} from 'services/parts';
import {useNavigate, useParams} from 'react-router-dom';

const PartConfigDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    fetchPartConfig(id)
      .then(response => {
        const config = response.data;
        navigate(`/part/${config.partId}?configId=${config.id}`);
      }).catch(() => {
      navigate('/404');
    });
  }, []);

  return (
    <>
    </>
  );
};

export default PartConfigDetails;
