export const emptyLabelVal = () => {
  return '-';
};

/**
 * @description Return default value if provided value is not defined or null
 * @param {*} value
 */
export const handleEmptyValue = value => {
  if (!value) {
    return emptyLabelVal();
  }

  // handle number
  if (typeof value === 'number') return value;

  if (value.trim().length === 0) {
    return emptyLabelVal();
  }

  return value || emptyLabelVal();
};

/**
 * @description Material type have special case of null
 * @param {*} value
 */
export const handleEmptyMaterialType = value => {
  if (value === 'material_null') {
    return emptyLabelVal();
  }
  return value || emptyLabelVal();
};

/**
 * @description Bounds have special case of null
 * @param {*} value
 */
export const handleEmptyBounds = value => {
  if (value === 'null / null / null') {
    return emptyLabelVal();
  }
  return value;
};

export const copyToClipboard = inputId => {
  /* Get the text field */
  var copyText = document.getElementById(inputId);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand('copy');
};
