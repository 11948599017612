import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { useTranslation } from 'react-i18next';
import '../parts-modals.scss';
const useStyles = makeStyles(styles);

export const DeleteListModal = ({ confirm, open }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    if (open) {
      deleteModal();
    } else {
      closeAlert();
    }
  }, [open]);

  const hideAlert = isConfirmed => {
    confirm(isConfirmed);
    setAlert(null);
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const deleteModal = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_general_delete')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={`${classes.button} ${classes.danger}`}
        cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
        title={t('mdl_delete_part_list_title')}
      >
        {t('mdl_delete_list_description')}
      </SweetAlert>
    );
  };

  return <div>{alert}</div>;
};
