import React from 'react';
import { PaginationComponent } from 'containers/Pagination/Pagination';
import { Grid } from '@mui/material';

/**
 * Use all item list components with this layout the need pagination
 * @param {*} param0
 */
export default function PaginationLayout({ pagination, getPage, children }) {
  return (
    <Grid container item xs={12}>
      {children}
      {pagination && (
        <PaginationComponent paginationObject={pagination} getPage={getPage} />
      )}
    </Grid>
  );
}
