import React, { useState, useRef, useContext, useEffect } from 'react';
import { TextField } from '@mui/material';

import { ConfigStep } from './ConfigStep/ConfigStep';
import InlineEditField from 'components/InlineEditField/InlineEditField';

import './ConfigSidebar.scss';
import { ConfigContext } from '../../ConfigContext';

import { UPDATE_TEMP_DATA } from 'components/Modals/ConfigsModal/ConfigTypes';

import { updateConfigInfo } from '../../ConfigActions';
import { useTranslation } from 'react-i18next';
import PartThumbnail from 'components/PartDetails/PartInformation/PartThumbnail/PartThumbnail';

export const ConfigSidebar = ({ part, steps, tempData }) => {
  const { dispatch, state } = useContext(ConfigContext);
  const { t } = useTranslation(['partconfigurator', 'filespage']);

  const [originalConfigName, setOriginalConfigName] = useState(
    tempData.name || ''
  );
  const [configName, setConfigName] = useState('');
  const [configNameError, setConfigNameError] = useState(false);

  const configNameInputRef = useRef(null);

  useEffect(() => {
    if (tempData.name) {
      setConfigName(tempData.name);
    }
  }, [tempData.name]);

  const handleConfigNameChange = event => {
    const name = event.target.value;
    if (!name.trim()) {
      setConfigNameError(true);
      setConfigName('');
    } else {
      setConfigNameError(false);
      setConfigName(name);
    }
  };

  const handleConfigNameSave = isCancel => {
    if (isCancel) {
      setConfigName(originalConfigName);
      return;
    }

    setOriginalConfigName(configName);

    dispatch({
      type: UPDATE_TEMP_DATA,
      payload: {
        name: configName,
      },
    });

    return updateConfigInfo(state.tempData.configId, { name: configName });
  };

  return (
    <div className="sidebar">
      <div className="config-name-wrap">
        <InlineEditField
          text={configName}
          type="input"
          placeholder={t('config_name')}
          displayVariant="body2"
          done={handleConfigNameSave}
        >
          <TextField
            autoFocus
            fullWidth
            value={configName}
            inputRef={configNameInputRef}
            error={configNameError}
            inputProps={{
              type: 'text',
              name: `Config${new Date().getTime()}`,
              placeholder: t('config_name'),
            }}
            onChange={handleConfigNameChange}
            className="config-name"
          />
        </InlineEditField>
      </div>
      <div className="config-steps">
        {tempData.attachments && (
          <div className="part-thumb-sidebar">
            <PartThumbnail attachments={tempData.attachments} />
          </div>
        )}
        {steps && steps.map((step, i) => (
          <ConfigStep
            key={i}
            step={step}
          />
        ))}
      </div>
      <div className="part-name">
        <div className={'part-name-lbl'}>{t('pc_mdl_part_name')}</div>
        <div className={'part-name-val'}>{`${part.name}`}</div>
      </div>
    </div>
  );
};
