import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import CustomInput from 'components/CustomInput/CustomInput';
import ShareIcon from '@mui/icons-material/Share';
import makeStyles from '@mui/styles/makeStyles';

import './ShareModal.scss';
import '../../parts-modals.scss';
import { Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateShareListModal } from '../ShareLinkStages/CreateShareListModal/CreateShareListModal';
import ShareDetailsModal from '../ShareLinkStages/ShareDetailsModal/ShareDetailsModal';
import ShareLinkModal from '../ShareLinkStages/ShareLinkModal/ShareLinkModal';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);

export const ShareModal = ({ selectedParts, dataType }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();

  const [alert, setAlert] = useState(null);
  const listData = useRef(null);
  const shareOptions = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      shareModal();
    }
  }, [open]);

  useEffect(() => {}, [alert]);

  const createListModal = () => {
    setAlert(
      <CreateShareListModal
        open={true}
        confirm={listData}
        next={shareDetailsModal}
        setOpen={setOpen}
        partIds={selectedParts}
      />
    );
  };

  const shareDetailsModal = () => {
    setAlert(
      <ShareDetailsModal
        open={true}
        setOpen={setOpen}
        confirm={shareOptions}
        next={shareLinkModal}
        listData={listData}
      />
    );
  };

  const shareLinkModal = () => {
    const data = {
      expiryDatetime: shareOptions.current.expiryDatetime,
      password: shareOptions.current.password,
      link: `${window.location.hostname}/download/${shareOptions.current.urlId}`,
    };
    setAlert(
      <ShareLinkModal
        open={true}
        setOpen={setOpen}
        confirm={setOpen}
        data={data}
      />
    );
  };

  const shareModal = () =>
    setAlert(
      <SweetAlert
        customClass="share-modal"
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('mdl_share_title')}
        closeOnClickOutside={false}
        showCloseButton={true}
        onConfirm={() => {}}
        onCancel={() => setOpen(false)}
        showConfirm={false}
        showCancel={false}
        confirmBtnCssClass={`${classes.button} ${classes.success} md-disabled-button`}
        cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
        disabled
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            inputProps={{
              disabled: true,
            }}
            labelText={t('mdl_share_with_label')}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText={t('mdl_share_msg_label')}
            inputProps={{
              multiline: true,
              rows: 2,
              disabled: true,
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid container spacing={1} justifyContent={'center'}>
          <Grid item>
            <Button
              onClick={() => setOpen(false)}
              className={`${classes.button} ${classes.cancel}`}
            >
              {t('mdl_general_cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled
              onClick={() => setOpen(false)}
              className={`${classes.button} ${classes.success} md-disabled-button`}
              style={{ color: '#fff' }}
            >
              {t('mdl_share_send')}
            </Button>
          </Grid>
        </Grid>
        <Box component="div" m={3}>
          {t('mdl_share_link_description')}
        </Box>
        <Box component="div">
          <Button
            className={`${classes.button} ${classes.success}`}
            onClick={() => {
              dataType === 'parts' ? createListModal() : shareDetailsModal();
            }}
          >
            {t('mdl_share_link_create')}
          </Button>
        </Box>
      </SweetAlert>
    );

  return (
    <>
      {open && alert ? alert : null}
      <Button onClick={() => setOpen(true)} variant="outlined">
        <ShareIcon />
      </Button>
    </>
  );
};
