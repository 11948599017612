import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CardContent, CardHeader, Grid, Paper, Tooltip} from '@mui/material';
import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import ListCardActions from 'components/PartLists/ListCardActions/ListCardActions';
import StatusChip from 'components/StatusChip/StatusChip';
import {DeleteListModal, RenameListModal, ShareListModal} from 'components/Modals';
import {deletePartList, fetchPartListFiles, fetchThumbnalFromUrl} from 'services/part-lists';
import {ListCardActionsEnum} from 'models/enums/ListCardActionsEnum';
import noPreview from 'assets/img/no-preview.jpg';
import hourglass from 'assets/img/hourglass.gif';
import warning from 'assets/img/warning.gif';
import './PartListCard.scss';
import Card from '../Card/Card';

export const PartListCard = ({list, refresh}) => {
  const {t} = useTranslation('partlists');
  const [renameListData] = useState({id: null, name: ''});
  const [, setLoading] = useState(true);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [successModalData, setSuccessModalData] = useState({title: '', description: ''});
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  useEffect(() => {
    async function getThumbnail () {
      if (!list) {
        setThumbnailSrc('');
      } else {
        setLoading(true);
        await loadThumbnail();
        setLoading(false);
      }
    }

    getThumbnail();
  }, [list]);

  const renderInfoPill = partList => {
    return (
      <Grid item xs={12} container justifyContent="center">
        <Grid item>
          <StatusChip data={partList} />
        </Grid>
      </Grid>
    );
  };

  const handleActionClick = ({value, list}) => {
    switch (value) {
      case ListCardActionsEnum.Download.val:
        if (list) {
          fetchPartListFiles(list.id);
        }
        break;

      case ListCardActionsEnum.Rename.val:
        break;

      case ListCardActionsEnum.Share.val:
        setOpenShareModal(true);
        break;

      case ListCardActionsEnum.Delete.val:
        setOpenDeleteModal(true);
        break;

      default:
        break;
    }
  };

  const handleSuccessConfirm = () => {
    setIsSuccessModalOpen(false);
    setSuccessModalData({title: '', description: ''});
  };

  const confirmModalRename = () => {
    setOpenRenameModal(false);
  };

  const confirmShareModal = () => {
    setOpenShareModal(false);
  };

  const confirmDeleteModal = isConfirmed => {
    if (isConfirmed) {
      deletePartList(list.id)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            refresh && typeof refresh === 'function' && refresh();
          }
        })
        .catch(() => {});
    }

    setOpenDeleteModal(false);
  };

  const loadThumbnail = async () => {
    const response = await fetchThumbnalFromUrl(list.id, list.thumbnail, {
      width: 500,
      height: 500,
    });
    if (response) {
      setThumbnailSrc(response.thumbnail);
    }
  };

  return (
    <>
      <Paper className={'part-list-card'} style={{justifyContent: 'center'}}>
        <Card style={{width: '100%', boxShadow: 'none', borderRadius: 15, marginTop: 3, marginBottom: 12}}>
          <CardHeader
            title={
              list.name.trim().length > 18 ?
                <NavLink to={`/part-lists/${list.id}`} style={{
                  textDecoration: 'none',
                  color: '#000',
                }}
                >
                  <Tooltip title={list.name} placement={'bottom'} arrow>
                    <div>{list.name.substr(0, 17).trim()}&hellip;</div>
                  </Tooltip>
                </NavLink> :
                <NavLink to={`/part-lists/${list.id}`} style={{
                  textDecoration: 'none',
                  color: '#000',
                }}
                >
                  <div>{list.name.trim()}</div>
                </NavLink>
            }
            subheader={<div style={{fontSize: 'small'}}>{list.kmsId}</div>}
          />
          <CardContent style={{textAlign: 'center', margin: 0, padding: 0}}>
            <NavLink to={`/part-lists/${list.id}`}>
              <CardContent style={{textAlign: 'center'}}>
                <img
                  src={thumbnailSrc || noPreview}
                  onLoad={loadThumbnail}
                  alt="Card Thumbnail"
                  style={{
                    height: 200,
                    maxWidth: '100%',
                    backgroundSize: 'contain',
                  }}
                />
              </CardContent>
            </NavLink>
            <Grid container className="part-list-card-navfooter">
              {renderInfoPill(list)}
              <Grid
                container
                item
                sm={7}
                alignContent="center"
                justify="flex-start"
                className="num-parts"
              >
                <span>{`${list.partCount} ${t('number_of_parts')}`}</span>
              </Grid>
              <Grid
                container
                item
                sm={5}
                justifyContent={'flex-end'}
                alignItems={'center'}
                className={'list-actions'}
              >
                {list.maRunning &&
                <Grid item>
                  <Tooltip title={t('ma_running')}>
                    <img src={hourglass} alt={'hourglass'} style={{width: '18px', height: '18px', alignSelf: 'center'}} />
                  </Tooltip>
                </Grid>}

                {list.maRejected || list.maPartiallyRejected &&
                <Grid item>
                  <Tooltip title={t('ma_rejected')}>
                    <img src={warning} alt={'warning'} style={{width: '20px', height: '20px', alignSelf: 'center', marginLeft: '10px'}} />
                  </Tooltip>
                </Grid>
                }

                <Grid item>
                  <ListCardActions
                    partsCount={list.partCount}
                    onActionClick={({value}) => handleActionClick({value, list})}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <RenameListModal
        open={openRenameModal}
        confirm={confirmModalRename}
        name={renameListData.name}
      />

      <ShareListModal
        open={openShareModal}
        setOpen={setOpenShareModal}
        confirm={confirmShareModal}
        listId={list.id}
        listName={list.name}
      />
      <DeleteListModal open={openDeleteModal} confirm={confirmDeleteModal} />
      <SuccessModal
        open={isSuccessModalOpen}
        title={successModalData.title}
        description={successModalData.description}
        onConfirm={handleSuccessConfirm}
      />
    </>
  );
};
