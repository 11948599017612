import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import './InlineEditField.scss';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

//
// Inline edit field component
// Props:
// - text: display text (also used in input as value)
// - done: function that is called on focus out
// - placeholder: placeholder text if text prop is missing or null
// - children: input field and other elements defined as children
// - childRef: input reference
// - displayVariant: text display variant while not in edit mode
//

const InlineEditField = ({
  text,
  done,
  onCancelClick,
  placeholder,
  children,
  id,
  childRef,
  displayVariant,
  redirectRoute,
  onEditClick,
  onEditClose,
  // lock the input in one state
  lock,
  ...props
}) => {
  const [isEditing, setEditing] = useState(null);
  const cancel = useRef(false);

  useEffect(() => {
    // focus input
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
    // call done after focus out
    if (isEditing !== null && isEditing === false) {
      done(cancel.current, text);
    }
  }, [isEditing, childRef]);

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('keydown', handleKeyPress);
      document.addEventListener('editClick', closeEdit);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('editClick', closeEdit);
    };
  }, [isEditing]);

  const closeEdit = event => {
    if (event.detail.editId !== id) {
      handleListNameCancel();
    }
  };

  // check for key press - Enter (confirm) or Escape (cancel)
  const handleKeyPress = event => {
    switch (event.key) {
      case 'Enter':
        handleListNameSave();
        break;
      case 'Escape':
        handleListNameCancel();
        break;
      default:
        break;
    }
  };

  const handleListNameSave = () => {
    if (typeof onEditClose === 'function') {
      onEditClose();
    }
    cancel.current = false;
    setEditing(false);
  };

  const handleListNameCancel = () => {
    cancel.current = true;
    setEditing(false);
    if (typeof onEditClose === 'function') {
      onEditClose();
    }
    if (typeof onCancelClick === 'function') {
      onCancelClick();
    }
  };

  const handleEditClick = () => {
    if (typeof onEditClick === 'function') {
      onEditClick(id);
    }

    if (lock) {
      return;
    }
    setEditing(true);
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div className="input-wrapper">
          {children}
          <div className="inline-action-buttons">
            <Button size="small" color="primary" onClick={handleListNameSave}>
              <CheckIcon fontSize="small" />
            </Button>
            <Button
              size="small"
              color="secondary"
              onClick={handleListNameCancel}
            >
              <CloseIcon fontSize="small" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="inline-edit" style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
          <Typography variant={displayVariant}>
            {redirectRoute ? (
              <Link to={redirectRoute}>
                {text || placeholder}
              </Link>
            ) : (text || placeholder)}
          </Typography>
          <EditIcon
            fontSize="small"
            className="edit-icon"
            style={{color: '#88898A'}}
            onClick={() => handleEditClick()}
          />
        </div>
      )}
    </section>
  );
};

export default InlineEditField;
