import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {getCurrentUserInfo, isAuthenticated, logoutUser} from 'services/auth';
import {connect, useDispatch, useSelector} from 'react-redux';
import initializeFirebase from 'utils/firebase';
import initializeAutopilot from 'utils/autopilot';
import initializeUserflow from 'utils/userflow';
import {Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import TopBar from 'components/TopBar/TopBar';
import routes from 'routes.js';
import {NewPartList, PartDetails} from 'containers';
import PartListEstimator from 'containers/PartListEstimator/PartListEstimator';
import PartConfigDetails from 'containers/PartConfigDetails/PartConfigDetails';
import OrderDetails from '../containers/OrderDetails/OrderDetails';
import ErrorPage from 'views/Pages/ErrorPage';
import {PartListDetails} from '../containers';
import UserSettings from 'containers/UserSettings/UserSettings';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import {getAllProcesses, getProcesses, getSuppliers, getUserInfo} from 'store/general/generalActions';
import {pinAsStickyHeader} from 'store/breadcrumbs/breadcrumbsActions';
import {addScrollListener, removeScrollListener} from 'utils/eventsHelpers';
import Knackbar from 'components/Knackbar/Knackbar';

const useStyles = makeStyles(styles);

const AppLayoutComponent = props => {
  const {...rest} = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [color] = React.useState('blue');
  const [bgColor] = React.useState('white');
  const dispatch = useDispatch();
  const containerReference = React.createRef();
  const breadcrumbs = useSelector(state => state.breadcrumbs.data);
  const breadcrumbActions = useSelector(state => state.breadcrumbs.actions);

  const fetchUserInfo = async () => {
    try {
      const {firstName, lastName, email, language} = await getCurrentUserInfo();
      initializeFirebase(email);
      initializeAutopilot(email);
      initializeUserflow(`${firstName} ${lastName}`, email, language);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(getUserInfo());
  });

  useEffect(() => {
    dispatch(getProcesses());
    dispatch(getAllProcesses());
    dispatch(getSuppliers());
    fetchUserInfo();
  }, [dispatch]);

  useEffect(() => {
    if (containerReference && containerReference.current)
      containerReference.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
  }, [props.match]);

  // styles
  const classes = useStyles();
  const mainPanelClasses = classes.mainPanel;

  // ref for main panel div
  const mainPanel = React.createRef();

  // functions for changeing the states from components
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoutes = (routes, containerRef) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === '/') {
        return (
          <Route
            path={prop.layout + prop.path}
            element={<prop.component {...props} containerRef={containerRef} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    const breakpointFromTop = -75;
    const breadcrumbsScrollListener = addScrollListener(
      'app-layout-container',
      breakpointFromTop,
      changeSelectionPinPosition,
    );
    return () => {
      removeScrollListener(breadcrumbsScrollListener);
    };
  }, []);

  const changeSelectionPinPosition = ({reachedBreakPoint}) => {
    dispatch(pinAsStickyHeader(reachedBreakPoint));
  };

  return (
    <div className={classes.wrapper}>
      <TopBar {...props} />

      <Sidebar
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={true}
        {...rest}
      />

      <Grid container>
        <Grid
          item xs={12} style={{
          paddingTop: 10,
          paddingLeft: 84,
          paddingRight: 4,
          position: 'fixed',
          width: '100%',
          backgroundColor: 'transparent',
        }}
        >
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            containered={true}
            actions={breadcrumbActions}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={`${mainPanelClasses} ${classes.container} app-layout app-layout-container`}>
            <Knackbar />
            <Routes>
              <Route path={'/'} key={'home'} element={<Navigate to={'/home'} replace={true} />} />
              <Route
                path={'/part/:id'}
                element={<PartDetails />}
                key={'details'}
              />
              <Route
                path={'/part-lists/new'}
                element={<NewPartList />}
                key={'list-new'}
              />
              <Route
                path={'/part-lists/:id/estimate'}
                element={<PartListEstimator />}
                key={'list-estimate'}
              />
              <Route
                path={'/part-lists/:id'}
                element={<PartListDetails />}
                key={'list-details'}
              />
              <Route
                path={'/part-configs/:id'}
                element={<PartConfigDetails />}
                key={'config-details'}
              />
              <Route
                path={'/orders/:id'}
                element={<OrderDetails />}
                key={'order-details'}
              />
              <Route
                path={'/user-settings'}
                element={<UserSettings />}
                key={'settings'}
              />
              {getRoutes(routes, mainPanel)}
              <Route element={<ErrorPage />} />
            </Routes>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({general}) => ({
  general,
});

connect(mapStateToProps, {
  getProcesses,
  getUserInfo,
})(AppLayoutComponent);

export default ({...rest}) => {
  return (
    <Routes>
      <Route
        {...rest}
        element={isAuthenticated() ? <AppLayoutComponent {...rest} /> : logoutUser()}
      />
    </Routes>
  );
};
