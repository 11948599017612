import React, {useRef, useState, useEffect} from 'react';
import i18n                                 from 'i18n';
import makeStyles from '@mui/styles/makeStyles';
import classNames                           from 'classnames';
import {changeUserLanguage}                 from '../../services/auth';
import MenuItem                             from '@mui/material/MenuItem';
import Paper                                from '@mui/material/Paper';
import ClickAwayListener                    from '@mui/material/ClickAwayListener';
import Grow                                 from '@mui/material/Grow';
import Popper                               from '@mui/material/Popper';
import MenuList                             from '@mui/material/MenuList';
import Button                               from '@mui/material/Button';
import style                                from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import en                                   from 'assets/img/en.png';
import de                                   from 'assets/img/de.png';
import {logEvent}                           from 'utils/firebase';
import './LanguageToggle.scss';


const useStyle = makeStyles(style);

export const LanguageToggle = ({user}) => {
  const classes = useStyle();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // If the user's language is currently null, set the user's language to that of the
    // local storage or to that of the default value of the app
    if (!user.language) {
      changeUserLanguage(localStorage.getItem('lang') || process.env.REACT_APP_LANG);
      return;
    }

    localStorage.setItem('lang', user.language);
    i18n.changeLanguage(user.language);
  }, [user]);

  const changeLanguage = lng => {
    // Update the user's language
    changeUserLanguage(lng);

    // Update local store with the language
    localStorage.setItem('lang', lng);
    i18n.changeLanguage(lng);
    logEvent('kms_change_language', {language: lng});
    setOpen(false);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return <>
    <Button
      ref={anchorRef}
      onClick={handleToggle}
      aria-controls='profile-menu'
      className={classes.topBarButton}>
      {i18n.language === 'en' ? (
        <img src={en} className={'flag'} alt='English' />
      ) : (
        <img src={de} className={'flag'} alt='German' />
      )}
    </Button>

    <Popper
      open={open}
      anchorEl={anchorRef.current || {}}
      transition
      disablePortal
      placement='bottom'
      className={classNames({
        [classes.popperClose]: !open,
        [classes.popperResponsive]: true,
        [classes.popperNav]: true,
      })}
    >
      {({TransitionProps}) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'bottom',
          }}
        >
          <Paper id='menu-list-grow'>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                {i18n.language === 'en' ? (
                  <MenuItem
                    onClick={() => {
                      changeLanguage('de');
                    }}
                    className={classes.menuItem}
                  >
                    <img src={de} className={'flag'} alt='Deutsch' />
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      changeLanguage('en');
                    }}
                    className={classes.menuItem}
                  >
                    <img src={en} className={'flag'} alt='English' />
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>;
};
