import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Alert, Grid, IconButton} from '@mui/material';
import {InsertDriveFile, RemoveCircleOutline} from '@mui/icons-material';
import {Draggable} from 'react-beautiful-dnd';

class SourceAttachment extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {attachment, index, removeAttachmentFromPart} = this.props;

    return (
      <Draggable draggableId={`${attachment.id}`} index={index} key={attachment.id}>
        {provided => (
          <Alert
            ref={provided.innerRef}
            severity={'info'}
            variant={'filled'}
            icon={false}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Grid container columnSpacing={1} justifyContent={'center'} alignItems={'center'}>
              <Grid item><IconButton size={'small'} style={{color: 'white'}} disabled><InsertDriveFile style={{color: 'white'}} /></IconButton></Grid>
              <Grid item>{attachment.name}.{attachment.currentFile.fileFormat.extension}</Grid>
              <Grid item><IconButton size={'small'} style={{color: 'white'}} onClick={removeAttachmentFromPart}><RemoveCircleOutline /></IconButton></Grid>
            </Grid>
          </Alert>
        )}
      </Draggable>
    );
  }
}

export default withTranslation('automatic_configuration')(SourceAttachment);
