// import React, {Component, useContext} from 'react';
import React, {Component, setGlobal} from 'reactn';
import {withTranslation} from 'react-i18next';
import {Container, Grid, Typography} from '@mui/material';
import DashboardCard from 'components/Dashboard/DashboardCard';
import {Timelapse, Check, WarningAmber, PrecisionManufacturing} from '@mui/icons-material';
import {withSnackbar} from 'notistack';
import {withRouter} from '../../utils/helpers';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dashboardCards: [
        {
          model: 'part-list-teasers',
          title: 'ma_running',
          filter: {maRunning: true},
          icon: Timelapse,
          page: 'part-lists',
          emptyAction: () => setGlobal({isOpenUploadModal: true}),
          emptyLabel: 'create_part',
        },
        {
          model: 'part-list-teasers',
          title: 'quoting_in_progress',
          filter: {partListQuoteTeasers: {some: {statusKey: 'quoting_in_progress'}}},
          icon: Timelapse,
          page: 'part-lists',
        },
        {
          model: 'part-list-teasers',
          title: 'quoting_completed',
          filter: {
            partListQuoteTeasers: {
              some: {
                AND: [
                  {statusKey: 'quoting_completed'},
                  {quoteExpiryDatetime: {gt: new Date()}},
                ],
              },
              none: {
                statusKey: 'quoting_in_progress',
              },
            },
          },
          icon: WarningAmber,
          page: 'part-lists',
        },
        {
          model: 'ordered-part-list-teasers',
          title: 'in_production',
          filter: {statusKey: 'placed'},
          icon: PrecisionManufacturing,
          page: 'orders',
        },
        {
          model: 'ordered-part-list-teasers',
          title: 'delayed',
          filter: {and: [{statusKey: 'placed'}, {delayed: true}]},
          icon: WarningAmber,
          page: 'orders',
        },
        {
          model: 'ordered-part-list-teasers',
          title: 'delivered',
          filter: {statusKey: 'delivered'},
          icon: Check,
          page: 'orders',
        },
      ],
    };
  }

  render() {
    const {t} = this.props;

    return (
      <Container maxWidth={false}>
        <Typography variant={'h5'} sx={{paddingTop: '4px'}}>
          {t('part_lists')}
        </Typography>
        <Grid
          container
          spacing={8}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {this.state.dashboardCards.filter(dc => dc.model !== 'ordered-part-list-teasers').map(dashboardCard => (
            <Grid item key={dashboardCard.title} xs={12} md={6} lg={4} style={{textAlign: 'center'}}>
              <DashboardCard {...dashboardCard} {...this.props} />
            </Grid>
          ))}
        </Grid>
        <Typography variant={'h5'} sx={{paddingTop: '40px'}}>
          {t('orders')}
        </Typography>
        <Grid
          container
          spacing={8}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {this.state.dashboardCards.filter(dc => dc.model === 'ordered-part-list-teasers').map(dashboardCard => (
            <Grid item key={dashboardCard.title} xs={12} md={6} lg={4} style={{textAlign: 'center'}}>
              <DashboardCard {...dashboardCard} {...this.props} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withSnackbar(withTranslation('dashboard')(Dashboard)));
