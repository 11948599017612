import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableBody,
  Chip,
} from '@mui/material';
import {Settings} from '@mui/icons-material';

import {toCurrency} from '../../utils/numberHelpers';
import noPreview from 'assets/img/no-preview.jpg';
import {constructThumbnailUrl} from '../../services/orders';

class OrderDetailsTable extends Component {
  constructor(props) {
    super(props);
  }

  setThumbnailToNoPreviewAvailable = orderedPartId => {
    document.getElementById(`op-${orderedPartId}-thumbnail`).src = noPreview;
  };

  render() {
    const {t, order} = this.props;

    return (
      <Grid item direction={'column'} xs={12}>
        <Card>
          <CardHeader
            style={{backgroundColor: '#dfdfdf'}}
            title={
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Settings fontSize={'large'} />
                <Typography variant={'h5'} style={{paddingLeft: 10}}>{t('order_details:ordered_parts')}</Typography>
              </div>
            }
          />
          <CardContent>
            <Grid container spacing={2} direction={'column'}>
              {order.orderedPartConfigTeasers.map((opc, idx) => (
                <Grid key={opc.id} item container alignItems={'center'} spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <img
                      id={`op-${opc.id}-thumbnail`}
                      src={constructThumbnailUrl(opc.orderedPartId)}
                      alt="Part Thumbnail"
                      style={{
                        maxHeight: 200,
                        maxWidth: '100%',
                      }}
                      onError={() => this.setThumbnailToNoPreviewAvailable(opc.id)}
                    />
                  </Grid>
                  <Grid item container direction={'column'} xs={12} sm={6} md={8} lg={10} spacing={0}>
                    <Grid item style={{paddingBottom: 5}}><b style={{fontSize: 'larger'}}>{opc.orderedPart.name}</b></Grid>
                    <Grid item><i>{t('order_details:article_number')}</i>: {opc.orderedPart.articleNr}</Grid>
                    <Grid item><i>{t('order_details:drawing_number')}</i>: {opc.orderedPart.drawingNr}</Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{paddingBottom: 8}}>{t('order_details:ordered_configs')}</Typography>
                    <TableContainer component={Paper}>
                      <Table size={'small'} style={{overflow: 'hidden'}}>
                        <TableHead>
                          <TableRow style={{backgroundColor: '#e0e0e0'}}>
                            <TableCell><b>{t('order_details:name')}</b></TableCell>
                            <TableCell align={'right'}><b>{t('estimator:additional_cost')}</b></TableCell>
                            <TableCell align={'right'}><b>{t('estimator:total_cost')}</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={opc.id} style={{backgroundColor: idx % 2 !== 0 ? '#f6f6f6' : '#fff'}}>
                            <TableCell colSpan={7}>
                              <Grid container spacing={5} alignItems={'center'}>
                                <Grid item><b>{opc.name || opc.id}</b></Grid>
                                <Grid item>
                                  <Chip
                                    color={'secondary'}
                                    size={'small'}
                                    style={{color: '#fff'}}
                                    label={<><b>{t('estimator:batch_size')}</b>: {opc.batchSize}</>}
                                  />&nbsp;
                                  <Chip
                                    color={'secondary'}
                                    size={'small'}
                                    style={{color: '#fff'}}
                                    label={<><b>{t('estimator:material')}</b>: {opc.materialName}</>}
                                  />&nbsp;
                                  <Chip
                                    color={'secondary'}
                                    size={'small'}
                                    style={{color: '#fff'}}
                                    label={<><b>{t('estimator:material_cost')}</b>: {toCurrency(opc.materialCost)}</>}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          {opc.orderedPartConfigProcesses.map(opcp => (
                            <TableRow key={`${opc.id}_${opcp.id}`} style={{backgroundColor: idx % 2 !== 0 ? '#f6f6f6' : '#fff'}}>
                              <TableCell>
                                <div style={{paddingLeft: 25}}>
                                  {t(`processes:${opcp.process.key}`)} <small><i>{t(`estimator:${opcp.process.class}`)}</i></small>
                                </div>
                              </TableCell>
                              <TableCell align={'right'}>{opcp.process.class !== 'production_requirement' && toCurrency(opcp.orderedManufacturingAnalysisTeaser?.minPriceCost) || '-'}</TableCell>
                              <TableCell align={'right'}>{opcp.process.class !== 'production_requirement' && toCurrency(opcp.orderedManufacturingAnalysisTeaser?.totalCost) || '-'}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableHead>
                          <TableRow style={{backgroundColor: '#e0e0e0'}}>
                            <TableCell align={'right'}><b>{t('order_details:total_including_material_costs')}</b></TableCell>
                            <TableCell align={'right'}><b>{toCurrency(opc.totalMinPriceCost)}</b></TableCell>
                            <TableCell align={'right'}><b>{toCurrency(opc.totalCost)}</b></TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withTranslation(['orders', 'order_details', 'processes', 'estimator'])(OrderDetailsTable);
