import {PartStorage} from 'containers';
import {PartLists} from 'containers';
import {SharedParts} from 'containers/SharedParts/SharedParts';
import Orders from 'containers/Orders/Orders';
import Dashboard from 'containers/Dashboard/Dashboard';
import PartLibrary from './containers/PartLibrary/PartLibrary';

const dashRoutes = [
  {
    path: 'home',
    name: 'route_dashboard',
    key: 'route_dashboard',
    icon: 'dashboard',
    component: Dashboard,
    layout: '/',
  },
  {
    path: 'part-storage',
    name: 'route_storage_parts',
    key: 'route_storage_parts',
    icon: 'precision_manufacturing',
    component: PartStorage,
    layout: '/',
  },
  {
    path: 'part-library',
    name: 'route_storage_part_library',
    key: 'route_storage_part_library',
    icon: 'manage_search',
    component: PartLibrary,
    layout: '/',
  },
  {
    path: 'part-lists',
    name: 'route_storage_lists',
    key: 'route_storage_lists',
    icon: 'dynamic_feed',
    component: PartLists,
    layout: '/',
  },
  {
    path: 'orders',
    name: 'route_storage_orders',
    key: 'route_storage_orders',
    icon: 'shopping_cart',
    component: Orders,
    layout: '/',
  },
  {
    path: 'shared-parts',
    name: 'route_storage_shared',
    key: 'route_storage_shared',
    icon: 'share',
    component: SharedParts,
    layout: '/',
  },
];
export default dashRoutes;
