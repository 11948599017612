/**
 * @description Sort processes in order 1. main, post_process and product requirements
 */
export const sortProcesses = processes => {
  if (processes && !processes.length) {
    return processes;
  }

  // sort by process types
  const mainProcesses = processes.filter(x => x.process.class === 'process');
  const postProcesses = processes.filter(
    x => x.process.class === 'post_process'
  );
  const productRequirementsProcesses = processes.filter(
    x => x.process.class === 'production_requirement'
  );

  const sortedProcesses = [
    ...mainProcesses,
    ...postProcesses,
    ...productRequirementsProcesses,
  ];
  return sortedProcesses;
};
