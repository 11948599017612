import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {FormControl, TextField} from '@mui/material';

import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import 'moment/min/locales.min';

import './OrderCheckoutModal.scss';

class OrderCheckoutModalCalendarPicker extends Component {
  constructor(props) {
    super(props);
    
    moment.locale(props.i18n.language);
  }
  
  componentDidMount() {}

  render() {
    const {selectedDate, handleDateSelection, handleDateSelectionError, minDate} = this.props;

    return (
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateSelection}
            onError={handleDateSelectionError}
            renderInput={params => <TextField {...params} />}
            minDate={minDate || new Date()}
            format={'MM/DD/YYYY'}
            shouldDisableDate={moment => moment.isoWeekday() === 7} // Disable Sundays
          />
        </LocalizationProvider>
      </FormControl>
    );
  }
}

export default withTranslation('order_checkout_modal')(OrderCheckoutModalCalendarPicker);
