import React, {useState, useEffect}    from 'react';
import {NavLink}                       from 'react-router-dom';
import classnames                      from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Checkbox} from '@mui/material';
import Card                            from 'components/Card/Card';
import partCardStyles                  from 'assets/jss/material-dashboard-pro-react/components/cardStyle';
import {handleEmptyValue}              from 'utils/InputHelpers';
import './PartCard.scss';
import {useTranslation}                from 'react-i18next';
import {fetchThumbnalFromUrl}          from 'services/parts';
import Loading                         from 'components/Loading/Loading';
import noImage                         from 'assets/img/no-preview.jpg';


const useStyles = makeStyles(partCardStyles);

const CustomCheckbox = withStyles({
  root: {
    color: '#00acc1',
    '&$checked': {
      color: '#00acc1',
    },
  },
  checked: {},
})(props => <Checkbox color='default' {...props} />);

const PartCard = ({part, selectable = true, onSelect, selected, onCheckboxChange}) => {
  const classes = useStyles();
  const {t} = useTranslation(['filters', 'partstorage']);

  const [isSelected, setIsSelected] = useState(false);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getThumbnail() {
      if (!part) {
        setThumbnailSrc('');
      }
      else {
        setLoading(true);
        await loadThumbnail();
        setLoading(false);
      }
    }

    getThumbnail();
  }, [part]);

  const toggleCheckbox = selected => {
    setIsSelected(selected);
  };

  const toggleItem = () => {
    if (selectable && typeof onSelect === 'function') {
      const select = !isSelected;
      toggleCheckbox(select);
      onSelect({
        isSelected: select,
        part: {...part},
        toggleSelect: toggleCheckbox,
      });
    }
    
    if(selectable && typeof onCheckboxChange === 'function') {
      onCheckboxChange(!selected);
    }
  };

  const loadThumbnail = async () => {
    const response = await fetchThumbnalFromUrl(part.id, part.thumbnail, {
      width: 500,
      height: 500,
    });

    if (response) {
      setThumbnailSrc(response.thumbnail);
    }
  };

  return (
    <Card className='part-card'>
      {selectable && (
        <CustomCheckbox
          checked={isSelected || selected}
          value={part.id}
          className={classnames(
            classes.checkbox,
            (isSelected || selected) ? classes.checkboxChecked : 'checkbox',
          )}
          onChange={toggleItem}
          inputProps={{
            'aria-label': 'part checkbox',
          }}
        />
      )}
      <NavLink to={`/part/${part.id}`} className='card-image'>
        {loading ? (
          <Loading pageHeight={300} />
        ) : (
          <img
            src={thumbnailSrc || noImage}
            className='thumbnail'
            alt='thumbnail'
          />
        )}
      </NavLink>

      <div className='card-body'>
        <div className='content'>
          <NavLink to={`/part/${part.id}`} className='part-card-title'>
            <h5>{part.partName}</h5>
          </NavLink>
        </div>
        <div className='footer'>
          <div className='footer-info'>
            <p>
              {`${t('flt_drawing_number')}: ${handleEmptyValue(
                part.partDrawingNr,
              )}`}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PartCard;
