import React from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, TextField } from '@mui/material';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import './ShareLinkModal.scss';
import { copyToClipboard } from 'utils/InputHelpers';
import { toDisplayDate } from 'utils/datetimeHelpers';
const useStyles = makeStyles(styles);
const ShareLikModal = ({ open, data, confirm }) => {
  const { t } = useTranslation(['modals', 'errors']);
  const classes = useStyles(styles);
  const confirmAlert = () => {
    if (typeof confirm === 'function') {
      confirm(false);
    }
  };

  const handleCopyBtnClick = inpId => {
    copyToClipboard(inpId);
  };

  const expireDateLbl = () => {
    const date = data.expiryDatetime
      ? toDisplayDate(data.expiryDatetime)
      : t('mdl_copy_link_never_expires');
    return date;
  };

  return (
    <>
      {open && (
        <SweetAlert
          customClass={'copy-link-modal'}
          closeOnClickOutside={false}
          onConfirm={() => confirmAlert()}
          confirmBtnText={t('mdl_general_done')}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          title={t('mdl_copy_link_title')}
        >
          <Grid item xs={12} className={'share-link-modal-content'}>
            <p>
              <i>{t('mdl_copy_link_desc')}</i>
            </p>
            <h4 style={{ marginTop: 30, marginBottom: 20 }}>
              {t('mdl_copy_link_download')}
            </h4>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <TextField
                  id="download-link-inp"
                  InputProps={{
                    readOnly: true,
                  }}
                  label={t('mdl_copy_link_lbl')}
                  defaultValue={data.link}
                  fullWidth
                  styles={{ color: '#999 !important', opacity: 0.38 }}
                ></TextField>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="success"
                  style={{ marginTop: 7 }}
                  onClick={() => handleCopyBtnClick('download-link-inp')}
                >
                  {t('mdl_copy_link_btn_lbl')}
                </Button>
              </Grid>
              {data.password && (
                <>
                  <h4 style={{ marginTop: 30, marginBottom: 20 }}>
                    {t('mdl_copy_link_accessibility')}
                  </h4>
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        id="password-inp"
                        InputProps={{
                          readOnly: true,
                        }}
                        label={t('mdl_copy_link_password_protected')}
                        defaultValue={data.password}
                        fullWidth
                        styles={{ color: '#999 !important', opacity: 0.38 }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="success"
                        style={{ marginTop: 7 }}
                        onClick={() => handleCopyBtnClick('password-inp')}
                      >
                        {t('mdl_copy_link_copy_password_btn_lbl')}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <h4 style={{ marginTop: 30, marginBottom: 0 }}>
              {t('mdl_copy_link_valid_until')}
            </h4>
            <span>{expireDateLbl()}</span>
            <br></br>
          </Grid>
        </SweetAlert>
      )}
    </>
  );
};

export default ShareLikModal;
