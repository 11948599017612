import React, {Component} from 'react';
import {withTheme} from '@mui/styles';
import {withTranslation} from 'react-i18next';
import {Card, CardContent, CardActions, Grid, Typography, Button, Skeleton} from '@mui/material';
import {fetchLists} from 'services/part-lists';
import {getOrderTeasers} from 'services/orders';
import {getEstimatedPartLists} from 'services/estimated-part-lists';
import {Link} from 'react-router-dom';
import {updateBreadcrumbs} from '../../store/breadcrumbs/breadcrumbsActions';
import {connect} from 'react-redux';

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    this.props.setBreadcrumbs();
    this.state = {
      loading: true,
      rows: [],
      count: 0,
    };
  }

  componentDidMount() {
    this.getModel().catch(console.error);
  }

  getModel = async () => {
    const {model, filter} = this.props;

    this.setState({loading: true});

    const params = {
      filter: {
        where: filter,
        ...(model !== 'part-list-teasers' ? {
          // Loopback params
          limit: 3,
          order: 'id DESC',
        } : {
          // Knest API params
          take: 3,
          orderBy: {id: 'desc'},
        }),
      },
    };

    try {
      const fetchMethods = {
        'part-list-teasers': fetchLists,
        'estimated-part-list-teasers': getEstimatedPartLists,
        'ordered-part-list-teasers': getOrderTeasers,
      };

      const {data: {page, pagination: {totalItemCount}}} = await fetchMethods[model](params, model === 'part-list-teasers');

      this.setState({
        count: totalItemCount,
        rows: page,
      });

    } catch (e) {
      console.error(e);
      this.setState({count: 'N/A'});
    }

    this.setState({loading: false});
  };

  onViewMore = () => {
    const {navigate, filter, page} = this.props;

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('filter', JSON.stringify(filter));

    navigate({
      pathname: `/${page}`,
      search: urlSearchParams.toString(),
    });
  };

  render() {
    const {title, page, t, theme, emptyAction, emptyLabel} = this.props;
    const {count, rows, loading} = this.state;

    return (
      <Card>
        <CardContent>
          <Grid container justifyContent="center" rowSpacing={2}>
            <Grid item xs={12}>
              <Typography sx={{alignItems: 'center'}} variant="h5">
                {loading ? <Skeleton variant="text" animation="wave" width={'100%'} /> : (
                  <>
                    {<this.props.icon sx={{marginRight: '8px', marginBottom: '-3px'}} color="primary" />}
                    {`${t(title)}: ${count}`}
                  </>
                )}
              </Typography>
            </Grid>
            {rows.map(row => (
              <Grid item key={row.kmsId} xs={12} container justifyContent="center">
                <Typography variant="body">
                  {loading ?
                    <Skeleton variant="text" animation="wave" width={180} /> :
                    <Link
                      style={{color: theme.palette.secondary.main}}
                      to={`/${page}/${row.id}`}
                    >{row.kmsId}
                    </Link>
                  }
                </Typography>
              </Grid>
            ))
            }
            {rows.length < 3 && [...Array(3 - rows.length).keys()].map(i => (
              <Grid item xs={12} key={i} container justifyContent="center" sx={{minHeight: 37}}>
                <Typography variant="body">
                  {loading ? <Skeleton variant="text" animation="wave" width={180} /> : !rows.length && i === 1 && t(`empty_${title}`)}
                </Typography>
              </Grid>
            ))
            }
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            sx={{minWidth: '100%', padding: '12px'}}
            onClick={emptyAction && !rows.length ? emptyAction : this.onViewMore}
            disabled={loading || (!rows.length && !emptyAction)}
            variant="contained"
          >
            {emptyLabel && !rows.length ? t(emptyLabel) : t('view_more')}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: () => dispatch(
      updateBreadcrumbs({
        level: 0,
        path: window.location.pathname,
        text: 'dashboard:section_title',
      }),
    ),
  };
};

const connectDashboard = connect(null, mapDispatchToProps);

export default withTheme(withTranslation('dashboard')(connectDashboard(DashboardCard)));
