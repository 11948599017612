import React, { useRef, useEffect } from 'react';
import './CloneConfigListModal.scss';
import { PaginatedConfigCardComponent } from 'components/CloneConfigComponent/CloneConfigComponent';
import { useTranslation } from 'react-i18next';
import { CustomModal } from 'components/Modals/CustomModal/CustomModal';
import { useSelector } from 'react-redux';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Generates paginated list with all parts and configurations that can be selected for cloning
 */
export default function CloneConfigListModal({
  open,
  setOpen,
  title,
  confirm,
  enableSave,
}) {
  const selectedConfigs = useSelector(state => state.partList.selectedConfigs);
  const partListSelectedConfigs = useRef([]);

  const { t } = useTranslation('modals');
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    partListSelectedConfigs.current = selectedConfigs
      ? [...selectedConfigs]
      : [];
  }, [selectedConfigs]);

  const handleActionBtnClick = answer => {
    if (typeof onActionClick === 'undefined') {
      confirm(answer, [...partListSelectedConfigs.current]);
    }
  };

  const actionButtons = [
    {
      color: classes.cancel,
      onClick: () => handleActionBtnClick(false),
      text: t('mdl_general_cancel'),
    },
    {
      disabled: !enableSave,
      color: classes.success,
      onClick: () => handleActionBtnClick(true),
      text: t('mdl_general_save'),
    },
  ];

  return (
    <CustomModal
      open={open}
      setOpen={setOpen}
      actions={actionButtons}
      title={title}
      modalClasses={'clone-config-list-modal'}
      onCloseClick={() => handleActionBtnClick(false)}
      customStyles={{ width: 1200, height: 700 }}
      scrollDirection="y"
    >
      <PaginatedConfigCardComponent cloneModalMode={true} />
    </CustomModal>
  );
}
