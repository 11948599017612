import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getAllSharedListsAction,
  getInitialAllSharedListsAction,
} from 'containers/SharedParts/SharedPartsActions';
import { Container, Grid } from "@mui/material";
import SharePartsComponent from 'components/SharedParts/SharedPartsComponent/SharePartsComponent';
import SharedPartsEmptyPage from 'components/SharedParts/SharedPartsEmptyPage/SharedPartsEmptyPage';
import SearchInput from 'components/SharedParts/SearchInput/SearchInput';
import './SharedParts.scss';
import Loading from 'components/Loading/Loading';
import { updateBreadcrumbs } from 'store/breadcrumbs/breadcrumbsActions';

export const SharedParts = () => {
  const { t } = useTranslation('sharedparts');
  const dispatch = useDispatch();

  const links = useSelector(state => state.sharedParts.linksList);
  const isLoading = useSelector(state => state.sharedParts.loading);
  const isInitialLoading = useSelector(
    state => state.sharedParts.initialLoading
  );
  const listPagination = useSelector(
    state => state.sharedParts.linksList.pagination
  );

  const listOptions = { limit: 10, order: 'createdAt DESC' };
  const searchOptions = useRef({});
  const isSearchMode = useRef(false);

  useEffect(() => {
    dispatch(getInitialAllSharedListsAction());
    dispatch(
      updateBreadcrumbs({
        level: 0,
        path: '/shared-parts',
        text: 'sharedparts:shareparts_page_title',
      })
    );
  }, []);

  useEffect(() => {}, [links]);
  useEffect(() => {}, [isLoading]);

  const getListPage = event => {
    const pagination = listPagination;
    if (event.target.value === '<<' && pagination.currentPage > 0) {
      dispatch(
        getAllSharedListsAction({
          filter: {
            ...listOptions,
            page: pagination.currentPage - 1,
            ...searchOptions.current,
          },
        })
      );
    } else if (
      event.target.value === '>>' &&
      pagination.currentPage < pagination.totalPageCount
    ) {
      dispatch(
        getAllSharedListsAction({
          filter: {
            ...listOptions,
            page: pagination.currentPage + 1,
            ...searchOptions.current,
          },
        })
      );
    } else if (
      event.target.value === '>>' &&
      pagination.currentPage === pagination.totalPageCount
    ) {
      dispatch(
        getAllSharedListsAction({
          filter: {
            ...listOptions,
            page: pagination.currentPage,
            ...searchOptions.current,
          },
        })
      );
    } else {
      dispatch(
        getAllSharedListsAction({
          filter: {
            ...listOptions,
            page: event.target.value,
            ...searchOptions.current,
          },
        })
      );
    }
  };

  const setSearchOptions = nameValue => {
    isSearchMode.current = true;
    searchOptions.current = {
      where: { shareName: { ilike: `%${nameValue}%` } },
    };
  };

  const clearSearchOptions = () => {
    searchOptions.current = {};
    isSearchMode.current = false;
  };

  const handleSearchInpChange = nameValue => {
    if (nameValue.length === 0) {
      clearSearchOptions();
      dispatch(getInitialAllSharedListsAction());
    } else {
      setSearchOptions(nameValue);
      dispatch(
        getInitialAllSharedListsAction({
          filter: {
            ...listOptions,
            ...searchOptions.current,
          },
        })
      );
    }
  };

  const applyLayout = () => {
    if (!isInitialLoading && !isSearchMode.current && links.page.length === 0) {
      return <SharedPartsEmptyPage />;
    } else {
      return (
        <Container maxWidth={false}>
          <Grid container alignItems="center" className={'share-parts-page'}>
            <Grid item md={4}>
              <h2>{t('shareparts_page_title')}</h2>
            </Grid>
            <SearchInput onChange={handleSearchInpChange} />
            {isInitialLoading ? (
              <Loading />
            ) : (
              <SharePartsComponent
                list={links.page}
                pagination={links.pagination}
                getListPage={getListPage}
                listOptions={listOptions}
                searchOptions={searchOptions.current}
                isSearchMode={isSearchMode.current}
              />
            )}
          </Grid>
        </Container>
      );
    }
  };

  return applyLayout();
};
