import { getCurrentUserInfo, changePassword } from './auth'
import API from './api';

/**
 * @description Get current logged user info
 */
export const fetchCurrentUserInfo = async () => getCurrentUserInfo();

export const sendChangePassword = async payload => {
  return changePassword(payload);
};

export const startImpersonating = async assetId => {
  return API.post({
    url: `/users/current/start/impersonating/${assetId}`,
    skipAuthRefresh: true,
  });
};

export const stopImpersonating = async () => {
  return API.delete({
    url: `/users/current/stop/impersonating`,
    skipAuthRefresh: true,
  });
};

export const getCompanies = async companyName => {
  return API.get({
    url: '/companies',
    params: {
      filter: {
        where: {
          companyName: {ilike: `%${companyName}%`},
        }
      }
    }
  });
}