import React from 'react';
import { Paper } from '@mui/material';

import { ConfigCardHeader } from './ConfigCardHeader/ConfigCardHeader';
import { ConfigCardBody } from './ConfigCardBody/ConfigCardBody';

import './ConfigCard.scss';

export const ConfigCard = ({
  part,
  fetchAll,
  selectableConfigs,
  toggleConfigSelect,
  canAddConfig,
  cloneModalMode,
  hideRemove,
}) => {
  return (
    <Paper className="config-card-wrapper">
      <ConfigCardHeader part={part} hideRemove={hideRemove} />
      <ConfigCardBody
        part={part}
        fetchAll={fetchAll}
        selectableConfigs={selectableConfigs}
        toggleConfigSelect={toggleConfigSelect}
        canAddConfig={canAddConfig}
        cloneModalMode={cloneModalMode}
        hideRemove={hideRemove}
      />
    </Paper>
  );
};
