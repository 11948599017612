import React, {useContext} from 'react';
import {UPDATE_TEMP_DATA} from 'components/Modals/ConfigsModal/ConfigTypes';
import {ConfigContext} from 'components/Modals/ConfigsModal/ConfigContext';
import {Checkbox, Grid, Tooltip, Divider, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {updatePartConfig} from 'services/configs';
import {MaterialForm, BatchSizesForm} from 'components/Modals/ConfigsModal/ConfigComponents/ConfigForms';
import {logEvent} from '../../../../../../utils/firebase';
import {useSelector} from 'react-redux';

export const GeneralForm = () => {
  const {t} = useTranslation(['partconfigurator']);
  const {dispatch, state} = useContext(ConfigContext);

  const currentUserInfo = useSelector(state => state.general.currentUserInfo);
  const suppliers = useSelector(state => state.general.suppliers);

  const referencePricesEnabled = currentUserInfo?.impersonatedCompany ?
    currentUserInfo.impersonatedCompany.features?.some(feature => feature.companyFeatureKey === 'reference_prices_enabled' && feature.value === 'true') :
    currentUserInfo.company.features?.some(feature => feature.companyFeatureKey === 'reference_prices_enabled' && feature.value === 'true');

  const frameworkContractsEnabled = currentUserInfo?.impersonatedCompany ?
    currentUserInfo.impersonatedCompany.features?.some(feature => feature.companyFeatureKey === 'framework_contracts_enabled' && feature.value === 'true') :
    currentUserInfo.company.features?.some(feature => feature.companyFeatureKey === 'framework_contracts_enabled' && feature.value === 'true');
  
  const userHasImpersonationRights = currentUserInfo?.roles.some(role => role.name === 'impersonation');

  const checkboxOnClick = async name => {
    const payload = {[name]: !state.tempData[name]};
    dispatch({
      type: UPDATE_TEMP_DATA,
      payload,
    });
    await updatePartConfig(state.tempData.configId, payload);
    logEvent(`click_config_${name}`, {value: payload[name]});
  };

  const updateBatchSizes = batchSizes => {
    dispatch({
      type: UPDATE_TEMP_DATA,
      payload: {
        batchSizes,
      },
    });
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} style={{paddingBottom: '0px'}}>
        <Typography variant="body1">
          {t('material')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MaterialForm 
          partConfig={state.tempData}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{paddingBottom: '0px'}}
        justifyContent={'flex-start'}
        spacing={2}
      >
        <Grid item xs={2}>
          <Typography variant="body1">
            {t('batch_sizes')}
          </Typography>
        </Grid>
        {referencePricesEnabled && <Grid item xs={2}>
          <Typography variant={'body1'}>
            {t('reference_prices')} [€]
          </Typography>
        </Grid>}
        {frameworkContractsEnabled && userHasImpersonationRights && <>
          <Grid item xs={2}>
            <Typography variant={'body1'}>
              {t('framework_contract')}
            </Typography>
            <Typography variant={'body1'}>
              {t('partner_target_cost')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'body1'}>
              {t('framework_contract')}
            </Typography>
            <Typography variant={'body1'}>
              {t('applied_margin')}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant={'body1'}>
              {t('framework_contract')}
            </Typography>
            <Typography variant={'body1'}>
              {t('assigned_supplier')}
            </Typography>
          </Grid>
        </>}
      </Grid>
      <Grid item xs={12}>
        <BatchSizesForm
          configId={state.tempData.configId}
          batchSizes={state.tempData.batchSizes}
          updateBatchSizes={updateBatchSizes}
          referencePricesEnabled={referencePricesEnabled}
          frameworkContractsEnabled={frameworkContractsEnabled}
          userHasImpersonationRights={userHasImpersonationRights}
          suppliers={suppliers}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={{paddingBottom: '0px'}}>
        <Typography variant="body1">
          {t('general')}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={t('pc_mdl_mirrored_tooltip')}>
          <div>
            <Checkbox
              checked={!!state.tempData.mirrored}
              onClick={() => checkboxOnClick('mirrored')}
              style={{color: '#00acc1'}}
            />
            {t('pc_mdl_mirrored')}
          </div>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t('pc_mdl_material_provided_tooltip')}>
          <div>
            <Checkbox
              checked={!!state.tempData.materialProvided}
              onClick={() => checkboxOnClick('materialProvided')}
              style={{color: '#00acc1'}}
            />
            {t('pc_mdl_material_provided')}
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
