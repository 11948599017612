import API from './api';

export const getProcessFeatures = async processId => {
  const data = await API.get({
    url: `/parts-processes/${processId}/parts-process-features`,
    params: {
      filter: {
        include: {
          relation: 'selectOptions',
          scope: {
            where: {processId}
          }
        },
      },
    },
  });
  
  data.data.forEach(pf => {
    pf.selectOptions = pf.selectOptions.map(so => ({key: so.selectOptionKey}));
  });
  
  return data;
}
