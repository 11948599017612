import React, {useState} from 'react';
import {Container, Grid, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import PartInformation from './PartInformation/PartInformation';
import PartConfigurations from './PartConfigurations/PartConfigurations';
import PartLists from './PartLists/PartLists';
import PartDetailsForm from './PartDetailsForm/PartDetailsForm';
import './PartDetails.scss';
import Loading from 'components/Loading/Loading';
import {useTranslation} from 'react-i18next';
import {DeletePartModal} from 'components/Modals/DeletePartModal/DeletePartModal';
import {ListModal} from 'components/Modals';
import {updatePartName} from 'containers/PartDetails/PartDetailsActions';
import {editPart} from 'services/parts';
import {DeleteAlert} from '../Alerts/DeleteAlert';
import {DeleteForever} from '@mui/icons-material';

import {deletePart} from '../../services/parts';
import {logEvent} from 'utils/firebase';
import ClickToEditTextField from '../ClickToEditTextField/ClickToEditTextField';

export default function PartDetailsComponent({
  part,
  attachments,
  general,
  getListPage,
  listsForPart,
  onRefreshModalList,
  onSelectedListsConfirm,
}) {
  const {t} = useTranslation(['partdetails', 'modals']);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  // Part name title
  const [partName, setPartName] = useState(part.name);

  //#region LIST MODAL
  const handleListModalConfirm = ({selectedLists, endConfirmLoading}) => {
    if (typeof onSelectedListsConfirm == 'function') {
      onSelectedListsConfirm({selectedLists, endConfirmLoading});
    }
  };

  const navigate = useNavigate();

  const [deletePartAlertOpen, setDeletePartAlertOpen] = useState(false);

  const handlePartDelete = () => {
    // If the part was successfully deleted, go to the parts storage page
    logEvent('delete_part');
    navigate('/part-storage');
  };

  const handleListRefresh = () => {
    onRefreshModalList();
  };

  const handlePartNameChange = value => {
    setPartName(value);
  };

  const handlePartNameSave = async () => {
    if (!!partName.length) {
      try {
        if (partName.trim() !== part.name) {
          const partNameToEdit = await editPart(part.id, {
            name: partName.trim(),
          });
          setPartName(partNameToEdit.data.name);
          dispatch(updatePartName(partNameToEdit.data.name));
          enqueueSnackbar('Name change successful', {variant: 'success'});
        }
      } catch (error) {
        enqueueSnackbar('Name change failed', {variant: 'error'});
      }
    } else {
      setPartName(part.name);
    }
  };

  //#endregion

  return part ? (
    <Container className="details-container">
      <Grid container alignItems="center">
        <Grid item md={4}>
          <ClickToEditTextField
            value={partName}
            onChange={handlePartNameChange}
            onBlur={handlePartNameSave}
            fontWeight={'520'}
            fontSize={'1.8725rem'}
            maxLength={255}
          />
        </Grid>
        <Grid
          item
          md={8}
          container
          justifyContent="flex-end"
          className={'part-details-action-bar'}
        >
          <ListModal
            buttonSize="medium"
            saveLoadingMsg={t('modals:mdl_saving_list_loading')}
            getListPage={getListPage}
            onConfirm={handleListModalConfirm}
            onListRefresh={handleListRefresh}
          />
          <Button
            onClick={() => setDeletePartAlertOpen(true)}
            variant={'outlined'}
          >
            <DeleteForever />
          </Button>
        </Grid>
      </Grid>

      <PartInformation
        part={part}
        general={general}
        lists={listsForPart}
        attachments={attachments}
      />
      <PartDetailsForm part={part} general={general} />
      <PartConfigurations part={part} workMode={'summary'} />
      <PartLists partLists={listsForPart} />
      <DeletePartModal withButton={false} />
      <DeleteAlert
        open={deletePartAlertOpen}
        triggerDelete={() => deletePart(part.id)}
        onCancel={() => setDeletePartAlertOpen(false)}
        onErrorConfirm={() => setDeletePartAlertOpen(false)}
        onSuccessConfirm={handlePartDelete}
        headerText={t('pd_delete_part_header')}
        description={t('pd_delete_part_description')}
      />
    </Container>
  ) : (
    <Loading />
  );
}
