var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getToken, handleUnauthorized } from './auth';
const API_URL = process.env.REACT_APP_PARTS_API;
const ORDERING_API_URL = process.env.REACT_APP_ORDERING_API;
const KNEST_ORDERING_API_URL = process.env.REACT_APP_KNEST_ORDERING_API;
const KNEST_PARTS_API_URL = process.env.REACT_APP_KNEST_PARTS_API;
const genUrl = (url, useOrderingAPI = false) => `${useOrderingAPI ? ORDERING_API_URL : API_URL}${url}`;
Axios.interceptors.request.use(request => {
    var _a;
    const userToken = getToken();
    // in case we have refresh token and current access token is expired or invalid token
    if (request.data && request.data.refresh_token) {
        return request;
    }
    if (userToken && userToken.access_token) {
        request.headers = request.headers || {};
        if (!((_a = request.url) === null || _a === void 0 ? void 0 : _a.match('part-list-archives')))
            request.headers['Authorization'] = `Bearer ${userToken.access_token}`;
    }
    return request;
});
createAuthRefreshInterceptor(Axios, handleUnauthorized, {
    statusCodes: [401, 403],
    pauseInstanceWhileRefreshing: false,
});
export default {
    get: (_a) => {
        var { url, params } = _a, args = __rest(_a, ["url", "params"]);
        return Axios(Object.assign({ method: 'get', url: genUrl(url), params }, args));
    },
    getWithUrl: (_a) => {
        var { url, params } = _a, args = __rest(_a, ["url", "params"]);
        return Axios(Object.assign({ method: 'get', url,
            params }, args));
    },
    post: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'post', url: genUrl(url), data }, args));
    },
    put: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'put', url: genUrl(url), data }, args));
    },
    patch: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'patch', url: genUrl(url), data }, args));
    },
    delete: (_a) => {
        var { url } = _a, args = __rest(_a, ["url"]);
        return Axios(Object.assign({ method: 'delete', url: genUrl(url) }, args));
    },
};
export const OrderingAPI = {
    get: (_a) => {
        var { url, params } = _a, args = __rest(_a, ["url", "params"]);
        return Axios(Object.assign({ method: 'get', url: genUrl(url, true), params }, args));
    },
    post: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'post', url: genUrl(url, true), data }, args));
    },
    patch: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'patch', url: genUrl(url, true), data }, args));
    },
    genUrl,
};
export const KnestOrderingAPI = {
    get: (_a) => {
        var { url, params } = _a, args = __rest(_a, ["url", "params"]);
        return Axios(Object.assign({ method: 'get', url: `${KNEST_ORDERING_API_URL}${url}`, params }, args));
    },
    post: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'post', url: `${KNEST_ORDERING_API_URL}${url}`, data }, args));
    },
};
export const KnestPartsAPI = {
    get: (_a) => {
        var { url, params } = _a, args = __rest(_a, ["url", "params"]);
        return Axios(Object.assign({ method: 'get', url: `${KNEST_PARTS_API_URL}${url}`, params }, args));
    },
    post: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'post', url: `${KNEST_PARTS_API_URL}${url}`, data }, args));
    },
    patch: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'patch', url: `${KNEST_PARTS_API_URL}${url}`, data }, args));
    },
    put: (_a) => {
        var { url, data } = _a, args = __rest(_a, ["url", "data"]);
        return Axios(Object.assign({ method: 'put', url: `${KNEST_PARTS_API_URL}${url}`, data }, args));
    },
    delete: (_a) => {
        var { url } = _a, args = __rest(_a, ["url"]);
        return Axios(Object.assign({ method: 'delete', url: `${KNEST_PARTS_API_URL}${url}` }, args));
    },
};
