var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Component } from 'react';
import { Box, Button, Chip, Container, InputAdornment, Paper, TextField, Tooltip, Typography, Unstable_Grid2 as Grid, } from '@mui/material';
import { updateBreadcrumbs } from '../../store/breadcrumbs/breadcrumbsActions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withTheme } from '@mui/styles';
import { Clear, InfoOutlined, PlaylistAdd, Search, TableView, Warning } from '@mui/icons-material';
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { withSnackbar } from 'notistack';
import PartLibraryNoRows from '../../../src/assets/img/part_library_no_rows.svg';
import { getAllBatchSizes, getEstimatesAsCSVForBatchSizes } from '../../services/part-library';
import './PartLibrary.scss';
import { AddToPartListModal } from '../../components/Modals';
import { toCurrency } from '../../utils/numberHelpers';
import PartLibraryConfigDetailsModal from '../../components/Modals/PartLibraryConfigDetailsModal/PartLibraryConfigDetailsModal';
const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: () => dispatch(updateBreadcrumbs({
            level: 0,
            path: window.location.pathname,
            text: 'part_library:section_title',
        })),
    };
};
const connectPartLibrary = connect(null, mapDispatchToProps);
class PartLibrary extends Component {
    constructor(props) {
        var _a;
        super(props);
        this.fetchDataAndRefresh = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                loading: true,
            });
            let rows = [];
            try {
                if (this.state.searchEntries.length) {
                    const { data } = yield getAllBatchSizes({
                        filter: {
                            where: {
                                or: this.state.searchEntries.length ? [
                                    ...this.state.searchEntries.map(entry => ({ partName: { ilike: `%${entry}%` } })),
                                    ...this.state.searchEntries.map(entry => ({ partArticleNr: { ilike: `%${entry}%` } })),
                                    ...this.state.searchEntries.map(entry => ({ partDrawingNr: { ilike: `%${entry}%` } })),
                                ] : undefined,
                            },
                            order: ['partConfigId DESC', 'batchSize ASC'],
                        },
                    });
                    // Sort the rows based on the search entries
                    rows = this.state.searchEntries.reduce((acc, entry) => {
                        const filteredRows = data.filter(row => {
                            var _a, _b, _c;
                            return ((_a = row.partName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(entry.toLowerCase())) ||
                                ((_b = row.partArticleNr) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(entry.toLowerCase())) ||
                                ((_c = row.partDrawingNr) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(entry.toLowerCase()));
                        });
                        return [...acc, ...filteredRows.filter(entry => !acc.some(row => row.id === entry.id))];
                    }, []);
                }
            }
            catch (err) {
                this.props.enqueueSnackbar(this.props.t('modals:mdl_general_error_title'), {
                    variant: 'error',
                });
            }
            this.setState({
                loading: false,
                rows,
            });
        });
        this.CustomNoRowsOverlay = () => (_jsxs(Grid, { container: true, direction: 'column', spacing: 4, sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }, children: [_jsx(Grid, { xs: 4, children: _jsx(Typography, { sx: { textAlign: 'center' }, children: this.props.t('no_rows_text') }) }), _jsx(Grid, { xs: 12, sx: { textAlign: 'center' }, children: _jsx("img", { alt: '', src: PartLibraryNoRows, style: { maxWidth: 'calc(20vh)' } }) })] }));
        this.handleSearchBarUpdate = event => {
            var _a;
            return this.setState({
                searchText: (_a = event.target) === null || _a === void 0 ? void 0 : _a.value,
            });
        };
        this.handleOnEnterKey = (event) => __awaiter(this, void 0, void 0, function* () {
            var _b, _c;
            if (event.key === 'Enter') {
                const parsedSearchEntries = (_c = (_b = event.target) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.split('\n').map((item) => item.trim()).filter((item) => item);
                if (parsedSearchEntries === null || parsedSearchEntries === void 0 ? void 0 : parsedSearchEntries.length) {
                    this.setState({
                        searchEntries: [...this.state.searchEntries, ...(new Set(parsedSearchEntries.filter(entry => !this.state.searchEntries.includes(entry))))],
                    }, () => __awaiter(this, void 0, void 0, function* () {
                        document.location.hash = `#searchEntries=${encodeURIComponent(JSON.stringify(this.state.searchEntries))}`;
                        // Fetch & Update Data
                        yield this.fetchDataAndRefresh();
                    }));
                }
                this.setState({
                    searchText: '',
                });
            }
        });
        this.handleSearchEntryDeletion = (searchEntry) => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                searchEntries: this.state.searchEntries.filter(entry => entry !== searchEntry),
            }, () => __awaiter(this, void 0, void 0, function* () {
                document.location.hash = `#searchEntries=${encodeURIComponent(JSON.stringify(this.state.searchEntries))}`;
                yield this.fetchDataAndRefresh();
            }));
        });
        this.renderManufacturingAnalysisChip = row => {
            const { t } = this.props;
            if (row.maRunning) {
                return _jsx(Chip, { color: 'primary', variant: 'outlined', label: _jsx(Typography, { fontSize: '.9rem', children: t('partlists:ma_running') }), size: 'small' });
            }
            if (row.maRejected) {
                return _jsx(Chip, { color: 'error', variant: 'outlined', label: _jsx(Typography, { fontSize: '.9rem', children: t('partlists:ma_rejected') }), size: 'small' });
            }
            if (row.maCompleted) {
                return _jsx(Chip, { color: 'success', variant: 'outlined', label: _jsx(Typography, { fontSize: '.9rem', children: t('partlists:ma_complete') }), size: 'small' });
            }
            return _jsx(_Fragment, {});
        };
        this.renderRowDetails = row => {
            return this.state.rows.findIndex(el => el.id === row.id) === this.state.rows.findIndex(el => el.partConfigId === row.partConfigId);
        };
        this.setAddToPartListModalState = (addToPartListModalOpen) => {
            this.setState({
                addToPartListModalOpen,
            });
        };
        this.formatPricePerPiece = row => {
            if (!row.estimates)
                return '';
            return toCurrency(row.estimates.totalCostPerPart);
        };
        this.renderPricePerPieceCell = row => {
            const { t } = this.props;
            if (!row.estimates) {
                return _jsx(_Fragment, { children: _jsx(Tooltip, { title: t('request_estimates_to_view_prices'), arrow: true, children: _jsx(InfoOutlined, {}) }) });
            }
            if (row.estimates.batchSizeErrored) {
                return _jsx(_Fragment, { children: _jsx(Tooltip, { title: t('could_not_estimate'), children: _jsx(Warning, {}) }) });
            }
            return _jsxs(_Fragment, { children: [row.estimates.referencePrice ? _jsx(Tooltip, { title: t('reference_price'), arrow: true, children: _jsx("span", { children: "\u24C7\u00A0" }) }) : _jsx(_Fragment, {}), row.estimates.frameworkContractPrice ? _jsx(Tooltip, { title: t('framework_contract_price'), arrow: true, children: _jsx("span", { children: "\u24BB\u00A0" }) }) : _jsx(_Fragment, {}), toCurrency(row.estimates.totalCostPerPart)] });
        };
        this.downloadEstimatesAsCSV = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                loading: true,
            });
            try {
                yield getEstimatesAsCSVForBatchSizes([...new Set(this.state.rows.map(row => row.partConfigId))]);
            }
            catch (err) {
                this.props.enqueueSnackbar(this.props.t('modals:mdl_general_error_title'), {
                    variant: 'error',
                });
            }
            this.setState({
                loading: false,
            });
        });
        this.clearSearchEntries = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                searchEntries: [],
            }, () => __awaiter(this, void 0, void 0, function* () {
                document.location.hash = '';
                yield this.fetchDataAndRefresh();
            }));
        });
        this.props.setBreadcrumbs();
        const queryParams = new URLSearchParams((_a = document.location.hash) === null || _a === void 0 ? void 0 : _a.substring(1));
        let searchEntries = [];
        const searchEntriesFromQuery = queryParams.get('searchEntries');
        if (searchEntriesFromQuery) {
            try {
                const parsedEntries = JSON.parse(searchEntriesFromQuery);
                if (Array.isArray(parsedEntries) && parsedEntries.every(entry => typeof entry === 'string'))
                    searchEntries = parsedEntries;
            }
            catch (err) { }
        }
        this.state = {
            rows: [],
            loading: false,
            searchText: '',
            searchEntries,
            selectedBatchSizeIds: [],
            addToPartListModalOpen: false,
            configDetailsModalOpen: false,
            selectedPartConfigId: null,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchDataAndRefresh();
        });
    }
    render() {
        const { t } = this.props;
        return (_jsxs(Container, { maxWidth: false, sx: { height: 'calc(100% - 68px)' }, children: [_jsxs(Box, { sx: { display: 'flex', height: '100%', flexDirection: 'column' }, children: [_jsx(Box, { marginBottom: 2, sx: { flex: '0 1 auto' }, children: _jsxs(Grid, { container: true, alignItems: 'center', rowSpacing: 1, children: [_jsx(Grid, { xs: 5, children: _jsx(TextField, { variant: 'outlined', size: 'small', sx: { backgroundColor: 'white' }, InputProps: {
                                                startAdornment: _jsx(InputAdornment, { position: 'start', children: _jsx(Search, {}) }),
                                                endAdornment: _jsx(Tooltip, { title: t('subtitle'), children: _jsx(InfoOutlined, {}) }),
                                            }, multiline: true, maxRows: 1, fullWidth: true, placeholder: t('search_part_number'), value: this.state.searchText, onChange: this.handleSearchBarUpdate, onKeyUp: this.handleOnEnterKey, disabled: this.state.loading }) }), _jsx(Grid, { xs: 7, children: _jsxs(Grid, { container: true, justifyContent: 'flex-end', display: 'flex', spacing: 1, children: [_jsx(Grid, { children: _jsx(Tooltip, { title: t('clear_all_search_entries'), arrow: true, children: _jsx("span", { children: _jsx(Button, { variant: 'outlined', disabled: !this.state.searchEntries.length || this.state.loading, onClick: this.clearSearchEntries, children: _jsx(Clear, {}) }) }) }) }), _jsx(Grid, { children: _jsx(Tooltip, { title: t('export_as_csv'), arrow: true, children: _jsx("span", { children: _jsx(Button, { variant: 'outlined', disabled: !this.state.rows.length || this.state.loading, onClick: this.downloadEstimatesAsCSV, children: _jsx(TableView, {}) }) }) }) }), _jsx(Grid, { children: _jsx(Tooltip, { title: t('add_to_part_list'), arrow: true, children: _jsxs("span", { children: [_jsx(Button, { variant: 'outlined', disabled: !this.state.selectedBatchSizeIds.length || this.state.loading, onClick: () => this.setAddToPartListModalState(true), children: _jsx(PlaylistAdd, {}) }), _jsx(AddToPartListModal
                                                                //@ts-ignore
                                                                , { 
                                                                    //@ts-ignore
                                                                    selectedPartConfigIds: this.state.rows.filter(row => this.state.selectedBatchSizeIds.includes(row.id)).map(row => row.partConfigId), open: this.state.addToPartListModalOpen, setOpen: this.setAddToPartListModalState })] }) }) })] }) }), _jsx(Grid, { container: true, xs: 12, spacing: 1, sx: { maxHeight: 80, overflowY: 'scroll' }, children: this.state.searchEntries.map(searchEntry => (_jsx(Grid, { children: _jsx(Chip, { label: searchEntry, size: 'small', onDelete: () => this.handleSearchEntryDeletion(searchEntry), disabled: this.state.loading }) }))) })] }) }), _jsx(Box, { sx: { flex: '1 1 auto' }, children: _jsx(Paper, { sx: { height: '100%' }, children: _jsx(DataGridPro, { columns: [
                                        Object.assign(Object.assign({}, GRID_CHECKBOX_SELECTION_COL_DEF), { renderCell: params => { var _a; return this.renderRowDetails(params.row) ? (_a = GRID_CHECKBOX_SELECTION_COL_DEF.renderCell) === null || _a === void 0 ? void 0 : _a.call(GRID_CHECKBOX_SELECTION_COL_DEF, params) : _jsx(_Fragment, {}); } }),
                                        {
                                            field: 'partConfigId',
                                            headerName: t('config_id'),
                                            flex: 0.75,
                                            renderCell: ({ row }) => this.renderRowDetails(row)
                                                ? _jsx("a", { onClick: () => this.setState({ configDetailsModalOpen: true, selectedPartConfigId: row.partConfigId }), children: row.partConfigId })
                                                : '',
                                            sortable: false,
                                            filterable: false,
                                        },
                                        {
                                            field: 'partName',
                                            headerName: t('part_name'),
                                            flex: 1.5,
                                            renderCell: ({ row }) => this.renderRowDetails(row) ? row.partName : '',
                                            sortable: false,
                                            filterable: false,
                                        },
                                        {
                                            field: 'partDrawingNr',
                                            headerName: t('drawing_nr'),
                                            flex: 1.5,
                                            renderCell: ({ row }) => this.renderRowDetails(row) ? row.partDrawingNr : '',
                                            sortable: false,
                                            filterable: false,
                                        },
                                        { field: 'batchSize', headerName: t('batch_size'), flex: 0.5, sortable: false, filterable: false, type: 'number' },
                                        {
                                            field: 'pricePerPiece',
                                            headerName: t('price_per_part'),
                                            flex: 1,
                                            sortable: false,
                                            filterable: false,
                                            align: 'center',
                                            headerAlign: 'center',
                                            valueGetter: ({ row }) => this.formatPricePerPiece(row),
                                            renderCell: ({ row }) => this.renderPricePerPieceCell(row),
                                        },
                                        // {field: 'projectTag', headerName: 'Project Tag', flex: 1.5}, TODO: Add support for project tags
                                        {
                                            field: 'previouslyOrdered',
                                            headerName: t('ordered'),
                                            flex: 0.75,
                                            type: 'boolean',
                                            valueGetter: params => !!params.row.orderedPartConfigIds,
                                            sortable: false,
                                            filterable: false,
                                        },
                                        {
                                            field: 'maStatus',
                                            headerName: t('ma_status'),
                                            renderHeader: ({ colDef: { headerName } }) => !this.state.rows.every(bs => bs.maCompleted) ?
                                                _jsxs(_Fragment, { children: [headerName, "\u00A0\u00A0", _jsx(Tooltip, { title: t('not_all_parts_analyzed'), children: _jsx(Warning, {}) })] })
                                                : headerName,
                                            flex: 1.5,
                                            disableExport: true,
                                            renderCell: ({ row }) => this.renderManufacturingAnalysisChip(row),
                                            sortable: false,
                                            filterable: false,
                                        },
                                    ], rows: this.state.rows, disableColumnFilter: true, disableColumnMenu: true, checkboxSelection: true, hideFooter: true, components: {
                                        NoRowsOverlay: this.CustomNoRowsOverlay,
                                    }, loading: this.state.loading, density: 'compact', isRowSelectable: ({ row }) => this.renderRowDetails(row), selectionModel: this.state.selectedBatchSizeIds, onSelectionModelChange: selectionModel => this.setState({ selectedBatchSizeIds: selectionModel }), className: 'part-library', disableSelectionOnClick: true }) }) })] }), _jsx(PartLibraryConfigDetailsModal
                //@ts-ignore
                , { 
                    //@ts-ignore
                    onClose: () => this.setState({ configDetailsModalOpen: false, selectedPartConfigId: null }), open: this.state.configDetailsModalOpen, partConfigId: this.state.selectedPartConfigId, t: t })] }));
    }
}
export default withTheme(withSnackbar(withTranslation(['part_library', 'common', 'modals', 'partlists'])(connectPartLibrary(PartLibrary))));
