import API from "./api";
import { getToken } from "./auth";
import { createDownloadHref } from "./part-lists";

export const getEstimatedPartLists = async params => {
  return API.get({
    url: '/estimated-part-list-teasers',
    params,
  });
}

export const getEstimatedPartListById = async (id, params) => {
  return API.get({
    url: `/estimated-part-list-teasers/${id}`,
    params,
  });
}

export const patchEstimatedPartList = async (id, data) =>
  await API.patch({
    url: `/estimated-part-lists/${id}`,
    data,
  });

export const constructEstimatedPartListThumbnailUrl = estimatedPartListId => {
  const userToken = getToken();
  return `${process.env.REACT_APP_PARTS_API}/estimated-part-lists/${estimatedPartListId}/thumbnail?access_token=${userToken.access_token}`;
}

export const getEstimatedPartListEstimates = (id, batchSizeIds) => {
  return API.get({
    url: `/estimated-part-lists/${id}/estimate`,
    params: {
      batchSizeIds,
    },
  });
};

export const getEstimatedPartListLeadTime = (id, batchSizeIds) => {
  return API.get({
    url: `/estimated-part-lists/${id}/estimate/lead-time`,
    params: {
      batchSizeIds,
    },
  });
};

export const downloadEstimatedPartListCalculatedCosts = async (id, batchSizeIds, downloadCsv = false, selectedBatchSizesOnly = false) => {
  const response = await API.get({
    url: `/estimated-part-lists/${id}/estimate/${downloadCsv ? 'csv' : 'download'}`,
    params: {
      batchSizeIds,
      selectedBatchSizesOnly,
    },
    responseType: 'blob',
  });
  return createDownloadHref(response);
};

export const lockPartList = async partListId => {
  return API.post({
    url: `/estimated-part-lists/clone-part-list/${partListId}`
  });
}