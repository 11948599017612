import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {UPDATE_TEMP_DATA} from 'components/Modals/ConfigsModal/ConfigTypes';
import {ConfigContext} from 'components/Modals/ConfigsModal/ConfigContext';
import {CustomProcessForm} from '../CustomConfigForm/CustomProcessForm';
import {StepType} from 'components/Modals/ConfigsModal/Models/StepTypeEnum';
import {ProcessType} from 'components/Modals/ConfigsModal/Models/ProcessTypeEnum';
import {getConfigProcessForm} from 'components/Modals/ConfigsModal/services/processServices';

export const ProcessForm = ({processType}) => {
  const {dispatch, state} = useContext(ConfigContext);
  const {t} = useTranslation(['partconfigurator', 'processes']);
  const {enqueueSnackbar} = useSnackbar();

  // process
  const [selectedProcess, setSelectedProcess] = useState(null);
  const currentProcess = useRef(null);

  //form
  const [formData, setFormData] = useState({});
  const [processFormLoading, setProcessFormLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  /**
   * Updates for steps changes
   */

  useEffect(() => {
    currentProcess.current = {
      ...state.tempData[processType][state.currentSubstep],
    };

    currentProcess.current.processSelection
      ? setSelectedProcess(currentProcess.current.processSelection)
      : setSelectedProcess(null);

    setShowForm(false);
  }, [
    state.currentStep,
    state.currentSubstep,
    processType,
    state.tempData[processType],
  ]);

  useEffect(() => {
    setProcessFormLoading(true);
    setFormData({});
    if (
      !!selectedProcess &&
      processType !== StepType.PRODUCT_REQUIREMENTS.name &&
      currentProcess.current.process &&
      Object.keys(currentProcess.current.process).length
    ) {
      if (currentProcess.current.process.processId === selectedProcess.id) {
        getConfigProcessForm(
          state.tempData.configId,
          {...currentProcess.current.process},
          currentProcess.current.processSelection,
          onGetFormSuccess,
          onGetFormDataError,
        );
      }
    } else {
      setProcessFormLoading(false);
    }
  }, [selectedProcess]);

  // Updates for data
  useEffect(() => {
    currentProcess.current = {
      ...state.tempData[processType][state.currentSubstep],
    };
    setProcessFormLoading(false);

    if (Object.keys(currentProcess.current).length) {
      if (Object.keys(currentProcess.current.formData).length) {
        setFormData(currentProcess.current.formData);
      }
    } else {
      setFormData({});
    }
  }, [state.tempData[processType]]);

  const handleSelectedProcess = process => {
    setSelectedProcess({...process});
  };

  /**
   * FORM GET EVENTS
   */
  const onGetFormSuccess = ({processSelection, process, formData}) => {
    updateState({
      processSelection,
      process,
      formData,
    });
    setProcessFormLoading(false);
    setShowForm(true);
  };

  const onGetFormDataError = () => {
    enqueueSnackbar(t('pc_mdl_get_data_form_error'), {
      variant: 'error',
    });
    setProcessFormLoading(false);
    setShowForm(true);
  };

  const updateState = ({processSelection, process, formData}) => {
    const processData = {
      processSelection,
      process,
      formData: Object.keys(formData).length ? formData : {},
    };

    let newProcesses = [...state.tempData[processType]];
    newProcesses[state.currentSubstep] = {...processData};
    dispatch({
      type: UPDATE_TEMP_DATA,
      payload: {
        [processType]: [...newProcesses],
      },
    });

    setFormData({...processData.formData});
    setShowForm(true);
  };

  const getStepType = () => {
    switch (processType) {
      case StepType.MAIN_PROCESS.name:
        return StepType.MAIN_PROCESS.val;
      case StepType.POST_PROCESS.name:
        return StepType.POST_PROCESS.val;
      case StepType.PRODUCT_REQUIREMENTS.name:
        return StepType.PRODUCT_REQUIREMENTS.val;
      default:
        break;
    }
  };

  const getProcessType = () => {
    switch (processType) {
      case StepType.MAIN_PROCESS.name:
        return ProcessType.MAIN_PROCESS.name;
      case StepType.POST_PROCESS.name:
        return ProcessType.POST_PROCESS.name;
      case StepType.PRODUCT_REQUIREMENTS.name:
        return ProcessType.PRODUCT_REQUIREMENTS.name;
      default:
        break;
    }
  };
  return (
    <CustomProcessForm
      processClass={getProcessType()}
      processType={processType}
      currentProcessRef={currentProcess}
      stepType={getStepType()}
      selectedProcess={selectedProcess}
      onProcessSelection={handleSelectedProcess}
      processList={state.tempData[processType]}
      formData={formData}
      setFormData={setFormData}
      processFormLoading={processFormLoading}
      setProcessFormLoading={setProcessFormLoading}
      showForm={showForm}
      setShowForm={setShowForm}
      placeholder={t('partconfigurator:process_select')}
    />
  );
};
