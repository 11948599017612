import React, {Component, Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Radio,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Chip,
  FormControlLabel,
} from '@mui/material';
import {Warning} from '@mui/icons-material';
import {toCurrency} from 'utils/numberHelpers';
import {Link} from 'react-router-dom';
import {logEvent} from 'utils/firebase';
import {withTheme} from '@mui/styles';
import {connect} from 'react-redux';

const mapStateToProps = state => ({
  currentUserInfo: state.general?.currentUserInfo,
});

const connectPartListEstimatorTable = connect(mapStateToProps);

export class PartListEstimatorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  rowStyle(index) {
    return {
      backgroundColor: `rgba(0, 0, 0, ${index % 2 ? 0 : 0.04})`,
    };
  }

  cellStyle(config, width = '10%', invalid, batchSizeErrored) {
    let style = {width};
    style.color = invalid ? this.props.theme.palette.error.main : config.errors || batchSizeErrored ? '#88898A' : null;
    return style;
  }

  handleRowClick({id, totalCost}, config) {
    if (config.errors || !totalCost > 0 || this.props.loading) return;

    let batchSizeIds = this.props.batchSizeIds;

    // If the batch size IDs already includes this batch size ID, then deselect
    // this batch size to allow not ordering it
    if (batchSizeIds.includes(id)) {
      logEvent('estimate_deselect_batch_size');
      return this.props.setBatchSizeIds(batchSizeIds.filter(batchSizeId => batchSizeId !== id));
    }

    const configBatchSizeIds = config.batchSizes.map(bs => bs.id);
    // Set the batch size ID to that of the selected batch size from that config,
    // removing all the other batch size IDs
    batchSizeIds = [...this.props.batchSizeIds.filter(id => !configBatchSizeIds.includes(id)), id];
    logEvent('estimate_select_batch_size');

    // Update parent component
    this.props.setBatchSizeIds(batchSizeIds);
  }

  getPartArticleAndDrawingNrChips = config => {
    const {t} = this.props;

    return <>
      <Chip
        variant={'outlined'} size={'small'} label={`${t('article_nr')}: ${config.partArticleNr?.trim().length ? config.partArticleNr.trim() : '-'}`}
        style={{margin: 4}}
      />
      <Chip
        variant={'outlined'} size={'small'} label={`${t('drawing_nr')}: ${config.partDrawingNr?.trim().length ? config.partDrawingNr.trim() : '-'}`}
        style={{margin: 4}}
      />
    </>;
  };

  render() {
    const {
      t,
      configs,
      totalCost,
      batchSizeIds,
      currentUserInfo,
      latestPartListQuoteStatus,
      loading,
    } = this.props;

    const userHasImpersonationRights = currentUserInfo?.roles?.some(role => role.name === 'impersonation');

    return (
      <>
        <TableContainer component={Paper} style={{boxShadow: '0 3px 10px -2px rgba(0,0,0,0.47)'}}>
          <Table size={'small'}>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: 'rgb(220,220,220)',
                  borderBottom: 'solid 2px black',
                }}
              >
                <TableCell style={{width: '80%'}}>
                  <b>{t('estimator:part')}</b>
                </TableCell>
                <TableCell style={{width: '10%'}} align={'right'}>
                  <Tooltip placement={'top'} title={t('total_cost_per_part')}><b>{t('estimator:total_cost_per_part_abbreviation')}</b></Tooltip>
                </TableCell>
                <TableCell style={{width: '10%'}} align={'right'}>
                  <b>{t('estimator:total_cost')}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configs.map((config, index) => (
                <Fragment key={config.id}>
                  <TableRow style={this.rowStyle(index)}>
                    <TableCell colSpan={3} style={{borderBottom: 'none'}}>
                      <b>
                        <Link to={`/part/${config.partId}`}>{config.partName}</Link>
                        {this.getPartArticleAndDrawingNrChips(config)}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow style={this.rowStyle(index)}>
                    <TableCell colSpan={3}>
                      <b>
                        <Link to={`/part/${config.partId}?configId=${config.id}`}>
                          {`${t('estimator:config')} ${config.name || config.id}, ${t('estimator:material')}: ${config.materialName}`}
                        </Link>
                      </b>
                    </TableCell>
                  </TableRow>
                  {config.batchSizes.map(batchSize => (
                    <Fragment key={`batchSize_${batchSize.id}`}>
                      <TableRow
                        key={`${config.partId}+${config.id}+${batchSize.id}`}
                        style={{...this.rowStyle(index), cursor: !batchSize.totalCost > 0 ? 'auto' : 'pointer'}}
                        onClick={() => this.handleRowClick(batchSize, config)}
                      >
                        <TableCell style={this.cellStyle(config, '30%', false, batchSize.batchSizeErrored)}>
                          <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={1} />
                            <Grid item>
                              <FormControlLabel
                                disabled={!!config.errors || !batchSize.totalCost > 0 || loading}
                                checked={batchSizeIds.includes(batchSize.id)}
                                control={<Radio size={'small'} style={{padding: 0, margin: 0}} />}
                                label={<></>}
                              />
                            </Grid>
                            <Grid item>{`${t('estimator:batch_size')}: ${batchSize.batchSize}`}</Grid>
                            {
                              config.errors && latestPartListQuoteStatus === 'quoting_completed' && <Tooltip title={t(`estimator:quoting_failed`)}>
                                <Grid item><Warning color={'disabled'} /></Grid>
                              </Tooltip>
                            }
                            {batchSize.maComment &&
                              <Tooltip
                                title={t(`reject_reasons:${batchSize.maComment}`) === `reject_reasons:${batchSize.maComment}`
                                  ? batchSize.maComment
                                  : t(`reject_reasons:${batchSize.maComment}`)}
                              >
                                <Grid item><Warning color={'disabled'} fontSize={'small'} /></Grid>
                              </Tooltip>
                            }
                            {
                              userHasImpersonationRights &&
                              batchSize.frameworkSupplierCostPerUnit &&
                              !(batchSize.frameworkAppliedMargin && batchSize.frameworkSelectedSupplierId) &&
                              <Tooltip
                                title={t('estimator:could_not_calculate_framework_contract_costs')}
                              >
                                <Grid item><Warning color={'disabled'} /></Grid>
                              </Tooltip>
                            }
                          </Grid>
                        </TableCell>
                        <TableCell style={this.cellStyle(config, '10%', false, batchSize.batchSizeErrored)} align={'right'}>
                          {toCurrency(batchSize.totalCostPerPart) || '-'}
                        </TableCell>
                        <TableCell style={this.cellStyle(config, '10%', false, batchSize.batchSizeErrored)} align={'right'}>
                          {toCurrency(batchSize.totalCost) || '-'}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
              <TableRow
                style={{
                  backgroundColor: 'rgb(220,220,220)',
                  borderTop: 'solid 2px black',
                }}
              >
                <TableCell style={{width: '80%'}} align={'right'} colSpan={2}>
                  <b>{t('estimator:total_cost')}</b>
                </TableCell>
                <TableCell style={{width: '10%'}} align={'right'}>
                  <b>{toCurrency(totalCost)}</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default withTheme(withTranslation(['estimator', 'processes', 'reject_reasons'])(connectPartListEstimatorTable(PartListEstimatorTable)));
