import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, CircularProgress, Grid, TextField, Typography} from '@mui/material';

import {createList} from 'services/part-lists';
import itemsToListImg from 'assets/img/items-to-list.png';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

class CreatePartListForm extends Component {
  constructor (props) {
    super(props);

    this.state = {
      partListName: '',
      skipAutomaticConfiguration: false,
      createdPartList: null,
      loading: false,
    };
  }

  createPartList = async (skipAutomaticConfiguration = false) => {
    await this.setState({
      skipAutomaticConfiguration,
      loading: true,
    });

    const mappedPartIds = new Set(this.props.uploadedFiles.reduce((acc, file) => {
      if (!file.errors.length && file.filesList?.length) {
        for (const innerFile of file.filesList) {
          if (!innerFile.errorKeysList.length) {
            acc.add(innerFile.partId);
          }
        }
      }

      return acc;
    }, new Set()));

    const {data: createdPartList} = await createList({
      name: this.state.partListName,
      partIds: [...mappedPartIds],
    });

    await this.setState({
      createdPartList: createdPartList,
    });

    await this.props.setCreatedPartList(createdPartList.id, skipAutomaticConfiguration);
    
    await this.setState({
      loading: false,
    })
  };

  render () {
    const {t} = this.props;

    return <>
      <Grid container style={{width: '100%', height: '100%', margin: 0, padding: '40px 0', overflowY: 'scroll'}} rowSpacing={2} alignItems={'center'}>
        {!this.state.createdPartList || !this.state.skipAutomaticConfiguration || this.state.loading ? (
          <>
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <img src={itemsToListImg} alt={'Add Parts to Part List'} style={{width: 'max(20vw, 200px)'}} />
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <Typography variant={'h5'}>{t('cpl_create_part_list_title')}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={3} />
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                fullWidth
                label={`${t('cpl_list_name')}*`}
                value={this.state.partListName}
                onChange={({target: {value}}) => this.setState({partListName: value})}
                style={{margin: '50px 0'}}
                variant={'standard'}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={3} />
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <Button
                onClick={() => this.createPartList()}
                size={'large'}
                variant={'contained'}
                color={'primary'}
                style={{color: 'white'}}
                disabled={!this.state.partListName.length || this.state.loading}
              >
                {this.state.loading && !this.state.skipAutomaticConfiguration && <CircularProgress size={24} style={{marginRight: 16}} /> || ''}
                {t('cpl_create_part_list_btn')}
              </Button>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <Button
                onClick={() => this.createPartList(true)}
                size={'small'}
                disabled={!this.state.partListName.length || this.state.loading}
              >
                {this.state.loading && this.state.skipAutomaticConfiguration && <CircularProgress size={24} style={{marginRight: 16}} /> || ''}
                {t('cpl_create_part_list_skip_auto_config_btn')}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <CloudDoneIcon style={{fontSize: 160, color: '#4CAF50'}} />
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center', margin: '40px, 0'}} alignContent={'center'}>
              <Typography variant={'h5'}>{t('cpl_created_list_title')}</Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}} alignContent={'center'}>
              <Button
                size={'large'}
                color={'primary'}
                style={{color: 'white'}}
                variant={'contained'}
                href={/part-lists/ + this.state.createdPartList.id}
              >
                {t('cpl_created_list_go_to_list')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>;
  }
}

export default withTranslation('fileuploaded')(CreatePartListForm);