import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function CustomFormLayout(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      spacing={3}
    >
      {props.children}
    </Grid>
  );
}
