import React, {Component, createRef} from 'react';
import {Grid, Button, Typography, CircularProgress, Box, IconButton} from '@mui/material';
import Dropzone from 'react-dropzone';
import {CloudUpload, Layers, InsertDriveFile, Close, Check, Error, FolderOpen} from '@mui/icons-material';
import {withTranslation} from 'react-i18next';
import {Alert} from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import {logEvent} from 'utils/firebase';
import axios from 'axios';

import {uploadFile} from '../../services/upload';

class UploadFiles extends Component {
  constructor(props) {
    super(props);

    this.dropzoneRef = createRef();

    this.state = {
      filesToUpload: [],
    };
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    const mappedAcceptedFiles = acceptedFiles.map(file => ({
      uuid: uuidv4(),
      file,
      errors: [],
      cancelTokenSource: axios.CancelToken.source(),
    }));

    const mappedRejectedFiles = rejectedFiles.map(file => ({
      file: file.file,
      errors: file.errors.map(err => err.code),
    }));

    this.setState({
      filesToUpload: [...this.state.filesToUpload, ...mappedAcceptedFiles, ...mappedRejectedFiles],
    });

    const responses = await Promise.all(mappedAcceptedFiles.map(async acceptedFile => {
      try {
        const formData = new FormData();
        formData.append('file', acceptedFile.file);

        const response = await uploadFile(this.props.url, formData, {params: {force: true}}, acceptedFile.cancelTokenSource.token);

        logEvent('upload_file');

        return {
          uuid: acceptedFile.uuid,
          file: acceptedFile.file,
          isArchive: response?.data?.isArchive,
          filesList: response?.data?.filesList,
          errors: response.status !== 'success' ? [response.error.code] : response?.data?.errorKeysList,
        };
      } catch (e) {
        return {
          uuid: acceptedFile.uuid,
          file: acceptedFile.file,
          errors: ['unknown-error'],
        };
      }
    }));

    await this.props.setUploadedFiles([...this.props.uploadedFiles, ...responses]);
  };

  openFileDialog = () => {
    this.dropzoneRef?.current?.open();
  };

  fileValidator = file => {
    const maxFileSize = file.type === 'application/zip' ? 250 : 50;
    const maxFileSizeInBytes = maxFileSize * (10 ** 6);

    if (file.size > maxFileSizeInBytes) {
      return {code: 'max-file-size-exceeded'};
    }

    return null;
  };

  translateErrorKey = errorKey => {
    const {t} = this.props;

    switch (errorKey) {
      case 'invalid-format-within-archive':
        return t('cpl_file_type_not_accepted');
      case 'max-archive-file-size-exceeded':
      case 'max-file-size-exceeded':
        return t('cpl_file_too_large');
      case 'file-invalid-type':
      case 'invalid-file-format':
        return t('cpl_file_type_not_accepted');
      case 'duplicated-file-content':
        return t('cpl_file_is_duplicated_in_archive');
      case 'upload-canceled':
        return t('cpl_upload_canceled');
      case 'cannot-belong-to-multiple-parts':
        return t('cpl_belong_to_multiple_parts');
      case 'could-not-convert-file':
        return t('cpl_could_not_convert_file');
      default:
        return t('cpl_item_general_error');
    }
  };

  getAlertSeverity = (fileUuid, zipFileIndex) => {
    if (this.fileUploadIsErrored(fileUuid)) {
      return 'error';
    }

    if (this.fileIsUploading(fileUuid)) {
      return 'info';
    }

    const uploadedFile = this.getUploadedFile(fileUuid);

    if (!isNaN(zipFileIndex)) {
      if (uploadedFile.filesList[zipFileIndex].errorKeysList.length) {
        return 'error';
      }

      return 'success';
    }

    if (uploadedFile.isArchive) {
      return 'info';
    }

    return 'success';
  };

  cancelUpload = fileUuid => {
    const file = this.state.filesToUpload.find(f => f.uuid === fileUuid);

    if (this.fileIsUploading(fileUuid)) {
      file.cancelTokenSource.cancel('user-triggered-cancellation');
    }
  };

  getAlertAction = (fileUuid, zipFileIndex) => {
    if (this.fileUploadIsErrored(fileUuid)) {
      return <></>;
    }

    if (this.fileIsUploading(fileUuid)) {
      return (
        <IconButton
          color={'inherit'}
          onClick={() => this.cancelUpload(fileUuid)}
          size="large"
        >
          <Close />
        </IconButton>
      );
    }

    if (!isNaN(zipFileIndex)) {
      return <></>;
    }

    return (
      <IconButton disabled style={{color: 'white'}} size="large">
        <Check />
      </IconButton>
    );
  };

  getAlertIcon = (fileUuid, zipFileIndex) => {
    if (this.fileUploadIsErrored(fileUuid)) {
      return <Error style={{marginLeft: 8, marginRight: 8, marginTop: 8}} />;
    }

    if (this.fileIsUploading(fileUuid)) {
      return <Box position="relative" display="inline-flex">
        <CircularProgress variant={'indeterminate'} style={{color: 'white'}} />
        <Box
          top={0} left={0} bottom={0} right={0}
          position={'absolute'} display={'flex'} alignItems={'center'} justifyContent={'center'}
        >
          <CloudUpload />
        </Box>
      </Box>;
    }

    const uploadedFile = this.getUploadedFile(fileUuid);

    if (!isNaN(zipFileIndex)) {
      if (uploadedFile.filesList[zipFileIndex].errorKeysList.length) {
        return <Error style={{marginLeft: 8, marginRight: 8, marginTop: 8}} />;
      }

      return <InsertDriveFile style={{marginLeft: 8, marginRight: 8, marginTop: 8}} />;
    }

    if (uploadedFile.isArchive) {
      return <FolderOpen style={{marginLeft: 8, marginRight: 8, marginTop: 8}} />;
    }

    return <InsertDriveFile style={{marginLeft: 8, marginRight: 8, marginTop: 8}} />;
  };

  getAlertContent = (fileUuid, zipFileIndex) => {
    const {t} = this.props;

    if (this.fileUploadIsErrored(fileUuid)) {
      return this.translateErrorKey(this.fileErrors(fileUuid)[0]);
    }

    if (this.fileIsUploading(fileUuid)) {
      return t('cpl_uploading_file');
    }

    const uploadedFile = this.getUploadedFile(fileUuid);

    if (!isNaN(zipFileIndex)) {
      if (uploadedFile.filesList[zipFileIndex].errorKeysList.length) {
        return this.translateErrorKey(uploadedFile.filesList[zipFileIndex].errorKeysList[0]);
      }

      return `${t('cpl_attached_to_part')}: ${uploadedFile.filesList[zipFileIndex].partName}`;
    }

    if (uploadedFile.isArchive) {
      if (!uploadedFile.filesList.length) {
        return t('cpl_archive_empty');
      }

      return `${t('cpl_archive_contents_below')}:`;
    }

    return `${t('cpl_attached_to_part')}${uploadedFile.filesList[0].partName ? `: ${uploadedFile.filesList[0].partName}` : '.'}`;
  };

  getUploadedFile = fileUuid => {
    return this.props.uploadedFiles.find(f => f.uuid === fileUuid);
  };

  fileIsUploading = fileUuid => {
    return !this.getUploadedFile(fileUuid);
  };

  uploadedFileErrors = fileUuid => {
    let uploadedFile = this.getUploadedFile(fileUuid);
    return uploadedFile?.errors?.length && uploadedFile.errors || !uploadedFile?.isArchive && uploadedFile?.filesList[0]?.errorKeysList;
  };

  fileErrors = fileUuid => {
    const file = this.state.filesToUpload.find(f => f.uuid === fileUuid);

    return file.errors.length ? file.errors : (this.uploadedFileErrors(fileUuid) || []);
  };

  fileUploadIsErrored = fileUuid => {
    return this.state.filesToUpload.find(f => f.uuid === fileUuid).errors.length || this.uploadedFileErrors(fileUuid)?.length || false;
  };

  render() {
    const {t} = this.props;

    return (
      <Dropzone
        onDrop={this.onDrop}
        ref={this.dropzoneRef}
        accept={['.pdf', '.step', '.stl', '.stp', '.dxf', '.dwg', '.zip', '.tif', '.tiff']}
        validator={this.fileValidator}
        noClick
      >
        {({getRootProps, getInputProps, isDragActive}) => (
          <div style={{height: '100%', width: '95%', marginLeft: '2.5%'}} {...getRootProps()}>
            <input {...getInputProps()} />
            <Grid container justifyContent={'center'} style={{height: '100%'}}>
              {isDragActive ? (
                // Drop files here
                <Grid container item xs={12} alignContent={'center'} rowSpacing={2}>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Layers style={{color: '#00ACC1', fontSize: 120}} />
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'h5'}>
                      {t('cpl_drop_files_here')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : this.state.filesToUpload.length > 0 ? (
                // Files uploaded
                <>
                  {/* File count & File size */}
                  <Grid container item xs={12} justifyContent={'center'} alignContent={'flex-start'} spacing={3} style={{marginTop: 8}}>
                    <Grid container item sm={12} md={7} justifyContent={'flex-start'} alignContent={'center'} spacing={2}>
                      <Grid container item xs={6}>
                        <Grid item xs={1}>
                          <div style={{width: 5, height: 35, backgroundColor: '#e0e0e0'}} />
                        </Grid>
                        <Grid item xs={11} alignItems={'flex-end'}>
                          <Typography variant={'h5'}>{this.props.uploadedFiles.length} {t('cpl_files')}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={6}>
                        <Grid item xs={1}>
                          <div style={{width: 5, height: 35, backgroundColor: '#e0e0e0'}} />
                        </Grid>
                        <Grid item xs={11} alignItems={'flex-end'}>
                          <Typography variant={'h5'}>
                            {this.props.uploadedFiles.reduce((acc, file) => acc + (file.file.size / 10 ** 6), 0).toFixed(2)} MB
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Alert severity={'info'} variant={'outlined'} color={'info'}>{t('cpl_files_limits_info')}</Alert>
                      </Grid>
                    </Grid>
                    <Grid container item sm={12} md={5}>
                      <Grid item xs={12} style={{textAlign: 'center'}}>
                        <CloudUpload style={{color: '#00ACC1', fontSize: 60}} />
                        <br />
                        <Typography variant={'body1'}>
                          {t('cpl_drop_and_drag_here')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Typography variant={'body2'}>
                          {t('cpl_or')}, <Button onClick={this.openFileDialog}>{t('cpl_browse')}</Button> {t('cpl_to_choose_files')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* File list */}
                  <Grid
                    item
                    xs={12}
                    style={{marginBottom: 72, height: 'calc(80vh - 280px)', width: '100%', overflowY: 'scroll'}}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={1}
                      direction={'row'}
                      alignItems={'flex-start'}
                    >
                      {this.state.filesToUpload.map(fileToUpload => (
                        <React.Fragment key={fileToUpload.uuid}>
                          <Grid item xs={12}>
                            <Alert
                              variant={'filled'}
                              severity={this.getAlertSeverity(fileToUpload.uuid)}
                              icon={this.getAlertIcon(fileToUpload.uuid)}
                              action={this.getAlertAction(fileToUpload.uuid)}
                            >
                              <Grid container>
                                <Grid item xs={12}>
                                  {fileToUpload.file.name}
                                </Grid>
                                <Grid item xs={12}>
                                  {this.getAlertContent(fileToUpload.uuid)}
                                </Grid>
                              </Grid>
                            </Alert>
                          </Grid>
                          {this.getUploadedFile(fileToUpload.uuid)?.isArchive && (
                            <Grid container item xs={12} spacing={1} style={{margin: 0}}>
                              {this.getUploadedFile(fileToUpload.uuid).filesList.map((file, index) => (
                                <>
                                  <Grid item xs={12}>
                                    <Alert
                                      variant={'filled'}
                                      severity={this.getAlertSeverity(fileToUpload.uuid, index)}
                                      icon={this.getAlertIcon(fileToUpload.uuid, index)}
                                      action={this.getAlertAction(fileToUpload.uuid, index)}
                                      style={{marginLeft: 16}}
                                    >
                                      <Grid container>
                                        <Grid item xs={12}>
                                          {file.originalName}
                                        </Grid>
                                        <Grid item xs={12}>
                                          {this.getAlertContent(fileToUpload.uuid, index)}
                                        </Grid>
                                      </Grid>
                                    </Alert>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </>
              ) : (
                // Drag & Drop files
                <Grid
                  container
                  item
                  justifyContent={'center'}
                  alignContent={'center'}
                  rowSpacing={5}
                  style={{
                    margin: 0,
                  }}
                >
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <CloudUpload style={{color: '#00ACC1', fontSize: 120}} />
                    <br />
                    <Typography variant={'h5'}>
                      {t('cpl_drop_and_drag_here')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'}>
                      {t('cpl_or')}, <Button onClick={this.openFileDialog}>{t('cpl_browse')}</Button> {t('cpl_to_choose_files')}
                    </Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                    <Alert severity={'info'} variant={'outlined'} color={'info'}>{t('cpl_files_limits_info')}</Alert>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default withTranslation('fileuploaded')(UploadFiles);
