import { jsx as _jsx } from "react/jsx-runtime";
import { getToken } from 'services/auth';
import moment from 'moment/moment';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import RandExp from 'randexp';
export const getLinkWithToken = (link) => {
    return `${link}?access_token=${JSON.parse(getToken()).access_token}`;
};
export const getThumbnailURL = (id) => {
    return `${process.env.REACT_APP_PARTS_API}/attachments/${id}/files/current/thumbnail?access_token=${getToken().access_token}`;
};
export const transformStringToPascal = (string) => {
    if (string.indexOf('_') === -1)
        return string.replace(/^\w/, c => c.toUpperCase());
    return string
        .split('_')
        .map(word => word.replace(/^\w/, c => c.toUpperCase()))
        .join('');
};
/**
 * App layout page scroll
 */
export const disableAppLayoutPageScroll = () => {
    const page = document.getElementsByClassName('app-layout')[0];
    if (page === null || page === void 0 ? void 0 : page.style) {
        page.style.overflow = 'hidden';
    }
};
export const enableAppLayoutPageScroll = () => {
    const page = document.getElementsByClassName('app-layout')[0];
    if (page === null || page === void 0 ? void 0 : page.style) {
        page.style.overflow = '';
    }
};
export const isAppLayoutPageDisabled = () => {
    const page = document.getElementsByClassName('app-layout')[0];
    if (page === null || page === void 0 ? void 0 : page.style) {
        return page.style.overflow === 'hidden';
    }
};
export const generateRandomPassword = () => {
    const generator = new RandExp(/([A-Za-z0-9!@#$%^&*()\-_+=\[\]{}\\|;:'",.<>/?]){16,20}/);
    return generator.gen();
};
export const isObjectEmpty = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
};
export const sortArrayByObjPropName = (data, propName) => {
    return data.sort((first, second) => {
        if (first[propName] < second[propName]) {
            return -1;
        }
        if (first[propName] > second[propName]) {
            return 1;
        }
        return 0;
    });
};
export const generateRandomNumberForId = () => {
    return new Date().getTime();
};
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const getBytes = (x) => {
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1000 && ++l) {
        n = n / 1000;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return {
        number: parseFloat(n.toFixed(n < 10 && l > 0 ? 1 : 0)),
        unit: units[l],
    };
};
export const getInMegabytes = (bytes) => bytes / 1000000;
export const calculatePercent = (currentCount, maxCount) => {
    return ((currentCount * 100) / maxCount).toFixed(2);
};
export const generateUniqId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export const calculateDeliveryDateFromLeadTime = (leadTime) => {
    const minDeliveryDate = moment();
    if ([6, 7].includes(minDeliveryDate.isoWeekday())) {
        minDeliveryDate.add(8 - minDeliveryDate.isoWeekday(), 'days');
    }
    for (let days = 1; days <= leadTime; days++) {
        minDeliveryDate.add(1, 'day');
        if (minDeliveryDate.isoWeekday() === 6) {
            minDeliveryDate.add(2, 'days');
        }
    }
    return minDeliveryDate;
};
export const withRouter = (Component) => {
    return (props) => {
        const params = useParams();
        const navigate = useNavigate();
        const location = useLocation();
        const [searchParams, setSearchParams] = useSearchParams();
        return _jsx(Component, Object.assign({}, props, { params: params, navigate: navigate, location: location, searchParams: searchParams, setSearchParams: setSearchParams }));
    };
};
export const withTheme = (Component) => (props) => {
    const theme = useTheme();
    return _jsx(Component, Object.assign({}, props, { theme: theme }));
};
export const ResizeDetector = (props) => {
    const { ref } = useResizeDetector(props);
    return _jsx("div", { ref: ref, style: { width: '100%' } });
};
