import API from './api';
import {createDownloadHref} from './part-lists';

export const getAllBatchSizes = async params => {
  return API.get({
    url: '/batch-size-teasers',
    params,
  });
};

export const getEstimatesForBatchSizes = async partConfigIds => {
  return API.get({
    url: '/batch-size-teasers/estimates',
    params: {
      partConfigIds,
    },
  });
};

export const getEstimatesAsCSVForBatchSizes = async partConfigIds => {
  const response = await API.get({
    url: '/batch-size-teasers/estimates/csv',
    params: {
      partConfigIds,
    },
    responseType: 'blob',
  });

  return createDownloadHref(response);
};