import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, setGlobal } from 'reactn';
import { createRoot } from 'react-dom/client';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import store from 'store';
import AppLayout from 'layouts/AppLayout.js';
import ErrorPage from './views/Pages/ErrorPage';
import { ShareLinkPage } from 'containers/ShareLinkPage/ShareLinkPage';
import Loading from './components/Loading/Loading';
import BrowserSupport from 'components/BrowserSupport/BrowserSupport';
import { isBrowserSupported } from 'utils/browserSupportHelpers';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { createTheme, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import './i18n';
import 'assets/scss/theme.scss?v=1.8.0';
import { LicenseInfo } from '@mui/x-license-pro';
//Init MUI X Pro
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE || '');
if (process.env.REACT_APP_SENTRY_ENABLE) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        autoSessionTracking: true,
    });
}
setGlobal({
    isOpenUploadModal: false,
}).catch(console.error);
const palette = {
    primary: {
        main: '#32BEE1',
    },
    secondary: {
        main: '#656D79',
    },
    info: {
        main: '#32BEE1',
    },
    warning: {
        main: '#F7B01E',
    },
    error: {
        main: '#FF645F',
    },
    success: {
        main: '#43CCAD',
    },
};
const theme = createTheme({
    palette,
    components: {
        // Name of the component
        MuiTextField: {
            defaultProps: {
                // The props to change the default for.
                variant: 'standard',
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: palette.secondary.main,
                    borderColor: palette.secondary.main,
                    '&:hover': {
                        color: palette.primary.main,
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        color: 'white',
                        '&:hover': {
                            color: 'white',
                        },
                    },
                },
            ],
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});
const App = () => {
    if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', process.env.REACT_APP_LANG || 'en');
    }
    return isBrowserSupported() ? (_jsx(ReduxProvider, { store: store, children: _jsx(Suspense, { fallback: _jsx(Loading, {}), children: _jsx(SnackbarProvider, { autoHideDuration: 3500, preventDuplicate: true, maxSnack: 4, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: _jsx(DndProvider, { backend: HTML5Backend, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: theme, children: _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/download/:id", element: _jsx(ShareLinkPage, {}) }), _jsx(Route, { path: '/*', element: _jsx(AppLayout, { path: "/*" }) }), _jsx(Route, { element: _jsx(ErrorPage, {}) })] }) }) }) }) }) }) }) })) : (_jsx(BrowserSupport, {}));
};
const root = createRoot(document.getElementById('root'));
root.render(App());
