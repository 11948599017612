import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmptyPartSearchList = () => {
  const { t } = useTranslation('partlistdetails');
  return (
    <Grid
      container
      style={{ minHeight: 200, height: '33vh' }}
      alignItems="center"
    >
      <Grid item sm={12} style={{ textAlign: 'center' }}>
        <h4>{t('plpd_search_part_info_title_msg')}</h4>
        <p>{t('plpd_search_part_info_subtitle_msg')}</p>
      </Grid>
    </Grid>
  );
};

export default EmptyPartSearchList;
