import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(styles);

export const DeleteFileModal = ({ confirm, open, loading }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  useEffect(() => {
    if (open) {
      deleteModal();
    } else {
      closeAlert();
    }
  }, [open]);

  const hideAlert = isConfirmed => {
    confirm(isConfirmed);
    setAlert(null);
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const deleteModal = () => {
    setAlert(
      <SweetAlert
        disabled={loading}
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_general_confirm')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        title={t('mdl_delete_file_title')}
      >
        {loading && (
          <div className="delete-file-modal-indicator">
            <CircularProgress />
          </div>
        )}
        {t('mdl_delete_file_description')}
      </SweetAlert>
    );
  };

  return <>{alert}</>;
};
