import React, {useEffect, useRef, useState} from 'react';
import './PartFiles.scss';
import {useTranslation} from 'react-i18next';
import {Button, Grid, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddIcon from '@mui/icons-material/Add';

import Table from 'components/Table/Table.js';

import {fetchFile, fetchPdfPreview} from 'services/parts';
import {patchAttachment} from 'services/attachments';

import {toDisplayDate} from 'utils/datetimeHelpers';
import {UploadModal} from 'components/Modals';
import {useDispatch, useSelector} from 'react-redux';
import {getPartAttachments, unassignPartAttachment, unassignPartAttachmentCleanup} from 'containers/PartDetails/PartDetailsActions';
import {UnassignAttachmentModal} from 'components/Modals/UnassignModals/UnassignAttachmentModal/UnassignAttachmentModal';
import {useSnackbar} from 'notistack';

const MySwal = withReactContent(Swal);

const FileActionType = {
  Edit: 0,
  Preview: 1,
  Download: 2,
  Delete: 3,
};

const PartFiles = ({attachments, id}) => {
  const {t} = useTranslation(['partdetails', 'partlistdetails', 'modals']);
  const theme = useTheme();
  const {unassignLoading, unassignSuccess, unassignError, data: {partConfigs}} = useSelector(
    state => state.part,
  );
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [isDeleteFileModalOpen, setDeleteFileModalOpen] = useState(false);
  const [localAttachments] = useState([...attachments]);
  /**
   * @description Use as a temporary file data in delete file modal so we can
   * remove the row on confirm
   */
  const [, setDeleteFileData] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const selectedAttachment = useRef(null);

  useEffect(() => {
    if (!unassignLoading && unassignSuccess) {
      dispatch(getPartAttachments(id));
      dispatch(unassignPartAttachmentCleanup());
      showSuccessNotification();
    }
  }, [unassignLoading]);

  const forceUpdate = async () => {
    const {isConfirmed} = await MySwal.fire({
      title: <Typography variant={'h5'}>{t('partlistdetails:plpd_invalidate_ma_force')}</Typography>,
      icon: 'warning',
      confirmButtonText: '✓',
      showCancelButton: true,
      cancelButtonText: t('partlistdetails:general_cancel'),
      reverseButtons: true,
      confirmButtonColor: theme.palette.primary.main,
    });

    if (isConfirmed) {
      dispatch(unassignPartAttachment({...selectedAttachment.current, force: true}));
    }

    // Cleanup
    selectedAttachment.current = null;
    dispatch(unassignPartAttachmentCleanup());

    location.reload();
  };

  useEffect(() => {
    if (!unassignError) return;

    if (unassignError?.response?.status === 420) {
      forceUpdate();
      return;
    }

    showErrorNotification();
  }, [unassignError]);

  /**
   * @description Delete file modal events
   */
  const handleDeleteConfirm = isConfirmed => {
    setDeleteFileModalOpen(false);
    if (isConfirmed && !!selectedAttachment.current) {
      dispatch(unassignPartAttachment({...selectedAttachment.current}));
    } else {
      // if cancel is click remove the current selected value
      selectedAttachment.current = null;
    }
  };

  const showSuccessNotification = () => {
    if (!unassignLoading && unassignSuccess) {
      enqueueSnackbar(t('partlistdetails:plpd_delete_file_success'), {
        variant: 'success',
      });
    }
  };

  const showErrorNotification = () => {
    selectedAttachment.current = null;
    dispatch(unassignPartAttachmentCleanup());
    enqueueSnackbar(t('modals:mdl_general_error_title'), {variant: 'error'});
    setDeleteFileModalOpen(false);
  };

  const openDeleteModal = ({rowId, attachmentId}) => {
    setDeleteFileData({rowId, attachmentId});
    setDeleteFileModalOpen(true);
  };

  const handleUploadConfirm = () => {
    setOpenUploadModal(false);
    dispatch(getPartAttachments(id));
  };

  const handleAttachmentEditName = async attachment => {
    const {value: name} = await Swal.fire({
      input: 'text',
      confirmButtonColor: '#00acc1',
      inputPlaceholder: t('new_attachment_name'),
      showConfirmButton: false,
      inputValue: attachment.name,
      inputValidator: value => {
        if (!value) {
          return t('name_cannot_be_empty');
        }
      },
    });

    if (name) {
      try {
        await patchAttachment(attachment.id, {name});
        dispatch(getPartAttachments(id));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleActionClick = actionData => {
    selectedAttachment.current = {
      partId: id,
      attachmentId: actionData.attachmentId,
    };
    switch (actionData.type) {
      case FileActionType.Edit:
        const editTarget = attachments.find(
          el => el.id === actionData.attachmentId,
        );
        handleAttachmentEditName(editTarget);
        break;
      case FileActionType.Preview:
        const previewTarget = attachments.find(
          el => el.id === actionData.attachmentId,
        );
        previewTarget.fileFormatKey === 'pdf'
          ? fetchPdfPreview(previewTarget.currentFileId)
          : fetchFile(
            previewTarget.id,
            previewTarget.name,
            previewTarget.fileFormatKey,
          );
        break;
      case FileActionType.Download:
        const downloadTarget = attachments.find(
          el => el.id === actionData.attachmentId,
        );
        fetchFile(
          downloadTarget.id,
          downloadTarget.name,
          downloadTarget.fileFormatKey,
        );
        break;
      case FileActionType.Delete:
        openDeleteModal(actionData);
        break;
      default:
        break;
    }
  };

  const createFileActions = (rowId, attachmentId, onActionClick) => {
    return [
      {
        color: 'primary',
        icon: EditIcon,
        clickHandler: onActionClick,
        type: FileActionType.Edit,
      },
      {
        color: 'info',
        icon: FindInPageIcon,
        clickHandler: onActionClick,
        type: FileActionType.Preview,
      },
      {
        color: 'primary',
        icon: GetAppIcon,
        clickHandler: onActionClick,
        type: FileActionType.Download,
      },
      {
        color: 'danger',
        icon: RemoveCircleOutlineIcon,
        clickHandler: onActionClick,
        type: FileActionType.Delete,
      },
    ].map((prop, key) => {
      return (
        <Button
          variant="outlined"
          style={{margin: 3, minWidth: 30, width: 41}}
          disabled={prop.disabled}
          key={key}
          onClick={() =>
            prop.clickHandler({
              rowId,
              attachmentId,
              type: prop.type,
            })
          }
        >
          <prop.icon style={{padding: 3, minWidth: 30}} />
        </Button>
      );
    });
  };

  const partHasMA = () => {
    if (!partConfigs) return false;
    return partConfigs?.some(pc => pc.batchSizes?.some(bs => bs.manufacturingAnalyses?.length));
  };

  const onUploadModalOpen = async () => {
    if (!partHasMA()) return setOpenUploadModal(true);

    const {isConfirmed} = await MySwal.fire({
      title: <Typography variant={'h5'}>{t('invalidate_ma_alert')}</Typography>,
      icon: 'warning',
      confirmButtonText: '✓',
      showCancelButton: true,
      cancelButtonText: t('partlistdetails:general_cancel'),
      reverseButtons: true,
      confirmButtonColor: '#32bee1',
    });

    if (isConfirmed) {
      return setOpenUploadModal(true);
    }
  };

  return (
    <>
      <h3>{t('pdf_title')}</h3>
      <Grid item xs={12} style={{marginBottom: 40}}>
        <Button variant="outlined" onClick={() => onUploadModalOpen()}>
          <AddIcon /> {t('pdf_action_button')}
        </Button>
      </Grid>
      <Paper className={'part-files-content'}>
        <Table
          tableHead={[
            '#',
            t('pdf_name'),
            t('pdf_file_type'),
            t('pdf_upload_date'),
            t('pdf_actions'),
          ]}
          tableData={
            localAttachments
              ? localAttachments.map((attachment, index) => {
                return [
                  index + 1,
                  attachment.name,
                  attachment.fileFormatKey || '-',
                  attachment.createdAt
                    ? toDisplayDate(attachment.createdAt)
                    : '-',
                  createFileActions(index, attachment.id, handleActionClick),
                ];
              })
              : []
          }
        />
      </Paper>
      <UploadModal
        confirm={handleUploadConfirm}
        open={openUploadModal}
        setOpen={setOpenUploadModal}
        url={`/parts/${id}/upload`}
        listUpload
      />
      <UnassignAttachmentModal
        loading={unassignLoading}
        success={unassignSuccess}
        error={unassignError}
        withButton={false}
        confirm={handleDeleteConfirm}
        open={isDeleteFileModalOpen}
      />
    </>
  );
};

export default PartFiles;
