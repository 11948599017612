import {
  NAVIGATE_BREADCRUMB,
  BREADCRUMB_PIN_AS_STICKY_HEADER,
} from './breadcrumbsTypes';

const getInitialState = () => {
  if (window.location.pathname.includes('part-lists')) {
    return {
      data: [
        {
          level: 0,
          path: '/part-lists',
          text: 'partlists:section_title',
        },
      ],
      pinAsStickyHeader: false,
    };
  } else if (window.location.pathname.includes('orders')) {
    return {
      data: [
        {
          level: 0,
          path: '/orders',
          text: 'orders:section_title',
        },
      ],
      pinAsStickyHeader: false,
    };
  } else if (window.location.pathname.includes('home')) {
    return {
      data: [
        {
          level: 0,
          path: '/home',
          text: 'dashboard:section_title',
        },
      ],
      pinAsStickyHeader: false,
    };
  } else {
    return {
      data: [
        {
          level: 0,
          path: '/part-storage',
          text: 'partstorage:section_title',
        },
      ],
      pinAsStickyHeader: false,
    };
  }
};

const constructBreadcrumbs = (currentState, newItem) => {
  if(Array.isArray(newItem)) {
    return newItem.sort((obj1, obj2) => obj1.level - obj2.level)
  }
  
  let nextState;

  if (currentState[0].path === '/part-storage' && newItem.level > 0) {
    nextState = [currentState[0], newItem];
    return nextState;
  } else if (newItem.level === 0) {
    nextState = [newItem];
    return nextState;
  }

  let currentItemAtLevel = currentState.find(
    item => item.level === newItem.level
  );

  if (!!currentItemAtLevel) {
    nextState = currentState.map(item => {
      if (item === currentItemAtLevel) {
        return newItem;
      } else {
        return item;
      }
    });
  } else {
    nextState = [...currentState, newItem];
  }

  nextState = nextState.sort((obj1, obj2) =>
    obj1.level > obj2.level ? 1 : -1
  );
  nextState.length = newItem.level + 1;

  return nextState.sort((obj1, obj2) => (obj1.level > obj2.level ? 1 : -1));
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case NAVIGATE_BREADCRUMB:
      return {
        data: constructBreadcrumbs(state.data, action.payload),
        actions: action.payload?.breadcrumbActions || undefined,
      };
    case BREADCRUMB_PIN_AS_STICKY_HEADER:
      return {
        ...state,
        pinAsStickyHeader: action.payload,
      };
    default:
      return state;
  }
};
