import React, {useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import './ShareDetailsModal.scss';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Grid, TextField} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {FormControl, Select, MenuItem} from '@mui/material';
import {generateRandomPassword} from 'utils/helpers';
import {validatePassword} from 'utils/validationsHelpers';
import {createSharedLink} from 'services/shared-lists';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles(styles);

const selectStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ShareDetailsModal = ({open, setOpen, confirm, listData, next}) => {
  const {t} = useTranslation(['modals', 'errors']);
  const classes = useStyles();
  const selectClasses = selectStyles();
  const [accessibility, setAccessibility] = useState('');
  const [accessibilityError, setAccessibilityError] = useState(false);
  const accessibilityOptions = {
    withoutPassword: 0,
    setPassword: 1,
    generatePassword: 2,
    linkNeverExpires: 3,
  };

  // password input
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState('');
  const [disablePassword, setDisablePassword] = useState(false);
  const [passwordError, setPasswordError] = React.useState({
    error: false,
    msg: '',
  });

  const [disableSaveBtn, setDisableSaveBtn] = useState(false);

  const [selectedDate, setSelectedDate] = React.useState(moment().add(30, 'days'));
  const [isDateNeverExpired, setDateNeverExpired] = useState(false);

  const handleAccessibilitySelection = event => {
    const accessibilityVal = event.target.value;
    // set value
    setAccessibility(accessibilityVal);
    setAccessibilityError(false);
    setPasswordError({
      error: false,
      msg: '',
    });

    // password setup
    switch (accessibilityVal) {
      case accessibilityOptions.withoutPassword:
        setShowPassword(false);
        setPassword(null);
        break;
      case accessibilityOptions.setPassword:
        setPassword(null);
        setShowPassword(true);
        setDisablePassword(false);
        break;
      case accessibilityOptions.generatePassword:
        setPassword(generateRandomPassword());
        setShowPassword(true);
        setDisablePassword(true);
        break;
      case accessibilityOptions.linkNeverExpires:
        setDateNeverExpired(true);
        break;
      default:
        break;
    }
  };
  const handleDateChange = date => {
    setSelectedDate(date.toISOString());
  };

  const resetForm = () => {
    setAccessibility('');
    setAccessibilityError(false);
    setSelectedDate(moment().add(30, 'days'));
    setPassword(null);
    setPasswordError({
      error: false,
      msg: '',
    });
  };

  const passwordLabel = () => {
    return disablePassword
      ? t('mdl_shared_link_generated_pass_lbl')
      : t('mdl_shared_link_accessibility_set_pass');
  };

  const closeAlert = () => {
    resetForm();
    setOpen(false);
  };

  const validateForm = () => {
    let isFormValid = true;
    // user must select accessibility option
    if (accessibility === '') {
      setAccessibilityError(true);
      isFormValid = false;
    }

    // if user select to enter the password manually
    // validate input
    if (accessibility === accessibilityOptions.setPassword) {
      const validation = validatePassword(password);
      setPasswordError(validation);
      if (validation.error) {
        isFormValid = false;
      }
    }
    return isFormValid;
  };

  const confirmAlert = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    setDisableSaveBtn(true);

    const data = {
      expiryDatetime: isDateNeverExpired ? null : selectedDate,
      password: password,
    };

    const requestData = {
      partListId: listData.current.id,
      shareName: listData.current.name,
      ...data,
    };

    const linkData = await createSharedLink(requestData).catch(() => {
      setDisablePassword(true);
      return;
    });
    setDisablePassword(false);
    confirm.current = linkData.data;
    next();
    resetForm();
  };

  return (
    <>
      {open && (
        <SweetAlert
          style={{maxHeight: '90%', overflowY: 'auto'}}
          disabled={disableSaveBtn}
          customClass={'share-details-modal'}
          showCancel
          closeOnClickOutside={false}
          onConfirm={() => confirmAlert()}
          onCancel={() => closeAlert()}
          confirmBtnText={t('mdl_general_save')}
          cancelBtnText={t('mdl_general_cancel')}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.cancel}
          title={t('mdl_shared_link_creat_link_title')}
        >
          <Grid item xs={12} className={'secure-link-modal-content'}>
            <h4>{t('mdl_shared_link_accessibility_title')}</h4>
            <p>{t('mdl_shared_link_accessibility_desc')}</p>
            <Grid item xs={12}>
              <div>
                <FormControl className={selectClasses.formControl}>
                  <InputLabel id="accessibility-label">
                    {t('mdl_shared_link_select_accessibility')}
                  </InputLabel>
                  <Select
                    id="accessibility-cbx"
                    labelId="accessibility-label"
                    value={accessibility}
                    onChange={handleAccessibilitySelection}
                    className={selectStyles.selectEmpty}
                  >
                    <MenuItem value={accessibilityOptions.withoutPassword}>
                      {t('mdl_shared_link_accessibility_no_pass')}
                    </MenuItem>
                    <MenuItem value={accessibilityOptions.setPassword}>
                      {t('mdl_shared_link_accessibility_set_pass')}
                    </MenuItem>
                    <MenuItem value={accessibilityOptions.generatePassword}>
                      {t('mdl_shared_link_generate_pass')}
                    </MenuItem>
                  </Select>
                  {accessibilityError && (
                    <div className={'inp-error'}>
                      {t('mdl_shared_link_select_accessibility_error')}
                    </div>
                  )}
                </FormControl>
              </div>
            </Grid>
            {showPassword && (
              <>
                <br></br>
                <Grid item xs={12}>
                  <TextField
                    disabled={disablePassword}
                    label={passwordLabel()}
                    error={passwordError.error}
                    type="text"
                    fullWidth
                    value={password || ''}
                    onChange={event => {
                      setPasswordError('');
                      setPassword(event.target.value);
                    }}
                  />
                  {!disablePassword && (
                    <div
                      style={{fontSize: 12}}
                      className={passwordError.error && 'inp-error '}
                    >
                      {t('mdl_shared_link_password_info')}
                    </div>
                  )}
                </Grid>
              </>
            )}
            <br></br>
            <h4>
              <b>{t('mdl_shared_link_link_valid_until_title')}</b>
            </h4>
            <p>{t('mdl_shared_link_link_valid_until_desc')}</p>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={params => <TextField {...params} />}
                  minDate={moment()}
                  maxDate={moment().add(12, 'months')}
                  format={'MM/DD/YYYY'}
                  disabled={isDateNeverExpired}
                />
              </LocalizationProvider>
            </Grid>

            <FormControlLabel
              style={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 14}}
              control={
                <Checkbox
                  checked={isDateNeverExpired}
                  onChange={() => {
                    setDateNeverExpired(!isDateNeverExpired);
                  }}
                  value="never-expires"
                  color="primary"
                />
              }
              label={t('mdl_shared_link_accessibility_no_expiration')}
            />
          </Grid>
        </SweetAlert>
      )}
    </>
  );
};

export default ShareDetailsModal;
