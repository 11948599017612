import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Trans, withTranslation} from 'react-i18next';
import {Button, Chip, Container, Grid, Paper, Tooltip} from '@mui/material';
import {BarChart, ShoppingCart, TableView, ViewComfy} from '@mui/icons-material';
import {withSnackbar} from 'notistack';
import InfiniteScroll from 'react-infinite-scroller';

import {getOrderTeasers} from 'services/orders';
import {LoadingWithoutHeight} from 'components/Loading/Loading';
import OrderCard from 'components/Orders/OrderCard';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import {updateBreadcrumbs} from 'store/breadcrumbs/breadcrumbsActions';
import {DataGrid} from '@mui/x-data-grid';
import {NavLink} from 'react-router-dom';
import {toDisplayDate} from '../../utils/datetimeHelpers';
import warning from '../../assets/img/warning.gif';

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: () => dispatch(
      updateBreadcrumbs({
        level: 0,
        path: window.location.pathname,
        text: 'orders:section_title',
      }),
    ),
  };
};

const connectOrders = connect(null, mapDispatchToProps);

class Orders extends Component {
  constructor(props) {
    super(props);

    this.props.setBreadcrumbs();

    this.filterInputs = [
      {name: 'name', label: 'name', type: 'string'},
      {name: 'placed', label: 'placed', type: 'boolean'},
      {name: 'delayed', label: 'delayed', type: 'boolean'},
      {name: 'delivered', label: 'delivered', type: 'boolean'},
    ];

    this.defaultDataState = {
      orders: [],
      page: 0,
      totalPageCount: 0,
      totalItemCount: 0,
    };

    this.state = {
      loading: true,
      showInfiniteScroll: window.localStorage.getItem('showInfiniteScroll') ? window.localStorage.getItem('showInfiniteScroll') === 'true' : false,
      fields: {},
      ...this.defaultDataState,
    };

    window.localStorage.setItem('showInfiniteScroll', this.state.showInfiniteScroll.toString());
  }

  refresh = async () => {
    await this.getOrderTeasersAndUpdateState(1, true);
  };

  toggleView = () => {
    window.localStorage.setItem('showInfiniteScroll', (!this.state.showInfiniteScroll).toString());

    this.setState({
      showInfiniteScroll: !this.state.showInfiniteScroll,
      loading: true,
      ...this.defaultDataState,
    });
  };

  getFilter = (page = 1) => {
    const {fields} = this.state;

    const where = {};

    if (fields && Object.keys(fields).length) {
      const stringFields = Object.keys(fields).filter(fieldName => this.filterInputs.find(i => i.name === fieldName)?.type !== 'boolean');
      const booleanFields = Object.keys(fields)
        .filter(fieldName => fieldName !== 'delayed' && this.filterInputs.find(i => i.name === fieldName)?.type === 'boolean');
      const or = [];

      stringFields.forEach(fieldName => {
        if (fields[fieldName]) {
          or.push({
            [fieldName]: {ilike: `%${fields[fieldName].trim()}%`},
          });
        }
      });

      if (booleanFields.length) {
        or.push({
          statusKey: {
            inq: booleanFields,
          },
        });
      }

      if (fields.delayed) {
        where.delayed = true;
      }

      where.or = or;
    }

    return {
      filter: {
        limit: 25,
        order: 'exportedAt DESC, id DESC',
        page,
        include: [
          {
            relation: 'orderedAttachments',
            scope: {
              include: ['attachmentType', 'fileFormat'],
            },
          },
        ],
        ...where?.length || where?.or?.length && {where},
      },
    };
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fields !== this.state.fields ||
      prevState.showInfiniteScroll !== this.state.showInfiniteScroll
    ) {
      try {
        await this.setState({
          ...this.defaultDataState,
        });

        await this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  }

  getOrderTeasersAndUpdateState = async (page = 1, refresh = false) => {
    if (refresh) {
      this.setState({
        loading: true,
      });
    }

    // Get Orders
    const {data: {page: orders, pagination: {totalPageCount, totalItemCount, currentPage}}} = await getOrderTeasers(this.getFilter(page));

    this.setState({
      orders: refresh ? orders : this.state.showInfiniteScroll ? [...this.state.orders, ...orders] : orders,
      page: orders.length > 0 ? currentPage : 0,
      totalPageCount,
      totalItemCount,
      loading: false,
    });
  };

  render() {
    const {t} = this.props;

    return (
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid container justifyContent={'flex-end'} item xs={12} spacing={2} alignItems={'center'}>
            <Grid item>
              <Button
                variant={'outlined'}
                startIcon={this.state.showInfiniteScroll ? <TableView /> : <ViewComfy />}
                onClick={this.toggleView}
              >
                {t('common:cmn_toggle_view')}
              </Button>
            </Grid>
            <Grid item>
              <FilterMenu
                search={fields => this.setState({fields})}
                inputs={this.filterInputs}
                history={this.props.history}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{height: 'calc(100vh - 276px'}}>
            {this.state.loading && this.state.showInfiniteScroll ? (
              <Grid item sm={12}>
                <LoadingWithoutHeight />
              </Grid>
            ) : this.state.showInfiniteScroll ? (
              <div style={{height: '100%', maxHeight: '100%', overflowY: 'auto'}}>
                <InfiniteScroll
                  hasMore={this.state.page < this.state.totalPageCount}
                  loadMore={() => this.getOrderTeasersAndUpdateState(this.state.page + 1)}
                  loader={<LoadingWithoutHeight />}
                  useWindow={false}
                >
                  <Grid container>
                    {this.state.orders.map((order, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                        <OrderCard order={order} />
                      </Grid>
                    ))}
                    {!this.state.orders.length && <Grid item xs={12} container justifyContent={'center'}>
                      <Grid item sx={{maxWidth: 600}} textAlign={'center'}>
                        <h3 align="center">
                          <Trans
                            i18nKey={'orders:no_orders'}
                            components={[
                              <Button
                                variant="outlined"
                                style={{paddingRight: '0px', paddingLeft: '12px', color: '#3d4858', borderColor: '#3d4858'}}
                                startIcon={<BarChart color="black" />}
                                disabled
                              />,
                              <ShoppingCart fontSize={'small'} style={{marginBottom: -2}} />,
                            ]}
                          />
                        </h3>
                      </Grid>
                    </Grid>}
                  </Grid>
                </InfiniteScroll>
              </div>
            ) : (
              <Paper sx={{height: '100%'}}>
                <DataGrid
                  rows={this.state.orders}
                  columns={[
                    {field: 'kmsId', headerName: 'ID', flex: 0.25, sortable: false},
                    {
                      field: 'name',
                      headerName: 'Name',
                      renderCell: params => <NavLink to={`/orders/${params.row.id}`}>{params.value}</NavLink>,
                      flex: 1,
                      sortable: false,
                    },
                    {
                      field: 'internalOrderNumber',
                      headerName: t('internal_order_number'),
                      renderCell: params => params.value?.length ? params.value : '-',
                      flex: 0.75,
                      sortable: false,
                    },
                    {field: 'exportedAt', headerName: t('ordered_on'), renderCell: params => toDisplayDate(params.value), flex: 0.75, sortable: false},
                    {
                      field: 'desiredDeliveryDate',
                      headerName: t('order_details:estimated_delivery_date'),
                      renderCell: params => <div style={{display: 'flex', alignItems: 'center'}}>
                        {toDisplayDate(params.value)}
                        {
                          params.row.delayed &&
                          !['delivered', 'invoice_sent', 'invoice_paid'].includes(params.row.statusKey) &&
                          <Tooltip title={t('order_delayed')} placement={'bottom'} arrow>
                            <img src={warning} alt={'warning'} style={{width: '20px', height: '20px', marginBottom: 4, marginLeft: 4}} />
                          </Tooltip>
                        }
                      </div>,
                      flex: 0.75,
                      sortable: false,
                    },
                    {
                      field: 'status',
                      headerName: t('order_details:order_status'),
                      renderCell: params => <Chip
                        color={'success'}
                        style={{color: 'white'}}
                        label={t(`status_${params.row.statusKey}`)}
                      />,
                      flex: 0.75,
                      sortable: false,
                    },
                  ]}
                  page={this.state.page - 1}
                  paginationMode={'server'}
                  pageSize={25}
                  rowCount={this.state.totalItemCount}
                  rowsPerPageOptions={[25]}
                  disableSelectionOnClick
                  onPageChange={page => this.getOrderTeasersAndUpdateState(page + 1)}
                  loading={this.state.loading}
                  disableColumnFilter
                  disableColumnMenu
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withSnackbar(withTranslation(['orders', 'order_details', 'common'])(connectOrders(Orders)));
