import {
  SHARED_LIST_GET,
  SHARED_LIST_GET_SUCCESS,
  SHARED_LIST_GET_ERROR,
  SHARED_LISTS_GET,
  SHARED_LISTS_GET_SUCCESS,
  SHARED_LISTS_GET_ERROR,
  SHARED_INITIAL_LISTS_GET,
  SHARED_INITIAL_LISTS_GET_SUCCESS,
  SHARED_INITIAL_LISTS_GET_ERROR,
  SHARED_LIST_CREATE,
  SHARED_LIST_CREATE_SUCCESS,
  SHARED_LIST_CREATE_ERROR,
  SHARED_LIST_UPDATE,
  SHARED_LIST_UPDATE_SUCCESS,
  SHARED_LIST_UPDATE_ERROR,
  SHARED_LIST_DELETE,
  SHARED_LIST_DELETE_SUCCESS,
  SHARED_LIST_DELETE_ERROR,
  SHARED_LIST_CLEAR_ERROR,
} from './SharedPartsTypes';
import { batch } from 'react-redux';
import {
  fetchAllSharedLists,
  updateAllSharedLinks,
  deleteAllSharedList,
  fetchSharedList,
  createSharedLink,
} from 'services/shared-lists';

export const getSharedListAction = id => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_GET,
      });

      const response = await fetchSharedList(id);
      batch(() => {
        dispatch({
          type: SHARED_LIST_GET_SUCCESS,
          payload: response.data,
        });
      });
    } catch (error) {
      dispatch({
        type: SHARED_LIST_GET_ERROR,
        payload: error,
      });
    }
  };
};

export const getInitialAllSharedListsAction = params => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_CLEAR_ERROR,
      });
      dispatch({
        type: SHARED_INITIAL_LISTS_GET,
      });
      const response = await fetchAllSharedLists(
        params || {
          filter: { order: 'createdAt DESC' },
        }
      );

      dispatch({
        type: SHARED_INITIAL_LISTS_GET_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SHARED_INITIAL_LISTS_GET_ERROR,
        payload: error,
      });
    }
  };
};

export const getAllSharedListsAction = params => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_CLEAR_ERROR,
      });
      dispatch({
        type: SHARED_LISTS_GET,
      });
      const response = await fetchAllSharedLists(
        params || {
          filter: { order: 'createdAt DESC' },
        }
      );
      dispatch({
        type: SHARED_LISTS_GET_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SHARED_LISTS_GET_ERROR,
        payload: error,
      });
    }
  };
};

export const createSharedListAction = payload => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_CREATE,
      });

      const response = await createSharedLink(payload);
      batch(() => {
        dispatch({
          type: SHARED_LIST_CREATE_SUCCESS,
          payload: response.data,
        });
      });
    } catch (error) {
      dispatch({
        type: SHARED_LIST_CREATE_ERROR,
        payload: error,
      });
    }
  };
};

export const updateSharedListAction = ({ payload, ids, listPagination }) => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_UPDATE,
      });
      await updateAllSharedLinks(payload, ids);
      batch(() => {
        dispatch({
          type: SHARED_LIST_UPDATE_SUCCESS,
        });
        // refresh the list
        dispatch(getAllSharedListsAction(listPagination));
      });
    } catch (error) {
      dispatch({
        type: SHARED_LIST_UPDATE_ERROR,
        payload: error,
      });
    }
  };
};

export const deleteSharedListAction = ({ ids, listPagination }) => {
  return async dispatch => {
    try {
      dispatch({
        type: SHARED_LIST_DELETE,
      });

      const response = await deleteAllSharedList(ids);

      batch(() => {
        dispatch({
          type: SHARED_LIST_DELETE_SUCCESS,
          payload: response.data,
        });
        dispatch(getAllSharedListsAction(listPagination));
      });
    } catch (error) {
      dispatch({
        type: SHARED_LIST_DELETE_ERROR,
        payload: error,
      });
    }
  };
};
