import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Chip, Grid, Grow, Icon, Typography} from '@mui/material';
import { Alert } from '@mui/material';
import {Filter1, InsertDriveFile} from '@mui/icons-material';

class ReviewAndSubmit extends Component {
  constructor (props) {
    super(props);
  }
  
  render () {
    const {t, partList} = this.props;
    return (
      <>
        <Grow in={true} timeout={650}>
          <Grid container rowSpacing={2}>
            {partList?.partConfigs?.map(partConfig => (
              <Grid item xs={12}>
                <Alert
                  variant={'outlined'}
                  icon={<Icon color={'inherit'}>precision_manufacturing</Icon>}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant={'body1'}>{partConfig.part.name}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{width: '100%'}}>
                      <div
                        style={{
                          display: 'flex',
                          alignItem: 'center',
                          justifyContent: 'flex-start',
                          gap: 10,
                        }}
                      >
                        <InsertDriveFile color={'primary'} />
                        <Typography variant={'body1'}>{t('attachments')}:</Typography>
                      </div>
                    </Grid>
                    <Grid container item xs={12} style={{width: '100%'}} spacing={1}>
                      {partConfig.part?.attachments?.length ? (
                        <>
                          {partConfig.part.attachments.map(a => (
                            <Grid item>
                              <Chip 
                                label={`${a.name}.${a.currentFile.fileFormat.extension}`}
                                color={'primary'}
                                style={{color: 'white'}}
                              />
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <Grid item>
                          <Typography>{t('no_attachments')}</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{width: '100%'}}>
                      <div
                        style={{
                          display: 'flex',
                          alignItem: 'center',
                          justifyContent: 'flex-start',
                          gap: 10,
                        }}
                      >
                        <Filter1 color={'primary'} />
                        <Typography variant={'body1'}>{t('batch_sizes')}: {partConfig.batchSizes.map(bs => bs.batchSize).join(', ') || t('no_batch_sizes')}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Alert>
              </Grid>
            ))}
          </Grid>
        </Grow>
      </>
    )
  }
}

export default withTranslation('automatic_configuration')(ReviewAndSubmit)