import {
  GET_ALL_PROCESSES,
  GET_ALL_PROCESSES_ERROR,
  GET_ALL_PROCESSES_SUCCESS,
  GET_CURRENT_USER_INFO,
  GET_CURRENT_USER_INFO_ERROR,
  GET_CURRENT_USER_INFO_SUCCESS,
  GET_FIRST_LISTS,
  GET_FIRST_LISTS_ERROR,
  GET_FIRST_LISTS_SUCCESS,
  GET_MATERIALS,
  GET_MATERIALS_ERROR,
  GET_MATERIALS_MORE,
  GET_MATERIALS_MORE_ERROR,
  GET_MATERIALS_MORE_SUCCESS,
  GET_MATERIALS_SUCCESS,
  GET_PROCESSES,
  GET_PROCESSES_ERROR,
  GET_PROCESSES_SUCCESS,
  GET_SUPPLIERS,
  GET_SUPPLIERS_ERROR,
  GET_SUPPLIERS_SUCCESS,
} from './generalTypes';

import {fetchAllProcesses, fetchProcesses} from 'services/general';
import {fetchMaterials} from 'services/materials';
import {fetchCurrentUserInfo} from 'services/users';
import {fetchFirstPageLists} from 'services/part-lists';
import {getAllSuppliers} from '../../services/suppliers';

export const getUserInfo = () => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_CURRENT_USER_INFO,
      });

      const response = await fetchCurrentUserInfo();
      dispatch({
        type: GET_CURRENT_USER_INFO_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_CURRENT_USER_INFO_ERROR,
        payload: error,
      });
    }
  };
};

export const getSuppliers = () => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_SUPPLIERS,
      });

      const {data} = await getAllSuppliers();
      dispatch({
        type: GET_SUPPLIERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_SUPPLIERS_ERROR,
        payload: err,
      });
    }
  };
};

export const getProcesses = (params = {
  filter: {
    include: {
      partsProcessFeatures: {
        include: {
          processFeature: true,
          selectOptions: true,
        },
      },
    },
  },
}) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_PROCESSES,
      });

      const response = await fetchProcesses({
        ...params,
      });
      dispatch({
        type: GET_PROCESSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PROCESSES_ERROR,
        payload: error,
      });
    }
  };
};

export const getAllProcesses = (params = {
  filter: {
    include: {
      processProcessFeatures: {
        include: {
          processFeature: true,
          processProcessFeatureSelectOptions: true,
        },
      },
    },
  },
}) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_ALL_PROCESSES,
      });

      const {data} = await fetchAllProcesses(params);
      dispatch({
        type: GET_ALL_PROCESSES_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: GET_ALL_PROCESSES_ERROR,
        payload: e,
      });
    }
  };
};

export const getMaterials = (params = null, callback) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_MATERIALS,
      });

      const response = await fetchMaterials({
        ...params,
      });

      callback(response.data);

      dispatch({
        type: GET_MATERIALS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);

      dispatch({
        type: GET_MATERIALS_ERROR,
        payload: error,
      });
    }
  };
};

export const getMoreMaterials = (params = null, pagination) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_MATERIALS_MORE,
      });

      const hasMore = pagination.currentPage < pagination.totalPageCount;

      const response = hasMore
        ? await fetchMaterials({
          ...params,
          page: pagination.currentPage + 1,
        })
        : {data: {}};

      dispatch({
        type: GET_MATERIALS_MORE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MATERIALS_MORE_ERROR,
        payload: error,
      });
    }
  };
};

export const getFirstPageLists = (filter = {filter: {limit: 4}}) => {
  return async dispatch => {
    dispatch({
      type: GET_FIRST_LISTS,
    });

    try {
      const response = await fetchFirstPageLists(filter);
      dispatch({
        type: GET_FIRST_LISTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FIRST_LISTS_ERROR,
        payload: error,
      });
    }
  };
};
