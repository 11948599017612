import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import '../parts-modals.scss';
import './RenameListModal.scss';

const useStyles = makeStyles(styles);

export const RenameListModal = ({ confirm, open, name }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (open) {
      renameModal();
    } else {
      closeAlert();
    }
  }, [open]);

  const hideAlert = response => {
    if (confirm) {
      confirm(response);
    }
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const renameModal = () => {
    setAlert(
      <SweetAlert
        // input
        // defaultValue={name}
        showCancel
        closeOnClickOutside={false}
        onConfirm={response => hideAlert(response)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_rename')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={`${classes.button} ${classes.success} md-disabled-button`}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        title={t('mdl_rename_part_list_title')}
        disabled
      >
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Grid item xs={10}>
            <CustomInput
              inputProps={{
                disabled: false,
                value: name,
              }}
              className="name-filter"
              color="primary"
              labelText={t('mdl_rename_list_name').toUpperCase()}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </Grid>
        </div>
      </SweetAlert>
    );
  };

  return <>{alert}</>;
};
