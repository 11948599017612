import React, { useContext, useEffect, useState, useRef } from 'react';
import { ConfigSubstep } from '../ConfigSubstep/ConfigSubstep';
import { ConfigContext } from 'components/Modals/ConfigsModal/ConfigContext';

import {
  CREATE_SUBSTEP,
  REMOVE_SUBSTEP,
  CHANGE_SUBSTEP,
} from 'components/Modals/ConfigsModal/ConfigTypes';
import DeleteSubstepModal from '../../ModalAlerts/DeleteSubstepModal';

import './ConfigStep.scss';
import { StepType } from '../../../Models/StepTypeEnum';
import { useTranslation } from 'react-i18next';
import { deleteProcess } from 'services/configs';
import { UPDATE_TEMP_DATA } from 'components/Modals/ConfigsModal/ConfigTypes';
import { CHANGE_STEP } from 'components/Modals/ConfigsModal/ConfigTypes';
import { useSnackbar } from 'notistack';
import { REMOVING_POST_PROCESS_SUBSTEP } from 'components/Modals/ConfigsModal/ConfigTypes';
import { REMOVING_MAIN_PROCESS_SUBSTEP } from 'components/Modals/ConfigsModal/ConfigTypes';
import { getFreeSubstepSpot } from 'components/Modals/ConfigsModal/utils/stepsHelper';
import { REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP } from 'components/Modals/ConfigsModal/ConfigTypes';
import { getProcessesCount } from 'components/Modals/ConfigsModal/utils/stepsHelper';

export const ConfigStep = ({ step }) => {
  const { t } = useTranslation(['partconfigurator']);
  const { dispatch, state } = useContext(ConfigContext);
  //REMOVE
  const [showRemoveStepAlert, setShowRemoveStepAlert] = useState(false);
  const removeStepData = useRef(null);
  const removingPostProcess = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalTitle, setDeleteModalTitle] = useState('');

  useEffect(() => {
    if (
      state.deletePostProcessSubstep ||
      state.deleteMainProcessSubstep ||
      state.deleteProductRequirement
    ) {
      navigateToProcessStep();
    }
  }, [state.substeps]);


  const canAddMoreSubsteps = (substeps, step) => {
    switch (step.type) {
      case StepType.MAIN_PROCESS.val:
        if (hasEmptyMainProcess()) {
          enqueueSnackbar(t('pc_pdl_select_one_main_process_msg'), {
            variant: 'info',
          });
          return false;
        }
        const limitedMainProcessTabs = substeps.filter(
          substep => substep.stepId === step.id
        );
        return limitedMainProcessTabs.length < step.limit;

      case StepType.POST_PROCESS.val:
        if (!hasSelectedMainProcess()) {
          enqueueSnackbar(t('pc_pdl_select_main_process_msg'), {
            variant: 'info',
          });
          return false;
        }
        // maximum tabs without selected process is one.
        const filteredSubsteps = substeps.filter(
          substep => substep.stepId === step.id
        );

        const steps = filteredSubsteps.filter(item => item.value === null);
        if (steps.length > 0) {
          enqueueSnackbar(t('pc_pdl_select_post_process_msg'), {
            variant: 'info',
          });
          return false;
        }
        break;

      case StepType.PRODUCT_REQUIREMENTS.val:
        if (!hasSelectedMainProcess()) {
          enqueueSnackbar(t('pc_pdl_select_main_process_msg'), {
            variant: 'info',
          });
          return false;
        }

        const limitedProdReqTabs = substeps.filter(
          substep => substep.stepId === step.id
        );
        return limitedProdReqTabs.length < step.limit;
    }

    return true;
  };

  const hasEmptyMainProcess = () => {
    const emptyMainProcesses = state.substeps.filter(
      substep => substep.value === null && substep.type === 2
    )

    return emptyMainProcesses.length === 1
  }

  const hasSelectedMainProcess = () => {
    // One main process must be selected
    const mainProcesses = state.substeps.filter(
      substep => substep.stepId === StepType.MAIN_PROCESS.val
    );

    const hasProcess =
      mainProcesses.length > 0 && state.tempData.mainProcesses.length;

    return !!hasProcess;
  };

  const [canAddMore] = useState(
    state.substeps.filter(substep => substep.stepId === step.id).length <
    step.limit
  );
  const addSubstep = step => {
    const substepId = state.substeps.filter(item => item.stepId === step.id)
      .length;
    const canAdd = canAddMoreSubsteps(state.substeps, step);
    if (canAdd) {
      dispatch({
        type: CREATE_SUBSTEP,
        payload: {
          id: substepId,
          stepId: step.id,
          name: t(`partconfigurator:${step.empty_key}`),
          value: null,
          type: step.type,
        },
      });
      dispatch({
        type: CHANGE_SUBSTEP,
        payload: {
          currentSubstep: substepId,
          currentStep: step.id,
        },
      });
    }
    hasEmptyMainProcess();
  };

  const navigateToProcessStep = () => {
    const processesCount = getProcessesCount(
      state.deleteMainProcessSubstep
        ? state.tempData.mainProcesses
        : state.tempData.postProcesses
    );

    if (state.deleteProductRequirement || processesCount === 0) {
      dispatch({
        type: CHANGE_STEP,
        payload: {
          currentStep: 0,
          currentSubstep: 0,
        },
      });
    } else {
      const processes = state.deleteMainProcessSubstep
        ? [...state.tempData.mainProcesses]
        : [...state.tempData.postProcesses];

      const stepToChange = getFreeSubstepSpot(
        processes,
        state.tempData.currentSubstep
      );

      dispatch({
        type: CHANGE_STEP,
        payload: {
          currentStep: state.deleteMainProcessSubstep
            ? StepType.MAIN_PROCESS.val
            : StepType.POST_PROCESS.val,
          currentSubstep: stepToChange,
        },
      });
    }

    if (state.deleteMainProcessSubstep) {
      dispatch({
        type: REMOVING_MAIN_PROCESS_SUBSTEP,
        payload: { isDeleting: false },
      });
    } else if (state.deletePostProcessSubstep) {
      dispatch({
        type: REMOVING_POST_PROCESS_SUBSTEP,
        payload: { isDeleting: false },
      });
    } else if (state.deleteProductRequirement) {
      dispatch({
        type: REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP,
        payload: { isDeleting: false },
      });
    }
  };

  /**
   * REMOVE
   */
  const handleRemoveModalConfirm = answer => {
    if (answer) {
      removingPostProcess.current = true;
      if (removeStepData.current && state.tempData.configId) {
        let removeProcess = null;
        if (
          removeStepData.current.stepId === StepType.POST_PROCESS.val ||
          removeStepData.current.stepId === StepType.MAIN_PROCESS.val
        ) {
          const { process } =
            removeStepData.current.stepId === StepType.MAIN_PROCESS.val
              ? state.tempData.mainProcesses[removeStepData.current.id]
              : state.tempData.postProcesses[removeStepData.current.id];
          removeProcess = { ...process };
        } else if (
          removeStepData.current.stepId === StepType.PRODUCT_REQUIREMENTS.val
        ) {
          const process =
            state.tempData.productRequirements &&
              state.tempData.productRequirements.length > 0
              ? state.tempData.productRequirements[0].process
              : null;
          removeProcess = { ...process };
        }

        if (!removeProcess) {
          return;
        }

        deleteProcess(state.tempData.configId, removeProcess.id)
          .then(() => {
            const processMapping = {1: 'mainProcesses', 2: 'postProcesses', 3: 'productRequirements'};
            const processType = processMapping[removeStepData.current.stepId];

            const payload = {
              [processType]: state.tempData[processType].filter(p => p.process.id !== removeProcess.id)
            };

            dispatch({
              type: UPDATE_TEMP_DATA,
              payload,
            });

            const actionMapping = {1: 'REMOVING_MAIN_PROCESS_SUBSTEP', 2: 'REMOVING_POST_PROCESS_SUBSTEP', 3: 'REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP'};
            dispatch({
              type: actionMapping[removeStepData.current.stepId],
              payload: { isDeleting: true },
            });

            dispatch({
              type: REMOVE_SUBSTEP,
              payload: {
                Id: removeStepData.current.stepId,
                substepId: removeStepData.current.id,
              },
            });
          })
          .catch(error => {
            enqueueSnackbar(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message,
              {
                variant: 'error',
              }
            );
          });
      }
    }
    setShowRemoveStepAlert(false);
  };

  const handleRemoveStep = ({ id, stepId }) => {
    let processToDelete = undefined;

    switch (stepId) {
      case StepType.MAIN_PROCESS.val:
        processToDelete = state.tempData.mainProcesses[id];
        setDeleteModalTitle(t('pc_mdl_delete_process_title'));
        break;
      case StepType.POST_PROCESS.val:
        processToDelete = state.tempData.postProcesses[id];
        setDeleteModalTitle(t('pc_mdl_delete_process_title'));
        break;
      case StepType.PRODUCT_REQUIREMENTS.val:
        processToDelete = state.tempData.productRequirements[id];
        setDeleteModalTitle(t('pc_mdl_delete_product_req_title'));
        break;
      default:
        break;
    }

    removeStepData.current = { stepId, id };

    if (processToDelete !== undefined) {
      setShowRemoveStepAlert(true);
    } else {
      removingPostProcess.current = true;
      if (stepId === StepType.MAIN_PROCESS.val) {
        dispatch({
          type: REMOVING_MAIN_PROCESS_SUBSTEP,
          payload: { isDeleting: true },
        });
      } else if (stepId === StepType.POST_PROCESS.val) {
        dispatch({
          type: REMOVING_POST_PROCESS_SUBSTEP,
          payload: { isDeleting: true },
        });
      } else if (stepId === StepType.PRODUCT_REQUIREMENTS.val) {
        dispatch({
          type: REMOVING_PRODUCT_REQUIREMENTS_SUBSTEP,
          payload: { isDeleting: true },
        });
      }

      /**
       * Removed step without data selection
       */
      dispatch({
        type: REMOVE_SUBSTEP,
        payload: {
          Id: stepId,
          substepId: id,
        },
      });
    }
  };

  const hasRemoveBtn = stepType => {
    return (
      stepType === StepType.MAIN_PROCESS.val ||
      stepType === StepType.POST_PROCESS.val ||
      stepType === StepType.PRODUCT_REQUIREMENTS.val
    );
  };

  return (
    <>
      <div className="config-step">
        <h6 className="config-title">{t(`partconfigurator:${step.key}`)}</h6>
        {state.substeps.map(substep => {
          if (substep.stepId === step.id) {
            return (
              <ConfigSubstep
                key={`substep-${substep.id}${new Date().getTime()}`}
                id={substep.id}
                stepId={step.id}
                name={substep.name}
                showRemoveBtn={hasRemoveBtn(substep.type)}
                onRemoveSubstepClick={handleRemoveStep}
              />
            );
          } else {
            return null;
          }
        })}
        <button
          className={`add-substep ${canAddMore ? 'show-add' : ''}`}
          onClick={() => addSubstep(step)}
        >
          {t(`partconfigurator:${step.add_key}`)}
        </button>
      </div>
      <DeleteSubstepModal
        open={showRemoveStepAlert}
        confirm={handleRemoveModalConfirm}
        title={deleteModalTitle}
      />
    </>
  );
};
