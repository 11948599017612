import API, {KnestPartsAPI} from './api';

export const fetchProcesses = async params =>
  await KnestPartsAPI.get({
    url: '/parts-processes',
    params,
  });

export const fetchMaterials = async params =>
  await API.get({
    url: '/materials',
    params,
  });

export const fetchAllProcesses = async (params = null) =>
  await KnestPartsAPI.get({
    url: '/processes',
    params,
  });

export const globalSearch = async params =>
  API.get({
    url: '/search',
    params,
  });

export const getCompanyUsers = async include => {
  return KnestPartsAPI.get({
    url: `/companies/current/users`,
    params: {include},
  });
}; 
