import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import '../parts-modals.scss';
const useStyles = makeStyles(styles);

export const DeleteModal = ({ confirm, disabled = true }) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);

  const hideAlert = isConfirmed => {
    if (isConfirmed) {
      confirm(true);
    } else {
      confirm(false);
    }
    setAlert(null);
  };
  const deleteModal = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        closeOnClickOutside={false}
        onConfirm={() => hideAlert(true)}
        onCancel={() => hideAlert(false)}
        confirmBtnText={t('mdl_general_delete')}
        cancelBtnText={t('mdl_general_cancel')}
        confirmBtnCssClass={`${classes.button} ${classes.danger}`}
        cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
        title={t('mdl_delete_title')}
      >
        {t('mdl_delete_description')}
      </SweetAlert>
    );
  };

  return (
    <div>
      {alert}
      <Button variant="outlined" onClick={deleteModal} disabled={disabled}>
        <DeleteIcon />
      </Button>
    </div>
  );
};
