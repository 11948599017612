import React, {Component} from 'react';
import {Button, Container, Grid, IconButton, Paper, Tooltip} from '@mui/material';
import {updateBreadcrumbs} from '../../store/breadcrumbs/breadcrumbsActions';
import {connect} from 'react-redux';
import FilterMenu from '../../components/FilterMenu/FilterMenu';
import {BarChart, TableView, ViewComfy} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroller';
import Loading, {LoadingWithoutHeight} from '../../components/Loading/Loading';
import {withTranslation} from 'react-i18next';
import {NoItemsError} from '../../components/Errors/NoItemsError/NoItemsError';
import {DataGrid} from '@mui/x-data-grid';
import {PartListCard} from '../../components/PartListCard/PartListCard';
import {fetchLists} from '../../services/part-lists';
import {NavLink} from 'react-router-dom';
import StatusChip from '../../components/StatusChip/StatusChip';
import hourglass from 'assets/img/hourglass.gif';
import warning from 'assets/img/warning.gif';

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbs: () => dispatch(
      updateBreadcrumbs({
        level: 0,
        path: window.location.pathname,
        text: 'partlists:section_title',
      }),
    ),
  };
};

const connectPartLists = connect(null, mapDispatchToProps);

class PartLists extends Component {
  constructor(props) {
    super(props);

    this.props.setBreadcrumbs();

    this.filterInputs = [
      {name: 'name', label: 'flt_list_name'},
      {name: 'partNames', label: 'flt_part_name'},
      {name: 'partArticleNrs', label: 'flt_article_number'},
      {name: 'partDrawingNrs', label: 'flt_drawing_number'},
      {name: 'maRunning', label: 'flt_ma_running', type: 'boolean'},
      {name: 'maComplete', label: 'flt_ma_completed', type: 'boolean'},
      {name: 'maPartiallyComplete', label: 'flt_ma_partially_complete', type: 'boolean'},
      {name: 'maRejected', label: 'flt_ma_rejected', type: 'boolean'},
      {name: 'maPartiallyRejected', label: 'flt_ma_partially_rejected', type: 'boolean'},
    ];

    this.defaultDataState = {
      partLists: [],
      page: 0,
      totalPageCount: 0,
      totalItemCount: 0,
    };

    this.state = {
      loading: true,
      showInfiniteScroll: window.localStorage.getItem('showInfiniteScroll') ? window.localStorage.getItem('showInfiniteScroll') === 'true' : false,
      fields: {},
      ...this.defaultDataState,
    };

    window.localStorage.setItem('showInfiniteScroll', this.state.showInfiniteScroll.toString());
  }

  refresh = async () => {
    await this.getPartListTeasersAndUpdateState(1, true);
  };

  componentDidMount() {
    window.addEventListener('filesUploaded', this.refresh);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fields !== this.state.fields ||
      prevState.showInfiniteScroll !== this.state.showInfiniteScroll
    ) {
      try {
        this.setState({
          ...this.defaultDataState,
        }, this.refresh);
      } catch (err) {
        console.error(err);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('filesUploaded', this.refresh);
  }

  getFilter = (page = 1) => {
    const {fields} = this.state;

    const where = {};

    if (fields && Object.keys(fields).length) {
      const or = [];

      const otherFields = Object.keys(fields).filter(fieldName => !this.filterInputs.find(i => i.name === fieldName));
      const stringFields = Object.keys(fields).filter(fieldName => this.filterInputs.find(i => i.name === fieldName)?.type !== 'boolean' && !otherFields.includes(fieldName));
      const booleanFields = Object.keys(fields).filter(fieldName => this.filterInputs.find(i => i.name === fieldName)?.type === 'boolean');

      otherFields.forEach(fieldName => {
        or.push({
          [fieldName]: fields[fieldName],
        });
      });

      stringFields.forEach(fieldName => {
        if (fields[fieldName]) {
          or.push({
            [fieldName]: {ilike: `%${fields[fieldName].trim()}%`},
          });
        }
      });

      booleanFields.forEach(fieldName => {
        or.push({
          [fieldName]: true,
        });
      });

      where.OR = or;
    }

    return {
      filter: {
        limit: 25,
        orderBy: [{updatedAt: 'desc'}, {id: 'desc'}],
        page,
        ...where?.length || where?.OR?.length && {where},
      },
    };
  };

  getPartListTeasersAndUpdateState = async (page = 1, refresh = false) => {
    if (refresh) {
      this.setState({loading: true});
    }

    const {data: {page: partLists, pagination: {totalPageCount, totalItemCount, currentPage}}} = await fetchLists(this.getFilter(page), true);

    this.setState({
      partLists: refresh ? partLists : this.state.showInfiniteScroll ? [...this.state.partLists, ...partLists] : partLists,
      page: partLists.length > 0 ? currentPage : 0,
      totalPageCount,
      totalItemCount,
      loading: false,
    });
  };

  toggleView = () => {
    window.localStorage.setItem('showInfiniteScroll', (!this.state.showInfiniteScroll).toString());

    this.setState({
      showInfiniteScroll: !this.state.showInfiniteScroll,
      loading: true,
      ...this.defaultDataState,
    });
  };

  onSelect = (partId, newState) => {
    this.setState({
      selectedPartIds: newState ? [...this.state.selectedPartIds, partId] : this.state.selectedPartIds.filter(id => id !== partId),
    });
  };

  render() {
    const {t, history} = this.props;

    return (
      <Container maxWidth={false}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} container justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
            <Grid item>
              <Button
                variant={'outlined'}
                startIcon={this.state.showInfiniteScroll ? <TableView /> : <ViewComfy />}
                onClick={this.toggleView}
              >
                {t('common:cmn_toggle_view')}
              </Button>
            </Grid>
            <Grid item>
              <FilterMenu
                search={fields => this.setState({fields: fields || {}})}
                inputs={this.filterInputs}
                history={history}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{height: 'calc(100vh - 276px)'}}>
            {this.state.loading && this.state.showInfiniteScroll ? <Loading /> : this.state.showInfiniteScroll ? (
              <div style={{height: '100%', maxHeight: '100%', overflowY: 'auto'}}>
                <InfiniteScroll
                  hasMore={this.state.page < this.state.totalPageCount}
                  loadMore={() => this.getPartListTeasersAndUpdateState(this.state.page + 1)}
                  loader={<LoadingWithoutHeight />}
                  useWindow={false}
                >
                  <Grid container>
                    {this.state.partLists.map(partList => (<>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`part-list-${partList.id}`}>
                        <PartListCard list={partList} refresh={this.refresh} />
                      </Grid>
                    </>))}
                    {!this.state.partLists.length ?
                      <Grid item xs={12}>
                        <NoItemsError pageType={'lists'} />
                      </Grid> :
                      <></>}
                  </Grid>
                </InfiniteScroll>
              </div>
            ) : (
              <Paper sx={{height: '100%'}}>
                <DataGrid
                  rows={this.state.partLists}
                  columns={[
                    {field: 'kmsId', headerName: 'ID', flex: 0.25, sortable: false},
                    {
                      field: 'name',
                      headerName: t('part_list_name'),
                      renderCell: params => <NavLink to={`/part-lists/${params.row.id}`}>{params.value}</NavLink>,
                      flex: 1,
                      sortable: false,
                    },
                    {field: 'partCount', headerName: t('header_number_of_parts'), flex: 0.2, sortable: false, align: 'center'},
                    {
                      field: 'status', headerName: t('status'), flex: 0.75, sortable: false,
                      renderCell: params => {
                        return <Grid container spacing={1} justifyContent={'flex-start'} alignItems={'center'}>
                          <Grid item><StatusChip data={params.row} /></Grid>
                          {params.row.maRunning ?
                            <Grid item>
                              <Tooltip title={t('ma_running')}>
                                <img src={hourglass} alt={'hourglass'} style={{width: '18px', height: '18px', alignSelf: 'center'}} />
                              </Tooltip>
                            </Grid> : <></>}
                          {params.row.maRejected || params.row.maPartiallyRejected ?
                            <Grid item>
                              <Tooltip title={t('ma_rejected')}>
                                <img src={warning} alt={'warning'} style={{width: '20px', height: '20px', alignSelf: 'center', marginLeft: '10px'}} />
                              </Tooltip>
                            </Grid> : <></>}
                        </Grid>;
                      },
                    },
                    {
                      field: 'actions', headerName: '', flex: 0.2, sortable: false, align: 'center',
                      renderCell: params => <Tooltip title={t('go_to_analysis')}>
                        <NavLink to={`/part-lists/${params.row.id}/estimate`}>
                          <IconButton size={'small'}><BarChart /></IconButton>
                        </NavLink>
                      </Tooltip>,
                    },
                  ]}
                  page={this.state.page - 1}
                  paginationMode={'server'}
                  pageSize={25}
                  rowCount={this.state.totalItemCount}
                  rowsPerPageOptions={[25]}
                  disableSelectionOnClick
                  onPageChange={page => this.getPartListTeasersAndUpdateState(page + 1)}
                  loading={this.state.loading}
                  disableColumnFilter
                  disableColumnMenu
                />
              </Paper>
            )}
          </Grid>
        </Grid>

      </Container>
    );
  }
}

export default withTranslation(['partlists', 'common'])(connectPartLists(PartLists));
