import React from 'react';
import { Grid } from '@mui/material';
import PartThumbnail from './PartThumbnail/PartThumbnail';
import Loading from 'components/Loading/Loading';
import PartFiles from '../PartFiles/PartFiles';

import '../PartDetails.scss';

export default function PartInformation({ part, attachments }) {
  return part ? (
    <Grid container spacing={4} className={'part-details-info pd-section'}>
      <Grid item md={4}>
        <PartThumbnail attachments={attachments} />
      </Grid>
      <Grid container item spacing={2} md={8} alignContent={'flex-start'}>
        <PartFiles attachments={attachments} id={part.id} />
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
}
