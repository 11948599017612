import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import SweetAlert from 'react-bootstrap-sweetalert';

import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);

export const DeleteAlert = ({
  headerText = null,
  description = null,
  open = false,
  triggerDelete = () => {},
  onCancel = () => {},
  onErrorConfirm = () => {},
  onSuccessConfirm = () => {},
  successText = null,
}) => {
  const { t } = useTranslation('modals');
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await triggerDelete();
      setDeleted(true);
    } catch (err) {
      setError('An error has occurred. Please try again later.');
    }
  };

  const closeAlert = () => {
    setLoading(false);
    setDeleted(false);
    setError(null);
  };

  const handleCancel = () => {
    closeAlert();
    return onCancel();
  };

  const handleErrorConfirm = () => {
    closeAlert();
    return onErrorConfirm();
  };

  const handleSuccessConfirm = () => {
    closeAlert();
    return onSuccessConfirm();
  };

  return (
    <>
      {open && !(deleted || error) && (
        <SweetAlert
          warning
          showCancel
          showConfirm
          onConfirm={handleDelete}
          onCancel={handleCancel}
          confirmBtnText={t('mdl_general_delete')}
          cancelBtnText={t('mdl_general_cancel')}
          confirmBtnCssClass={`${classes.button} ${classes.danger}`}
          cancelBtnCssClass={`${classes.button} ${classes.cancel}`}
          title={''}
          disabled={loading}
        >
          <h3>{headerText}</h3>
          <h5>{description}</h5>
          {loading && (
            <div>
              <br />
              <CircularProgress />
            </div>
          )}
        </SweetAlert>
      )}
      {open && deleted && (
        <SweetAlert
          success
          showConfirm
          onConfirm={handleSuccessConfirm}
          confirmBtnText={'OK'}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
          title={''}
        >
          <h3>{successText}</h3>
        </SweetAlert>
      )}
      {open && error && (
        <SweetAlert
          danger
          showConfirm
          onConfirm={handleErrorConfirm}
          confirmBtnText={'OK'}
          confirmBtnCssClass={`${classes.button} ${classes.default}`}
        >
          {error}
        </SweetAlert>
      )}
    </>
  );
};
