import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Container, Grid} from '@mui/material';
import PaginationLayout from 'components/ItemLists/ItemsListLayouts/PaginationLayout';
import {PartListConfigItems} from 'components/ItemLists/PartListConfigItems/PartListConfigItems';
import Loading from 'components/Loading/Loading';
import {
  getPaginatedPartsForList,
  selectConfig,
  removeConfig,
} from 'containers/PartListDetails/PartListDetailsActions';
import './CloneConfigComponent.scss';

const Component = ({cloneModalMode = false}) => {
  const dispatch = useDispatch();
  const {data, partsForList, loading} = useSelector(state => state.partList);

  const [partFilters] = useState({
    filter: {
      limit: 10,
      order: 'createdAt DESC',
      page: 1,
    },
  });

  const handleListPageChange = event => {
    let pageFilter = {
      listId: data.id,
      filter: {
        ...partFilters.filter,
      },
    };

    switch (event.target.value) {
      case '<<':
        pageFilter.filter.page = partsForList.pagination.currentPage - 1;
        dispatch(getPaginatedPartsForList(pageFilter));
        break;
      case '>>':
        pageFilter.filter.page = partsForList.pagination.currentPage + 1;
        dispatch(getPaginatedPartsForList(pageFilter));
        break;
      default:
        pageFilter.filter.page = event.target.value;
        dispatch(getPaginatedPartsForList(pageFilter));
        break;
    }
  };

  const handleToggleConfigSelect = event => {
    if (event.toggle) {
      dispatch(selectConfig(event.configId));
    } else {
      dispatch(removeConfig(event.configId));
    }
  };

  return (
    <div className="ccfg-wrapper">
      <Container>
        <Grid container>
          <Grid item sm={12}>
            {loading ? (
              <Loading />
            ) : (
              <PaginationLayout
                pagination={partsForList.pagination}
                getPage={handleListPageChange}
              >
                <PartListConfigItems
                  items={partsForList}
                  toggleConfigSelect={handleToggleConfigSelect}
                  selectableConfigs
                  cloneModalMode={cloneModalMode}
                  canAddConfig
                  hideRemove
                />
              </PaginationLayout>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export const PaginatedConfigCardComponent = Component;
