import React, {Component} from 'react';
import './AddToPartListModal.scss';
import {withTranslation} from 'react-i18next';
import {
  Button,
  Checkbox, CircularProgress,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper, TextField,
  Typography,
} from '@mui/material';
import {Close, DynamicFeed} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroller';
import {toDisplayDate} from '../../../utils/datetimeHelpers';
import {assignConfigsToList, assignPartsToList, createList, fetchLists, fetchPartList, rearrangePartConfigs} from '../../../services/part-lists';
import {LoadingWithoutHeight} from '../../Loading/Loading';
import {LoadingButton} from '@mui/lab';
import {withSnackbar} from 'notistack';
import {withTheme} from '@mui/styles';

class AddToPartListModal extends Component {
  constructor (props) {
    super(props);

    this.defaultState = {
      selectedPartListIds: [],
      page: 0,
      totalPageCount: 0,
      totalItemCount: 0,
      saving: false,
    };

    this.state = {
      loading: true,
      createNewPartListModalOpen: false,
      newPartListName: '',
      savingNewList: false,
      ...this.defaultState,
    };
  }

  async componentDidUpdate (prevProps) {
    if (this.props.open && !prevProps.open) {
      // Modal opened
      await this.refresh();
    }
  }

  refresh = async () => {
    await this.getPartListTeasersAndUpdateState(1, true);
  };

  getPartListTeasersAndUpdateState = async (page = 1, refresh = false) => {
    if (refresh) {
      this.setState({loading: true});
    }

    const filter = {
      limit: 12,
      order: 'updatedAt DESC, id DESC',
      page,
    };

    const {data: {page: partLists, pagination: {totalPageCount, totalItemCount, currentPage}}} = await fetchLists({filter});

    this.setState({
      partLists: refresh ? partLists : [...this.state.partLists, ...partLists],
      page: partLists.length > 0 ? currentPage : 0,
      totalPageCount,
      totalItemCount,
      loading: false,
    });
  };

  handleClose = () => {
    this.props.setOpen(false);
    this.setState({
      ...this.defaultState,
    });
  };

  createNewPartList = async () => {
    await this.setState({
      savingNewList: true,
    })
    
    try {
      await createList({name: this.state.newPartListName})
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('mdl_general_error_title'), {
        variant: 'error',
      });
    }
    
    await this.refresh();
    await this.setState({
      savingNewList: false,
      newPartListName: '',
      createNewPartListModalOpen: false,
    });
  };

  addPartsToPartList = async () => {
    const {selectedPartIds, selectedPartConfigIds} = this.props;
    this.setState({saving: true});

    try {
      selectedPartIds?.length && await Promise.all(this.state.selectedPartListIds.map(async listId => {
        await assignPartsToList({
          listId,
          partIds: selectedPartIds,
        });
      }));
      
      selectedPartConfigIds?.length && await Promise.all(this.state.selectedPartListIds.map(async listId => {
        // To rearrange, we need to fetch the configs of that list first
        const {data: {partConfigs}} = await fetchPartList(listId, {filter: {include: 'partConfigs'}}, false);
        
        // Add the new configurations to the list
        await assignConfigsToList({
          listId,
          partConfigIdsList: selectedPartConfigIds,
        });
        
        // Rearrange the configurations
        await rearrangePartConfigs(listId, [
          ...partConfigs.map(config => config.id).filter(id => !selectedPartConfigIds.includes(id)), 
          ...selectedPartConfigIds
        ]);
      }))
    } catch (err) {
      console.error(err);
      this.props.enqueueSnackbar(this.props.t('mdl_general_error_title'), {
        variant: 'error',
      });

      return;
    }

    this.props.enqueueSnackbar(this.props.t('mdl_parts_assigned_to_lists_success'), {
      variant: 'success',
    });
    this.handleClose();
  };

  handleCheckboxChange = (partListId, checked) => {
    this.setState({
      selectedPartListIds: checked ?
        [...this.state.selectedPartListIds, partListId] :
        this.state.selectedPartListIds.filter(id => id !== partListId),
    });
  };

  render () {
    const {t} = this.props;

    return <>
      <Modal open={this.props.open} closeAfterTransition>
        <Fade in={this.props.open}>
          <div className={'add-to-part-list-modal'}>
            <Paper className={'add-to-part-list-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-add-to-part-list-modal-button'}
                onClick={this.handleClose}
              >
                <Close />
              </IconButton>
              <Grid
                container
                rowSpacing={3}
                justifyContent={'center'}
                alignContent={'space-between'}
                alignItems={'center'}
                className={'add-to-part-list-modal-content'}
              >
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant={'h5'}>
                    <b>{t('mdl_list_title')}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                  <Button variant={'contained'} onClick={() => this.setState({createNewPartListModalOpen: true})}>
                    {t('mdl_list_create_button')}
                  </Button>
                </Grid>
                <Grid item xs={12} container sx={{height: 'calc(100% - 180px)'}}>
                  {this.state.loading ? <LoadingWithoutHeight /> :
                    <div style={{height: '100%', maxHeight: '100%', width: '100%', overflowY: 'scroll'}}>
                      <InfiniteScroll
                        hasMore={this.state.page < this.state.totalPageCount}
                        loadMore={() => this.getPartListTeasersAndUpdateState(this.state.page + 1)}
                        loader={<Grid container alignItems={'center'} justifyContent={'center'} spacing={2} sx={{padding: '8px 0px'}}>
                          <Grid item><CircularProgress size={30} /></Grid>
                          <Grid item><Typography variant={'body2'}>{t('mdl_mam_moving')}</Typography></Grid>
                        </Grid>}
                        useWindow={false}
                      >
                        <Grid container rowSpacing={2}>
                          {this.state.partLists.map(partList => (<>
                            <Grid item xs={12} container spacing={2} alignItems={'center'} justifyContent={'center'} key={`part-list-${partList.id}`}>
                              <Grid item xs={2} textAlign={'center'}>
                                <DynamicFeed />
                              </Grid>
                              <Grid item xs={8}>
                                <Typography variant={'body1'}>{partList.name}</Typography>
                                <Typography variant={'body2'}>{toDisplayDate(partList.createdAt)}</Typography>
                                <Typography variant={'body2'}>{`${partList.partCount} ${t('partlists:number_of_parts')}`}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Checkbox
                                  checked={this.state.selectedPartListIds.includes(partList.id)}
                                  onChange={({target: {checked}}) => this.handleCheckboxChange(partList.id, checked)}
                                />
                              </Grid>
                            </Grid>
                          </>))}
                          {!this.state.partLists.length ?
                            <Grid item xs={12} textAlign={'center'}>
                              <Typography variant={'body1'} sx={{padding: '24px 0'}}>
                                {t('mdl_list_no_lists')}
                              </Typography>
                            </Grid> :
                            <></>}
                        </Grid>
                      </InfiniteScroll>
                    </div>}
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                  <LoadingButton
                    variant={'contained'}
                    onClick={this.addPartsToPartList}
                    disabled={!this.state.selectedPartListIds.length}
                    loading={this.state.saving}
                  >
                    {t('mdl_general_confirm')}
                  </LoadingButton>
                </Grid>
              </Grid>
              <Modal open={this.state.createNewPartListModalOpen} closeAfterTransition>
                <Fade in={this.state.createNewPartListModalOpen}>
                  <Paper className={'create-part-list-nested-modal'}>
                    <Grid container rowSpacing={2} justifyContent={'center'} className={'create-part-list-nested-modal-content'}>
                      <Grid item xs={12}>
                        <TextField 
                          fullWidth
                          placeholder={t('mdl_rename_list_name')}
                          value={this.state.newPartListName}
                          onChange={({target: {value}}) => this.setState({newPartListName: value})}
                        />
                      </Grid>
                      <Grid item xs={12} container spacing={2} justifyContent={'flex-end'}>
                        <Grid item>
                          <Button 
                            variant={'contained'}
                            color={'secondary'}
                            onClick={() => this.setState({createNewPartListModalOpen: false})}
                          >
                            {t('mdl_general_cancel')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <LoadingButton 
                            variant={'contained'}
                            onClick={this.createNewPartList}
                            loading={this.state.savingNewList}
                            disabled={!this.state.newPartListName?.length}
                          >
                            {t('mdl_list_create_button')}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Fade>
              </Modal>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </>;
  }
}

export default withTheme(withSnackbar(withTranslation(['modals', 'partlists'])(AddToPartListModal)));