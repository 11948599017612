var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import API, { KnestPartsAPI } from './api';
const baseUrl = id => `/part-configs/${id}`;
// CONFIG
export const cancelManufacturingAnalysis = (configId, params) => {
    return API.delete({
        url: `${baseUrl(configId)}/manufacturing-analysis`,
        params,
    });
};
export const triggerManufacturingAnalysis = (configId) => __awaiter(void 0, void 0, void 0, function* () {
    yield API.post({
        url: `${baseUrl(configId)}/manufacturing-analysis`,
    });
});
export const createEmptyConfig = (partId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.post({
        url: '/part-configs',
        data: {
            partId,
        },
    });
});
export const requestConfigurationSupport = (id, partListId, message) => {
    const data = { message, partListId: undefined };
    if (partListId) {
        data.partListId = partListId;
    }
    return API.post({
        url: `${baseUrl(id)}/configuration-support`,
        data,
    });
};
export const putBatchSize = (configId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.put({
        url: `/part-configs/${configId}/batch-sizes`,
        data,
    });
});
export const deleteBatchSize = (configId_1, batchSizeId_1, ...args_1) => __awaiter(void 0, [configId_1, batchSizeId_1, ...args_1], void 0, function* (configId, batchSizeId, force = false) {
    return yield API.delete({
        url: `/part-configs/${configId}/batch-sizes/${batchSizeId}?force=${force}`,
    });
});
// FEATURES VALUES
export const updatePartConfig = (id_1, payload_1, ...args_2) => __awaiter(void 0, [id_1, payload_1, ...args_2], void 0, function* (id, payload, force = false) {
    return yield KnestPartsAPI.patch({
        url: `/part-configs/${id}?force=${force}`,
        data: payload,
    });
});
/**
 * @description Save/update the values from forms about the (post)process
 * @param {*} configId
 * @param {*} partConfigProcessId
 * @param {*} formValues
 */
export const updateProcessFeaturesValues = (configId, partConfigProcessId, formValues) => __awaiter(void 0, void 0, void 0, function* () {
    return API.put({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}/process-feature-values`,
        data: Object.assign({}, formValues),
    });
});
/**
 * @description Get (post)process form values
 * @param {*} configId
 * @param {*} partConfigProcessId
 */
export const getProcessFeaturesValues = (configId, partConfigProcessId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.get({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}/process-feature-values`,
        params: {},
    });
});
export const deleteProcess = (configId, partConfigProcessId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield API.delete({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}`,
        data: { id: configId, processId: partConfigProcessId },
    });
});
export const deletePartConfig = (partConfigId) => __awaiter(void 0, void 0, void 0, function* () {
    return API.delete({
        url: baseUrl(partConfigId),
    });
});
export const getPartConfig = (partConfigId, params) => __awaiter(void 0, void 0, void 0, function* () {
    return API.get({
        url: baseUrl(partConfigId),
        params,
    });
});
export const createPartConfigProcess = (configId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return KnestPartsAPI.post({
        url: `${baseUrl(configId)}/part-config-processes`,
        data,
    });
});
export const updatePartConfigProcess = (configId, partConfigProcessId, data) => __awaiter(void 0, void 0, void 0, function* () {
    return KnestPartsAPI.patch({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}`,
        data,
    });
});
export const upsertProcessFeatureValue = (configId, partConfigProcessId, processFeatureTypeKey, value) => __awaiter(void 0, void 0, void 0, function* () {
    return KnestPartsAPI.put({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}/process-feature-values`,
        data: {
            processFeatureTypeKey,
            value,
        },
    });
});
export const deletePartConfigProcess = (configId, partConfigProcessId) => __awaiter(void 0, void 0, void 0, function* () {
    return KnestPartsAPI.delete({
        url: `${baseUrl(configId)}/part-config-processes/${partConfigProcessId}`,
    });
});
